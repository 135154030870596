import { FC, useState } from 'react';
import {
  Button,
  Form,
  Input,
  notification,
  Popconfirm,
  Select,
} from 'antd';
import { ThumbsDown, ThumbsUp } from 'lucide-react';
import { ConfirmationPopupContainer, Container } from './styles';
import { rejectPoeReasons } from '../constants';
import { map } from 'lodash';
import { changeUserPoeStatusBulk } from '@/services/admin-user.service';

type Args = {
  userIds: string[];
  onCompleted?: () => void;
};

const ApprovalButtons: FC<Args> = ({ userIds, onCompleted }) => {
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState({
    reject: false,
    approve: false,
  });
  const [rejectTag, setRejectTag] = useState('');

  
  const onRejectConfirmed = async (values: any) => {
    
    setLoading({ reject: true, approve: false });
    const { status, totalUsers} = await changeUserPoeStatusBulk(userIds, "reject", values['rejectedText'], rejectTag);
    if (status) {
      setOpen(false);
      form.resetFields();
      onCompleted?.();

      if (totalUsers > 0) {
        notification.success({
          message: 'Success',
          description: `${totalUsers} POE rejection are requested, processing may take time due to queue.`,
        });
      } else {
        notification.warning({
          message: 'Success',
          description: `No POE rejection are requested, please try again later.`,
        });
      }
    } else {
      notification.error({
        message: 'Error',
        description: `Error rejecting POE.`,
      });
    }

    setLoading({ reject: false, approve: false });
  };

  const onApproveConfirmed = async (values: any) => {
    setLoading({ reject: false, approve: true });
    const { status = false, totalUsers = 0 } = await changeUserPoeStatusBulk(userIds, "approve", '', '');
    if (status) {
      setOpen(false);
      form.resetFields();
      onCompleted?.();

      if (totalUsers > 0) {
        notification.success({
          message: 'Success',
          description: `${totalUsers} POE approval are requested, processing may take time due to queue.`,
        });
      } else {
        notification.warning({
          message: 'Success',
          description: `No POE approval are requested, please try again later.`,
        });
      }
    } else {
      notification.error({
        message: 'Error',
        description: `Error approving POE.`,
      });
    }

    setLoading({ reject: false, approve: false });
  };

  return (
    <Container>
      <Popconfirm
        open={open}
        overlayInnerStyle={{
          padding: 24,
          paddingTop: 16,
        }}
        okText={
          userIds.length > 1
            ? `Reject ${userIds.length} POEs`
            : 'Reject POE'
        }
        cancelButtonProps={{
          size: 'large',
        }}
        okButtonProps={{
          danger: true,
          disabled: !form.validateFields,
          size: 'large',
          loading: loading.reject,
        }}
        onConfirm={() => {
          form.submit();
        }}
        onCancel={() => setOpen(false)}
        title="Reject Confirmation"
        description={
          <ConfirmationPopupContainer>
            <Form
              form={form}
              onFinish={onRejectConfirmed}
              onValuesChange={(changedValues: any, allValues) => {
                const { rejectedBy } = changedValues;
                if (rejectedBy) {
                  // @ts-ignore
                  const selectedReason = rejectPoeReasons[String(rejectedBy)];
                  const rejectTag = selectedReason ? selectedReason.tag : '';
                  setRejectTag(rejectTag);
                  form.setFieldsValue({
                    rejectedText: selectedReason ? selectedReason.suggest : '',
                  });
                }
              }}
            >
              <Form.Item
                name="rejectedBy"
                rules={[{ required: true, message: 'Please select a reason' }]}
              >
                <Select allowClear showSearch placeholder={'Select a reason'} >
                  {map(rejectPoeReasons, (reason, key) => (
                    <Select.Option value={key} key={`option-key-${key}`}>
                      {reason.menu}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="rejectedText"
                rules={[
                  { required: true, message: 'Please enter the description'  },
                ]}
              >
                <Input.TextArea rows={4} showCount allowClear/>
              </Form.Item>

            </Form>
          </ConfirmationPopupContainer>
        }
        icon={false}
      >
        <Button
          onClick={() => {
            form.resetFields();
            setOpen(true);
          }}
          size={'large'}
          icon={<ThumbsDown size={16} />}
          className={'reject'}
          loading={loading.reject}
        >
          {userIds.length > 1
          ? `Reject ${userIds.length} POEs`
          : 'Reject POE'}
        </Button>
      </Popconfirm>
      <Button
        size={'large'}
        icon={<ThumbsUp size={16} />}
        className={'approve'}
        onClick={onApproveConfirmed}
        loading={loading.approve}
      >
        {userIds.length > 1
          ? `Approve ${userIds.length} POEs`
          : 'Approve POE'}
      </Button>
    </Container>
  );
};

export default ApprovalButtons;
