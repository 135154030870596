export const rejectPoeReasons = {
  notValid: {
    menu: 'Not Valid format',
    suggest: 'Not a valid document format',
    tag: 'not_valid_format',
  },
  notReadable: {
    menu: 'Not Readable',
    suggest: 'Not a readable document',
    tag: 'not_readable',
  },
  invalidName: {
    menu: 'Invalid Name',
    suggest: 'The provided name does not match our records',
    tag: 'invalid_name',
  },
  wrongStore: {
    menu: 'POE from Incorrect Store',
    suggest:
      'The proof of employment is not associated with your designated store',
    tag: 'wrong_store',
  },
  invalidDate: {
    menu: 'Invalid Contract Date',
    title: 'The contract date appears to be invalid or missing',
    tag: 'invalid_date',
    suggest:
      'The contract date appears to be invalid or missing. Kindly submit the most recent contract to proceed',
  },
  other: {
    menu: 'Other Issue',
    suggest: 'Feel free to provide more details or contact us for assistance',
    tag: 'other',
  },
};
