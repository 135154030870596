import { TabsContainer, TabsControlContainer } from '../../Migration/styles';
import { Switch, Case } from 'react-if';
import { Card, Table, Tabs, Typography } from 'antd';

import { useTable } from '@/hooks';
import { CompanyReadType } from '../../types';
import BrandCompanyTable from '../Table/Brand';
import MemberCompanyTable from '../Table/Member';

interface AssociatedDataCompanyTabProps {
  id: string | null | undefined;
  currentTab: string;
  setCurrentTab: (currentTab: string) => void;
}

const AssociatedDataCompanyTab = ({
  id,
  currentTab,
  setCurrentTab,
}: AssociatedDataCompanyTabProps) => {
  return (
    <>
      <TabsControlContainer>
        <Tabs
          items={[
            {
              key: 'tab-brands',
              label: 'Brands',
            },
            {
              key: 'tab-members',
              label: 'Members',
            },
          ]}
          onChange={(tab: string) => setCurrentTab(tab)}
        />
      </TabsControlContainer>

      <TabsContainer>
        <Switch>
          <Case condition={currentTab === 'tab-brands'}>
            <BrandCompanyTable id={id} />
          </Case>
          <Case condition={currentTab === 'tab-members'}>
            <MemberCompanyTable id={id} />
          </Case>
        </Switch>
      </TabsContainer>
    </>
  );
};

export default AssociatedDataCompanyTab;
