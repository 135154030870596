import { handleError, httpGet } from './util/axios';

export const getListDetails = async (navigate: any, listName: string) => {
  try {
    return httpGet('queue/api', `admin/list/${listName}/details`);
  } catch (error: any) {
    handleError(error, navigate);
  }
};

export const getJobDetails = async (jobId: string) => {
  try {
    return httpGet('queue/api', `admin/job/${jobId}/details`);
  } catch (error: any) {
    handleError(error);
  }
};

export const resendPendingJobs = async (listName: string) => {
  try {
    return httpGet('queue/api', `admin/list/${listName}/resend-pending-jobs`);
  } catch (error: any) {
    handleError(error);
  }
};

export const resendJobToKafka = async (jobId: string) => {
  try {
    return httpGet('queue/api', `admin/job/${jobId}/resend`);
  } catch (error: any) {
    handleError(error);
  }
};

export const reactivateJob = async (jobId: string) => {
  try {
    return httpGet('queue/api', `admin/job/${jobId}/reactivate`);
  } catch (error: any) {
    handleError(error);
  }
};

export const getQueueTotals = async () => {
  try {
    return httpGet('queue/api', `admin/totals`);
  } catch (error: any) {
    handleError(error);
  }
};
