export const rejectReasons = {
  duplicated: {
    menu: 'Duplicate receipt',
    title: 'The receipt is a duplicate',
    suggest: 'Whoops! This receipt was submitted before!',
  },
  notReadable: {
    menu: 'Receipt not readable',
    title: 'The receipt is not entirely readable',
    suggest:
      'Unfortunately, we could not read everything on the receipt. Please submit again!',
  },
  wrongStore: {
    menu: 'Receipt from wrong store',
    title: 'The receipt is not from your store',
    suggest:
      'Uh-oh! It looks like this receipt was not from your store. Please submit again!',
  },
  invalidDate: {
    menu: 'Invalid date',
    title: 'The receipt is outside the date range of the contest',
    suggest:
      'Whoops! The contest had not begun yet. Please only submit receipts from the start of the contest onward.',
  },
  incorrectAmount: {
    menu: 'Incorrect amount',
    title: 'The amount entered does not seem right',
    suggest:
      'Oh-oh, it looks like the amount you entered does not correspond to the product sold.',
  },
  inelegibleProduct: {
    menu: 'Ineligible product',
    title: 'The product is ineligible ',
    suggest: 'Sadly, this product is not part of that contest!',
  },
  productNotFound: {
    menu: 'Product not found',
    title: 'The product was not found on the receipt',
    suggest:
      'Oh! We could not find the item on the receipt. Please submit again!',
  },
  incomplete: {
    menu: 'Incomplete',
    title: 'This submission is incomplete',
    suggest:
      'Whoops! Not all the fields were completed so we could not accept this submission.',
  },
  other: {
    menu: 'Other',
    title: 'This submission is invalid',
    suggest: '',
  },
};
