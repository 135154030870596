import { Spin } from 'antd';
import styled from 'styled-components';

const Content = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .ant-spin-dot {
    font-size: 40px;
    width: 2.5em;
    height: 2.5em;

    .ant-spin-dot-item {
      width: 50px;
      height: 50px;
    }
  }
`;

const LoadingPage = () => {
  return (
    <Content>
      <Spin tip="Loading..." />
    </Content>
  );
};

export default LoadingPage;
