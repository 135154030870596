import { Card, Table, Typography } from 'antd';

import { useTable } from '@/hooks';
import { CompanyReadType } from '@/pages/Company/types';
import { startCase } from 'lodash';

interface MemberCompanyTableProps {
  id: string | null | undefined;
}

const MemberCompanyTable = ({ id }: MemberCompanyTableProps) => {
  const { tableProps, refresh } = useTable<CompanyReadType>({
    service: 'brand/read',
    path: `admin/company/${id}/members`,
    defaultSort: {
      field: 'fullName',
      order: 'ascend',
    },
    perPage: 10,
  });

  return (
    <Table {...tableProps} rowKey={'id'}>
      <Table.Column
        title="Identifier"
        dataIndex="identifier"
        key="identifier"
        sorter
        fixed="left"
        render={(text: any, record: any, index: number) => {
          return (
            <Typography.Text ellipsis copyable>
              {record.id}
            </Typography.Text>
          );
        }}
      />
      <Table.Column
        title="Name"
        dataIndex="name"
        key="name"
        sorter
        fixed="left"
        render={(text: any, record: any, index: number) => {
          return <Typography.Text ellipsis>{record.fullName}</Typography.Text>;
        }}
      />
      <Table.Column
        title="Email"
        dataIndex="email"
        key="email"
        sorter
        fixed="left"
        render={(text: any, record: any, index: number) => {
          return <Typography.Text ellipsis>{record.email}</Typography.Text>;
        }}
      />
      <Table.Column
        title="Role"
        dataIndex="role"
        key="role"
        sorter
        fixed="left"
        render={(text: any, record: any, index: number) => {
          return (
            <Typography.Text ellipsis>{startCase(record.role)}</Typography.Text>
          );
        }}
      />
    </Table>
  );
};

export default MemberCompanyTable;
