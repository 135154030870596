import { Form } from 'antd';
import styled from 'styled-components';

export const FormContainer = styled.div`
  margin-top: 1rem;
`;

export const FormButtons = styled(Form.Item)`
  margin-top: 2rem;

  .ant-form-item-control-input-content {
    display: flex;
    gap: 0.5rem;
    justify-content: flex-end;
  }
`;
