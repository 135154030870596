import styled from 'styled-components';
import { Button, Drawer, Modal } from 'antd';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;

  .table-filter-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .search-input {
      width: 400px;
    }
  }
`;

export const CommentModal = styled(Modal)`
  .body {
    padding: 0;
  }
`;

export const TableCommentButton = styled(Button)`
  font-size: 18px;
  position: relative;
  z-index: 10;

  svg {
    width: 24px;
    height: 24px;
    color: #6f56bc;
  }
`;

export const TableDateFilterContent = styled.div`
  padding: 8px 16px 16px;

  .title-filter {
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 8px;
  }

  .actions {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
    column-gap: 8px;

    button {
      flex: 1;
    }
  }
`;

export const AdvancedFilterContent = styled.div`
  width: 300px;
  padding-top: 8px;

  .ant-divider-horizontal {
    margin: 6px 0 0;
    color: #d6d6d6;

    svg {
      margin-top: 2px;
    }
  }

  .footer {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
  }
`;
