import { FC, Key, useEffect, useState } from 'react';
import {
  Avatar,
  Badge,
  Button,
  Col,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Table,
  Tag,
  Typography,
} from 'antd';
import { useTable } from '@/hooks';
import { Brand } from '@/types';
import { IoPlanetOutline } from 'react-icons/io5';
import { TableContainer } from '@/styles';
import { ModalFooter } from '@/components/BrandPickerModal/styles';
import { filter, isFunction } from 'lodash';

type BrandPickerProps = {
  onSelect: (
    selectedIds: string[],
    brands: Brand[],
    /**
     * Used along with the {@link BrandPickerProps.storeId `storeId`} prop
     * to assign brands to the store as approved or blocked. This parameter only
     * exists when the `storeId` prop is provided.
     */
    action?: 'approve' | 'block',
  ) => void;
  selected: string[];
  multiple?: boolean;
  onClose: () => void;
  hideInactive?: boolean;
  removeSelected?: boolean;
  onSelectBrands?: (brands: Brand[]) => void;
  /**
   * Used to filter only brands that are not connected to the store with the
   * provided ID (i.e. brands that are not approved, blocked or pending).
   *
   * This prop also affects the parameters passed to the
   * {@link BrandPickerProps.onSelect `onSelect`} prop, and changes the footer
   * buttons to be used to approve or block the selected brands.
   */
  storeId?: string;
  /** Forces a specific type filter to be used when filtering the list of brands
   * and hides the type filter selector. */
  forceTypeFilter?: 'all' | 'brand' | 'retailer';
};

type BrandPickerModalProps = BrandPickerProps & {
  open: boolean;
};

const BrandPicker: FC<BrandPickerProps> = ({
  selected,
  onSelect,
  onSelectBrands,
  onClose,
  multiple,
  hideInactive,
  removeSelected,
  storeId,
  forceTypeFilter,
}) => {
  const [filters, setFilters] = useState({
    text: '',
    type: forceTypeFilter || 'all',
  });
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);

  useEffect(() => {
    setSelectedRowKeys(selected);
  }, [selected]);

  const { tableProps } = useTable<Brand>({
    service: 'brand/read',
    path: `admin/list`,
    requestType: 'post',
    perPage: 10,
    params: {
      status: 'approved',
      selected,
      filters: {
        text: filters.text,
        type: filters.type,
        hideInactive,
        removeSelected,
        storeId,
      },
    },
  });

  const onChangeSelection = (
    brandIds: string[],
    /** @see {@link BrandPickerProps.onSelect `onSelect`} `type` param */
    action?: 'approve' | 'block',
  ) => {
    const brands = filter(tableProps?.dataSource, (brand: Brand) => {
      return brandIds.includes(brand.id);
    });

    onSelect(brandIds, brands, storeId ? action : undefined);

    if (onSelectBrands) {
      onSelectBrands(brands);
    }
  };

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      {storeId && (
        <Typography.Paragraph
          style={{ margin: 'unset' }}
          type="secondary"
          ellipsis={{ expandable: 'collapsible' }}
        >
          This list only includes brands that are not approved, blocked or
          pending. To approve, block, or unlink brands that are approved,
          pending, or blocked, please use the "Brands" tab on this page.
        </Typography.Paragraph>
      )}

      <Row gutter={16}>
        <Col span={forceTypeFilter ? 24 : 16}>
          <Input.Search
            placeholder="Search by brand name"
            allowClear
            onSearch={(value) => {
              setFilters((prev) => ({ ...prev, text: value }));
            }}
            style={{ width: '100%' }}
          />
        </Col>
        {!forceTypeFilter && (
          <Col span={8}>
            <Select
              style={{ width: '100%' }}
              optionFilterProp="children"
              value={filters.type}
              onChange={(value) =>
                setFilters((prev) => ({ ...prev, type: value }))
              }
              options={[
                {
                  value: 'all',
                  label: 'Showing All',
                },
                {
                  value: 'retailer',
                  label: 'Showing retailers Only',
                },
                {
                  value: 'brand',
                  label: 'Showing brands Only',
                },
              ]}
            />
          </Col>
        )}
      </Row>

      <TableContainer>
        <Table
          {...tableProps}
          pagination={{
            ...tableProps.pagination,
            showSizeChanger: false,
          }}
          rowKey="id"
          size="small"
          onRow={(record: Brand) => ({
            onClick: () => {
              if (!multiple) {
                setSelectedRowKeys([record.id]);
                return;
              }

              if (selectedRowKeys.includes(record.id)) {
                setSelectedRowKeys((prev) =>
                  prev.filter((key) => key !== record.id),
                );
              } else {
                setSelectedRowKeys([...selectedRowKeys, record.id]);
              }
            },
          })}
          rowSelection={{
            selectedRowKeys,
            type: multiple ? 'checkbox' : 'radio',
            preserveSelectedRowKeys: true,
            hideSelectAll: false,
            onChange: (keys) => {
              setSelectedRowKeys(keys);
            },
          }}
        >
          <Table.Column
            title="Brand"
            dataIndex="name"
            key="name"
            sorter
            render={(text, record: Brand) => {
              return (
                <Space.Compact>
                  <Avatar
                    src={record.logoUrl}
                    style={{ marginRight: 10 }}
                    size={44}
                    shape="square"
                    icon={<IoPlanetOutline />}
                  />

                  <Space.Compact
                    direction="vertical"
                    style={{
                      display: 'flex',
                      alignItems: 'flex-start',
                      justifyContent: 'center',
                    }}
                  >
                    <Typography.Text
                      className="name"
                      ellipsis
                      strong
                      style={{ fontSize: 16, lineHeight: '22px' }}
                    >
                      {record.name}
                    </Typography.Text>
                    <div style={{ display: 'flex' }}>
                      {record.isManaged ? (
                        <Tag
                          color="#ff8000"
                          style={{
                            fontSize: 10,
                            fontWeight: 300,
                            padding: '0 4px',
                            borderRadius: 4,
                          }}
                        >
                          Retailer
                        </Tag>
                      ) : (
                        <Tag
                          color="purple"
                          style={{
                            fontSize: 10,
                            fontWeight: 300,
                            padding: '0 4px',
                            borderRadius: 4,
                          }}
                        >
                          Brand
                        </Tag>
                      )}
                      {record.isInactive && (
                        <Tag
                          color="red"
                          style={{
                            fontSize: 10,
                            fontWeight: 300,
                            padding: '0 4px',
                            borderRadius: 4,
                          }}
                        >
                          Inactive
                        </Tag>
                      )}
                    </div>
                  </Space.Compact>
                </Space.Compact>
              );
            }}
          />
        </Table>
      </TableContainer>

      {storeId ? (
        <ModalFooter>
          <Button
            type="primary"
            danger
            disabled={selectedRowKeys.length === 0}
            onClick={() => {
              onChangeSelection(
                selectedRowKeys.map((key) => key.toString()),
                'block',
              );
              onClose();
            }}
          >
            Block
          </Button>

          <Button
            type="primary"
            disabled={selectedRowKeys.length === 0}
            onClick={() => {
              onChangeSelection(
                selectedRowKeys.map((key) => key.toString()),
                'approve',
              );
              onClose();
            }}
          >
            Approve
          </Button>
        </ModalFooter>
      ) : (
        <ModalFooter>
          <Button onClick={() => onClose()}>Cancel</Button>

          <Button
            type="primary"
            onClick={() => {
              onChangeSelection(selectedRowKeys.map((key) => key.toString()));
              onClose();
            }}
          >
            Confirm
          </Button>
        </ModalFooter>
      )}
    </Space>
  );
};

const BrandPickerModal: FC<BrandPickerModalProps> = ({
  open,
  onClose,
  onSelect,
  selected,
  multiple = false,
  hideInactive = false,
  removeSelected = false,
  storeId,
  forceTypeFilter,
  onSelectBrands,
}) => {
  return (
    <Modal
      title={multiple ? 'Select Brands' : 'Select Brand'}
      open={open}
      onCancel={onClose}
      centered
      footer={null}
      destroyOnClose
    >
      <BrandPicker
        selected={selected}
        onSelect={onSelect}
        multiple={multiple}
        onClose={onClose}
        hideInactive={hideInactive}
        removeSelected={removeSelected}
        storeId={storeId}
        forceTypeFilter={forceTypeFilter}
        onSelectBrands={onSelectBrands}
      />
    </Modal>
  );
};

export default BrandPickerModal;
