import { registerAuditUser } from '@/services/admin-user.service';
import { useSession } from '@/store/session';
import { getObjectDiff } from '@/utils';

type AuditArgs = {
  target?: string;
  data?: object;
  changed?: object;
  entityId?: string;
};

export type AuditForm = AuditArgs & {
  action: 'access' | 'click' | 'create' | 'update' | 'delete';
  userId: string;
  date: Date;
  browserAgent?: string;
  resourceName: string;
};

type Props = {
  resourceName: string;
};

export const useAudit = ({ resourceName }: Props) => {
  const session = useSession();

  const saveTransaction = async (audit: any) => {
    const sessionState = session.state.get();

    if (!sessionState.isLoggedIn || !sessionState.user) {
      console.error(`[useAudit] user is not logged in`);
      return;
    }

    const data: AuditForm = {
      userId: sessionState.user.id,
      date: new Date(),
      action: audit.action,
      resourceName,
      target: audit.target,
      data: audit.data,
      changed: audit.changed,
      browserAgent: navigator.userAgent,
      entityId: audit.entityId,
    };

    if (audit.action === 'update') {
      const _data = audit.data || {};
      const _changed = audit.changed || {};
      data.changed = getObjectDiff(_data, _changed);
    }

    await registerAuditUser(data);
  };

  const onAccess = () => {
    return saveTransaction({
      action: 'access',
    });
  };

  const onClick = (args: AuditArgs) => {
    return saveTransaction({
      ...args,
      action: 'click',
    });
  };

  const onCreate = (args: AuditArgs) => {
    return saveTransaction({
      ...args,
      action: 'create',
    });
  };

  const onUpdate = (args: AuditArgs) => {
    return saveTransaction({
      ...args,
      action: 'update',
    });
  };

  const onDelete = (args: AuditArgs) => {
    return saveTransaction({
      ...args,
      action: 'delete',
    });
  };

  const onDetail = (args: AuditArgs) => {
    return saveTransaction({
      ...args,
      action: 'detail',
    });
  };

  const onSearch = (args: AuditArgs) => {
    return saveTransaction({
      ...args,
      action: 'search',
    });
  };

  const onPaginate = (args: AuditArgs) => {
    return saveTransaction({
      ...args,
      action: 'paginate',
    });
  };

  return {
    onAccess,
    onClick,
    onCreate,
    onUpdate,
    onDelete,
    onDetail,
    onSearch,
    onPaginate,
  };
};
