export enum BrandLanguageEnum {
  FRENCH = 'fr',
  ENGLISH = 'en',
}

export enum CompanyTypeEnum {
  TEAM = 'team',
  RETAILER = 'retailer',
}

export type BrandType = {
  id: string;
  name: string;
  language: BrandLanguageEnum;
  hidden?: false;
  private?: false;
  exclusive?: false;
  notifyAllUsers?: false;
  logoUrl?: string;
  logAsBase64?: string;
};

export type OwnerType = {
  id: string;
  fullName: string;
  email: string;
  password?: string;
};

export type CompanyReadType = {
  id: string;
  name: string;
  type: CompanyTypeEnum;
  alias: string;
  isDeleted?: boolean;
  totalBrands?: number;
  totalMembers?: number;
  createdAt?: string;
  updatedAt?: string;
};
export type CompanyWriteType = {
  id: string;
  name: string;
  type: CompanyTypeEnum;
  alias: string;
  managedBrand: BrandType;
  owner: OwnerType;
  isDeleted?: boolean;
  totalBrands?: number;
  totalMembers?: number;
  brands?: object[];
  members?: object[];
  createdAt?: string;
  updatedAt?: string;
};
