import { WebUser } from "@/types";
import { Modal, Space, Typography } from "antd";
import { BrandProps } from "../BrandTab";

type Props = {
  isOpen: boolean;
  brand: BrandProps | null;
  user: WebUser;
  onClose: () => void;
  onConfirm: () => void;
};

export function ConfirmUnlinkModal({ brand, user, isOpen, onClose, onConfirm }: Props) {
  return (
    <Modal
      title="Confirm Unlink Brand"
      open={isOpen}
      centered
      okButtonProps={{ type: 'default', danger: true }}
      cancelButtonProps={{ type: 'primary' }}
      okText="Confirm"
      onOk={onConfirm}
      onCancel={onClose}
    >
      <Space direction="vertical" size={8} style={{ paddingTop: 24, paddingBottom: 24 }}>
        <Typography.Text>
          You’re about to unlink <b>{user?.fullName}</b> from <b>{brand?.name}</b>. By doing that you will remove full access to <b>{brand?.name}</b> for <b>{user.fullName}</b>, they won’t be able to access any campaigns from this brand on the mobile app.
        </Typography.Text>
        <Typography.Text>
          Make sure to confirm this action or to <span style={{ color: '#FF4D4F' }}>cancel if this is a mistake.</span>
        </Typography.Text>
      </Space>
    </Modal>
  );
}