import Comments from './Comments';
import { Modal } from 'antd';

type Args = {
  open: boolean;
  onClose: () => void;
  walletId: string;
  type: 'consumer' | 'retailer';
};

const CommentModal = ({ open, onClose, walletId, type }: Args) => {
  return (
    <Modal
      title={'Cashout Request Comments'}
      open={open}
      onCancel={onClose}
      destroyOnClose
      footer={null}
      width={580}
      centered
    >
      {open && walletId && <Comments walletId={walletId} type={type} />}
    </Modal>
  );
};

export default CommentModal;
