import { useNavigate, useParams } from 'react-router-dom';
import { getJobDetails } from '@/services/queue.service';
import { useEffect, useState } from 'react';
import { Descriptions, Space, Tag } from 'antd';
import PageHeader from '@/components/PageHeader';
import Layout from '@/components/Layout';
import { formatISODate } from '@/utils';
import { useAudit } from '@/hooks';

const Job = () => {
  const { id, jobId } = useParams();

  const [details, setDetails] = useState({
    id: '',
    listName: '',
    status: '',
    duration: 0,
    engineEndpoint: '',
    callbackEndpoint: '',
    isUnique: true,
    data: {},
    response: {},
    refId: '',
    totalRetries: 0,
    maxRetries: 0,
    isNotified: false,
    origin: '',
    statusChangedAt: 0,
    createdAt: 0,
    updatedAt: 0,
  });

  const navigate = useNavigate();

  const audit = useAudit({
    resourceName: 'dev-queue-job-list',
  });

  useEffect(() => {
    audit.onAccess();
    loadJobDetails(jobId);
  }, []);

  const loadJobDetails = async (jobId: string | undefined) => {
    if (!jobId) {
      return;
    }

    const data: any = await getJobDetails(jobId);

    setDetails((prev) => ({
      ...prev,
      ...data,
    }));
  };

  return (
    <Layout>
      <PageHeader title={`Job: ${jobId}`} />

      <Descriptions
        layout="vertical"
        bordered={false}
        column={1}
        contentStyle={{
          overflow: 'hidden',
          maxWidth: '100%',
        }}
      >
        <Descriptions.Item label="List Name">
          {details.listName}
        </Descriptions.Item>
        <Descriptions.Item label="Status">
          {details.status === 'pending' && <Tag color="grey">Pending</Tag>}
          {details.status === 'success' && <Tag color="green">Success</Tag>}
          {details.status === 'failed' && <Tag color="red">Failed</Tag>}
        </Descriptions.Item>
        <Descriptions.Item label="Duration">
          {details.duration}ms
        </Descriptions.Item>
        <Descriptions.Item label="Request Data">
          <pre style={{ maxWidth: '100%' }}>
            {JSON.stringify(details.data, null, 2)}
          </pre>
        </Descriptions.Item>

        <Descriptions.Item label="Endpoint">
          {details.engineEndpoint}
        </Descriptions.Item>
        <Descriptions.Item label="Unique">
          {details.isUnique ? 'Yes' : 'No'}
          {details.isUnique && <> / Ref: {details.refId}</>}
        </Descriptions.Item>

        <Descriptions.Item label="Date">
          <Space direction="vertical">
            <div className="date-row">
              <strong className="date-label">Created At: </strong>
              <span className="date-value">
                {formatISODate(
                  new Date(details.createdAt * 1000).toISOString(),
                )}
              </span>
            </div>
            {details.statusChangedAt > 0 && (
              <div className="date-row">
                <strong className="date-label">Status Changed: </strong>
                <span className="date-value">
                  {formatISODate(
                    new Date(details.statusChangedAt * 1000).toISOString(),
                  )}
                </span>
              </div>
            )}
            {details.updatedAt > 0 && (
              <div className="date-row">
                <strong className="date-label">Updated At: </strong>
                <span className="date-value">
                  {formatISODate(
                    new Date(details.updatedAt * 1000).toISOString(),
                  )}
                </span>
              </div>
            )}
          </Space>
        </Descriptions.Item>
        <Descriptions.Item label="Response Data">
          <pre>{JSON.stringify(details.response, null, 2)}</pre>
        </Descriptions.Item>
      </Descriptions>
    </Layout>
  );
};

export default Job;
