import { get, reduce, includes } from 'lodash';

export const parseGoogleResult = (result: object) => {
  const address = get(result, 'address_components');

  console.log('address', address);
  const addressObj: any = reduce(
    address,
    (addressObj: any, component) => {
      const types = get(component, 'types');

      if (includes(types, 'route')) {
        addressObj.street = get(component, 'long_name');
      }

      if (includes(types, 'street_number')) {
        addressObj.number = get(component, 'long_name');
      }

      if (
        includes(types, 'locality') ||
        includes(types, 'sublocality_level_1') ||
        includes(types, 'political')
      ) {
        addressObj.city = get(component, 'long_name');
      }

      if (includes(types, 'administrative_area_level_1')) {
        addressObj.state = get(component, 'long_name');
      }

      if (includes(types, 'postal_code')) {
        addressObj.zip = get(component, 'long_name');
      }

      if (includes(types, 'country')) {
        addressObj.country = get(component, 'long_name');
        addressObj.countryCode = get(component, 'short_name');
      }

      return addressObj;
    },
    {
      street: '',
      number: '',
      city: '',
      state: '',
      zip: '',
      country: '',
      countryCode: '',
    },
  );

  addressObj.formattedAddress = get(result, 'formatted_address');
  const _lat: any = get(result, 'geometry.location.lat');
  const _lng: any = get(result, 'geometry.location.lng');

  if (_lat && _lng) {
    addressObj.lat = _lat();
    addressObj.lng = _lng();
  }

  return addressObj;
};
