import { hookstate, useHookstate } from '@hookstate/core';
import { notification } from 'antd';
import { SalesIncentiveCompletion } from '@/types';
import { get } from 'lodash';
import { getCompletionsById } from '@/services/sales-incentive.service';
import { useMutation } from '@tanstack/react-query';

type Mode = 'single' | 'compare';

type DialogState = {
  completion: SalesIncentiveCompletion | null;
  mode: Mode;
  duplications: SalesIncentiveCompletion[];
  open: boolean;
  loading: boolean;
  /** The IDs of the completions that are currently being displayed in the
   * table. Used for going to the next completion when approving/rejecting a
   * completion. */
  currentPageCompletionIds: string[];
};

const dialogState = hookstate<DialogState>({
  completion: null,
  mode: 'single',
  duplications: [],
  open: false,
  loading: false,
  currentPageCompletionIds: [],
});

export const useSubmissionDialog = () => {
  const state = useHookstate(dialogState);

  const { mutate: loadCompletion } = useMutation({
    mutationFn: async (id: string) => {
      const result = await getCompletionsById([id]);
      const completion = result.completions?.[0] as
        | SalesIncentiveCompletion
        | undefined;

      if (!completion) {
        throw new Error('Completion not found');
      }

      return completion;
    },
    onSuccess: (completion) => {
      setTimeout(() => {
        state.completion.set(completion);
      }, 0);
    },
    onError: () => {
      notification.error({
        message: 'Error',
        description: 'Could not get completion data',
      });
    },
  });

  const openDialog = async ({ id }: { id: string }) => {
    resetDialog();

    loadCompletion(id, {
      onSuccess: () => {
        setTimeout(() => {
          state.open.set(true);
        }, 0);
      },
    });
  };

  const reloadCompletion = () => {
    const completion = state.completion.get();
    if (!completion) return;
    loadCompletion(completion.id);
  };

  const setProcessing = () => {
    state.completion.merge({ processing: true });
  };

  const closeDialog = () => {
    state.open.set(false);
  };

  const resetDialog = () => {
    state.completion.set(null);
    state.mode.set('single');
    state.duplications.set([]);
  };

  const switchMode = (mode: Mode) => {
    state.mode.set(mode);

    if (mode === 'compare') {
      loadDuplications();
    }
  };

  const loadDuplications = async () => {
    state.loading.set(true);

    const completionsId = get(
      state.get().completion,
      'analysis.data.identifier.duplicationsId',
      [],
    );

    const { completions = [] } = await getCompletionsById(completionsId);

    state.duplications.set(completions);

    setTimeout(() => state.loading.set(false), 100);
  };

  const setCurrentPageCompletionIds = (ids: string[]) => {
    state.currentPageCompletionIds.set(ids);
  };

  return {
    state: state.get(),
    openDialog,
    closeDialog,
    setProcessing,
    switchMode,
    reloadCompletion,
    setCurrentPageCompletionIds,
  };
};
