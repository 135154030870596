import styled from 'styled-components';
import { Tag } from 'antd';

export const Container = styled.div`
  border: 1px solid #f3f3f3;

  .header {
    padding: 16px 24px;

    .campaign-name {
      font-weight: 500;
      font-size: 16px;
    }
  }
`;

export const CompletionStatusTag = styled(Tag)`
  font-size: 16px;
  display: inline-flex;
  align-items: center;
  column-gap: 8px;
  padding: 6px 12px;

  svg {
  }

  span {
    font-weight: 700;
  }

  &.small {
    font-size: 12px;
    padding: 0 8px;

    svg {
      width: 12px;
      height: 12px;
    }
  }
`;

const BaseContainer = styled.div`
  > .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 56px;
    padding: 0 24px;
    border-bottom: 1px solid #f3f3f3;

    > .title {
      font-size: 18px;
      font-weight: 500;
    }
  }

  > .body {
    padding: 24px;
  }
`;

export const OverviewContainer = styled(BaseContainer)``;

export const PremiumReviewContainer = styled(BaseContainer)`
  > .body {
    .premium-review-timeline {
      .validation {
        .validation-title {
          font-size: 16px;
          font-weight: 600;
        }

        .validation-subtitle {
          font-size: 14px;
          font-weight: 400;
          display: flex;
          align-items: center;
          column-gap: 8px;

          &.failed {
            color: #ff4d4f;
          }

          &.passed {
            color: #52c41a;
          }
        }

        &.failed {
          .validation-title {
            color: #ff4d4f;
          }
        }

        &.passed {
          .validation-title {
            color: #52c41a;
          }
        }
      }
    }
  }
`;

export const AnswersContainer = styled(BaseContainer)`
  .answers-list {
    display: flex;
    flex-direction: column;
    row-gap: 24px;

    .answer-item {
      display: flex;
      align-items: flex-start;
      row-gap: 16px;
      column-gap: 8px;

      .indicator-column {
        width: 40px;
        .indicator {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #f3f3f3;
          font-size: 16px;
        }
      }

      .data-column {
        display: flex;
        flex-direction: column;
        row-gap: 8px;
        width: calc(100% - 48px);

        .answer-title {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          .title {
            font-size: 16px;
            font-weight: 500;
          }
        }

        .answer-data {
          .file:not(.image) {
            background-color: #1d0a31;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 180px;
            height: 180px;
            border-radius: 8px;

            .icon {
              color: white;
              font-size: 62px;
            }

            .overlay {
              background-color: rgba(103, 58, 183, 0.72);
              position: absolute;
              inset: 0;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 8px;
              opacity: 0;

              &:is(:hover, :focus-within) {
                opacity: 1;
              }

              button {
                background-color: unset;
                color: white;
                border: none;
                cursor: pointer;

                svg {
                  width: 32px;
                  height: 32px;
                }

                .ant-spin .ant-spin-dot-item {
                  background-color: white;
                }
              }
            }
          }

          .file-name {
            margin-top: 8px;
          }
        }

        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
`;

export const CommentsContainer = styled(BaseContainer)`
  .body {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
  }

  .comment-list {
    > div {
      padding: 16px 0;

      .comment {
        display: flex;
        flex-direction: column;
        row-gap: 4px;

        .comment-header {
          display: flex;
          align-items: center;
          font-weight: 400;
          font-size: 12px;
          column-gap: 8px;

          .comment-author {
            color: #848484;
            font-weight: 500;
          }

          .comment-date {
            font-size: 14px;

            color: #bfbfbf;
          }
        }

        .comment-body {
          font-size: 14px;
          color: #000000d9;
        }
      }
    }
  }
`;

export const ProductFeedbackContainer = styled(BaseContainer)`
  blockquote {
    position: relative;
    text-align: center;
    padding: 1rem 1.2rem;
    width: 80%; /* create space for the quotes */
    color: #484748;
    margin: 1rem auto 2rem;

    background: linear-gradient(to right, #6f56bc 4px, transparent 4px) 0 100%,
      linear-gradient(to left, #6f56bc 4px, transparent 4px) 100% 0,
      linear-gradient(to bottom, #6f56bc 4px, transparent 4px) 100% 0,
      linear-gradient(to top, #6f56bc 4px, transparent 4px) 0 100%;
    background-repeat: no-repeat;
    background-size: 20px 20px;

    svg {
      position: absolute;
      top: -16px;
      left: -10px;
      transform: rotate(180deg);
      font-size: 3rem;
      color: #6f56bc;
    }
  }
`;
