import { Brand } from '@/types';
import { BrandCellContainer } from './styles';
import { Avatar, Button } from 'antd';
import { IoPlanetOutline } from 'react-icons/io5';

type Props = {
  brand: Brand;
  onRemove: (brandId: string) => void;
  showClose?: boolean;
};

export function BrandCell({ brand, onRemove, showClose = true }: Props) {
  return (
    <BrandCellContainer>
      <Avatar
        size={24}
        src={brand?.logoUrl}
        shape="circle"
        icon={<IoPlanetOutline />}
      />
      <span style={{ paddingRight: showClose ? 0 : 8 }}>{brand.name}</span>
      {showClose && <Button onClick={() => onRemove(brand.id)}>X</Button>}
    </BrandCellContainer>
  );
}
