import Layout from "@/components/Layout";
import PageHeader from "@/components/PageHeader";
import { useAudit } from "@/hooks";
import { getDetails, changeDefaultProfile, deleteUser } from "@/services/web-user.service";
import { RoleDropdown, WebUser } from "@/types";
import { formatISODate } from "@/utils";
import { InfoCircleOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Badge, Button, Descriptions, Popconfirm, Popover, Select, Space, Tabs, Typography, message } from "antd";
import { useEffect, useMemo, useState } from "react";
import { Case, Switch } from "react-if";
import { useNavigate, useParams } from "react-router-dom";
import { BrandTab } from "./BrandTab";
import { TabsContainer, TabsControlContainer, UserPageHeader } from "./styles";
import LoadingPage from "@/pages/Loading";
import { filter } from "lodash";

const Details = () => {
  const { id: userId } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState('tab-brands');
  const [webUser, setWebUser] = useState<WebUser | null>(null);
  const [loading, setLoading] = useState(false);

  const audit = useAudit({
    resourceName: 'web-user',
  });

  useEffect(() => {
    audit.onAccess();
    loadWebUserDetails();
  }, []);

  const loadWebUserDetails = async () => {
    if (!userId) {
      return navigate('/brand/web-users');
    };
    setLoading(true);
    const { webUser } = await getDetails(userId);
    setLoading(false);

    if (!webUser) {
      navigate('/brand/web-users');
    }

    setWebUser(webUser);
  };

  const onChangeDefaultProfile = async (value: string) => {
    audit.onUpdate({ target: 'changeDefaultProfile', entityId: webUser?.id });
    setWebUser((prev) => ({
      ...prev!,
      defaultProfile: value,
    }));

    if (!webUser) return;
    const { status } = await changeDefaultProfile(webUser?.id, value);
    if (status) {
      message.success('Default profile updated successfully');
    } else {
      message.error('Failed to update default profile');
    }
  }

  const handleDeleteUser = async () => {
    audit.onDelete({ target: 'delete', entityId: webUser?.id });
    const { status } = await deleteUser(webUser?.id || '');
    if (status) {
      message.success('User deleted successfully');
      navigate('/brand/web-users');
    } else {
      message.error('Failed to delete user');
    }
  }

  const hasOwnerProfile = useMemo(() => {
    const profile = filter(webUser?.brands, { profile: 'owner' });
    return profile.length > 0;
  }, [webUser]);

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <Layout>
      <PageHeader title="Brand Web User Details">
        <Space>
          {!webUser?.isDeleted && (
            <Popconfirm
              title="Are you certain you wish to delete this user?"
              description="This action cannot be undone."
              onConfirm={() => handleDeleteUser()}
              onCancel={() => { }}
              disabled={hasOwnerProfile}
              okText="Yes"
              cancelText="No"
            >
              <Button disabled={hasOwnerProfile} danger>
                Delete user
              </Button>
            </Popconfirm>
          )}
        </Space>
      </PageHeader>

      <UserPageHeader>
        <div className="main-info">
          <div className="avatar">
            <Badge.Ribbon
              style={{ display: !webUser?.isDeleted ? 'none' : 'block' }}
              color={
                'red'
              }
              text={
                'Deleted User'
              }
            >
              <Avatar
                size={166}
                src={!webUser?.isDeleted ? webUser?.avatarUrl || null : null}
                shape="square"
                icon={<UserOutlined />}
              />
            </Badge.Ribbon>
          </div>
          <div className="info">
            <div className="name-container">
              <Typography.Title level={2}>
                {webUser?.fullName || 'User Name'}
              </Typography.Title>
              <Descriptions>
                <Descriptions.Item label="ID">
                  <Typography.Text copyable>{webUser?.id}</Typography.Text>
                </Descriptions.Item>
              </Descriptions>
            </div>
            <div className="details">
              <Descriptions layout="vertical">
                <Descriptions.Item label="Email">
                  <Typography.Text copyable editable={false}>
                    {webUser?.email || ''}
                  </Typography.Text>
                </Descriptions.Item>
                <Descriptions.Item label="Registration Date">
                  <Typography.Text>
                    {formatISODate(webUser?.createdAt || '')}
                  </Typography.Text>
                </Descriptions.Item>
                <Descriptions.Item label="Deletion Date">
                  <Typography.Text>
                    {formatISODate(webUser?.deletedAt || '')}
                  </Typography.Text>
                </Descriptions.Item>
                <Descriptions.Item label="Default Profile">
                  <Select
                    value={webUser?.defaultProfile || undefined}
                    style={{ width: 200 }}
                    disabled={webUser?.isDeleted}
                    onChange={(value) => onChangeDefaultProfile(value)}
                    placeholder="Select a profile"
                    options={RoleDropdown}
                  />
                  <Popover content={'This is a default value for the next brand linked to this user.'}>
                    <InfoCircleOutlined style={{ marginLeft: '8px', cursor: 'pointer' }} />
                  </Popover>
                </Descriptions.Item>
              </Descriptions>
            </div>
          </div>
        </div>
      </UserPageHeader>

      <TabsControlContainer>
        <Tabs
          items={[
            {
              key: 'tab-brands',
              label: 'Brands',
            },
          ]}
          onChange={(tab: string) => setCurrentTab(tab)}
        />
      </TabsControlContainer>

      <TabsContainer>
        <Switch>
          <Case condition={currentTab === 'tab-brands'}>
            <BrandTab
              user={webUser || {} as WebUser}
            />
          </Case>
        </Switch>
      </TabsContainer>

    </Layout>
  );
}

export default Details;