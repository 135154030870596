import styled from 'styled-components';

export const UserPageHeader = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  padding-top: 8px;

  .main-info {
    display: flex;
    column-gap: 24px;
    align-items: flex-start;

    .avatar {
      width: 166px;

      .ant-avatar-square {
        border-radius: 10px;
      }
    }

    .info {
      width: calc(100% - 190px);
      display: flex;
      flex-direction: column;
      row-gap: 4px;

      .name-container {
        h2 {
          margin-bottom: 0;
        }
      }

      .ant-descriptions-row {
        > th {
          padding-bottom: 0;
        }
      }
    }
  }

  .additional-info {
    .ant-descriptions-view {
      .ant-descriptions-item {
        padding-bottom: 0;
      }
    }

    .earning-info {
      gap: 0 !important;
    }
  }
`;

export const TabsControlContainer = styled.div`
  .ant-tabs-nav {
    margin-bottom: 0;
    padding: 0 8px;

    &::before {
      border: none;
    }
  }
`;

export const TabsContainer = styled.div`
  background-color: #edf3f7;
  padding: 24px;
  border-radius: 10px;
  min-height: 300px;
  max-width: 100%;
`;

export const BrandTabContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const BrandTabItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-top: 8px;
  min-height: 300px;
  width: 100%;
  flex-wrap: wrap;
  align-items: flex-start;
`;

export const BrandTabItem = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 2px;
  border: 1px solid #f0f0f0;
  width: 294px;
  background-color: #fff;
  .content {
    padding: 16px 24px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    border: 1px solid #f0f0f0;

    .item {
      display: flex;
      flex-direction: row;
      gap: 4px;
      .ant-typography {
        color: #00000073;
      }
    }
  }
  .ant-image {
    img {
      object-fit: cover;
    }
  }
  .footer {
    padding: 12px;
    display: flex;
    flex-direction: row;
    gap: 8px;
  }
`;
