import { AiFillFlag } from 'react-icons/ai';
import { BottomKpisContainer } from './styles';
import { FaClipboardList, FaRocket, FaStoreAlt, FaUsers } from 'react-icons/fa';
import { LuWorkflow } from 'react-icons/lu';
import { FC } from 'react';
import { MapData } from '.';

type Props = {
  data: MapData;
  selectedPeriod: number;
};

const BottomKpis: FC<Props> = ({ data, selectedPeriod }) => {
  return (
    <BottomKpisContainer>
      <div className="kpi">
        <div className="title">
          <div className="icon">
            <AiFillFlag />
          </div>
          <div className="text">
            <span className="main">Active Campaigns</span>
          </div>
        </div>
        <div className="value warn">{data.totalActiveCampaigns}</div>
        <div className="time">Now</div>
      </div>
      <div className="kpi">
        <div className="title">
          <div className="icon">
            <FaRocket />
          </div>
          <div className="text">
            <span className="main">Campaigns Launched</span>
          </div>
        </div>
        <div className="value">{data.totalCampaignsLaunched}</div>
        <div className="time warn">Last {selectedPeriod}h</div>
      </div>
      <div className="kpi">
        <div className="title">
          <div className="icon">
            <FaStoreAlt />
          </div>
          <div className="text">
            <span className="main">Affected Stores</span>
            <span className="sub">By campaigns launched</span>
          </div>
        </div>
        <div className="value">{data.campaignAffectedStores}</div>
        <div className="time warn">Last {selectedPeriod}h</div>
      </div>
      <div className="kpi">
        <div className="title">
          <div className="icon">
            <FaUsers />
          </div>
          <div className="text">
            <span className="main">Affected Users</span>
            <span className="sub">By campaigns launched</span>
          </div>
        </div>
        <div className="value">{data.campaignAffectedUsers}</div>
        <div className="time warn">Last {selectedPeriod}h</div>
      </div>
      <div className="kpi">
        <div className="title">
          <div className="icon">
            <FaClipboardList />
          </div>
          <div className="text">
            <span className="main">Active List</span>
            <span className="sub">Queue</span>
          </div>
        </div>
        <div className="value">{data.queueTotalActiveLists}</div>
        <div className="time warn">Last {selectedPeriod}h</div>
      </div>
      <div className="kpi">
        <div className="title">
          <div className="icon">
            <LuWorkflow />
          </div>
          <div className="text">
            <span className="main">Processed Jobs</span>
            <span className="sub">Queue</span>
          </div>
        </div>
        <div className="value">{data.queueTotalProcessedJobs}</div>
        <div className="time warn">Last {selectedPeriod}h</div>
      </div>
    </BottomKpisContainer>
  );
};

export default BottomKpis;
