import { FC, useMemo } from 'react';
import { Case, Switch } from 'react-if';
import { Button, Col, Row, Timeline, Card, Typography, Popover } from 'antd';
import { PremiumReviewContainer } from './styles';
import { find, get, isEmpty } from 'lodash';
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  SplitCellsOutlined,
} from '@ant-design/icons';
import ScanStatusTag from '../../ScanStatusTag';
import { useConsumerSubmissionDialog } from '../dialog-hook';
import { ConsumerCompletion } from '@/types';
import { formatISODate } from '@/utils';
import ReactJson from 'react-json-view';
import type { Entries } from 'type-fest';

import { MagnifiableImage } from '@/components/ImageMagnifier';

type Args = {
  completion: ConsumerCompletion;
};

const PremiumReview: FC<Args> = ({ completion }) => {
  const submissionDialog = useConsumerSubmissionDialog();

  const receiptImageUrl = useMemo<string | null>(() => {
    const answer = find(completion.answers, { type: 'scan_image' });

    return get(answer, 'answer', null);
  }, [completion.answers]);

  const analysis = useMemo(() => {
    const scanData = completion.analysis?.data;
    const isDetected = get(scanData, 'isDetected', false);
    const idDetected = get(scanData, 'identifier.detected', false);
    const duplicated = get(scanData, 'identifier.duplicated', false) as boolean;
    const duplicationsId = get(
      scanData,
      'identifier.duplications',
      [],
    ) as string[];
    const dateDetected = get(scanData, 'date.detected', false);
    const dateValid = get(scanData, 'date.valid', false);
    const addressDetected = get(scanData, 'address.detected', false);

    return {
      receipt: {
        detected: isDetected ? 'passed' : 'failed',
      },
      receiptId: {
        detected: idDetected ? 'passed' : 'failed',
        duplicated,
        duplicationsId,
      },
      date: {
        detected: dateDetected ? 'passed' : 'failed',
        valid: !dateDetected ? 'disabled' : dateValid ? 'passed' : 'failed',
      },
      address: {
        detected: addressDetected ? 'passed' : 'failed',
      },
    } as const;
  }, [completion.analysis?.data]);

  const getColor = (status: 'passed' | 'failed' | 'disabled') => {
    switch (status) {
      case 'passed':
        return '#52c41a';
      case 'failed':
        return '#FF4D4F';
      case 'disabled':
        return 'default';
      default:
        return 'default';
    }
  };

  const getIcon = (status: 'passed' | 'failed' | 'disabled') => {
    switch (status) {
      case 'passed':
        return <CheckCircleOutlined style={{ fontSize: 22 }} />;
      case 'failed':
        return <ExclamationCircleOutlined style={{ fontSize: 22 }} />;
      case 'disabled':
        return <ExclamationCircleOutlined style={{ fontSize: 22 }} />;
      default:
        return <ExclamationCircleOutlined style={{ fontSize: 22 }} />;
    }
  };

  const premiumReviewTimelineItems = useMemo(() => {
    const analysisEntries = Object.entries(analysis) as Entries<
      typeof analysis
    >;

    return analysisEntries.map((entry) => {
      const result = { type: entry[0], ...entry[1] } as {
        [K in keyof typeof analysis]: (typeof analysis)[K] & { type: K };
      }[keyof typeof analysis];

      return {
        color: getColor(result.detected),
        dot: getIcon(result.detected),
        children: (
          <div className={`validation ${result.detected}`}>
            {result.type === 'receipt' && (
              <>
                {result.detected === 'passed' ? (
                  <div className="validation-title">Receipt detected</div>
                ) : (
                  <div className="validation-title">Receipt not detected</div>
                )}
              </>
            )}

            {result.type === 'receiptId' && (
              <>
                {result.detected === 'passed' ? (
                  <div className="validation-title">
                    Receipt number detected
                  </div>
                ) : (
                  <div className="validation-title">
                    Receipt number not detected
                  </div>
                )}

                {result.detected === 'passed' && (
                  <div
                    className={`validation-subtitle ${
                      result.duplicated ? 'failed' : 'passed'
                    }`}
                  >
                    <Typography.Text copyable strong>
                      {get(completion, 'analysis.data.identifier.value', '')}
                    </Typography.Text>

                    {result.duplicated &&
                      submissionDialog.state.mode === 'single' &&
                      submissionDialog.state?.completion?.id ===
                        completion.id && (
                        <Button
                          icon={<SplitCellsOutlined />}
                          type="primary"
                          className="compare"
                          size="small"
                          onClick={() => submissionDialog.switchMode('compare')}
                        >
                          Compare
                        </Button>
                      )}
                  </div>
                )}
              </>
            )}

            {result.type === 'address' && (
              <>
                {result.detected === 'passed' ? (
                  <div className="validation-title">
                    Dealer address detected
                  </div>
                ) : (
                  <div className="validation-title">
                    Dealer address not detected
                  </div>
                )}

                <div className="validation-subtitle">
                  {get(completion, 'analysis.data.address.fullAddress', '')}
                </div>
              </>
            )}

            {result.type === 'date' && (
              <>
                {result.detected === 'passed' ? (
                  <div className="validation-title">Date detected</div>
                ) : (
                  <div className="validation-title">Date not detected</div>
                )}

                {result.detected === 'passed' && (
                  <div
                    className={`validation-subtitle ${result.valid}`}
                    style={{
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                    }}
                  >
                    <div>
                      Receipt Date:{' '}
                      {formatISODate(
                        get(completion, 'analysis.data.date.value', {}),
                      )}
                    </div>
                    <div>
                      Campaign Start Date:{' '}
                      {formatISODate(get(completion, 'bundle.launchedAt', {}))}
                    </div>
                    {!!completion.bundle.endDate && (
                      <div>
                        Campaign Finish Date:{' '}
                        {formatISODate(get(completion, 'bundle.endDate', {}))}
                      </div>
                    )}
                  </div>
                )}
              </>
            )}
          </div>
        ),
      };
    });
  }, [analysis, completion, submissionDialog]);

  return (
    <PremiumReviewContainer>
      <div className="header">
        <span className="title">Premium Review Result</span>

        <ScanStatusTag completion={completion} />
      </div>
      <div className="body">
        <Switch>
          <Case
            condition={
              ['toReview', 'good'].includes(completion.scanState) &&
              !isEmpty(completion.analysis)
            }
          >
            <Row gutter={2}>
              <Col span={9}>
                <Card title="Receipt">
                  <MagnifiableImage
                    src={receiptImageUrl || ''}
                    alt="Receipt"
                    enablePreview
                  />
                </Card>
              </Col>
              <Col span={15}>
                <Card
                  title={
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <span>Analysis</span>
                      <Popover
                        trigger="click"
                        title="Response Payload"
                        content={
                          <div
                            style={{
                              width: '600px',
                              maxWidth: '100%',
                              height: '360px',
                              overflow: 'auto',
                            }}
                          >
                            <ReactJson
                              src={completion.rawAnalysis || {}}
                              theme="monokai"
                            />
                          </div>
                        }
                      >
                        <Button>Hive Response</Button>
                      </Popover>
                    </div>
                  }
                >
                  <Timeline
                    className="premium-review-timeline"
                    items={premiumReviewTimelineItems}
                  />
                </Card>
              </Col>
            </Row>
          </Case>
          <Case condition={completion.scanState === 'pending'}>
            <div>Scan is pending</div>
          </Case>
          <Case condition={completion.scanState === 'failed'}>
            <div>Scan failed</div>
          </Case>
          <Case condition={completion.scanState === 'trying'}>
            <div>Attempts: ${completion.hiveAttempts || 1}</div>
          </Case>
        </Switch>
      </div>
    </PremiumReviewContainer>
  );
};

export default PremiumReview;
