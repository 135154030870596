import { BaseOptionType } from 'antd/es/select';
import { get } from 'lodash';
import { ScoreFilter } from './types';

export const googleKey = get(process.env, 'REACT_APP_GOOGLE_API_KEY', '');

export const refreshDataMessages = {
  button: 'Refresh Data',
  title: 'Refresh Data?',
  description: {
    singular:
      'This action will trigger a request to refresh the data of this store (possible conflicting stores, kpis and status). Are you sure?',
    plural:
      'This action will trigger a request to refresh the data of all these stores (possible conflicting stores, kpis and status). Are you sure?',
  },
};

export const actionFilters = [
  'notImported',
  'connected_existing',
  'created',
  'importing',
] as const;

export const scoreFilterOPtions = [
  { label: 'All scores', value: 'all' },
  { label: 'Address not found', value: 'addressNotFound' },
  { label: 'Score < 0.5', value: 'lowScore' },
  { label: 'Score ≥ 0.5 and < 0.75', value: 'mediumScore' },
  { label: 'Score ≥ 0.75', value: 'highScore' },
  { label: 'No conflicts', value: 'noConflict' },
] satisfies (BaseOptionType & {
  value: ScoreFilter;
})[];
