import styled from 'styled-components';

export const TableListName = styled.div`
  display: flex;
  flex-direction: column;

  .list-name {
    font-weight: 700;
    font-size: 16px;
  }

  .last-activity {
    display: flex;
    column-gap: 4px;
    align-items: center;
    font-size: 12px;
    color: #999999;

    .last-activity-value {
      font-weight: 700;
    }
  }
`;

export const TableListActions = styled.div`
  > button {
    width: 40px;
    height: 40px;
    background-color: #fff;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    border-radius: 10px;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    border: none;
    background-color: #6f56bc;
    color: #fff;
  }
`;
