import { ConsumerCompletion } from '@/types';
import { FC } from 'react';
import { ProductFeedbackContainer } from './styles';
import { Quote } from 'lucide-react';
import { When } from 'react-if';
import { Empty } from 'antd';
import { useConsumerSubmissionDialog } from '../dialog-hook';

type Args = {
  completion: ConsumerCompletion;
};

const ProductFeedback: FC<Args> = ({ completion }) => {
  return (
    <ProductFeedbackContainer>
      <div className="body">
        <When condition={completion.feedback}>
          <blockquote>
            <Quote />
            <span>{completion.feedback}</span>
          </blockquote>
        </When>
        <When condition={!completion.feedback}>
          <Empty description={'No feedback provided'} />
        </When>
      </div>
    </ProductFeedbackContainer>
  );
};

export default ProductFeedback;
