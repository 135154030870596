import { Space } from 'antd';
import styled from 'styled-components';

export const ConnectorInfo = styled(Space)`
  .details {
    .name {
      font-size: 18px;
      font-weight: 500;
    }

    .key {
      font-size: 14px;
      color: #999;
    }
  }
`;
