import Layout from '@/components/Layout';
import PageHeader from '@/components/PageHeader';
import {
  Button,
  Col,
  Input,
  Popconfirm,
  Row,
  Space,
  Statistic,
  Table,
  notification,
} from 'antd';
import {
  EyeOutlined,
  MenuUnfoldOutlined,
  ReloadOutlined,
} from '@ant-design/icons';
import { useAudit, useTable } from '@/hooks';
import { TableContainer } from '@/styles';
import { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import { TableListActions, TableListName } from './styles';
import { CgEye } from 'react-icons/cg';
import { getQueueTotals, resendPendingJobs } from '@/services/queue.service';

import { ButtonLink } from '@/components/ButtonLink';

type TableData = {
  id: string;
  name: string;
  lastActivityAt: number;
  totalRetryingJobs: number;
  totalSuccessJobs?: number;
  totalFailedJobs?: number;
};

const Page = () => {
  const [status, setStatus] = useState('approved');
  const [filter, setFilter] = useState('');
  const [resendLoading, setResendLoading] = useState(false);
  const { tableProps, refresh } = useTable<TableData>({
    service: 'queue/api',
    serviceKind: 'service',
    path: `admin/list`,
    params: {
      status,
      filters: {
        text: filter,
      },
    },
    defaultSort: {
      field: 'lastActivityAt',
      order: 'descend',
    },
  });
  const [totals, setTotals] = useState({
    pending: 0,
    success: 0,
    failed: 0,
  });

  const audit = useAudit({
    resourceName: 'dev-queue-list',
  });

  useEffect(() => {
    audit.onAccess();
    getTotals();
  }, []);

  const getTotals = async () => {
    const { pending = 0, success = 0, failed = 0 } = await getQueueTotals();

    setTotals((prev) => ({
      ...prev,
      pending,
      success,
      failed,
    }));
  };

  const onResend = async (listName: string) => {
    setResendLoading(true);
    const { totalJobs = 0 } = await resendPendingJobs(listName);

    setTimeout(() => {
      setResendLoading(false);
      notification.success({
        message: 'Success',
        description: `${totalJobs} jobs added to Kafka producer queue.`,
      });
      refresh();
    }, 1000);
  };

  return (
    <Layout>
      <PageHeader noBackButton title="Queue Lists">
        <Space>
          <Input.Search
            placeholder="Search by list name"
            allowClear
            onSearch={(value) => {
              setFilter(value);
            }}
            style={{ width: 260 }}
          />
          <Button
            onClick={() => {
              getTotals();
              refresh();
            }}
            type="primary"
            icon={<ReloadOutlined />}
          >
            Refresh List
          </Button>
        </Space>
      </PageHeader>

      <Row gutter={16}>
        <Col span={5}>
          <Statistic title="Total Pending" value={totals.pending} />
        </Col>

        <Col span={5}>
          <Statistic title="Total Success" value={totals.success} />
        </Col>

        <Col span={5}>
          <Statistic title="Total Failed" value={totals.failed} />
        </Col>
      </Row>

      <br />
      {/* <Tabs onChange={(activeKey: string) => setStatus(activeKey)}>
        <Tabs.TabPane tab="Approved" key="approved" />
        <Tabs.TabPane tab="Pending" key="pending" />
        <Tabs.TabPane tab="Rejected" key="rejected" />
      </Tabs> */}

      <TableContainer>
        <Table
          {...tableProps}
          rowKey={'id'}
          // onRow={(record, index) => {
          //   return {
          //     onClick: (event) => {
          //       navigate(`/dev/queue/${record.name}`);
          //     }, // click row
          //   };
          // }}
        >
          <Table.Column
            title="List Name"
            dataIndex="name"
            key="name"
            sorter
            render={(text: any, record: TableData, index: number) => {
              let lastActivity: any = '';
              if (record.lastActivityAt) {
                let date = new Date(record.lastActivityAt * 1000).toISOString();
                lastActivity = DateTime.fromISO(date).toRelative();
              }

              return (
                <TableListName>
                  <div className="list-name">{text}</div>

                  {lastActivity && (
                    <div className="last-activity">
                      <div className="last-activity-label">Last Activity</div>
                      <div className="last-activity-value">{lastActivity}</div>
                    </div>
                  )}
                </TableListName>
              );
            }}
          />
          <Table.Column
            title="Total Jobs"
            dataIndex="totalJobs"
            key="totalJobs"
            align="center"
            sorter
          />
          <Table.Column
            title="Pending Jobs"
            dataIndex="totalPendingJobs"
            key="totalPendingJobs"
            align="center"
            sorter
          />
          <Table.Column
            title="Success Jobs"
            dataIndex="totalSuccessJobs"
            key="totalSuccessJobs"
            align="center"
            sorter
          />
          <Table.Column
            title="Failed Jobs"
            dataIndex="totalFailedJobs"
            key="totalFailedJobs"
            align="center"
            sorter
          />

          <Table.Column
            title="Total Retrying Jobs"
            dataIndex="totalRetryingJobs"
            key="totalRetryingJobs"
            align="center"
            sorter
            render={(text: any, record: TableData) => {
              const value =
                record?.totalRetryingJobs !== undefined
                  ? record.totalRetryingJobs
                  : 0;
              return value;
            }}
          />

          <Table.Column
            title="% of Retrying Jobs"
            dataIndex="totalRetryingJobs"
            key="totalRetryingJobs"
            align="center"
            render={(text: any, record: TableData) => {
              const totalRetryingJobs =
                record?.totalRetryingJobs !== undefined
                  ? record.totalRetryingJobs
                  : 0;
              const totalSuccessJobs =
                record?.totalSuccessJobs !== undefined
                  ? record.totalSuccessJobs
                  : 0;
              const totalFailedJobs =
                record?.totalFailedJobs !== undefined
                  ? record.totalFailedJobs
                  : 0;

              if (
                totalRetryingJobs === 0 ||
                totalFailedJobs + totalSuccessJobs === 0
              ) {
                return '0 %';
              } else {
                const percentage =
                  (totalRetryingJobs / (totalSuccessJobs + totalFailedJobs)) *
                  100;
                const formattedPercentage =
                  percentage < 0.01
                    ? percentage.toExponential(2)
                    : percentage.toFixed(2);

                return `${formattedPercentage} %`;
              }
            }}
          />
          <Table.Column
            title=""
            dataIndex="actions"
            key="actions"
            align="center"
            render={(text: any, record: TableData, index: number) => {
              return (
                <Space>
                  <ButtonLink
                    to={`/dev/queue/${record.name}`}
                    type="primary"
                    size="small"
                    icon={<EyeOutlined />}
                    shape="round"
                  />
                  <Popconfirm
                    title="Resend pending jobs?"
                    description="This will add all pending jobs to Kafka producer queue."
                    onConfirm={() => onResend(record.name)}
                    okText="Yes, I'm sure"
                    cancelText="Cancel"
                  >
                    <Button
                      size="small"
                      icon={<MenuUnfoldOutlined />}
                      shape="round"
                    >
                      Resend
                    </Button>
                  </Popconfirm>
                </Space>
              );
            }}
          />
        </Table>
      </TableContainer>
    </Layout>
  );
};

export default Page;
