import styled from 'styled-components';

export const ReviewListInfo = styled.ul`
  padding-left: 20px;
`;

export const ApprovalSectionContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  column-gap: 8px;
  width: 280px;
`;

export const ApprovalSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 12px;

  > .header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
  }

  .instruction-item {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    margin-bottom: 4px;
  }
`;

export const ApprovalFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 8px;
  margin-top: 32px;
  border-top: 1px solid #e8e8e8;
  padding-top: 16px;

  .content-progress {
    max-width: calc(100% - 230px);
    height: 100%;
    display: flex;
    align-items: flex-start;
    margin-right: auto;
    column-gap: 8px;
    flex-direction: column;
    justify-content: center;
    line-height: 100%;
    row-gap: 2px;
  }
`;
