import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  min-height: 100vh;
  background: url(${require('../../static/images/auth-bg.png')});
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed;
  top: 0;
  left: 0;
  row-gap: 20px;

  .logo {
    width: 218px;
    height: 56px;
    margin-left: 14px;
    background: url(${require('../../static/images/white-logo.png')});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }

  .form-container {
    width: 380px;
    min-height: 200px;
    background: #fff;
    border-radius: 10px;
    padding: 20px;

    .form-header {
      font-size: 18px;
      font-weight: 700;
      text-align: center;
      margin-bottom: 20px;
      color: #333;
    }

    > form {
      display: flex;
      flex-direction: column;
      min-height: 120px;

      .button-container {
        margin-top: auto;
        margin-bottom: 0;

        button {
          background-color: #fa8004;
          border-color: #fa8004;
          color: #fff;
        }
      }
    }
  }
`;
