import { FC } from 'react';
import { Case, Switch } from 'react-if';
import { CompletionStatusTag } from './styles';
import {
  CircleDotDashed,
  ThumbsUp,
  ThumbsDown,
  PencilRuler,
  Hourglass,
} from 'lucide-react';
import type { DetailedManagerSubmission } from '@/types';

const StatusTag: FC<{
  status?: DetailedManagerSubmission['form']['status'];
  size?: string;
}> = ({ status = '', size = 'large' }) => {
  return (
    <Switch>
      <Case condition={status === 'draft'}>
        <CompletionStatusTag
          icon={<PencilRuler />}
          color="default"
          className={size}
        >
          Draft
        </CompletionStatusTag>
      </Case>
      <Case condition={status === 'pending'}>
        <CompletionStatusTag
          icon={<CircleDotDashed />}
          color="default"
          className={size}
        >
          Pending Approval
        </CompletionStatusTag>
      </Case>
      <Case condition={status === 'expired'}>
        <CompletionStatusTag icon={<Hourglass />} color="red" className={size}>
          Expired
        </CompletionStatusTag>
      </Case>
      <Case condition={status === 'approved'}>
        <CompletionStatusTag icon={<ThumbsUp />} color="green" className={size}>
          Approved
        </CompletionStatusTag>
      </Case>
      <Case condition={status === 'rejected'}>
        <CompletionStatusTag icon={<ThumbsDown />} color="red" className={size}>
          Rejected
        </CompletionStatusTag>
      </Case>
    </Switch>
  );
};

export default StatusTag;
