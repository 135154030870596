import { MobilePayments } from '@/types';
import { handleError, httpGet, httpPost } from './util/axios';

export const getMobilePayments = async () => {
  try {
    return httpGet('user-wallet/read', 'admin/mobile-payments');
  } catch (error: any) {
    handleError(error);
  }
};

export const saveMobilePayments = async (values: MobilePayments) => {
  console.log({ values });
  try {
    return httpPost('user-wallet/write', 'admin/mobile-payments', { values });
  } catch (error: any) {
    handleError(error);
  }
};
