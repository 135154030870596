import { useMemo, useState } from 'react';
import { Spin } from 'antd';
import styled from 'styled-components';
import type { PinturaDefaultImageWriterResult } from '@pqina/pintura';
import { PinturaEditorModal } from '@pqina/react-pintura';
import { PencilLineIcon } from 'lucide-react';

import { preventCorsErrorFromFileUrl } from '@/utils/file';
import { pinturaEditorRedactConfig } from '@/utils/pintura';

import { ImageMagnifier, useMagnifier } from '@/components/ImageMagnifier';

export type RedactableImageProps = React.ImgHTMLAttributes<HTMLImageElement> & {
  src: string;
  alt: string;
  /** @default false */
  enableMagnifier?: boolean;
  magnifierSize?: number;
  zoomLevel?: number;
  /** Whether the edited image is being saved */
  isSaving: boolean;
  onOpenEdit?: () => void;
  onEdit: (result: PinturaDefaultImageWriterResult) => void;
  onCancel?: () => void;
};

export function RedactableImage({
  enableMagnifier = false,
  magnifierSize,
  zoomLevel,
  isSaving,
  onOpenEdit,
  onCancel,
  onEdit,
  ...imgProps
}: RedactableImageProps) {
  const [isEditorOpen, setIsEditorOpen] = useState(false);

  const corsSafeSrc = useMemo(() => {
    return preventCorsErrorFromFileUrl(imgProps.src);
  }, [imgProps.src]);

  const { hoverableProps, magnifierProps, refs } = useMagnifier({
    src: imgProps.src,
    magnifierSize,
    initialZoomLevel: zoomLevel,
    enabled: enableMagnifier,
  });

  function handleOpenEdit() {
    setIsEditorOpen(true);
    onOpenEdit?.();
  }

  function handleEdit(result: PinturaDefaultImageWriterResult) {
    setIsEditorOpen(false);
    onEdit(result);
  }

  function handleCancel() {
    setIsEditorOpen(false);
    onCancel?.();
  }

  return (
    <>
      <Button
        disabled={isSaving}
        onClick={handleOpenEdit}
        {...hoverableProps}
        ref={refs.setHoverable}
      >
        {/* eslint-disable-next-line jsx-a11y/alt-text */}
        <img {...imgProps} />

        <Overlay data-visible={isSaving}>
          {isSaving ? (
            <Spin />
          ) : (
            <>
              <PencilLineIcon size={14} /> Redact image
            </>
          )}
        </Overlay>
      </Button>

      <ImageMagnifier {...magnifierProps} ref={refs.setMagnifier} />

      {isEditorOpen && (
        <PinturaEditorModal
          {...pinturaEditorRedactConfig}
          src={corsSafeSrc}
          onHide={handleCancel}
          onProcess={handleEdit}
        />
      )}
    </>
  );
}

const Button = styled.button`
  position: relative;
`;

const Overlay = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 0.875rem;
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
  opacity: 0;
  transition: opacity 150ms ease-in-out;

  ${Button}:is(:hover, :focus-visible) &,
  &[data-visible=true] {
    opacity: 1;
  }

  .ant-spin-dot-holder {
    color: white;
  }
`;
