import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Descriptions } from 'antd';
import { getDashboardData } from '@/services/report.service';

const UserStatistics: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [statistics, setStatistics] = useState({
    activelastDay: 0,
    activelastWeek: 0,
    activelastMonth: 0,
    newLastDay: 0,
    newLastWeek: 0,
    newLastMonth: 0,
  });

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    setLoading(true);
    getDashboardData('user-dashboard').then((res) => {
      setStatistics((prev) => ({
        ...prev,
        ...res.data,
      }));

      setLoading(false);
    });
  };

  return (
    <div className="site-statistic-demo-card">
      <Row gutter={16}>
        <Col span={12}>
          <Card loading={loading} title="Active Users">
            <Descriptions layout="vertical">
              <Descriptions.Item label="Last Day">
                <strong>{statistics.activelastDay}</strong>
              </Descriptions.Item>
              <Descriptions.Item label="Last Week">
                <strong>{statistics.activelastWeek}</strong>
              </Descriptions.Item>
              <Descriptions.Item label="Last Month">
                <strong>{statistics.activelastMonth}</strong>
              </Descriptions.Item>
            </Descriptions>
          </Card>
        </Col>
        <Col span={12}>
          <Card loading={loading} title="New Users">
            <Descriptions layout="vertical">
              <Descriptions.Item label="Last Day">
                <strong>{statistics.newLastDay}</strong>
              </Descriptions.Item>
              <Descriptions.Item label="Last Week">
                <strong>{statistics.newLastWeek}</strong>
              </Descriptions.Item>
              <Descriptions.Item label="Last Month">
                <strong>{statistics.newLastMonth}</strong>
              </Descriptions.Item>
            </Descriptions>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default UserStatistics;
