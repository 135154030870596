import { FC, useState } from 'react';
import Layout from '@/components/Layout';
import PageHeader from '@/components/PageHeader';
import { Space, Radio, Badge, Tabs } from 'antd';
import Consumer from './Consumer';
import Retailer from './Retailer';

const Page: FC = () => {
  const [activeTab, setActiveTab] = useState('retailers');
  return (
    <Layout>
      <PageHeader noBackButton title="Cashout Requests" />

      <Tabs
        defaultActiveKey={'consumer-marketing'}
        activeKey={activeTab}
        onChange={(key) => setActiveTab(key)}
        destroyInactiveTabPane
        type="card"
        items={[
          {
            key: 'retailers',
            label: (
              <Space>
                <span>Mobile Users</span>
              </Space>
            ),
            children: <Retailer />,
          },
          {
            key: 'consumers',
            label: (
              <Space>
                <span>Consumers</span>
              </Space>
            ),
            children: <Consumer />,
          },
        ]}
      />
    </Layout>
  );
};

export default Page;
