import { Card, Col, Row, Statistic } from 'antd';
import {
  ArrowDownOutlined,
  CheckOutlined,
  StopOutlined,
} from '@ant-design/icons';

interface ReportKafkaDlqCardsProps {
  topicTotal: number;
  groupIdTotal: number;
  clientIdTotal: number;
  statusFailedTotal: number;
  statusReprocessedTotal: number;
}

const ReportKafkaDlqCards = ({
  topicTotal,
  groupIdTotal,
  clientIdTotal,
  statusFailedTotal,
  statusReprocessedTotal,
}: ReportKafkaDlqCardsProps) => {
  return (
    <>
      <Card title="Summary last 72 hours">
        <Row gutter={16}>
          <Col span={4}>
            <Card bordered={false}>
              <Statistic
                title="Impacted Topics"
                value={topicTotal}
                precision={0}
                valueStyle={{ color: '#cf1322' }}
                prefix={<ArrowDownOutlined />}
              />
            </Card>
          </Col>
          <Col span={4}>
            <Card bordered={false}>
              <Statistic
                title="Impacted Group Id"
                value={groupIdTotal}
                precision={0}
                valueStyle={{ color: '#cf1322' }}
                prefix={<ArrowDownOutlined />}
              />
            </Card>
          </Col>
          <Col span={4}>
            <Card bordered={false}>
              <Statistic
                title="Impacted Client Id"
                value={clientIdTotal}
                precision={0}
                valueStyle={{ color: '#cf1322' }}
                prefix={<ArrowDownOutlined />}
              />
            </Card>
          </Col>
          <Col span={4}>
            <Card bordered={false}>
              <Statistic
                title="Status Failed"
                value={statusFailedTotal}
                precision={0}
                valueStyle={{ color: '#cf1322' }}
                prefix={<StopOutlined />}
              />
            </Card>
          </Col>
          <Col span={4}>
            <Card bordered={false}>
              <Statistic
                title="Status Reprocessed"
                value={statusReprocessedTotal}
                precision={0}
                valueStyle={{ color: '#51BF70' }}
                prefix={<CheckOutlined />}
              />
            </Card>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default ReportKafkaDlqCards;
