import React, { useMemo } from 'react';
import { Tag } from 'antd';
import styled from 'styled-components';
import { startCase } from 'lodash';
import { PiChartLineUp, PiBuildingsBold } from 'react-icons/pi';
import { WiMoonNew } from 'react-icons/wi';
import { CgArrowsExpandUpRight } from 'react-icons/cg';

type Segment = 'growth' | 'emerging' | 'enterprise' | undefined;

interface SegmentTagProps {
  segment: Segment | string | undefined;
}

const segmentConfig = {
  growth: {
    color: 'cyan',
    icon: <PiChartLineUp />,
  },
  emerging: {
    color: 'geekblue',
    icon: <CgArrowsExpandUpRight />,
  },
  enterprise: {
    color: 'orange',
    icon: <PiBuildingsBold />,
  },
  default: {
    color: '',
    icon: <WiMoonNew />,
  },
};

const Container = styled(Tag)`
  display: flex;
  align-items: center;
  column-gap: 6px;
  font-size: 12px;
  padding: 3px 6px;
  border-radius: 4px;
  line-height: 100%;
  flex-shrink: 0;
`;

const SegmentTag: React.FC<SegmentTagProps> = ({ segment }) => {
  const _segment = useMemo(() => {
    if (!segment) {
      return segmentConfig['default'];
    }
    if (segment && Object.keys(segmentConfig).includes(segment)) {
      // @ts-ignore
      return segmentConfig[segment];
    }

    return segmentConfig['default'];
  }, [segment]);

  return (
    <Container color={_segment.color}>
      {_segment.icon}
      {startCase(segment) || 'Default'}
    </Container>
  );
};

export default SegmentTag;
