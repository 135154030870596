import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  .actions {
    button {
      margin-top: 32px;
    }
  }
`;

export const TabsControlContainer = styled.div`
  .ant-tabs-nav {
    margin-bottom: 0;
    padding: 0 8px;

    &::before {
      border: none;
    }
  }
`;

export const TabsContainer = styled.div`
  background-color: #edf3f7;
  padding: 24px;
  border-radius: 10px;
`;

export const PaymentFormContainer = styled.div``;
