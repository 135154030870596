import { FC, useMemo } from 'react';
import { ConsumerCompletion } from '@/types';
import { AnswersContainer } from './styles';
import { find, map, orderBy, startCase } from 'lodash';
import { Tag } from 'antd';
import { Case, Default, Switch } from 'react-if';

import { MagnifiableImage } from '@/components/ImageMagnifier';

type Args = {
  completion: ConsumerCompletion;
};
const Answers: FC<Args> = ({ completion }) => {
  const answers = useMemo(() => {
    const items = map(completion.missionItems, (item) => {
      const answer = find(
        completion.answers,
        (answer) => answer.missionItemId === item.id,
      );

      return {
        ...item,
        answer: answer?.answer || '',
      };
    });

    return orderBy(items, 'order', 'asc');
  }, [completion.answers, completion.missionItems]);

  return (
    <AnswersContainer>
      <div className="body">
        <div className="answers-list">
          {answers.map((item, index) => {
            return (
              <div className="answer-item" key={`item-${item.type}-${index}`}>
                <div className="indicator-column">
                  <div className="indicator">
                    <span>{index + 1}</span>
                  </div>
                </div>
                <div className="data-column">
                  <div className="answer-title">
                    <Tag color="purple">{startCase(item.type)}</Tag>
                    <span className={'title'}>{item.title}</span>
                  </div>

                  <div className={`answer-data ${item.type}`}>
                    <Switch>
                      <Case condition={item.type === 'scan_image'}>
                        <MagnifiableImage
                          style={{ objectFit: 'contain' }}
                          src={item.answer}
                          alt="Receipt"
                          height={180}
                          enablePreview
                        />
                      </Case>
                      <Default>
                        <span>{item.answer}</span>
                      </Default>
                    </Switch>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </AnswersContainer>
  );
};

export default Answers;
