import { FC, useEffect, useState } from 'react';
import { CommentsContainer } from './styles';
import { Avatar, Button, Col, Form, Input, Row, Space } from 'antd';
import {
  getCompletionComments,
  saveCompletionComment,
} from '@/services/sales-incentive.service';
import { UserOutlined } from '@ant-design/icons';
import { DateTime } from 'luxon';
import { useSession } from '@/store';

type Args = {
  completionId: string;
};

type FormArgs = Args & { onSave: () => {} };

const CommentForm: FC<FormArgs> = ({ completionId, onSave }) => {
  const session = useSession();
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
  }, [completionId]);

  const handleSubmit = async (values: any) => {
    await saveCompletionComment(completionId, values.comment);

    onSave();
    form.resetFields();
  };

  const userName = session.state.get().user?.fullName;

  return (
    <Form onFinish={handleSubmit} form={form}>
      <Row justify={'end'}>
        <Col span={2}>
          <Avatar
            size={38}
            src={`https://ui-avatars.com/api/?name=${userName}&length=2&rounded=true`}
            icon={<UserOutlined />}
          />
        </Col>
        <Col span={22}>
          <Form.Item
            name={'comment'}
            rules={[{ required: true, message: 'Please enter a comment' }]}
          >
            <Input.TextArea
              autoSize={{
                minRows: 4,
                maxRows: 8,
              }}
              maxLength={100}
              showCount
            />
          </Form.Item>

          <Form.Item style={{ marginBottom: 0 }}>
            <Button type="primary" htmlType={'submit'}>
              Add Comment
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

const Comments: FC<Args> = ({ completionId }) => {
  const [comments, setComments] = useState([]);

  useEffect(() => {
    loadComments();
  }, []);

  const loadComments = async () => {
    const result = await getCompletionComments(completionId);

    setComments(result.comments);
  };

  const elapsedTime = (date: string) => {
    const _date = DateTime.fromISO(date);

    return _date.toRelative();
  };

  return (
    <CommentsContainer>
      <div className="body">
        <div className="comment-list">
          {comments.map((comment: any, index) => (
            <Row justify={'end'} key={`comment-${comment.id}-${index}`}>
              <Col span={2}>
                <Avatar
                  size={38}
                  src={`https://ui-avatars.com/api/?name=${comment.commentBy.name}&length=2&rounded=true`}
                />
              </Col>
              <Col span={22}>
                <div className="comment">
                  <div className="comment-header">
                    <span className="comment-author">
                      {comment.commentBy.name}
                    </span>
                    <span className="comment-date">
                      {elapsedTime(comment.createdAt)}
                    </span>
                  </div>
                  <div className="comment-body">{comment.comment}</div>
                </div>
              </Col>
            </Row>
          ))}
        </div>

        <CommentForm completionId={completionId} onSave={loadComments} />
      </div>
    </CommentsContainer>
  );
};

export default Comments;
