import Layout from '@/components/Layout';
import PageHeader from '@/components/PageHeader';
import { TableContainer } from '@/styles';
import { useAudit, useTable } from '@/hooks';
import { useState } from 'react';
import {
  Avatar,
  Space,
  Table,
  Typography,
  Tag,
  Tooltip,
  Dropdown,
  Button,
  Input,
  MenuProps,
} from 'antd';
import { get, map } from 'lodash';
import TagsRender from '@/pages/Brand/components/TagsRender';
import { formatMoney } from '@/utils';
import { MdStorefront } from 'react-icons/md';
import { LockIcon } from 'lucide-react';
import { When } from 'react-if';
import { IoShieldCheckmarkSharp } from 'react-icons/io5';
import { StatusTag } from '@/pages/Store/List/styles';
import { FaAsterisk } from 'react-icons/fa';
import { LuEyeOff } from 'react-icons/lu';
import { Store } from '@/types';
import { PiDotsThreeOutline, PiPencil } from 'react-icons/pi';
import { SiStackedit } from 'react-icons/si';
import { GoVerified } from 'react-icons/go';
import { Link, useNavigate } from 'react-router-dom';
import { useSession } from '@/store';
import BrandPickerModal from '@/components/BrandPickerModal';
import { createDraftStore } from '@/services/store.service';

const Page = () => {
  const navigate = useNavigate();
  const session = useSession();
  const [textFilter, setTextFilter] = useState('');

  const { tableProps, refresh } = useTable<Store>({
    service: 'store/read',
    path: `admin/list`,
    params: {
      filters: {
        text: textFilter,
      },
    },
    defaultSort: {
      field: 'updatedAt',
      order: 'descend',
    },
  });

  const audit = useAudit({
    resourceName: 'store',
  });

  const onCreateDraftStore = async () => {
    const { storeId } = await createDraftStore();

    navigate(`/store/${storeId}/edit`);
  };

  const getTableMenuItems = (store: Store): NonNullable<MenuProps['items']> => {
    const items: NonNullable<MenuProps['items']> = [
      {
        key: `store-menu-manage-${store.id}`,
        label: <Link to={`/store/${store.id}/manage`}>Manage</Link>,
        icon: <SiStackedit size={20} />,
      },
    ];

    if (session.hasPermission('stores.edit')) {
      items.push({
        key: `store-edit-menu-${store.id}`,
        label: <Link to={`/store/${store.id}/edit`}>Edit</Link>,
        icon: <GoVerified size={20} />,
        onClick: () => {
          audit.onClick({
            target: 'store-edit-button',
            data: {
              storeId: store.id,
            },
          });
        },
      });
    }

    return items;
  };

  return (
    <>
      <Layout>
        <PageHeader noBackButton title="Stores">
          <Space>
            <Input.Search
              placeholder="Search by store name or address"
              allowClear
              onSearch={(value) => {
                setTextFilter(value);
              }}
              style={{ width: 360 }}
            />

            <Button type="primary" onClick={() => onCreateDraftStore()}>
              Create Store
            </Button>
          </Space>
        </PageHeader>

        <br />

        <TableContainer>
          <Table {...tableProps} rowKey={'id'}>
            <Table.Column
              title="Store"
              key="name"
              dataIndex={'name'}
              width={350}
              align="left"
              sorter
              ellipsis
              filters={[
                {
                  text: 'Private',
                  value: 'private',
                },
                {
                  text: 'Locked',
                  value: 'locked',
                },
                {
                  text: 'Exclusive',
                  value: 'exclusive',
                },
                {
                  text: 'Hidden',
                  value: 'hidden',
                },
              ]}
              render={(_, record: Store) => {
                return (
                  <Space>
                    <Avatar
                      icon={<MdStorefront style={{ marginTop: 6 }} />}
                      src={record.logoUrl}
                      shape={'square'}
                      size={70}
                    />
                    <Space.Compact
                      direction="vertical"
                      className="info"
                      style={{ marginTop: 4 }}
                    >
                      <Space.Compact>
                        <When condition={record.isPrivate}>
                          <Tooltip title="Private Store">
                            <StatusTag
                              color={'geekblue'}
                              icon={<IoShieldCheckmarkSharp />}
                            />
                          </Tooltip>
                        </When>

                        <When condition={record.isLocked}>
                          <Tooltip title="Locked Store">
                            <StatusTag icon={<LockIcon />} color={'red'} />
                          </Tooltip>
                        </When>

                        <When condition={record.isExclusive}>
                          <Tooltip title="Exclusive Store">
                            <StatusTag icon={<FaAsterisk />} color={'gold'} />
                          </Tooltip>
                        </When>

                        <When condition={record.isHidden}>
                          <Tooltip title="Hidden Store">
                            <StatusTag icon={<LuEyeOff />} color={'default'} />
                          </Tooltip>
                        </When>
                      </Space.Compact>
                      <Typography.Title
                        level={4}
                        ellipsis
                        style={{ marginBottom: 0 }}
                      >
                        {record.name}
                      </Typography.Title>
                      <Typography.Text
                        ellipsis
                        className="address"
                        type={'secondary'}
                        style={{ fontSize: 12 }}
                      >
                        {get(record, 'address.formattedAddress', '')}
                      </Typography.Text>
                    </Space.Compact>
                  </Space>
                );
              }}
            />

            <Table.Column
              title="Industries"
              key="storeIndustries"
              align="center"
              width={200}
              sorter
              render={(_, record: Store) => {
                if (
                  !record.storeIndustries ||
                  record.storeIndustries.length === 0
                ) {
                  return '-';
                }

                return (
                  <TagsRender
                    max={1}
                    items={map(record.storeIndustries, (row) => row.name)}
                  />
                );
              }}
            />

            <Table.Column
              title="Connected Brands"
              key="totalSales"
              dataIndex={'totalSales'}
              align="center"
              sorter
              render={(_, record: Store) => {
                return (
                  <Space.Compact direction={'vertical'}>
                    <Typography.Text ellipsis strong>
                      {record.totalApprovedBrands}
                    </Typography.Text>
                    {record.totalPendingBrands > 0 && (
                      <Typography.Text
                        ellipsis
                        type={'secondary'}
                        style={{ fontSize: 12 }}
                      >
                        Pending: {record.totalPendingBrands}
                      </Typography.Text>
                    )}
                    {record.totalBlockedBrands > 0 && (
                      <Typography.Text
                        ellipsis
                        type={'danger'}
                        style={{ fontSize: 12 }}
                      >
                        Blocked: {record.totalBlockedBrands}
                      </Typography.Text>
                    )}
                  </Space.Compact>
                );
              }}
            />

            <Table.Column
              title="Users"
              key="totalUsers"
              dataIndex="totalUsers"
              align="center"
              sorter
              render={(_, record: Store) => {
                return (
                  <Space.Compact direction={'vertical'}>
                    <Typography.Text ellipsis strong>
                      {record.totalUsers}
                    </Typography.Text>
                    {record.totalPendingUsers > 0 && (
                      <Typography.Text
                        ellipsis
                        type={'secondary'}
                        style={{ fontSize: 12 }}
                      >
                        Pending: {record.totalPendingUsers}
                      </Typography.Text>
                    )}
                  </Space.Compact>
                );
              }}
            />

            <Table.Column
              title="Total Earnings"
              key="totalEarnings"
              dataIndex="totalEarnings"
              align="center"
              sorter
              render={(_, record: Store) => {
                return (
                  <Typography.Text strong>
                    {formatMoney(record.totalEarnings)}
                  </Typography.Text>
                );
              }}
            />

            <Table.Column
              title="Total Sales"
              key="totalSales"
              dataIndex="totalSales"
              align="center"
              sorter
              render={(_, record: Store) => {
                return (
                  <Typography.Text strong>
                    {formatMoney(record.totalSales)}
                  </Typography.Text>
                );
              }}
            />

            <Table.Column
              title="Actions"
              dataIndex="actions"
              key="actions"
              align="right"
              fixed="right"
              width={100}
              render={(text: any, record: Store, index: number) => {
                return (
                  <Dropdown menu={{ items: getTableMenuItems(record) }}>
                    <Button>
                      <PiDotsThreeOutline size={20} />
                    </Button>
                  </Dropdown>
                );
              }}
            />
          </Table>
        </TableContainer>
      </Layout>
    </>
  );
};

export default Page;
