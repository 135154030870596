import React, { useRef, useState } from 'react';
import { Upload, Button, Modal, Form, FormProps, Image, Avatar } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { Container } from './styles';
import { Switch, Case, Default } from 'react-if';
import { BsImageAlt, BsImageFill } from 'react-icons/bs';

interface CropUploadProps {
  value?: string;
  onChange?: (value: string) => void;
}

const CropUpload: React.FC<CropUploadProps> = ({ value, onChange }) => {
  const [previewVisible, setPreviewVisible] = useState(false);
  const [fileList, setFileList] = useState<any[]>([]);
  const cropperRef = useRef<Cropper | any>(null);

  const getCropData = () => {
    const imageElement: any = cropperRef?.current;
    const cropper = imageElement?.cropper;
    const croppedCanvas = cropper.getCroppedCanvas();
    if (croppedCanvas) {
      const croppedData = croppedCanvas.toDataURL();
      if (onChange) {
        onChange(croppedData);
      }
    }
  };

  const uploadProps = {
    fileList,
    beforeUpload: (file: any) => {
      setFileList([file]);
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setFileList([{ thumbUrl: reader.result }]);
        setPreviewVisible(true);
      };
      return false;
    },
    onRemove: () => {
      setFileList([]);
      if (onChange) {
        onChange('');
      }
      return true;
    },
  };

  return (
    <Container>
      <Switch>
        <Case condition={value}>
          <Image src={value} width={200} />
        </Case>
        <Default>
          <Avatar size={200} shape="square" icon={<BsImageAlt />} />
        </Default>
      </Switch>

      <Upload {...uploadProps}>
        <Button icon={<UploadOutlined />}>Upload & Crop</Button>
      </Upload>

      <Modal
        open={previewVisible}
        title="Crop Image"
        onCancel={() => setPreviewVisible(false)}
        onOk={() => {
          getCropData();
          setPreviewVisible(false);
        }}
      >
        <Cropper
          ref={cropperRef}
          src={fileList[0]?.thumbUrl || ''}
          style={{ height: 400, width: '100%' }}
          aspectRatio={1 / 1}
        />
      </Modal>
    </Container>
  );
};

export default CropUpload;
