import Layout from '@/components/Layout';
import PageHeader from '@/components/PageHeader';
import {
  Button,
  Checkbox,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Typography,
  notification,
} from 'antd';
import { FormContainer } from './styles';
import { merge } from 'lodash';
import { useEffect, useState } from 'react';

import LoadingPage from '@/pages/Loading';
import { useAudit } from '@/hooks';
import Upload, { RcFile, UploadFile, UploadProps } from 'antd/es/upload';
import { useNavigate } from 'react-router-dom';
import { createCompany } from '@/services/company.service';
import { CompanyTypeEnum, BrandLanguageEnum, CompanyWriteType } from '../types';
import ImgCrop from 'antd-img-crop';

type FormData = CompanyWriteType;

const CompanyAdd = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [pageLoaded, setPageLoaded] = useState(false);
  const [imageBrandLogoUrl, setImageBrandLogoUrl] = useState('');
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [formData, setFormData] = useState<FormData>({
    id: '',
    name: '',
    type: CompanyTypeEnum.TEAM,
    alias: '',
    managedBrand: {
      id: '',
      name: '',
      language: BrandLanguageEnum.ENGLISH,
      hidden: false,
      notifyAllUsers: false,
      logoUrl: '',
    },
    owner: {
      id: '',
      fullName: '',
      email: '',
    },
  });
  const [form] = Form.useForm();

  const audit = useAudit({
    resourceName: 'company-add',
  });

  useEffect(() => {
    audit.onAccess();
    setTimeout(() => {
      setPageLoaded(true);
    }, 0);
  }, []);

  const onFileChange: UploadProps['onChange'] = async ({
    fileList: newFileList,
  }) => {
    setFileList(newFileList);
    if (newFileList.length >= 0) {
      const file = newFileList[0];
      let imageAsBase64 = file.url as string;
      if (!imageAsBase64) {
        imageAsBase64 = await new Promise((resolve) => {
          const reader = new FileReader();
          reader.readAsDataURL(file.originFileObj as RcFile);
          reader.onload = () => resolve(reader.result as string);
        });
      }
      setImageBrandLogoUrl(imageAsBase64);
    }
  };

  const onPreview = async (file: UploadFile) => {
    let src = file.url as string;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj as RcFile);
        reader.onload = () => resolve(reader.result as string);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  if (!pageLoaded) {
    return <LoadingPage />;
  }

  const cancelEdit = () => {
    audit.onClick({ target: 'cancel-edit' });
    navigate('/company');
  };

  const onFinish = async (dataFromForm: FormData) => {
    setLoading(true);
    if (imageBrandLogoUrl) {
      formData.managedBrand.logAsBase64 = imageBrandLogoUrl;
    }
    const payload = merge({}, formData, dataFromForm);

    const savedData = await createCompany(payload);
    setFormData({ ...savedData });
    audit.onCreate({
      target: 'save',
      data: savedData,
      entityId: savedData.id,
    });
    notification.success({
      message: 'Success',
      description: 'User created successfully',
    });
    navigate(`/company`);
    setLoading(false);
  };

  const handleHiddenCheckboxChange = (e: any) => {
    setFormData({
      ...formData,
      managedBrand: {
        ...formData.managedBrand,
        hidden: e.target.checked,
      },
    });
  };

  const handleNotifyAllUsersCheckboxChange = (e: any) => {
    setFormData({
      ...formData,
      managedBrand: {
        ...formData.managedBrand,
        notifyAllUsers: e.target.checked,
      },
    });
  };

  return (
    <Layout>
      <PageHeader title="Add Company" />

      <FormContainer>
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          initialValues={formData}
          disabled={loading}
        >
          <Divider orientation="left" orientationMargin="0">
            Company{' '}
            {formData.id && (
              <Typography.Text ellipsis copyable style={{ fontSize: 13 }}>
                ({formData.id})
              </Typography.Text>
            )}
          </Divider>
          {formData.createdAt && (
            <>
              <Typography.Text ellipsis style={{ fontSize: 11 }}>
                <strong>Created at:</strong> {formData.createdAt}
              </Typography.Text>
              &nbsp;&nbsp;
              <Typography.Text ellipsis style={{ fontSize: 11 }}>
                <strong>Last Update:</strong> {formData.updatedAt}
              </Typography.Text>
              <br />
              <br />
            </>
          )}

          <Form.Item
            name="name"
            label="Name"
            rules={[{ required: true }]}
            help="Full Name's Company"
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="alias"
            label="Alias"
            rules={[{ required: true }]}
            help="Short Name's Company"
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="type"
            label="Type"
            rules={[{ required: true }]}
            help="Company should access EFR or EFB"
          >
            <Select
              allowClear
              showSearch
              style={{ width: 200 }}
              placeholder="Select a type"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? '').includes(input)
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? '')
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? '').toLowerCase())
              }
              options={[
                {
                  value: '',
                  label: '',
                },
                {
                  value: 'team',
                  label: 'Brand Team',
                },
                {
                  value: 'retailer',
                  label: 'Retailer',
                },
              ]}
            />
          </Form.Item>

          <br />

          <Divider orientation="left" orientationMargin="0">
            Brand{' '}
            {formData.managedBrand?.id && (
              <Typography.Text ellipsis copyable style={{ fontSize: 13 }}>
                ({formData.managedBrand?.id})
              </Typography.Text>
            )}
          </Divider>

          <Form.Item
            name={['managedBrand', 'name']}
            label="Name"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={['managedBrand', 'language']}
            label="Language"
            rules={[{ required: true }]}
          >
            <Select
              allowClear
              showSearch
              style={{ width: 200 }}
              placeholder="Select a language"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? '').includes(input)
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? '')
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? '').toLowerCase())
              }
              options={[
                {
                  value: '',
                  label: '',
                },
                {
                  value: 'en',
                  label: 'English',
                },
                {
                  value: 'fr',
                  label: 'French',
                },
              ]}
            />
          </Form.Item>

          <Row>
            <Form.Item
              name={['managedBrand', 'hidden']}
              valuePropName="checked"
            >
              <Checkbox onChange={handleHiddenCheckboxChange}>
                Hidden Brand
              </Checkbox>
            </Form.Item>

            <Form.Item
              name={['managedBrand', 'notifyAllUsers']}
              valuePropName="checked"
            >
              <Checkbox onChange={handleNotifyAllUsersCheckboxChange}>
                Notify All Users
              </Checkbox>
            </Form.Item>
          </Row>

          <br />
          <ImgCrop rotationSlider>
            <Upload
              listType="picture-card"
              fileList={fileList}
              onChange={onFileChange}
              onPreview={onPreview}
            >
              {fileList.length < 1 && '+ Upload'}
            </Upload>
          </ImgCrop>

          <br />

          <Divider orientation="left" orientationMargin="0">
            Owner{' '}
            {formData.owner?.id && (
              <Typography.Text ellipsis copyable style={{ fontSize: 13 }}>
                ({formData.owner?.id})
              </Typography.Text>
            )}
          </Divider>

          <Form.Item
            label="Name"
            name={['owner', 'fullName']}
            help="User's name. The user can access EFR or EFB with master access"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Email"
            name={['owner', 'email']}
            help="User's email"
            rules={[{ required: true }, { type: 'email' }]}
          >
            <Input />
          </Form.Item>

          <div style={{ textAlign: 'right', marginTop: 32 }}>
            <Form.Item>
              <Button onClick={cancelEdit}>Cancel</Button>
              &nbsp;
              <Button type="primary" htmlType="submit" loading={loading}>
                Save
              </Button>
            </Form.Item>
          </div>
        </Form>
      </FormContainer>
    </Layout>
  );
};

export default CompanyAdd;
