import { AxioRequestError } from '@/errors/axio.request.error';
import {
  NOTIFICATION_DURATION,
  NOTIFICATION_PLACEMENT,
} from '@/utils/notification.config';
import { getAllAppValues, removeAppValues } from '@/utils/storage';
import { notification } from 'antd';
import axios from 'axios';
import { isEmpty } from 'lodash';
import { Service } from './type';
import { getServiceUrl } from './service-url';

export const getHeaders = () => {
  const {
    accessToken = '',
    currentBrandId = '',
    timeZone = 'America/New_York',
    type = 'web',
    companyId = '',
    ...args
  } = getAllAppValues();

  const headers: any = {};
  if (!isEmpty(accessToken)) {
    headers['Authorization'] = `Bearer ${accessToken}`;
  }

  if (!isEmpty(companyId)) {
    headers['CompanyId'] = companyId;
  }

  if (!isEmpty(currentBrandId)) {
    headers['CurrentBrand'] = currentBrandId;
  }

  return headers;
};

export function handleError(error: any, navigate?: any) {
  var statusCode = error.httpStatusCode ? error.httpStatusCode : 500;

  switch (statusCode) {
    case 400: // bad request
      notification.error({
        message: `Bad Request: ${error.message}`,
        placement: NOTIFICATION_PLACEMENT,
        duration: NOTIFICATION_DURATION,
      }); // Redirect to the login page
      break;
    case 401: // Unauthorized
      removeAppValues();
      notification.error({
        message: `Not authorized`,
        placement: NOTIFICATION_PLACEMENT,
        duration: NOTIFICATION_DURATION,
      }); // Redirect to the login page
      navigate('/login'); //Redirect to the login page
      break;
    case 403: // Forbidden
      notification.error({
        message: `You don't have access to this page.`,
        placement: NOTIFICATION_PLACEMENT,
        duration: NOTIFICATION_DURATION,
      });
      navigate('/403'); //Redirect to the 403 page
      break;
    case 404: // Not Found
      notification.error({
        message: `Resource not found.`,
        placement: NOTIFICATION_PLACEMENT,
        duration: NOTIFICATION_DURATION,
      });
      navigate('/404'); // Redirect to the 404 page
      break;
    default:
      console.error('API error:', error);
      notification.error({
        message: 'General Error',
        placement: NOTIFICATION_PLACEMENT,
        duration: NOTIFICATION_DURATION,
      });
      navigate('/500');
      // Handle other errors or show a generic error page
      break;
  }
}

export const httpPost = async (
  service: Service,
  action: string,
  data: any = {},
) => {
  const baseURL = getServiceUrl(service);

  if (!baseURL) {
    throw new Error('Service url definition not found');
  }

  try {
    const response = await axios.post(`${baseURL}/${action}`, data, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error: any) {
    throw new AxioRequestError(error.message, error.response.data.statusCode);
  }
};

export const httpPut = async (
  service: Service,
  action: string,
  data: any = {},
) => {
  const baseURL = getServiceUrl(service);

  if (!baseURL) {
    throw new Error('Service url definition not found');
  }

  try {
    const response = await axios.put(`${baseURL}/${action}`, data, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error: any) {
    console.error(error);
    console.error(error.message);
    var axioRequestError = new AxioRequestError(
      error.message,
      error.response.data.statusCode,
    );
    throw axioRequestError;
  }
};

export const httpGet = async (service: Service, action: string) => {
  const baseURL = getServiceUrl(service);

  if (!baseURL) {
    throw new Error('Service url definition not found');
  }

  try {
    const response = await axios.get(`${baseURL}/${action}`, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

export const httpDelete = async (service: Service, action: string) => {
  const baseURL = getServiceUrl(service);

  if (!baseURL) {
    throw new Error('Service url definition not found');
  }
  try {
    const response = await axios.delete(`${baseURL}/${action}`, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};
