import styled from 'styled-components';

export const UserPageHeader = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  padding-top: 8px;
  margin-bottom: 32px;

  .main-info {
    display: flex;
    column-gap: 24px;
    align-items: flex-start;

    .avatar {
      width: 166px;

      .ant-avatar-square {
        border-radius: 10px;
      }
    }

    .icon-info {
      width: 15px;
      height: 15px;
      font-size: 15px;
      color: #ababab;
    }

    .info {
      width: calc(100% - 190px);
      display: flex;
      flex-direction: column;
      row-gap: 4px;

      .name-container {
        h2 {
          margin-bottom: 0;
        }
      }

      .ant-descriptions-row {
        > th {
          padding-bottom: 0;
        }
      }
    }
  }

  .additional-info {
    .ant-descriptions-view {
      .ant-descriptions-item {
        padding-bottom: 0;
      }
    }

    .earning-info {
      gap: 0 !important;
    }
  }
`;

export const TabsControlContainer = styled.div`
  .ant-tabs-nav {
    margin-bottom: 0;
    padding: 0 8px;

    &::before {
      border: none;
    }
  }
`;

export const TabsContainer = styled.div`
  background-color: #edf3f7;
  padding: 24px;
  border-radius: 10px;
  min-height: 300px;
`;

export const WalletTabContainer = styled.div``;
