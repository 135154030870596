import styled from 'styled-components';

export const Wrapper = styled.button`
  width: 100%;
  height: 66px;
  display: flex;
  align-items: center;
  column-gap: 8px;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  cursor: pointer;

  &:hover {
    border-color: #6f56bc;
    box-shadow: 0 0 0 2px rgba(117, 30, 217, 0.08);
    outline: 0;
  }

  span {
    font-size: 16px;
    color: #333333;
    font-weight: 500;
  }

  .ant-avatar {
    svg {
      font-size: 28px;
      color: #fff;
    }
  }

  &.empty {
    border-style: dashed;
  }
`;
