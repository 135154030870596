import { WebUserBrand } from '@/types';
import { Tag, Popover } from 'antd';
import { Link, useNavigate } from 'react-router-dom';

type Args = {
  items: WebUserBrand[];
  max?: number;
};

const typeColorMap = {
  'Sales Rep': 'purple',
  'No Access': 'error',
  Owner: 'volcano',
  Admin: 'magenta',
  Master: 'success',
  Viewer: 'default',
  'Store Manager': 'warning',
};

const getColor = (type: string) => {
  // @ts-ignore
  return typeColorMap[type] || 'default';
};

const BrandTagsRender = ({ items, max = 3 }: Args) => {
  const randomNumber = new Date().getTime();
  const directTags = items.slice(0, max).map((item, index) => (
    // @ts-ignore
    <Popover content={item.profile}>
      <Link to={`/brand/${item.id}/manage`} target="_blank">
        <Tag
          style={{ cursor: 'pointer', marginBottom: '4px' }}
          key={`${randomNumber}-${item.id}`}
          color={getColor(item.profile)}
        >
          {item.name}
        </Tag>
      </Link>
    </Popover>
  ));

  const extraTags = items.slice(max).map((item, index) => (
    <Tag style={{ minWidth: 36 }} key={index + randomNumber}>
      {item.name}
    </Tag>
  ));

  const popoverContent = (
    <div
      style={{
        maxWidth: 300,
        display: 'flex',
        flexWrap: 'wrap',
        columnGap: 4,
        rowGap: 6,
      }}
    >
      {extraTags}
    </div>
  );

  return (
    <>
      {directTags}
      {extraTags.length > 0 && (
        <Popover content={popoverContent}>
          <Tag>+{extraTags.length} ...</Tag>
        </Popover>
      )}
    </>
  );
};

export default BrandTagsRender;
