import { ReactNode, FC, useEffect } from 'react';
import { Container } from './styles';
import { useLocation } from 'react-router-dom';
import { MdArrowBack } from 'react-icons/md';

type PageHeaderProps = {
  title: string | ReactNode;
  pageName?: string;
  noBackButton?: boolean;
  subtitle?: string;
  children?: ReactNode;
};
const PageHeader: FC<PageHeaderProps> = ({
  title,
  pageName = '',
  subtitle = '',
  children,
  noBackButton = false,
}) => {
  const location = useLocation();

  useEffect(() => {
    let pageTitle = '';
    if (pageName) {
      pageTitle = pageName;
    } else if (typeof title === 'string') {
      pageTitle = title;
    }

    document.title = `ENDVR CRM | ${pageTitle}`;
  }, []);

  return (
    <Container className="page-header">
      {!noBackButton && location.pathname !== '/' && (
        <button className="back-button" onClick={() => window.history.back()}>
          <MdArrowBack color="#6F56BC" />
        </button>
      )}

      <h1>{title}</h1>
      <h2>{subtitle}</h2>

      {children && <div className="actions">{children}</div>}
    </Container>
  );
};

export default PageHeader;
