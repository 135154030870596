export default [
  {
    country: 'Afghanistan',
    currency: 'AFN',
  },
  {
    country: 'Albania',
    currency: 'ALL',
  },
  {
    country: 'Algeria',
    currency: 'DZD',
  },
  {
    country: 'American Samoa',
    currency: 'USD',
  },
  {
    country: 'Andorra',
    currency: 'EUR',
  },
  {
    country: 'Angola',
    currency: 'AOA',
  },
  {
    country: 'Anguilla',
    currency: 'XCD',
  },
  {
    country: 'Antarctica',
    currency: 'XCD',
  },
  {
    country: 'Antigua and Barbuda',
    currency: 'XCD',
  },
  {
    country: 'Argentina',
    currency: 'ARS',
  },
  {
    country: 'Armenia',
    currency: 'AMD',
  },
  {
    country: 'Aruba',
    currency: 'AWG',
  },
  {
    country: 'Australia',
    currency: 'AUD',
  },
  {
    country: 'Austria',
    currency: 'EUR',
  },
  {
    country: 'Azerbaijan',
    currency: 'AZN',
  },
  {
    country: 'Bahamas',
    currency: 'BSD',
  },
  {
    country: 'Bahrain',
    currency: 'BHD',
  },
  {
    country: 'Bangladesh',
    currency: 'BDT',
  },
  {
    country: 'Barbados',
    currency: 'BBD',
  },
  {
    country: 'Belarus',
    currency: 'BYR',
  },
  {
    country: 'Belgium',
    currency: 'EUR',
  },
  {
    country: 'Belize',
    currency: 'BZD',
  },
  {
    country: 'Benin',
    currency: 'XOF',
  },
  {
    country: 'Bermuda',
    currency: 'BMD',
  },
  {
    country: 'Bhutan',
    currency: 'BTN',
  },
  {
    country: 'Bolivia',
    currency: 'BOB',
  },
  {
    country: 'Bosnia and Herzegovina',
    currency: 'BAM',
  },
  {
    country: 'Botswana',
    currency: 'BWP',
  },
  {
    country: 'Bouvet Island',
    currency: 'NOK',
  },
  {
    country: 'Brazil',
    currency: 'BRL',
  },
  {
    country: 'British Indian Ocean Territory',
    currency: 'USD',
  },
  {
    country: 'Brunei',
    currency: 'BND',
  },
  {
    country: 'Bulgaria',
    currency: 'BGN',
  },
  {
    country: 'Burkina Faso',
    currency: 'XOF',
  },
  {
    country: 'Burundi',
    currency: 'BIF',
  },
  {
    country: 'Cambodia',
    currency: 'KHR',
  },
  {
    country: 'Cameroon',
    currency: 'XAF',
  },
  {
    country: 'Canada',
    currency: 'CAD',
  },
  {
    country: 'Cape Verde',
    currency: 'CVE',
  },
  {
    country: 'Cayman Islands',
    currency: 'KYD',
  },
  {
    country: 'Central African Republic',
    currency: 'XAF',
  },
  {
    country: 'Chad',
    currency: 'XAF',
  },
  {
    country: 'Chile',
    currency: 'CLP',
  },
  {
    country: 'China',
    currency: 'CNY',
  },
  {
    country: 'Christmas Island',
    currency: 'AUD',
  },
  {
    country: 'Cocos (Keeling) Islands',
    currency: 'AUD',
  },
  {
    country: 'Colombia',
    currency: 'COP',
  },
  {
    country: 'Comoros',
    currency: 'KMF',
  },
  {
    country: 'Congo',
    currency: 'XAF',
  },
  {
    country: 'Cook Islands',
    currency: 'NZD',
  },
  {
    country: 'Costa Rica',
    currency: 'CRC',
  },
  {
    country: 'Croatia',
    currency: 'HRK',
  },
  {
    country: 'Cuba',
    currency: 'CUP',
  },
  {
    country: 'Cyprus',
    currency: 'EUR',
  },
  {
    country: 'Czech Republic',
    currency: 'CZK',
  },
  {
    country: 'Denmark',
    currency: 'DKK',
  },
  {
    country: 'Djibouti',
    currency: 'DJF',
  },
  {
    country: 'Dominica',
    currency: 'XCD',
  },
  {
    country: 'Dominican Republic',
    currency: 'DOP',
  },
  {
    country: 'East Timor',
    currency: 'USD',
  },
  {
    country: 'Ecuador',
    currency: 'ECS',
  },
  {
    country: 'Egypt',
    currency: 'EGP',
  },
  {
    country: 'El Salvador',
    currency: 'SVC',
  },
  {
    country: 'England',
    currency: 'GBP',
  },
  {
    country: 'Equatorial Guinea',
    currency: 'XAF',
  },
  {
    country: 'Eritrea',
    currency: 'ERN',
  },
  {
    country: 'Estonia',
    currency: 'EUR',
  },
  {
    country: 'Ethiopia',
    currency: 'ETB',
  },
  {
    country: 'Falkland Islands',
    currency: 'FKP',
  },
  {
    country: 'Faroe Islands',
    currency: 'DKK',
  },
  {
    country: 'Fiji Islands',
    currency: 'FJD',
  },
  {
    country: 'Finland',
    currency: 'EUR',
  },
  {
    country: 'France',
    currency: 'EUR',
  },
  {
    country: 'French Guiana',
    currency: 'EUR',
  },
  {
    country: 'French Polynesia',
    currency: 'XPF',
  },
  {
    country: 'French Southern territories',
    currency: 'EUR',
  },
  {
    country: 'Gabon',
    currency: 'XAF',
  },
  {
    country: 'Gambia',
    currency: 'GMD',
  },
  {
    country: 'Georgia',
    currency: 'GEL',
  },
  {
    country: 'Germany',
    currency: 'EUR',
  },
  {
    country: 'Ghana',
    currency: 'GHS',
  },
  {
    country: 'Gibraltar',
    currency: 'GIP',
  },
  {
    country: 'Greece',
    currency: 'EUR',
  },
  {
    country: 'Greenland',
    currency: 'DKK',
  },
  {
    country: 'Grenada',
    currency: 'XCD',
  },
  {
    country: 'Guadeloupe',
    currency: 'EUR',
  },
  {
    country: 'Guam',
    currency: 'USD',
  },
  {
    country: 'Guatemala',
    currency: 'QTQ',
  },
  {
    country: 'Guinea',
    currency: 'GNF',
  },
  {
    country: 'Guinea-Bissau',
    currency: 'CFA',
  },
  {
    country: 'Guyana',
    currency: 'GYD',
  },
  {
    country: 'Haiti',
    currency: 'HTG',
  },
  {
    country: 'Heard Island and McDonald Islands',
    currency: 'AUD',
  },
  {
    country: 'Holy See (Vatican City State)',
    currency: 'EUR',
  },
  {
    country: 'Honduras',
    currency: 'HNL',
  },
  {
    country: 'Hong Kong',
    currency: 'HKD',
  },
  {
    country: 'Hungary',
    currency: 'HUF',
  },
  {
    country: 'Iceland',
    currency: 'ISK',
  },
  {
    country: 'India',
    currency: 'INR',
  },
  {
    country: 'Indonesia',
    currency: 'IDR',
  },
  {
    country: 'Iran',
    currency: 'IRR',
  },
  {
    country: 'Iraq',
    currency: 'IQD',
  },
  {
    country: 'Ireland',
    currency: 'EUR',
  },
  {
    country: 'Israel',
    currency: 'ILS',
  },
  {
    country: 'Italy',
    currency: 'EUR',
  },
  {
    country: 'Ivory Coast',
    currency: 'XOF',
  },
  {
    country: 'Jamaica',
    currency: 'JMD',
  },
  {
    country: 'Japan',
    currency: 'JPY',
  },
  {
    country: 'Jordan',
    currency: 'JOD',
  },
  {
    country: 'Kazakhstan',
    currency: 'KZT',
  },
  {
    country: 'Kenya',
    currency: 'KES',
  },
  {
    country: 'Kiribati',
    currency: 'AUD',
  },
  {
    country: 'Kuwait',
    currency: 'KWD',
  },
  {
    country: 'Kyrgyzstan',
    currency: 'KGS',
  },
  {
    country: 'Laos',
    currency: 'LAK',
  },
  {
    country: 'Latvia',
    currency: 'LVL',
  },
  {
    country: 'Lebanon',
    currency: 'LBP',
  },
  {
    country: 'Lesotho',
    currency: 'LSL',
  },
  {
    country: 'Liberia',
    currency: 'LRD',
  },
  {
    country: 'Libyan Arab Jamahiriya',
    currency: 'LYD',
  },
  {
    country: 'Liechtenstein',
    currency: 'CHF',
  },
  {
    country: 'Lithuania',
    currency: 'LTL',
  },
  {
    country: 'Luxembourg',
    currency: 'EUR',
  },
  {
    country: 'Macao',
    currency: 'MOP',
  },
  {
    country: 'North Macedonia',
    currency: 'MKD',
  },
  {
    country: 'Madagascar',
    currency: 'MGF',
  },
  {
    country: 'Malawi',
    currency: 'MWK',
  },
  {
    country: 'Malaysia',
    currency: 'MYR',
  },
  {
    country: 'Maldives',
    currency: 'MVR',
  },
  {
    country: 'Mali',
    currency: 'XOF',
  },
  {
    country: 'Malta',
    currency: 'EUR',
  },
  {
    country: 'Marshall Islands',
    currency: 'USD',
  },
  {
    country: 'Martinique',
    currency: 'EUR',
  },
  {
    country: 'Mauritania',
    currency: 'MRO',
  },
  {
    country: 'Mauritius',
    currency: 'MUR',
  },
  {
    country: 'Mayotte',
    currency: 'EUR',
  },
  {
    country: 'Mexico',
    currency: 'MXN',
  },
  {
    country: 'Micronesia, Federated States of',
    currency: 'USD',
  },
  {
    country: 'Moldova',
    currency: 'MDL',
  },
  {
    country: 'Monaco',
    currency: 'EUR',
  },
  {
    country: 'Mongolia',
    currency: 'MNT',
  },
  {
    country: 'Montserrat',
    currency: 'XCD',
  },
  {
    country: 'Morocco',
    currency: 'MAD',
  },
  {
    country: 'Mozambique',
    currency: 'MZN',
  },
  {
    country: 'Myanmar',
    currency: 'MMR',
  },
  {
    country: 'Namibia',
    currency: 'NAD',
  },
  {
    country: 'Nauru',
    currency: 'AUD',
  },
  {
    country: 'Nepal',
    currency: 'NPR',
  },
  {
    country: 'Netherlands',
    currency: 'EUR',
  },
  {
    country: 'Netherlands Antilles',
    currency: 'ANG',
  },
  {
    country: 'New Caledonia',
    currency: 'XPF',
  },
  {
    country: 'New Zealand',
    currency: 'NZD',
  },
  {
    country: 'Nicaragua',
    currency: 'NIO',
  },
  {
    country: 'Niger',
    currency: 'XOF',
  },
  {
    country: 'Nigeria',
    currency: 'NGN',
  },
  {
    country: 'Niue',
    currency: 'NZD',
  },
  {
    country: 'Norfolk Island',
    currency: 'AUD',
  },
  {
    country: 'North Korea',
    currency: 'KPW',
  },
  {
    country: 'Northern Ireland',
    currency: 'GBP',
  },
  {
    country: 'Northern Mariana Islands',
    currency: 'USD',
  },
  {
    country: 'Norway',
    currency: 'NOK',
  },
  {
    country: 'Oman',
    currency: 'OMR',
  },
  {
    country: 'Pakistan',
    currency: 'PKR',
  },
  {
    country: 'Palau',
    currency: 'USD',
  },
  {
    country: 'Palestine',
    currency: null,
  },
  {
    country: 'Panama',
    currency: 'PAB',
  },
  {
    country: 'Papua New Guinea',
    currency: 'PGK',
  },
  {
    country: 'Paraguay',
    currency: 'PYG',
  },
  {
    country: 'Peru',
    currency: 'PEN',
  },
  {
    country: 'Philippines',
    currency: 'PHP',
  },
  {
    country: 'Pitcairn',
    currency: 'NZD',
  },
  {
    country: 'Poland',
    currency: 'PLN',
  },
  {
    country: 'Portugal',
    currency: 'EUR',
  },
  {
    country: 'Puerto Rico',
    currency: 'USD',
  },
  {
    country: 'Qatar',
    currency: 'QAR',
  },
  {
    country: 'Reunion',
    currency: 'EUR',
  },
  {
    country: 'Romania',
    currency: 'RON',
  },
  {
    country: 'Russian Federation',
    currency: 'RUB',
  },
  {
    country: 'Rwanda',
    currency: 'RWF',
  },
  {
    country: 'Saint Helena',
    currency: 'SHP',
  },
  {
    country: 'Saint Kitts and Nevis',
    currency: 'XCD',
  },
  {
    country: 'Saint Lucia',
    currency: 'XCD',
  },
  {
    country: 'Saint Pierre and Miquelon',
    currency: 'EUR',
  },
  {
    country: 'Saint Vincent and the Grenadines',
    currency: 'XCD',
  },
  {
    country: 'Samoa',
    currency: 'WST',
  },
  {
    country: 'San Marino',
    currency: 'EUR',
  },
  {
    country: 'Sao Tome and Principe',
    currency: 'STD',
  },
  {
    country: 'Saudi Arabia',
    currency: 'SAR',
  },
  {
    country: 'Scotland',
    currency: 'GBP',
  },
  {
    country: 'Senegal',
    currency: 'XOF',
  },
  {
    country: 'Serbia',
    currency: 'RSD',
  },
  {
    country: 'Seychelles',
    currency: 'SCR',
  },
  {
    country: 'Sierra Leone',
    currency: 'SLL',
  },
  {
    country: 'Singapore',
    currency: 'SGD',
  },
  {
    country: 'Slovakia',
    currency: 'EUR',
  },
  {
    country: 'Slovenia',
    currency: 'EUR',
  },
  {
    country: 'Solomon Islands',
    currency: 'SBD',
  },
  {
    country: 'Somalia',
    currency: 'SOS',
  },
  {
    country: 'South Africa',
    currency: 'ZAR',
  },
  {
    country: 'South Georgia and the South Sandwich Islands',
    currency: 'GBP',
  },
  {
    country: 'South Korea',
    currency: 'KRW',
  },
  {
    country: 'South Sudan',
    currency: 'SSP',
  },
  {
    country: 'Spain',
    currency: 'EUR',
  },
  {
    country: 'Sri Lanka',
    currency: 'LKR',
  },
  {
    country: 'Sudan',
    currency: 'SDG',
  },
  {
    country: 'Suriname',
    currency: 'SRD',
  },
  {
    country: 'Svalbard and Jan Mayen',
    currency: 'NOK',
  },
  {
    country: 'Swaziland',
    currency: 'SZL',
  },
  {
    country: 'Sweden',
    currency: 'SEK',
  },
  {
    country: 'Switzerland',
    currency: 'CHF',
  },
  {
    country: 'Syria',
    currency: 'SYP',
  },
  {
    country: 'Tajikistan',
    currency: 'TJS',
  },
  {
    country: 'Tanzania',
    currency: 'TZS',
  },
  {
    country: 'Thailand',
    currency: 'THB',
  },
  {
    country: 'The Democratic Republic of Congo',
    currency: 'CDF',
  },
  {
    country: 'Togo',
    currency: 'XOF',
  },
  {
    country: 'Tokelau',
    currency: 'NZD',
  },
  {
    country: 'Tonga',
    currency: 'TOP',
  },
  {
    country: 'Trinidad and Tobago',
    currency: 'TTD',
  },
  {
    country: 'Tunisia',
    currency: 'TND',
  },
  {
    country: 'Turkey',
    currency: 'TRY',
  },
  {
    country: 'Turkmenistan',
    currency: 'TMT',
  },
  {
    country: 'Turks and Caicos Islands',
    currency: 'USD',
  },
  {
    country: 'Tuvalu',
    currency: 'AUD',
  },
  {
    country: 'Uganda',
    currency: 'UGX',
  },
  {
    country: 'Ukraine',
    currency: 'UAH',
  },
  {
    country: 'United Arab Emirates',
    currency: 'AED',
  },
  {
    country: 'United Kingdom',
    currency: 'GBP',
  },
  {
    country: 'United States',
    currency: 'USD',
  },
  {
    country: 'United States Minor Outlying Islands',
    currency: 'USD',
  },
  {
    country: 'Uruguay',
    currency: 'UYU',
  },
  {
    country: 'Uzbekistan',
    currency: 'UZS',
  },
  {
    country: 'Vanuatu',
    currency: 'VUV',
  },
  {
    country: 'Venezuela',
    currency: 'VEF',
  },
  {
    country: 'Vietnam',
    currency: 'VND',
  },
  {
    country: 'Virgin Islands, British',
    currency: 'USD',
  },
  {
    country: 'Virgin Islands, U.S.',
    currency: 'USD',
  },
  {
    country: 'Wales',
    currency: 'GBP',
  },
  {
    country: 'Wallis and Futuna',
    currency: 'XPF',
  },
  {
    country: 'Western Sahara',
    currency: 'MAD',
  },
  {
    country: 'Yemen',
    currency: 'YER',
  },
  {
    country: 'Zambia',
    currency: 'ZMW',
  },
  {
    country: 'Zimbabwe',
    currency: 'ZWD',
  },
];
