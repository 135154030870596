import { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import NotAuthorizedPage from '@/pages/Errors/NotAuthorized';
import Login from '@/pages/Login';
import { useNavigate } from 'react-router-dom';
import { useSession } from '@/store/session';
import LoadingPage from '@/pages/Loading';

type Props = {
  allowedPermissions: string[];
  children: ReactNode;
};

const ProtectedRoute: FC<Props> = ({ children, allowedPermissions = [] }) => {
  const navigate = useNavigate();
  const session = useSession();

  const sessionSatate = session.state.get();

  const isAuthorized = useMemo(() => {
    if (allowedPermissions.includes('*')) {
      return true;
    }

    const checks = allowedPermissions.map((permission) =>
      session.hasPermission(permission),
    );

    return checks.some((check) => check);
  }, [sessionSatate.permissions, allowedPermissions]);

  useEffect(() => {
    session.loadSession();
  }, []);

  if (!sessionSatate.sessionLoaded) {
    return <LoadingPage />;
  }

  if (!sessionSatate.isLoggedIn) {
    return <Login />;
  }

  if (!isAuthorized) {
    return <NotAuthorizedPage />;
  }

  return <>{children}</>;
};

export default ProtectedRoute;
