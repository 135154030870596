import { StoreProps } from './types';

export function formatScore(
  score: number | undefined,
  weight: number | undefined,
) {
  if (score === undefined) return '?';
  return `${score.toFixed(2)}` + (weight ? ` (*${weight})` : '');
}

export function getScoreColor(score: number | undefined) {
  if (score === undefined) return 'default';

  if (score < 0.5) return 'error';
  if (score < 0.75) return 'warning';
  return 'success';
}

export function formatDistance(distance: number | undefined) {
  if (distance === undefined) return '?';
  return `${distance.toFixed(2)} m`;
}

export function canImportStore(
  store: Pick<StoreProps, 'status' | 'action' | 'address'>,
) {
  /* Do not allow to importing stores that were not processed yet or whose
  address was not found or that are being imported */
  return (
    store.status !== 'pending' &&
    store.address !== undefined &&
    store.action !== 'importing'
  );
}
