import Layout from '@/components/Layout';
import PageHeader from '@/components/PageHeader';
import {
  Button,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Typography,
  notification,
} from 'antd';
import { FormButtons, FormContainer } from './styles';
import { merge } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import LoadingPage from '@/pages/Loading';
import { useAudit } from '@/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import {
  getAllCompanyMembers,
  getCompanyDetails,
  updateCompany,
} from '@/services/company.service';
import { CompanyTypeEnum } from '../types';
import AssociatedDataCompanyTab from '../Components/Tab';
import { normalizeSearchString } from '@/utils';

type FormData = {
  name: string;
  type: CompanyTypeEnum;
  alias: string;
  ownerId: string;
};

const CompanyEdit = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [currentTab, setCurrentTab] = useState('tab-brands');
  const [form] = Form.useForm<FormData>();

  const audit = useAudit({
    resourceName: 'company-edit',
  });

  const queryClient = useQueryClient();

  const { data: company, isLoading: isLoadingCompany } = useQuery({
    queryKey: ['company-edit-details', id],
    queryFn: async () => {
      const result = await getCompanyDetails(id!);
      if (!result) return null;
      return result.company;
    },
    enabled: id !== undefined,
  });

  const { data: members, isLoading: isLoadingMembers } = useQuery({
    queryKey: ['company-members', id],
    queryFn: async () => await getAllCompanyMembers(id!),
    enabled: id !== undefined,
  });

  const companyOwner = useMemo(() => {
    return members?.find((member) => member.role === 'owner');
  }, [members]);

  const cancelEdit = () => {
    audit.onClick({ target: 'cancel-edit' });
    navigate('/company');
  };

  const { mutate: onFinish, isPending: isUpdatingBrand } = useMutation({
    mutationFn: async (formData: FormData) => {
      const payload = merge({}, company, formData);

      const savedData = await updateCompany(id, payload);
      if (!savedData) {
        notification.error({
          message: 'Error',
          description: 'Could not update company',
        });
        return;
      }

      audit.onUpdate({ target: 'save', data: payload, entityId: id });
      notification.success({
        message: 'Success',
        description: 'Company updated successfully',
      });
      navigate(`/company`);
    },
  });

  useEffect(() => {
    audit.onAccess();
  }, []);

  /* Clear query cache when component unmounts to prevent stale data when
  accessing the page again */
  useEffect(() => {
    return () => {
      queryClient.removeQueries({ queryKey: ['company-members', id] });
    };
  }, [id, queryClient]);

  if (isLoadingCompany || isLoadingMembers) {
    return <LoadingPage />;
  }

  if (!company || !members || !companyOwner) {
    return (
      <Layout>
        <PageHeader title="Edit Company" />

        <p>Could not load company details. Please try again later.</p>
      </Layout>
    );
  }

  return (
    <Layout>
      <PageHeader title="Edit Company" />

      <Divider orientation="left" orientationMargin="0">
        Company ID:{' '}
        <Typography.Text copyable style={{ fontSize: 13 }}>
          {company.id}
        </Typography.Text>
      </Divider>

      <Row>
        <Typography.Text style={{ fontSize: 11 }}>
          <strong>Created at:</strong> {company.createdAt}
        </Typography.Text>
      </Row>
      <Row>
        <Typography.Text style={{ fontSize: 11 }}>
          <strong>Last Update:</strong> {company.updatedAt}
        </Typography.Text>
      </Row>

      <FormContainer>
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          initialValues={
            {
              name: company.name,
              type: company.type,
              alias: company.alias,
              ownerId: companyOwner.id,
            } satisfies FormData
          }
          disabled={isUpdatingBrand}
        >
          <Form.Item<FormData>
            name="name"
            label="Name"
            rules={[{ required: true }]}
            help="Company's full name"
          >
            <Input />
          </Form.Item>

          <Form.Item<FormData>
            name="alias"
            label="Alias"
            rules={[{ required: true }]}
            help="Company's short name"
          >
            <Input />
          </Form.Item>

          <Form.Item<FormData>
            name="type"
            label="Type"
            rules={[{ required: true }]}
            help="Company should access EFR or EFB"
          >
            <Select
              allowClear
              showSearch
              style={{ width: 200 }}
              placeholder="Select a type"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? '').includes(input)
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? '')
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? '').toLowerCase())
              }
              options={[
                { value: '', label: '' },
                { value: 'team', label: 'Brand Team' },
                { value: 'retailer', label: 'Retailer' },
              ]}
            />
          </Form.Item>

          <Form.Item<FormData>
            name="ownerId"
            label="Owner"
            help="Select a member of the company to be the owner. When changing the owner, the current one will be set as an Admin of the company and its brand."
            rules={[{ required: true }]}
          >
            <Select
              placeholder="Select a owner"
              showSearch
              options={members.map((member) => ({
                value: member.id,
                label: (
                  <>
                    {member.fullName}{' '}
                    <span style={{ opacity: 0.5 }}>
                      ({member.email} - {member.role})
                    </span>
                  </>
                ),
                labelValue: `${member.fullName} (${member.email} - ${member.role})`,
              }))}
              filterOption={(input, option) => {
                return normalizeSearchString(option?.labelValue ?? '')
                  .toLowerCase()
                  .includes(normalizeSearchString(input).toLowerCase());
              }}
            />
          </Form.Item>

          <FormButtons>
            <Button onClick={cancelEdit}>Cancel</Button>

            <Button type="primary" htmlType="submit" loading={isUpdatingBrand}>
              Save
            </Button>
          </FormButtons>
        </Form>
      </FormContainer>
    </Layout>
  );
};

export default CompanyEdit;
