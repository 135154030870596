import Layout from '@/components/Layout';
import PageHeader from '@/components/PageHeader';
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Row,
  Switch as SwitchInput,
  Tabs,
} from 'antd';
import { map, startCase } from 'lodash';
import {
  Container,
  PaymentFormContainer,
  TabsContainer,
  TabsControlContainer,
} from './styles';
import { useSettingsController } from './useSettingsController';
import { When } from 'react-if';

export function Settings() {
  const {
    loadingPayments,
    mobilePayments,
    onFinishPaymentForm,
    paymentForm,
    paymentFormChanged,
    hasChanges,
    savingPayments,
    cancelPaymentForm,
    setCurrentTab,
    currentTab,
  } = useSettingsController();

  return (
    <Layout>
      <PageHeader title="Geral Settings" />
      <Container>
        <TabsControlContainer>
          <Tabs
            items={[
              {
                key: 'tab-payments',
                label: 'Mobile Payments',
              },
            ]}
            onChange={(tab: string) => setCurrentTab(tab)}
          />
        </TabsControlContainer>

        <TabsContainer>
          {currentTab === 'tab-payments' && (
            <PaymentFormContainer>
              <Form
                form={paymentForm}
                onFinish={onFinishPaymentForm}
                disabled={loadingPayments}
                onFieldsChange={paymentFormChanged}
              >
                {map(mobilePayments, (item, index) => (
                  <div key={`${index}-${item.type}`}>
                    <Divider orientation="left" orientationMargin="0">
                      {startCase(item.type)}
                    </Divider>
                    <Row gutter={16}>
                      <Col span={4}>
                        <Form.Item
                          name={['payments', index, 'isActive']}
                          valuePropName="checked"
                          help="Enable or disable this payment method"
                        >
                          <SwitchInput
                            checkedChildren="Actived"
                            unCheckedChildren="inactived"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          name={['payments', index, 'minValue']}
                          rules={[{ required: true }]}
                          help="Minimum value required to use this payment method without fees"
                        >
                          <InputNumber
                            placeholder="Min Value"
                            addonAfter="$"
                            style={{ width: '90%' }}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          name={['payments', index, 'fee']}
                          rules={[{ required: true }]}
                          help="Fee percentage charged to the user"
                        >
                          <InputNumber
                            placeholder="Fee"
                            addonAfter="%"
                            style={{ width: '90%' }}
                          />
                        </Form.Item>
                      </Col>
                      <Form.Item
                        name={['payments', index, 'type']}
                        hidden={true}
                      >
                        <Input />
                      </Form.Item>
                    </Row>
                  </div>
                ))}
                <Form.Item className="actions">
                  {hasChanges && (
                    <>
                      <Button onClick={cancelPaymentForm}>Cancel</Button>
                      &nbsp;
                      <Button
                        type="primary"
                        htmlType="submit"
                        disabled={savingPayments}
                      >
                        Save
                      </Button>
                    </>
                  )}
                </Form.Item>
              </Form>
            </PaymentFormContainer>
          )}
        </TabsContainer>
      </Container>
    </Layout>
  );
}
