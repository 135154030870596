import { FC, useState } from 'react';
import {
  Alert,
  Button,
  Form,
  Input,
  notification,
  Popconfirm,
  Select,
} from 'antd';
import { ThumbsDown, ThumbsUp } from 'lucide-react';
import { ConfirmationPopupContainer, Container } from './styles';
import { rejectReasons } from '../contants';
import { map } from 'lodash';
import { requestCompletionApprovals } from '@/services/consumer.service';
import { useAudit } from '@/hooks';
import { useSession } from '@/store';

type Args = {
  completionsId: string[];
  onCompleted?: () => void;
};

const ApprovalButtons: FC<Args> = ({ completionsId, onCompleted }) => {
  const session = useSession();
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState({
    reject: false,
    approve: false,
  });

  const audit = useAudit({
    resourceName: 'consumer-approval-actions',
  });

  const onRejectConfirmed = async (values: any) => {
    setLoading({ reject: true, approve: false });

    const { status = false, totalCompletions = 0 } =
      await requestCompletionApprovals({
        completionsId,
        status: 'rejected',
        rejectionArgs: values,
        lang: 'en',
      });

    if (status) {
      setOpen(false);
      form.resetFields();
      onCompleted?.();

      if (totalCompletions > 0) {
        notification.success({
          message: 'Success',
          description: `${totalCompletions} completions rejection are requested, processing may take time due to queue.`,
        });
      } else {
        notification.warning({
          message: 'Success',
          description: `No completions rejection are requested, please try again later.`,
        });
      }

      audit.onUpdate({
        target: 'completion',
        data: {
          status: 'rejected',
          rejectionArgs: values,
          completionsId,
        },
      });
    } else {
      notification.error({
        message: 'Error',
        description: `Error rejecting completions.`,
      });
    }

    setLoading({ reject: false, approve: false });
  };

  const onApproveConfirmed = async (values: any) => {
    setLoading({ reject: false, approve: true });
    const { status = false, totalCompletions = 0 } =
      await requestCompletionApprovals({
        completionsId,
        status: 'approved',
        lang: 'en',
      });

    if (status) {
      setOpen(false);
      form.resetFields();
      onCompleted?.();

      if (totalCompletions > 0) {
        notification.success({
          message: 'Success',
          description: `${totalCompletions} completions approval are requested, processing may take time due to queue.`,
        });
      } else {
        notification.warning({
          message: 'Success',
          description: `No completions approval are requested, please try again later.`,
        });
      }

      audit.onUpdate({
        target: 'completion',
        data: {
          status: 'approved',
          rejectionArgs: values,
          completionsId,
        },
      });
    } else {
      notification.error({
        message: 'Error',
        description: `Error rejecting completions.`,
      });
    }

    setLoading({ reject: false, approve: false });
  };

  if (!session.hasPermission('consumer_completion.edit')) {
    return (
      <Alert
        message="You do not have permission to approve completions"
        type="error"
        style={{
          textAlign: 'center',
        }}
      />
    );
  }

  return (
    <Container>
      <Popconfirm
        open={open}
        overlayInnerStyle={{
          padding: 24,
          paddingTop: 16,
        }}
        okText={
          completionsId.length > 1
            ? `Reject ${completionsId.length} Completions`
            : 'Reject Completion'
        }
        cancelButtonProps={{
          size: 'large',
        }}
        okButtonProps={{
          danger: true,
          disabled: !form.validateFields,
          size: 'large',
          loading: loading.reject,
        }}
        onConfirm={() => {
          form.submit();
        }}
        onCancel={() => setOpen(false)}
        title="Reject Confirmation"
        description={
          <ConfirmationPopupContainer>
            <Form
              form={form}
              onFinish={onRejectConfirmed}
              onValuesChange={(values: any, allValues) => {
                const key = Object.keys(values)[0];
                if (key === 'rejectBy') {
                  form.setFieldsValue({
                    // @ts-ignore
                    rejectText: rejectReasons[values[key]].suggest,
                  });
                }
              }}
            >
              <Form.Item
                name="rejectBy"
                rules={[{ required: true, message: 'Please select a reason' }]}
              >
                <Select allowClear showSearch placeholder={'Select a reason'}>
                  {map(rejectReasons, (reason, key) => (
                    <Select.Option value={key} key={`option-key-${key}`}>
                      {reason.menu}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="rejectText"
                rules={[
                  { required: true, message: 'Please enter the description' },
                ]}
              >
                <Input.TextArea rows={4} maxLength={70} showCount />
              </Form.Item>

              <Alert
                type="warning"
                showIcon
                message="The description will be sent to the user trough SMS."
              />
            </Form>
          </ConfirmationPopupContainer>
        }
        icon={false}
      >
        <Button
          onClick={() => {
            form.resetFields();
            setOpen(true);
          }}
          size={'large'}
          icon={<ThumbsDown size={16} />}
          className={'reject'}
          loading={loading.reject}
        >
          Reject
        </Button>
      </Popconfirm>
      <Button
        size={'large'}
        icon={<ThumbsUp size={16} />}
        className={'approve'}
        onClick={onApproveConfirmed}
        loading={loading.approve}
      >
        {completionsId.length > 1
          ? `Approve ${completionsId.length} Completions`
          : 'Approve Completion'}
      </Button>
    </Container>
  );
};

export default ApprovalButtons;
