import styled from 'styled-components';

export const TabsControlContainer = styled.div`
  .ant-tabs-nav {
    margin-bottom: 0;
    padding: 0 8px;

    &::before {
      border: none;
    }
  }
`;

export const TabsContainer = styled.div`
  background-color: #edf3f7;
  padding: 24px;
  border-radius: 10px;
  min-height: 300px;
`;
