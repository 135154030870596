import styled from 'styled-components';

export const ListContainer = styled.div`
  table {
    tbody {
      tr {
        .process-field {
          .process-row {
            display: flex;
            align-items: center;
            justify-content: center;
            column-gap: 4px;

            .process-label {
              font-weight: 700;
            }
          }
        }

        .preview-content {
          width: 40px;
          height: 40px;
          background-color: #fff;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          font-size: 16px;
          border-radius: 10px;
          box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
          cursor: pointer;
        }

        .actions-field {
          button {
            width: 40px;
            height: 40px;
            background-color: #fff;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            border-radius: 10px;
            box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
            cursor: pointer;
            border: none;
            background-color: #6f56bc;
            color: #fff;
          }
        }
      }
    }
  }
`;
