import { FC } from 'react';
import { ConsumerCompletion } from '@/types';
import { Case, Default, Switch } from 'react-if';
import { Tag } from 'antd';

type Args = {
  completion: ConsumerCompletion | null;
};

const ScanStatusTag: FC<Args> = ({ completion }) => {
  return (
    <Switch>
      <Case
        condition={
          completion?.scanState === 'zzz' && completion?.scanProcessing
        }
      >
        <Tag color={'geekblue'}>In Queue</Tag>
      </Case>
      <Case
        condition={
          completion?.scanState === 'zzz' && !completion?.scanProcessing
        }
      >
        <Tag>Not Requested</Tag>
      </Case>
      <Case condition={completion?.scanState === 'failed'}>
        <Tag color="error">Failed</Tag>
      </Case>
      <Case condition={completion?.scanState === 'trying'}>
        <Tag color="warning">Trying</Tag>
      </Case>
      <Case condition={completion?.scanState === 'toReview'}>
        <Tag color="orange">Take a look!</Tag>
      </Case>
      <Case condition={completion?.scanState === 'good'}>
        <Tag color={'success'}>Looks good</Tag>
      </Case>
      <Default>
        <span className="inactive-text">(not set)</span>
      </Default>
    </Switch>
  );
};

export default ScanStatusTag;
