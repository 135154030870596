import { Tag } from 'antd';
import { Switch, Case } from 'react-if';

const TypeTag = ({ type }: { type: string }) => {
  return (
    <Switch>
      <Case condition={type === 'contest'}>
        <Tag color="orange">Contest</Tag>
      </Case>
      <Case condition={type === 'learning'}>
        <Tag color="green">Learning</Tag>
      </Case>
      <Case condition={type === 'consumer'}>
        <Tag color="purple">Consumer</Tag>
      </Case>
      <Case condition={type === 'general'}>
        <Tag color="blue">General</Tag>
      </Case>
    </Switch>
  );
};

export default TypeTag;
