import { httpGet } from './util/axios';

export const getMissionItems = async (missionId: string): Promise<any[]> => {
  const { items = [] } = await httpGet(
    'mission/read',
    `admin/${missionId}/items`,
  );

  return items;
};
