import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  h5 {
    text-align: center;
    font-style: normal;
    font-weight: 400;
  }
`;

export const PickerContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TableContainer = styled.div`
  .table-acitons {
    a {
      color: #6f56bc;

      &.delete {
        color: #ff0000;
      }
    }
  }

  .table-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
      font-size: 16px;
      font-weight: 700;
    }
  }

  .inactive-text {
    color: #777777;
    font-style: italic;
    font-weight: 200;
    user-select: none;
  }

  table {
    &.clickable {
      tbody {
        tr {
          cursor: pointer;
        }
      }
    }
  }

  .ant-table-selection-column {
    .ant-checkbox {
      .ant-checkbox-inner {
        width: 24px;
        height: 24px;
        border-width: 2px;
        border-radius: 5px;

        &::after {
          width: 8px;
          height: 13px;
          top: 43%;
        }
      }

      &.ant-checkbox-indeterminate {
        .ant-checkbox-inner {
          &::after {
            width: 12px;
            height: 4px;
            top: 50%;
          }
        }
      }

      &:not(.ant-checkbox-checked) {
        border-color: #777777;
      }
    }
  }
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;

  .actions {
    display: flex;
    column-gap: 8px;
  }
`;
