import Layout from '@/components/Layout';
import PageHeader from '@/components/PageHeader';
import List from './list';
import { useEffect, useState } from 'react';
import { getQueueTotals } from '@/services/queue.service';
import { Badge, Tabs } from 'antd';
import { useAudit } from '@/hooks';

const ListPage = () => {
  const [status, setStatus] = useState('pending');

  const [totals, setTotals] = useState({
    pending: 0,
    success: 0,
    failed: 0,
  });

  const audit = useAudit({
    resourceName: 'dev-queue-list-job',
  });

  useEffect(() => {
    audit.onAccess();
    getTotals();
  }, []);

  const getTotals = async () => {
    const { pending = 0, success = 0, failed = 0 } = await getQueueTotals();

    setTotals((prev) => ({
      ...prev,
      pending,
      success,
      failed,
    }));
  };

  return (
    <Layout>
      <PageHeader title="Queue Jobs" />

      <Tabs onChange={(activeKey: string) => setStatus(activeKey)}>
        <Tabs.TabPane
          tab={
            <div>
              <span>Pending</span>{' '}
              <Badge
                count={totals.pending}
                showZero
                overflowCount={999999}
                color={status === 'pending' ? '#6f56bc' : 'grey'}
              />
            </div>
          }
          key="pending"
        />
        <Tabs.TabPane
          tab={
            <div>
              <span>Success</span>{' '}
              <Badge
                count={totals.success}
                showZero
                overflowCount={999999}
                color={status === 'success' ? '#6f56bc' : 'grey'}
              />
            </div>
          }
          key="success"
        />
        <Tabs.TabPane
          tab={
            <div>
              <span>Failed</span>{' '}
              <Badge
                count={totals.failed}
                showZero
                overflowCount={999999}
                color={status === 'failed' ? '#6f56bc' : 'grey'}
              />
            </div>
          }
          key="failed"
        />
      </Tabs>

      <List status={status} key={`list-${status}`} />
    </Layout>
  );
};

export default ListPage;
