import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  padding: 16px;
  background-color: #f0f2f5;
  border-radius: 8px;
  margin: 16px 0;

  .item {
    > span {
      font-size: 16px;
      font-weight: 500;
      margin-right: 8px;
    }
  }
`;
