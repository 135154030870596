import { useMemo, useState, type FC } from 'react';
import type { DetailedManagerSubmission } from '@/types';
import { AnswersContainer } from './styles';
import {
  Space,
  Descriptions,
  Typography,
  Table,
  Avatar,
  notification,
  Spin,
} from 'antd';
import { FaFile, FaFileExcel, FaFilePdf } from 'react-icons/fa';
import {
  Camera,
  Download,
  ReceiptText,
  ShoppingBasket,
  Users,
} from 'lucide-react';
import { useQuery } from '@tanstack/react-query';

import {
  formatISODate,
  formatMoneyWithoutCurrency,
  getNameAvatarUrl,
} from '@/utils';
import { validateMediaUrl } from '@/utils/media';

import { MagnifiableImage } from '@/components/ImageMagnifier';

type Args = {
  completion: DetailedManagerSubmission;
};

const Answers: FC<Args> = ({ completion }) => {
  const [isDownloadingFile, setIsDownloadingFile] = useState(false);

  const sellers = useMemo(() => {
    return completion.form.users.map(({ id, unitsSold, totalSales }) => {
      const user = completion.userOptions.find((user) => user.id === id);

      return {
        id,
        avatarUrl: user?.avatarUrl,
        fullName: user?.fullName || 'Unknown',
        unitsSold,
        totalSales,
      };
    });
  }, [completion.form.users, completion.userOptions]);

  const itemsSold = useMemo(() => {
    return completion.form.items.map(({ id, unitsSold, totalSales }) => {
      const item = completion.itemOptions.find((item) => item.id === id);

      return {
        id,
        title: item?.title || 'Unknown',
        unitsSold,
        totalSales,
      };
    });
  }, [completion.form.items, completion.itemOptions]);

  async function handleDownloadFile() {
    if (isDownloadingFile) return;

    setIsDownloadingFile(true);

    let blob;
    try {
      const response = await fetch(completion.form.salesReportUrl);
      blob = await response.blob();
    } catch (error) {
      notification.error({
        message: 'Failed to download file',
        description: 'Please try again later',
      });
    }

    setIsDownloadingFile(false);

    if (!blob) return;

    const objectUrl = URL.createObjectURL(blob);

    const anchor = document.createElement('a');
    anchor.style.display = 'none';
    anchor.href = objectUrl;
    anchor.download =
      completion.form.salesReportArgs?.fileName ||
      completion.form.salesReportUrl.split('/').at(-1)!;

    document.body.appendChild(anchor);
    anchor.click();

    URL.revokeObjectURL(objectUrl);
    anchor.remove();
  }

  const { data: salesReportIsValidImage } = useQuery({
    queryKey: ['validate-image-url', completion.form.salesReportUrl],
    queryFn: async () =>
      await validateMediaUrl('image', completion.form.salesReportUrl),
  });
  const salesReportIsImage =
    completion.form.salesReportArgs.fileType === 'image' ||
    salesReportIsValidImage;

  return (
    <AnswersContainer>
      <div className="body">
        <div className="answers-list">
          {completion.activeSections.uploadSalesReport && (
            <div className="answer-item">
              <div className="indicator-column">
                <div className="indicator">
                  <Camera />
                </div>
              </div>
              <div className="data-column">
                <div className="answer-title">
                  <span className="title">
                    Upload your sales report or your receipt
                  </span>
                </div>

                <figure className="answer-data">
                  <div className={`file ${salesReportIsImage ? 'image' : ''}`}>
                    {salesReportIsImage ? (
                      <MagnifiableImage
                        style={{ objectFit: 'contain' }}
                        src={completion.form.salesReportUrl}
                        alt="Sales Report/Receipt"
                        height={180}
                        enablePreview
                      />
                    ) : completion.form.salesReportArgs.fileType === 'pdf' ||
                      completion.form.salesReportUrl.endsWith('.pdf') ? (
                      <FaFilePdf className="icon" />
                    ) : completion.form.salesReportArgs.fileType === 'excel' ||
                      /\.xslx?$/.test(completion.form.salesReportUrl) ? (
                      <FaFileExcel className="icon" />
                    ) : (
                      <FaFile className="icon" />
                    )}

                    {!salesReportIsImage && (
                      <div className="overlay">
                        <button
                          onClick={handleDownloadFile}
                          disabled={isDownloadingFile}
                        >
                          {isDownloadingFile ? <Spin /> : <Download />}
                        </button>
                      </div>
                    )}
                  </div>
                  {!salesReportIsImage && (
                    <figcaption className="file-name">
                      <Typography.Text type="secondary">
                        {completion.form.salesReportArgs.fileName}
                      </Typography.Text>
                    </figcaption>
                  )}
                </figure>
              </div>
            </div>
          )}

          <div className="answer-item">
            <div className="indicator-column">
              <div className="indicator">
                <ReceiptText />
              </div>
            </div>
            <div className="data-column">
              <div className="answer-title">
                <span className="title">
                  Please select the sales report details
                </span>
              </div>

              <div className="answer-data">
                <Descriptions size="small" column={1}>
                  <Descriptions.Item label="Dates">
                    {formatISODate(
                      completion.form.dateRange.start,
                      'LLL dd, yyyy',
                    )}{' '}
                    -{' '}
                    {formatISODate(
                      completion.form.dateRange.end,
                      'LLL dd, yyyy',
                    )}
                  </Descriptions.Item>
                  <Descriptions.Item label="Number of units sold">
                    {completion.form.numberOfUnits}
                  </Descriptions.Item>
                  {completion.activeSections.enterSalesAmount && (
                    <Descriptions.Item label="Total amount">
                      <strong style={{ fontWeight: 600 }}>
                        {formatMoneyWithoutCurrency(
                          completion.form.totalAmount,
                        )}
                      </strong>
                    </Descriptions.Item>
                  )}
                </Descriptions>
              </div>
            </div>
          </div>

          <div className="answer-item">
            <div className="indicator-column">
              <div className="indicator">
                <Users />
              </div>
            </div>
            <div className="data-column">
              <div className="answer-title">
                <span className="title">
                  Please select the employees who are involved with these sales
                </span>
              </div>

              <div className="answer-data">
                <Table dataSource={sellers} rowKey={(seller) => seller.id}>
                  <Table.Column
                    title="Seller"
                    dataIndex="fullName"
                    key="fullName"
                    render={(_, record: (typeof sellers)[number]) => (
                      <Space>
                        <Avatar
                          src={
                            record.avatarUrl ||
                            getNameAvatarUrl(record.fullName)
                          }
                          size={24}
                        />
                        <span>{record.fullName}</span>
                      </Space>
                    )}
                  />
                  <Table.Column
                    title="Units Sold"
                    dataIndex="unitsSold"
                    key="unitsSold"
                  />
                  {completion.activeSections.enterSalesAmount && (
                    <Table.Column
                      title="Total Sales"
                      dataIndex="totalSales"
                      key="totalSales"
                      render={(totalSales: number) => (
                        <strong style={{ fontWeight: 600 }}>
                          {formatMoneyWithoutCurrency(totalSales)}
                        </strong>
                      )}
                    />
                  )}
                </Table>
              </div>
            </div>
          </div>

          {completion.activeSections.selectItemSold && (
            <div className="answer-item">
              <div className="indicator-column">
                <div className="indicator">
                  <ShoppingBasket />
                </div>
              </div>
              <div className="data-column">
                <div className="answer-title">
                  <span className="title">
                    Please select the items that were sold
                  </span>
                </div>

                <div className="answer-data">
                  <Table dataSource={itemsSold} rowKey={(item) => item.id}>
                    <Table.Column title="Item" dataIndex="title" key="title" />
                    <Table.Column
                      title="Units Sold"
                      dataIndex="unitsSold"
                      key="unitsSold"
                    />
                    {completion.activeSections.enterSalesAmount && (
                      <Table.Column
                        title="Total Sales"
                        dataIndex="totalSales"
                        key="totalSales"
                        render={(totalSales: number) => (
                          <strong style={{ fontWeight: 600 }}>
                            {formatMoneyWithoutCurrency(totalSales)}
                          </strong>
                        )}
                      />
                    )}
                  </Table>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </AnswersContainer>
  );
};

export default Answers;
