import styled from 'styled-components';
import { Button, Drawer, Modal } from 'antd';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;

  .table-filter-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .search-input {
      width: 300px;
    }
  }
`;

export const CommentModal = styled(Modal)`
  .body {
    padding: 0;
  }
`;

export const TableCommentButton = styled(Button)`
  font-size: 18px;
  position: relative;
  z-index: 10;

  svg {
    width: 24px;
    height: 24px;
    color: #6f56bc;
  }
`;
