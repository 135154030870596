import Layout from '@/components/Layout';
import PageHeader from '@/components/PageHeader';
import {
  Avatar,
  Badge,
  Button,
  Dropdown,
  Input,
  MenuProps,
  Space,
  Table,
  Tag,
  Typography,
} from 'antd';
import { useAudit, useTable } from '@/hooks';
import { TableContainer } from '@/styles';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { BrandInfoContainer } from '@/pages/Brand/List/styles';
import { IoPlanetOutline } from 'react-icons/io5';
import SegmentTag from '@/pages/Brand/components/SegmentTag';
import { PiDotsThreeOutline, PiPencil } from 'react-icons/pi';
import { SiStackedit } from 'react-icons/si';
import { Brand, MobileUser } from '@/types';
import { Case, Switch } from 'react-if';
import { PlusOutlined } from '@ant-design/icons';

import { ButtonLink } from '@/components/ButtonLink';

const Page = () => {
  const [status, setStatus] = useState('approved');
  const [filter, setFilter] = useState('');
  const [resendLoading, setResendLoading] = useState(false);
  const { tableProps, refresh } = useTable<Brand>({
    service: 'brand/read',
    path: `admin/list`,
    params: {
      filters: {
        text: filter,
        status,
      },
    },
    defaultSort: {
      field: 'updatedAt',
      order: 'descend',
    },
  });

  const audit = useAudit({
    resourceName: 'brand-list',
  });

  useEffect(() => {
    audit.onAccess();
  }, []);

  const getTableMenuItems = (
    record: Brand,
  ): NonNullable<MenuProps['items']> => {
    const items: NonNullable<MenuProps['items']> = [
      {
        key: `menu-edit-${record.id}`,
        label: <Link to={`/brand/${record.id}/edit`}>Edit</Link>,
        icon: <PiPencil size={20} />,
      },
    ];

    return items;
  };

  return (
    <Layout>
      <PageHeader noBackButton title="Brands">
        <Space>
          <Input.Search
            placeholder="Search by list name"
            allowClear
            onSearch={(value) => {
              setFilter(value);
            }}
            style={{ width: 260 }}
          />
          <ButtonLink type="primary" icon={<PlusOutlined />} to="/brand/create">
            Add Brand
          </ButtonLink>
        </Space>
      </PageHeader>

      <Space>
        <Button
          type={status === 'approved' ? 'primary' : 'default'}
          onClick={() => setStatus('approved')}
        >
          Approved
        </Button>
        <Button
          type={status === 'pending' ? 'primary' : 'default'}
          onClick={() => setStatus('pending')}
        >
          Pending
        </Button>
        <Button
          type={status === 'rejected' ? 'primary' : 'default'}
          danger={status === 'rejected'}
          onClick={() => setStatus('rejected')}
        >
          Rejected
        </Button>
      </Space>

      <br />

      <TableContainer>
        <Table {...tableProps} rowKey={'id'}>
          <Table.Column
            title="Brand"
            dataIndex="name"
            key="name"
            sorter
            render={(text, record: Brand) => {
              return (
                <BrandInfoContainer>
                  <Avatar
                    src={record.logoUrl}
                    style={{ marginRight: 10 }}
                    size={70}
                    shape={'square'}
                    icon={<IoPlanetOutline />}
                  />

                  <div className="info">
                    <Typography.Text className="name" ellipsis>
                      {record.name}
                    </Typography.Text>
                    <SegmentTag segment={record.segment} />
                  </div>
                </BrandInfoContainer>
              );
            }}
          />
          <Table.Column
            title="Type"
            dataIndex="isManaged"
            key="isManaged"
            align="center"
            sorter
            render={(text: any, record: Brand, index: number) => {
              return record.isManaged ? (
                <Badge text="Retailer" color="#ff8000" />
              ) : (
                <Badge text="Brand" color="#6f56bc" />
              );
            }}
          />

          <Table.Column
            title="Status"
            dataIndex="isInactive"
            align="center"
            sorter
            render={(text: any, record: Brand, index: number) => {
              return (
                <Switch>
                  <Case condition={record.isInactive}>
                    <Tag color="error">Inactive</Tag>
                  </Case>
                  <Case condition={!record.isInactive}>
                    <Tag color="success">Active</Tag>
                  </Case>
                </Switch>
              );
            }}
          />

          <Table.Column
            title="Language"
            dataIndex="language"
            key="language"
            align="center"
            sorter
          />

          <Table.Column
            title="Currency"
            dataIndex="currency"
            key="currency"
            align="center"
            sorter
          />

          <Table.Column
            title=""
            dataIndex="actions"
            key="actions"
            align="right"
            render={(text: any, record: Brand, index: number) => {
              return (
                <Space>
                  <ButtonLink
                    to={`/brand/${record.id}/manage`}
                    icon={
                      status === 'approved' ? <SiStackedit size={20} /> : null
                    }
                    style={{ alignItems: 'center', display: 'flex' }}
                  >
                    {status === 'approved' ? <>Manage</> : <>See Details</>}
                  </ButtonLink>
                  <Dropdown menu={{ items: getTableMenuItems(record) }}>
                    <Button>
                      <PiDotsThreeOutline size={20} />
                    </Button>
                  </Dropdown>
                </Space>
              );
            }}
          />
        </Table>
      </TableContainer>
    </Layout>
  );
};

export default Page;
