import Layout from '@/components/Layout';
import PageHeader from '@/components/PageHeader';
import {
  Button,
  Divider,
  Form,
  Input,
  Select,
  Table,
  notification,
} from 'antd';
import { FormContainer } from './styles';
import { sectors, basePermissions } from '@/data/admin-user';
import { startCase, values } from 'lodash';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  getAdminUserDetails,
  updateAdminUser,
} from '@/services/admin-user.service';
import LoadingPage from '@/pages/Loading';
import { useAudit } from '@/hooks';

type FormData = {
  id: string;
  fullName: string;
  email: string;
  sector: string;
  phoneNumber: string;
  blockedAdminPanelLogin: boolean;
  isMasterUser: boolean;
  isActive: boolean;
  hasAdminAccess: boolean;
  lastAccessAt: number;
  permissions: string[];
};

const AdminUserEdit = () => {
  const navigate = useNavigate();

  const { id } = useParams<{ id: string }>();
  const [pageLoaded, setPageLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [_serializedPermissions, _setSerializedPermissions] = useState<{
    [key: string]: string;
  }>({});

  const audit = useAudit({
    resourceName: 'admin-user-edit',
  });

  const [formData, setFormData] = useState<FormData>({
    id: '',
    fullName: '',
    email: '',
    sector: '',
    phoneNumber: '',
    blockedAdminPanelLogin: false,
    isMasterUser: false,
    isActive: false,
    hasAdminAccess: false,
    lastAccessAt: 0,
    permissions: [],
  });

  useEffect(() => {
    audit.onAccess();
    loadDetails();
  }, []);

  useEffect(() => {
    serializePermissions();
  }, [_serializedPermissions]);

  const loadDetails = async () => {
    if (!id) return;

    const { user } = await getAdminUserDetails(id);

    setFormData((prev) => ({
      ...prev,
      ...user,
    }));

    unserializePermissions(user.permissions);

    setTimeout(() => {
      setPageLoaded(true);
    }, 0);
  };

  const unserializePermissions = async (permissions: string[]) => {
    const _serializedPermissions: { [key: string]: string } = {};

    permissions.forEach((permission) => {
      const [module, action] = permission.split('.');
      _serializedPermissions[module] = permission;
    });

    _setSerializedPermissions(_serializedPermissions);
  };

  const serializePermissions = () => {
    const permissions = values(_serializedPermissions).filter(Boolean);

    setFormData((prev) => ({
      ...prev,
      permissions,
    }));

    return permissions;
  };

  const onFinish = async (data: FormData) => {
    if (!id) return;

    setLoading(true);

    const payload = {
      ...formData,
      ...data,
      permissions: serializePermissions(),
    };

    const { status } = await updateAdminUser(id, payload);

    audit.onUpdate({ target: 'save', data: payload, entityId: id });

    if (status) {
      notification.success({
        message: 'Success',
        description: 'User updated successfully',
      });
    } else {
      notification.error({
        message: 'Error',
        description: 'Failed to update user',
      });
    }

    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  if (!pageLoaded) {
    return <LoadingPage />;
  }

  const cancelEdit = () => {
    audit.onClick({ target: 'cancel-edit' });
    navigate('/admin-user');
  };

  return (
    <Layout>
      <PageHeader title="Edit User" />

      <FormContainer>
        <Form
          form={form}
          layout="vertical"
          autoComplete="off"
          onFinish={onFinish}
          initialValues={formData}
          disabled={loading}
        >
          <Divider orientation="left" orientationMargin="0">
            Personal
          </Divider>
          <Form.Item
            name="fullName"
            label="Full Name"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="email"
            label="Email"
            rules={[{ type: 'email' }, { required: true }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="phoneNumber"
            label="Phone Number"
            rules={[{ required: false }]}
          >
            <Input />
          </Form.Item>

          <br />

          <Divider orientation="left" orientationMargin="0">
            Company
          </Divider>

          <Form.Item name="sector" label="Sector" rules={[{ required: true }]}>
            <Select placeholder="Select a sector">
              {sectors.map((sector) => (
                <Select.Option key={`option-${sector.id}`} value={sector.id}>
                  {sector.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <br />

          <Divider orientation="left" orientationMargin="0">
            Accesss
          </Divider>

          <Form.Item
            label="Master User"
            name="isMasterUser"
            help="User can access EFR or EFB with master access"
            wrapperCol={{ xs: 24, sm: 24, md: 18, lg: 12 }}
          >
            <Select>
              <Select.Option value={true}>Yes</Select.Option>
              <Select.Option value={false}>No</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            label="Active Account"
            name="isActive"
            help="User is able to login and access the system"
            wrapperCol={{ xs: 24, sm: 24, md: 18, lg: 12 }}
          >
            <Select>
              <Select.Option value={true}>Yes</Select.Option>
              <Select.Option value={false}>No</Select.Option>
            </Select>
          </Form.Item>

          <br />

          <Divider orientation="left" orientationMargin="0">
            CRM Permissions
          </Divider>

          <Table
            dataSource={Object.keys(basePermissions).map((module) => ({
              module,
            }))}
            rowKey={'module'}
          >
            <Table.Column
              title="Modules"
              dataIndex="module"
              render={(text) => <strong>{startCase(text)}</strong>}
            />
            <Table.Column
              width={244}
              title="Permission"
              dataIndex="permission"
              render={(_, record: any) => {
                return (
                  <Select
                    placeholder="Select a permission"
                    value={_serializedPermissions[record.module] || undefined}
                    onChange={(value) => {
                      _setSerializedPermissions((prev) => ({
                        ...prev,
                        [record.module]: value,
                      }));
                    }}
                    allowClear
                    style={{ width: '100%' }}
                  >
                    {basePermissions[record.module].map((permission, i) => (
                      <Select.Option
                        key={`option-${i}${record.module}-${permission}`}
                        value={`${record.module}.${permission}`}
                      >
                        {startCase(permission)}
                      </Select.Option>
                    ))}
                  </Select>
                );
              }}
            />
          </Table>

          <div style={{ textAlign: 'right', marginTop: 32 }}>
            <Form.Item>
              <Button onClick={cancelEdit}>Cancel</Button>
              &nbsp;
              <Button type="primary" htmlType="submit" loading={loading}>
                Save
              </Button>
            </Form.Item>
          </div>
        </Form>
      </FormContainer>
    </Layout>
  );
};

export default AdminUserEdit;
