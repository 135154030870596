import { Descriptions, Form } from 'antd';
import styled from 'styled-components';

export const FormContainer = styled.div`
  .service-table {
    width: 100%;

    thead {
      tr {
        th {
          text-align: left;
          padding-bottom: 4px;
          font-size: 11px;
          text-transform: uppercase;
          font-weight: 600;
          color: #513e96;

          &:first-child {
            padding-left: 10px;
            /* width: 35%; */
          }

          &:last-child {
            text-align: right;
            padding-right: 10px;
          }
        }
      }
    }

    tbody {
      tr {
        background-color: #edf3f7;
        td {
          vertical-align: center;
          text-align: left;
          padding: 10px 0;

          .ant-form-item {
            margin-bottom: 0;
          }

          &:first-child {
            padding-left: 10px;
            font-weight: 600;
          }

          &:last-child {
            text-align: right;
            padding-right: 10px;
          }
        }
      }
    }
  }
`;

export const BrandDescription = styled(Descriptions)`
  .ant-descriptions-view {
    table {
      tbody {
        tr {
          .ant-descriptions-item {
            padding-bottom: 0;
          }

          &:nth-child(even) {
            .ant-descriptions-item {
              padding-bottom: 16px;
            }
          }
        }
      }
    }
  }
`;

export const FormButtons = styled(Form.Item)`
  margin-top: 2rem;

  .ant-form-item-control-input-content {
    display: flex;
    gap: 0.5rem;
  }
`;
