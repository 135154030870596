import styled from 'styled-components';

export const BrandsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-bottom: 16px;
`;

export const BrandCellContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding-left: 8px;
  overflow: hidden;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  font-size: 11px;
  font-weight: 500;

  > img {
    width: 24px;
    height: 24px;
    border-radius: 50%;
  }

  > button {
    font-size: 11px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    font-weight: 700;
  }
`;

export const ImportFileDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1rem;
`;

export const FiltersContainer = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
`;

export const StoreAddressAndConflicts = styled.div`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  gap: 1rem;

  & > div:not(.ant-divider) {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .ant-divider {
    height: 100%;
  }

  p {
    margin: unset;
  }

  @media not all and (min-width: 768px) {
    grid-template-columns: 1fr;
    gap: 2rem;

    .ant-divider {
      display: none;
    }
  }
`;

export const StoreConflicts = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  max-height: 20rem;
  overflow: auto;

  .ant-radio-wrapper {
    padding: 0.5rem;
    border: 1px solid #d9d9d9;
  }
`;

export const StoreConflictItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const ImportStorePopconfirmDescription = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  p {
    margin: unset;
  }
`;

export const ModalLoadingOverlay = styled.div`
  background-color: rgb(255 255 255 / 0.5);
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;
