import { FC, useEffect, useState } from 'react';
import { ImportedStoreModalData, StoreProps } from './types';
import { useTable } from '@/hooks';
import { Button, Table, Tag, Typography } from 'antd';
import { canImportStore, formatScore, getScoreColor } from './methods';
import ImportedStoreModal from './ImportedStoreModal';
import { useQuery } from '@tanstack/react-query';
import { getImportFileDetails } from '@/services/import.service';

type Props = {
  fileId: string;
  brandsId?: string[];
  actionFilter?: string[] | any;
  textFilter?: string;
  scoreFilter?: string;
  fullPage?: boolean;
  onChangeSelection?: (selected: StoreProps[]) => void;
  onChangeTotalItems?: (totalItems: number) => void;
  refreshCount?: number;
};

const DealerList: FC<Props> = ({
  fileId,
  brandsId = [],
  actionFilter = [],
  textFilter = '',
  scoreFilter = '',
  fullPage = false,
  onChangeSelection,
  onChangeTotalItems,
  refreshCount = 0,
}) => {
  const [selectedStores, setSelectedStores] = useState<StoreProps[]>([]);
  const [importedStoreModalData, setImportedStoreModalData] =
    useState<ImportedStoreModalData | null>(null);

  const { tableProps, refresh } = useTable<StoreProps>({
    service: 'import',
    path: `admin/store-list`,
    params: {
      fileId,
      filters: {
        text: textFilter,
        action: actionFilter,
        score: scoreFilter,
      },
    },
    perPage: fullPage ? 10 : 6,
  });

  const totalItems = tableProps?.pagination?.total || 0;

  useEffect(() => {
    onChangeTotalItems?.(totalItems);
  }, [totalItems]);

  useEffect(() => {
    refresh();
  }, [refreshCount]);

  useEffect(() => {
    onChangeSelection?.(selectedStores);
  }, [selectedStores]);

  const { data: importFileDetails, refetch: refetchImportFileDetails } =
    useQuery({
      queryKey: ['import-file-details', fileId],
      queryFn: async () => {
        const result = await getImportFileDetails(fileId);
        if (!result.status) {
          throw new Error(result.message);
        }
        return result.data;
      },
    });

  function onUpdateStoreAddress() {
    setImportedStoreModalData(null);
    // Refetch related data
    refetchImportFileDetails();
    refresh();
  }

  function onImportStore() {
    setImportedStoreModalData(null);
    // Refetch related data
    refetchImportFileDetails();
    refresh();
  }

  return (
    <>
      <Table
        {...tableProps}
        rowKey="id"
        tableLayout="fixed"
        className="clickable"
        rowSelection={{
          type: 'checkbox',
          getCheckboxProps: (record) => ({
            /* TODO: Should we allow to select stores that were created or
          connected with an existing store? */
            disabled: !canImportStore(record),
          }),
          selectedRowKeys: selectedStores.map((store) => store.id),
          onChange: (selectedRowKeys, selectedRows) => {
            setSelectedStores(selectedRows);
          },
        }}
        pagination={{
          ...tableProps.pagination,
          showSizeChanger: true,
        }}
      >
        <Table.Column
          title="Name"
          dataIndex="name"
          key="name"
          sorter
          render={(text, record: StoreProps) => (
            <Typography.Text>{record.name}</Typography.Text>
          )}
        />
        <Table.Column
          title="Address Sent"
          dataIndex={['payload', 'storeAddress']}
          key="payload.storeAddress"
          ellipsis
          render={(text, record: StoreProps) => (
            <Typography.Text>{record.payload.storeAddress}</Typography.Text>
          )}
        />

        {fullPage && (
          <Table.Column
            title="Address Processed"
            dataIndex="address"
            key="address"
            ellipsis
            render={(text, record: StoreProps) => {
              if (!record.addressProcessed) {
                return <Tag color="warning">Not Processed Yet</Tag>;
              }

              if (!record.address) {
                return <Tag color="error">Address Not Found</Tag>;
              }

              return (
                <Typography.Text>
                  {record.address.formattedAddress}
                </Typography.Text>
              );
            }}
          />
        )}
        {fullPage && (
          <Table.Column
            title="Situation"
            dataIndex="action"
            key="action"
            render={(text, record: StoreProps) => {
              const colorAndLabelByAction = {
                connected_existing: {
                  color: 'green',
                  label: 'Connected to Existing Store',
                },
                created: { color: 'blue', label: 'Created' },
                importing: { color: 'warning', label: 'Importing' },
              } satisfies Record<
                NonNullable<StoreProps['action']>,
                { color: string; label: string }
              >;

              const { color, label } =
                record.action !== undefined
                  ? colorAndLabelByAction[record.action]
                  : { color: 'default', label: 'Not Imported Yet' };

              return <Tag color={color}>{label}</Tag>;
            }}
          />
        )}
        <Table.Column
          title="Best Average Score"
          dataIndex="bestAverageScore"
          key="bestAverageScore"
          align="center"
          // TODO: Add sorting
          render={(text, record: StoreProps) => (
            <Tag color={getScoreColor(record.bestAverageScore)}>
              {formatScore(record.bestAverageScore, undefined)}
            </Tag>
          )}
        />
        <Table.Column
          title="Actions"
          dataIndex="actions"
          align="right"
          render={(text, record: StoreProps) => {
            // Do not allow editing stores that are being imported
            if (record.action === 'importing') {
              return null;
            }

            return (
              <Button
                onClick={() => {
                  setImportedStoreModalData({
                    id: record.id,
                    name: record.name,
                    status: record.status,
                    action: record.action,
                    address: record.address,
                    conflictStores: record.conflictStores,
                  });
                }}
              >
                Edit
              </Button>
            );
          }}
        />
      </Table>
      <ImportedStoreModal
        data={importedStoreModalData}
        idsOfBrandsToConnectWith={brandsId}
        scoreWeights={importFileDetails?.scoreWeights}
        onUpdateStoreAddress={onUpdateStoreAddress}
        onImportStore={onImportStore}
        onCancel={() => setImportedStoreModalData(null)}
      />
    </>
  );
};

export default DealerList;
