import { FC, useEffect, useState } from 'react';
import Layout from '@/components/Layout';
import { useSession } from '@/store/session';
import { useAudit } from '@/hooks';

const DashboardPage: FC = () => {
  const { state } = useSession();

  const user = state.get().user;
  const [fullName, setFullname] = useState(user?.fullName || '');

  const audit = useAudit({
    resourceName: 'initial-dashboard',
  });

  useEffect(() => {
    audit.onAccess();
  }, []);

  return (
    <Layout>
      <h1>Hi, {fullName}</h1>
    </Layout>
  );
};

export default DashboardPage;
