import {
  Button,
  Card,
  Col,
  Descriptions,
  Divider,
  Image,
  Modal,
  notification,
  Progress,
  Row,
  Space,
  Tabs,
  Tag,
  Typography,
} from 'antd';
import { FC, useEffect, useMemo, useState } from 'react';
import {
  getCampaignApprovalDetails,
  saveCampaignPartialReview,
  saveCampaignReview,
} from '@/services/campaign.service';
import { ApprovalFooter, ApprovalSection, ReviewListInfo } from './styles';
import SectionApproval from './SectionApproval';
import { Case, Default, Switch, When } from 'react-if';
import { formatISODate, formatMoney } from '@/utils';
import ReactPlayer from 'react-player';
import { map, reduce, startCase } from 'lodash';
import { IoMdCheckmarkCircle } from 'react-icons/io';
import { CampaignApprovalPayload } from './types';
import { rejectionReasons } from './constants';
import DealerImport from './DealerImportTab';
import {
  getCampaignStoresImportResult,
  getImportFileDetails,
} from '@/services/import.service';
import { useQuery } from '@tanstack/react-query';

type Props = {
  open: boolean;
  campaignId: string;
  onClose: () => void;
  onSave?: () => void;
};

const reviewIsPending = (review: { status: string; reason?: string }) => {
  if (!review || !review?.status || review?.status === 'pending') {
    return true;
  }

  if (review.status === 'rejected' && !review.reason) {
    return true;
  }

  return false;
};

const ApprovalModal: FC<Props> = ({ open, campaignId, onClose, onSave }) => {
  const [currentTab, setCurrentTab] = useState('missions-tab');
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [payload, setPayload] = useState<CampaignApprovalPayload | null>(null);
  const [missionOffset, setMissionOffset] = useState(0);
  const [dealerImport, setDealerImport] = useState({
    fileId: '',
    isAvailable: false,
    totalStores: 0,
    hasPendingStores: false,
  });
  const isUnderReview = payload?.campaign?.status === 'under_review';

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  useEffect(() => {
    if (isOpen) {
      setCurrentTab('missions-tab');
      setMissionOffset(0);
      setIsLoading(true);
      getCampaignApprovalDetails(campaignId).then(({ result }) => {
        setPayload(result);
        setIsLoading(false);

        loadDealerImportResults(result?.campaign?.storesImport?.fileId || '');
      });
    }
  }, [isOpen]);

  const currentMission = useMemo(() => {
    return payload?.missions[missionOffset] || null;
  }, [payload?.missions, missionOffset]);

  const loadDealerImportResults = async (fileId: string) => {
    if (!fileId) {
      setDealerImport((prev) => ({
        ...prev,
        isAvailable: false,
      }));

      return;
    }

    const result = await getCampaignStoresImportResult(fileId);

    setDealerImport((prev) => ({
      ...prev,
      isAvailable: true,
      fileId,
      totalStores: result.totalStores,
      hasPendingStores: result.hasPendingStores,
    }));
  };

  const getRejectionReasons = () => {
    const reasons: { section: string; reason: string }[] = [];

    if (!payload) {
      return reasons;
    }

    if (payload.campaign?.review?.name.status === 'rejected') {
      reasons.push({
        section: 'Campaign Name',
        reason: getRejectionReasonsString(
          payload.campaign?.review?.name.reason,
        ),
      });
    }

    if (payload.missions.length > 0) {
      for (const mission of payload.missions) {
        if (mission.review?.image?.status === 'rejected') {
          reasons.push({
            section: `Mission (${mission.title}), Image`,
            reason: getRejectionReasonsString(
              mission.review?.image?.reason || '',
            ),
          });
        }

        if (mission.review?.title?.status === 'rejected') {
          reasons.push({
            section: `Mission (${mission.title}), Title`,
            reason: getRejectionReasonsString(mission.review?.title?.reason),
          });
        }

        if (
          mission.instructionItems.length &&
          mission.review?.instructions?.status === 'rejected'
        ) {
          reasons.push({
            section: `Mission (${mission.title}), Instructions`,
            reason: getRejectionReasonsString(
              mission.review?.instructions?.reason || '',
            ),
          });
        }

        if (mission.actionItems.length > 0) {
          for (const actionItem of mission.actionItems) {
            if (actionItem.review?.status === 'rejected') {
              reasons.push({
                section: `Mission (${mission.title}), Action Item (${actionItem.title})`,
                reason: getRejectionReasonsString(actionItem.review?.reason),
              });
            }
          }
        }
      }
    }

    if (payload.rewards.length > 0) {
      for (const reward of payload.rewards) {
        if (reward.review?.status === 'rejected') {
          let section = `Reward (${reward.title}), ${startCase(reward.type)}`;

          if (reward.type === 'money') {
            section = `Reward (${formatMoney(reward.amount)}), Money`;
          }

          reasons.push({
            section,
            reason: getRejectionReasonsString(reward.review?.reason),
          });
        }
      }
    }

    return reasons;
  };

  const getRejectionReasonsString = (reasonType: string) => {
    return (
      rejectionReasons.find((reason: any) => reason.value === reasonType)
        ?.label || ''
    );
  };

  const progress = useMemo(() => {
    let total = 0;
    let pending = 0;
    let done = 0;
    let approvals = 0;
    let rejections = 0;

    if (!payload || payload.campaign?.review?.overview?.status !== 'pending') {
      return { total, pending, done, approvals, rejections };
    }

    total += 1;

    if (reviewIsPending(payload.campaign?.review?.name)) {
      pending += 1;
    } else if (payload.campaign?.review?.name.status === 'approved') {
      approvals += 1;
    } else {
      rejections += 1;
    }

    if (payload.missions.length > 0) {
      for (const mission of payload.missions) {
        if (mission.review?.image) {
          total += 1;
          if (reviewIsPending(mission.review.image)) {
            pending += 1;
          } else if (mission.review.image.status === 'approved') {
            approvals += 1;
          } else {
            rejections += 1;
          }
        }

        if (mission.review?.title) {
          total += 1;
          if (reviewIsPending(mission.review.title)) {
            pending += 1;
          } else if (mission.review.title.status === 'approved') {
            approvals += 1;
          } else {
            rejections += 1;
          }
        }

        if (mission.instructionItems.length && mission.review?.instructions) {
          total += 1;
          if (reviewIsPending(mission.review.instructions)) {
            pending += 1;
          } else if (mission.review.instructions.status === 'approved') {
            approvals += 1;
          } else {
            rejections += 1;
          }
        }

        if (mission.actionItems.length > 0) {
          for (const actionItem of mission.actionItems) {
            total += 1;
            if (reviewIsPending(actionItem.review)) {
              pending += 1;
            } else if (actionItem.review.status === 'approved') {
              approvals += 1;
            } else {
              rejections += 1;
            }
          }
        }
      }
    }

    if (payload.rewards.length > 0) {
      for (const reward of payload.rewards) {
        total += 1;

        if (reviewIsPending(reward.review)) {
          pending += 1;
        } else if (reward.review.status === 'approved') {
          approvals += 1;
        } else {
          rejections += 1;
        }
      }
    }

    if (dealerImport.isAvailable) {
      total += 1;
      if (dealerImport.hasPendingStores) {
        pending += 1;
      }
    }

    return {
      total,
      pending,
      approvals,
      rejections,
      done: total - pending,
    };
  }, [payload, dealerImport]);

  const onSaveReview = async (_status?: string) => {
    if (isLoading || progress.done < progress.total || !isUnderReview) {
      return;
    }

    setProcessing(true);

    try {
      const { status = false, message = '' } = await saveCampaignReview({
        bundleId: campaignId,
        status: _status,
        rejectionReasons: _status === 'rejected' ? getRejectionReasons() : [],
      });

      if (!status) {
        notification.error({
          message: 'Error',
          description: message || 'Failed to save review',
        });
        return;
      }

      notification.success({
        message: 'Success',
        description: 'Review saved',
      });
    } catch (error: any) {
      notification.error({
        message: 'Error',
        description: error.message || 'Failed to save review',
        placement: 'bottomRight',
      });
    }

    onClose();
    setProcessing(false);
    setIsOpen(false);
    onSave?.();
  };

  const savePartialReview = async (
    reviewEntity: string,
    reviewEntityId: string,
    payload: any,
  ) => {
    const result = await saveCampaignPartialReview({
      bundleId: campaignId,
      reviewEntity,
      reviewEntityId,
      reviewPayload: payload,
    });

    if (!result.status) {
      notification.error({
        message: 'Error',
        description: 'Failed to save partial review',
        placement: 'bottomRight',
      });
      return;
    }

    notification.success({
      message: 'Success',
      description: 'Partial review saved',
      placement: 'bottomRight',
    });
  };

  const importDetails = useQuery({
    queryKey: ['import-file-details', dealerImport.fileId],
    queryFn: async () => {
      const result = await getImportFileDetails(dealerImport.fileId);
      if (!result.status) {
        throw new Error(result.message);
      }
      return result.data;
    },
  });

  return (
    <Modal
      open={isOpen}
      loading={isLoading}
      title="Campaign Details"
      centered
      onCancel={() => {
        setIsOpen(false);
        onClose();
      }}
      onOk={() => {
        setIsOpen(false);
        onClose();
      }}
      width={1156}
      footer={
        <ApprovalFooter>
          <div className="content-progress">
            <Progress
              steps={progress.total}
              percent={
                progress.total > 0
                  ? Math.round((progress.done / progress.total) * 100)
                  : 0
              }
              size={[20, 20]}
            />
          </div>

          <Button
            disabled={
              progress.done < progress.total ||
              progress.rejections <= 0 ||
              !isUnderReview ||
              processing
            }
            type="primary"
            danger
            onClick={() => onSaveReview('rejected')}
          >
            Reject
          </Button>
          <Button
            disabled={
              progress.done < progress.total ||
              progress.rejections >= 1 ||
              !isUnderReview ||
              processing
            }
            type="primary"
            onClick={() => onSaveReview('approved')}
          >
            Approve Campaign
          </Button>
        </ApprovalFooter>
      }
    >
      <Row gutter={[16, 16]}>
        <Col span={17}>
          <Card>
            <Space>
              <Image
                width={32}
                height={32}
                src={payload?.brand?.logoUrl}
                preview={false}
              />
              <Space.Compact direction={'vertical'}>
                <Typography.Text strong>{payload?.brand?.name}</Typography.Text>
                <Typography.Text type={'secondary'} copyable>
                  {payload?.brand?.responsibleEmail}
                </Typography.Text>
              </Space.Compact>
            </Space>

            <Divider />

            <ApprovalSection>
              <div className="header">
                <div>
                  <Switch>
                    <Case condition={payload?.campaign.type === 'contest'}>
                      <Tag color="orange">Sales Incentive</Tag>
                    </Case>
                    <Case condition={payload?.campaign.type === 'learning'}>
                      <Tag color="blue">Digital Learning</Tag>
                    </Case>
                    <Case condition={payload?.campaign.type === 'consumer'}>
                      <Tag color="purple">Retail Ops</Tag>
                    </Case>
                    <Case condition={payload?.campaign.type === 'general'}>
                      <Tag color="green">Retail Ops</Tag>
                    </Case>
                  </Switch>
                </div>

                <SectionApproval
                  data={{
                    status: payload?.campaign.review?.name?.status || 'pending',
                    reason: payload?.campaign.review?.name?.reason || '',
                  }}
                  isDisabled={!isUnderReview}
                  onChange={(status, reason) => {
                    setPayload((prev: any) => {
                      const review = {
                        ...prev?.campaign?.review,
                        name: {
                          status,
                          reason,
                        },
                      };

                      savePartialReview('campaign', campaignId, review);

                      return {
                        ...prev,
                        campaign: {
                          ...prev?.campaign,
                          review,
                        },
                      };
                    });
                  }}
                />
              </div>

              <div>
                <Typography.Text strong>
                  {payload?.campaign.name}
                </Typography.Text>
              </div>

              <div>
                <Typography.Text type={'secondary'}>
                  <Space>
                    <When condition={payload?.campaign.launchedAt}>
                      <span>{formatISODate(payload?.campaign.launchedAt)}</span>
                    </When>
                    <span>to</span>
                    <When condition={payload?.campaign.endDate}>
                      <span>{formatISODate(payload?.campaign.endDate)}</span>
                    </When>
                  </Space>
                </Typography.Text>
              </div>
            </ApprovalSection>

            <Divider />

            <Tabs
              type="card"
              activeKey={currentTab}
              onChange={(tab) => setCurrentTab(tab)}
              items={
                [
                  {
                    label: 'Missions',
                    key: 'missions-tab',
                    children: (
                      <Space direction={'vertical'} style={{ width: '100%' }}>
                        <When
                          condition={
                            payload?.missions && payload?.missions.length > 1
                          }
                        >
                          <Button.Group>
                            {map(payload?.missions, (mission, index) => (
                              <Button
                                key={`mission-item-${index}`}
                                type={
                                  index === missionOffset
                                    ? 'primary'
                                    : 'default'
                                }
                                onClick={() => {
                                  setMissionOffset(index);
                                }}
                              >
                                Mission #{index + 1}
                              </Button>
                            ))}
                          </Button.Group>

                          <Divider style={{ margin: '8px 0 2px' }} />
                        </When>

                        <div key={`mission-review-${currentMission?.id}`}>
                          <ApprovalSection>
                            <div className="header">
                              <Typography.Text strong style={{ fontSize: 18 }}>
                                Mission Image
                              </Typography.Text>

                              <SectionApproval
                                isDisabled={!isUnderReview}
                                data={{
                                  status:
                                    currentMission?.review?.image?.status ||
                                    'pending',
                                  reason:
                                    currentMission?.review?.image?.reason || '',
                                }}
                                onChange={(status, reason) => {
                                  setPayload((prev: any) => {
                                    const review = {
                                      ...prev?.missions?.[missionOffset]
                                        ?.review,
                                      image: {
                                        status,
                                        reason,
                                      },
                                    };

                                    savePartialReview(
                                      'mission',
                                      prev?.missions?.[missionOffset]?.id,
                                      review,
                                    );

                                    const missions = prev?.missions;

                                    missions[missionOffset].review = review;

                                    return {
                                      ...prev,
                                      missions,
                                    };
                                  });
                                }}
                              />
                            </div>
                            <Image width={128} src={currentMission?.imageUrl} />
                          </ApprovalSection>

                          <Divider />

                          <ApprovalSection>
                            <div className="header">
                              <Typography.Text strong style={{ fontSize: 18 }}>
                                Mission Title
                              </Typography.Text>

                              <SectionApproval
                                isDisabled={!isUnderReview}
                                data={{
                                  status:
                                    currentMission?.review?.title?.status ||
                                    'pending',
                                  reason:
                                    currentMission?.review?.title?.reason || '',
                                }}
                                onChange={(status, reason) => {
                                  setPayload((prev: any) => {
                                    const review = {
                                      ...prev?.missions?.[missionOffset]
                                        ?.review,
                                      title: {
                                        status,
                                        reason,
                                      },
                                    };

                                    savePartialReview(
                                      'mission',
                                      prev?.missions?.[missionOffset]?.id,
                                      review,
                                    );

                                    const missions = prev?.missions;
                                    missions[missionOffset].review = review;

                                    return {
                                      ...prev,
                                      missions,
                                    };
                                  });
                                }}
                              />
                            </div>

                            <Typography.Text type={'secondary'}>
                              {currentMission?.title}
                            </Typography.Text>
                          </ApprovalSection>

                          {currentMission?.instructionItems &&
                            currentMission?.instructionItems.length > 0 && (
                              <>
                                <Divider />

                                <ApprovalSection>
                                  <div className="header">
                                    <Typography.Text
                                      strong
                                      style={{ fontSize: 18 }}
                                    >
                                      Instructions
                                    </Typography.Text>

                                    <SectionApproval
                                      isDisabled={!isUnderReview}
                                      data={{
                                        status:
                                          currentMission?.review?.instructions
                                            ?.status || 'pending',
                                        reason:
                                          currentMission?.review?.instructions
                                            ?.reason || '',
                                      }}
                                      onChange={(status, reason) => {
                                        setPayload((prev: any) => {
                                          const review = {
                                            ...prev?.missions?.[missionOffset]
                                              ?.review,
                                            instructions: {
                                              status,
                                              reason,
                                            },
                                          };

                                          savePartialReview(
                                            'mission',
                                            prev?.missions?.[missionOffset]?.id,
                                            review,
                                          );

                                          const missions = prev?.missions;
                                          missions[missionOffset].review =
                                            review;

                                          return {
                                            ...prev,
                                            missions,
                                          };
                                        });
                                      }}
                                    />
                                  </div>

                                  {currentMission?.instructionItems.map(
                                    (item, index) => (
                                      <div
                                        className="instruction-item"
                                        key={`instruction-item-${index}`}
                                      >
                                        <Typography.Text
                                          strong
                                          style={{ fontSize: 12 }}
                                        >
                                          Instruction #{index + 1} -{' '}
                                          <Tag>{startCase(item.type)}</Tag>
                                        </Typography.Text>

                                        <Switch>
                                          <Case
                                            condition={[
                                              'learn_title',
                                              'instruction',
                                              'learn_text',
                                            ].includes(item.type)}
                                          >
                                            <Typography.Paragraph
                                              type={'secondary'}
                                              ellipsis={{
                                                expandable: 'collapsible',
                                                rows: 2,
                                              }}
                                            >
                                              {item.title}
                                            </Typography.Paragraph>
                                          </Case>
                                          <Case
                                            condition={
                                              item.type === 'learn_media' &&
                                              item.subtype === 'video'
                                            }
                                          >
                                            <ReactPlayer
                                              width={230}
                                              height={129}
                                              url={item.fileArgs?.fileUrl}
                                              controls
                                              light={
                                                <img
                                                  src={item.previewUrl}
                                                  width={230}
                                                  alt="preview"
                                                />
                                              }
                                            />
                                          </Case>
                                          <Case
                                            condition={
                                              item.type === 'learn_media' &&
                                              item.subtype === 'image'
                                            }
                                          >
                                            <Image
                                              width={230}
                                              src={item.previewUrl}
                                            />
                                          </Case>
                                          <Case
                                            condition={
                                              item.type === 'share_post'
                                            }
                                          >
                                            <Space>
                                              {item.args?.previewUrl.length >
                                                0 && (
                                                <Image
                                                  width={129}
                                                  src={item.args.previewUrl}
                                                />
                                              )}
                                              <Typography.Paragraph
                                                type={'secondary'}
                                                ellipsis={{
                                                  expandable: 'collapsible',
                                                  rows: 6,
                                                }}
                                              >
                                                {item.args?.description}
                                              </Typography.Paragraph>
                                            </Space>
                                          </Case>

                                          {/* <Default>{JSON.stringify(item)}</Default> */}
                                        </Switch>
                                      </div>
                                    ),
                                  )}
                                </ApprovalSection>
                              </>
                            )}

                          {map(currentMission?.actionItems, (item, index) => (
                            <div key={`action-item-${index}`}>
                              <Divider />

                              <ApprovalSection>
                                <div className="header">
                                  <Typography.Text
                                    strong
                                    style={{ fontSize: 18 }}
                                  >
                                    Action: {startCase(item.type)}
                                  </Typography.Text>

                                  <SectionApproval
                                    isDisabled={!isUnderReview}
                                    data={{
                                      status: item.review?.status || 'pending',
                                      reason: item.review?.reason || '',
                                    }}
                                    onChange={(status, reason) => {
                                      setPayload((prev: any) => {
                                        const review = {
                                          ...prev?.missions?.[missionOffset]
                                            ?.actionItems?.[index]?.review,
                                          status,
                                          reason,
                                        };

                                        const actionItems =
                                          prev?.missions?.[missionOffset]
                                            .actionItems;

                                        savePartialReview(
                                          'mission_item',
                                          prev?.missions?.[missionOffset]
                                            ?.actionItems?.[index]?.id,
                                          review,
                                        );

                                        const missions = prev?.missions;

                                        actionItems[index].review = review;
                                        missions[missionOffset].actionItems =
                                          actionItems;

                                        return {
                                          ...prev,
                                          missions,
                                        };
                                      });
                                    }}
                                  />
                                </div>

                                <div>
                                  <Typography.Text type={'secondary'}>
                                    {item.title}
                                  </Typography.Text>
                                </div>

                                <Switch>
                                  <Case
                                    condition={
                                      item.type === 'survey_multiple_choice'
                                    }
                                  >
                                    <ul
                                      style={{
                                        paddingLeft: 20,
                                        listStyle: 'numeric',
                                      }}
                                    >
                                      {map(
                                        item.survey?.options,
                                        (option, index) => (
                                          <li key={`survey-option-${index}`}>
                                            <Typography.Text
                                              type={'secondary'}
                                              style={{ fontSize: 14 }}
                                            >
                                              {option.title}
                                            </Typography.Text>
                                          </li>
                                        ),
                                      )}
                                    </ul>
                                  </Case>
                                  <Case
                                    condition={
                                      item.type === 'quiz_multiple_choice_text'
                                    }
                                  >
                                    <ul
                                      style={{
                                        paddingLeft: 20,
                                        listStyle: 'numeric',
                                      }}
                                    >
                                      {map(
                                        item.quiz?.options,
                                        (option, index) => (
                                          <li
                                            key={`survey-option-${option.id}-${index}`}
                                          >
                                            <Typography.Text
                                              type={'secondary'}
                                              style={{ fontSize: 14 }}
                                            >
                                              {option.title}{' '}
                                              {option.id ===
                                              item.quiz?.rightAnswer ? (
                                                <IoMdCheckmarkCircle
                                                  size={16}
                                                  style={{
                                                    position: 'relative',
                                                    top: 3,
                                                    color: '#6f56bc',
                                                  }}
                                                />
                                              ) : (
                                                ''
                                              )}
                                            </Typography.Text>
                                          </li>
                                        ),
                                      )}
                                    </ul>
                                  </Case>
                                  <Case
                                    condition={
                                      item.type === 'survey_numeric_range'
                                    }
                                  >
                                    <Descriptions
                                      column={2}
                                      items={[
                                        {
                                          label: 'Min',
                                          children: `${item.survey?.min?.label} (${item.survey?.min?.value})`,
                                        },
                                        {
                                          label: 'Max',
                                          children: `${item.survey?.max?.label} (${item.survey?.max?.value})`,
                                        },
                                      ]}
                                    />
                                  </Case>
                                  <Case
                                    condition={
                                      item.type === 'quiz_multiple_choice_image'
                                    }
                                  >
                                    <ul
                                      style={{
                                        display: 'flex',
                                        listStyle: 'none',
                                        paddingLeft: 0,
                                        margin: 0,
                                        columnGap: 4,
                                      }}
                                    >
                                      {map(
                                        item.quiz?.options,
                                        (option, index) => (
                                          <li
                                            key={`survey-option-${option.id}-${index}`}
                                            style={{ position: 'relative' }}
                                          >
                                            {option.id ===
                                              item?.quiz?.rightAnswer && (
                                              <IoMdCheckmarkCircle
                                                size={18}
                                                style={{
                                                  position: 'absolute',
                                                  top: 4,
                                                  right: 4,
                                                  color: '#6f56bc',
                                                  zIndex: 1,
                                                }}
                                              />
                                            )}
                                            <Image
                                              width={128}
                                              src={option.imageUrl}
                                            />
                                          </li>
                                        ),
                                      )}
                                    </ul>
                                  </Case>
                                </Switch>
                              </ApprovalSection>
                            </div>
                          ))}
                        </div>
                      </Space>
                    ),
                  },
                  {
                    label: 'Rewards',
                    key: 'rewards-tab',
                    children: (
                      <Space direction={'vertical'} style={{ width: '100%' }}>
                        {map(payload?.rewards, (reward, index) => (
                          <div key={`reward-item-${index}`}>
                            {index > 0 && <Divider />}
                            <div className="reward-item">
                              <ApprovalSection>
                                <div className="header">
                                  <Typography.Text strong>
                                    {startCase(reward.type)}
                                  </Typography.Text>

                                  <SectionApproval
                                    isDisabled={!isUnderReview}
                                    data={{
                                      status:
                                        payload?.rewards[index].review
                                          ?.status || 'pending',
                                      reason:
                                        payload?.rewards[index].review
                                          ?.reason || '',
                                    }}
                                    onChange={(status, reason) => {
                                      setPayload((prev: any) => {
                                        const review = {
                                          ...prev?.rewards?.[index]?.review,
                                          status,
                                          reason,
                                        };

                                        savePartialReview(
                                          'reward',
                                          prev?.rewards?.[index]?.id,
                                          review,
                                        );

                                        const rewards = prev?.rewards;
                                        rewards[index].review = review;

                                        return {
                                          ...prev,
                                          rewards,
                                        };
                                      });
                                    }}
                                  />
                                </div>

                                <Switch>
                                  <Case condition={reward.type === 'money'}>
                                    <Descriptions column={1}>
                                      <Descriptions.Item label="Amount">
                                        {formatMoney(reward.amount)} per entry
                                      </Descriptions.Item>
                                      <Descriptions.Item label="Budget">
                                        {formatMoney(reward.budget || 0)}
                                      </Descriptions.Item>
                                      <Descriptions.Item label="Max Per User">
                                        {reward.maxPerUser <= 0
                                          ? 'Unlimited'
                                          : reward.maxPerUser}
                                      </Descriptions.Item>
                                    </Descriptions>
                                  </Case>
                                  <Case condition={reward.type === 'prize'}>
                                    <Descriptions column={1}>
                                      <Descriptions.Item label="Title">
                                        {reward.title}
                                      </Descriptions.Item>
                                      <Descriptions.Item label="Description">
                                        {reward.description}
                                      </Descriptions.Item>
                                      <Descriptions.Item label="Units">
                                        {reward.prizeUnits?.length || 0} Units
                                      </Descriptions.Item>
                                      <Descriptions.Item label="Image">
                                        <Image
                                          width={128}
                                          src={reward.imageUrl}
                                        />
                                      </Descriptions.Item>
                                      <Descriptions.Item label="Fine Print">
                                        {reward.finePrint}
                                      </Descriptions.Item>
                                    </Descriptions>
                                  </Case>

                                  <Default>
                                    <Descriptions column={1}>
                                      <Descriptions.Item label="Title">
                                        {reward.title}
                                      </Descriptions.Item>
                                      <Descriptions.Item label="Description">
                                        {reward.description}
                                      </Descriptions.Item>
                                      <Descriptions.Item label="Codes">
                                        {reward.codes?.length || 0} Codes
                                      </Descriptions.Item>
                                      <Descriptions.Item label="Max Per User">
                                        {reward.maxPerUser <= 0
                                          ? 'Unlimited'
                                          : reward.maxPerUser}
                                      </Descriptions.Item>
                                      <Descriptions.Item label="Fine Print">
                                        {reward.finePrint}
                                      </Descriptions.Item>
                                    </Descriptions>
                                  </Default>
                                </Switch>
                              </ApprovalSection>
                            </div>
                          </div>
                        ))}
                      </Space>
                    ),
                  },
                  dealerImport.isAvailable
                    ? {
                        label: 'Dealer Import',
                        key: 'dealer-import-tab',
                        children: (
                          <DealerImport
                            fileId={payload?.campaign?.storesImport?.fileId}
                            selected={
                              payload?.campaign?.storesImport?.selected || []
                            }
                            brandId={payload?.brand?.id || ''}
                            onChangePendingStores={(hasPending) => {
                              setDealerImport((prev) => ({
                                ...prev,
                                hasPendingStores: hasPending,
                              }));
                              importDetails.refetch();
                            }}
                          />
                        ),
                      }
                    : (null as any),
                ].filter(Boolean) as any[]
              }
            />
          </Card>
        </Col>
        <Col span={7}>
          <Switch>
            <Case condition={currentTab === 'dealer-import-tab'}>
              <Card title="Dealer List Validation Instructions">
                <Typography.Text strong>What You Need to Do:</Typography.Text>
                <br />
                <Typography.Text type="secondary">
                  Create or verify stores by checking if they already exist in
                  our system.
                </Typography.Text>
                <br />
                <br />
                <Typography.Text strong>How to Verify:</Typography.Text>
                <br />
                <Typography.Text type="secondary">
                  Create or verify stores by checking if they already exist in
                  our system.
                </Typography.Text>
                <ReviewListInfo>
                  <li>
                    <Typography.Text type="secondary">
                      Maximum Score: 1
                    </Typography.Text>
                  </li>
                  <li>
                    <Typography.Text type="secondary">
                      Minimum Score: 0
                    </Typography.Text>
                  </li>
                  <li>
                    <Typography.Text type="secondary">
                      Scores are based on similarity between the imported store
                      and the existing ones in the system.
                    </Typography.Text>
                  </li>
                  <li>
                    <Typography.Text type="secondary">
                      Some scores have different weights, indicated in
                      parentheses.
                    </Typography.Text>
                  </li>
                </ReviewListInfo>
                <br />
                <Typography.Text strong>Resume:</Typography.Text>
                {importDetails.data && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      rowGap: 8,
                    }}
                  >
                    <Tag color="error">
                      <strong>Stores with address not found:</strong>{' '}
                      {importDetails.data.storesWithAddressNotFound}
                    </Tag>

                    <Tag color="error">
                      <strong>Stores with score &lt; 0.5:</strong>{' '}
                      {importDetails.data.storesWithLowScore}
                    </Tag>

                    <Tag color="warning">
                      <strong>Stores with score &ge; 0.5 and &lt; 0.75:</strong>{' '}
                      {importDetails.data.storesWithMediumScore}
                    </Tag>

                    <Tag color="success">
                      <strong>Stores with score &ge; 0.75:</strong>{' '}
                      {importDetails.data.storesWithHighScore}
                    </Tag>
                    <Tag color="success">
                      <strong>Stores with no conflicts:</strong>{' '}
                      {importDetails.data.storesWithNoConflict}
                    </Tag>
                  </div>
                )}
              </Card>
            </Case>
            <Default>
              <Card title="Campaign Review Checklist">
                <Typography.Text strong>
                  What Should NOT Be Present:
                </Typography.Text>
                <br />
                <br />
                <Typography.Text strong>Inappropriate Images:</Typography.Text>
                <ReviewListInfo>
                  <li>
                    <Typography.Text type="secondary">
                      Logos or images that do not represent a real brand or are
                      inappropriate
                    </Typography.Text>
                  </li>
                  <li>
                    <Typography.Text type="secondary">
                      Use of copyrighted images without permission.
                    </Typography.Text>
                  </li>
                </ReviewListInfo>

                <Typography.Text strong>
                  Offensive or Non-Existent Names:
                </Typography.Text>
                <ReviewListInfo>
                  <li>
                    <Typography.Text type="secondary">
                      Campaign or brand names that are offensive or
                      inappropriate.
                    </Typography.Text>
                  </li>
                  <li>
                    <Typography.Text type="secondary">
                      Brands with names that already exist in the system.
                    </Typography.Text>
                  </li>
                </ReviewListInfo>

                <Typography.Text strong>False Information:</Typography.Text>
                <ReviewListInfo>
                  <li>
                    <Typography.Text type="secondary">
                      Campaigns or missions with misleading or incomplete
                      information.
                    </Typography.Text>
                  </li>
                  <li>
                    <Typography.Text type="secondary">
                      Fake campaigns or missions created with false data.
                    </Typography.Text>
                  </li>
                </ReviewListInfo>

                <Typography.Text strong>
                  Violation of Guidelines:
                </Typography.Text>
                <ReviewListInfo>
                  <li>
                    <Typography.Text type="secondary">
                      Any content that violates platform guidelines, such as
                      offensive language in mission instructions or campaign
                      names.
                    </Typography.Text>
                  </li>
                </ReviewListInfo>
              </Card>
            </Default>
          </Switch>
        </Col>
      </Row>
    </Modal>
  );
};

export default ApprovalModal;
