import { FC, useMemo } from 'react';
import { SalesIncentiveCompletion } from '@/types';
import { OverviewContainer } from './styles';
import StatusTag from './StatusTag';
import { formatISODate, formatMoney, getNameAvatarUrl } from '@/utils';
import { Avatar, Descriptions, Divider, Space, Typography } from 'antd';
import { get, startCase } from 'lodash';
import { Link } from 'react-router-dom';
import { ExternalLink } from 'lucide-react';
import { When } from 'react-if';
import { useSubmissionDialog } from '../dialog-hook';

type Args = {
  completion: SalesIncentiveCompletion;
};

const Overview: FC<Args> = ({ completion }) => {
  const submissionDialog = useSubmissionDialog();

  const descriptionLayout = useMemo(() => {
    return submissionDialog.state.mode === 'compare'
      ? 'vertical'
      : 'horizontal';
  }, [submissionDialog.state.mode]);

  return (
    <OverviewContainer>
      <div className="body">
        <Descriptions
          layout={descriptionLayout}
          title={'Completion'}
          size={'small'}
          column={2}
        >
          <Descriptions.Item label="ID">
            <Typography.Text copyable>{completion.id}</Typography.Text>
          </Descriptions.Item>
          <Descriptions.Item label="Status">
            <StatusTag
              status={completion.status}
              processing={completion.processing}
              size={'small'}
            />
          </Descriptions.Item>

          <Descriptions.Item label="Earnings">
            <When condition={!completion.earnings}>
              <span>N/A</span>
            </When>
            <When condition={completion.earnings}>
              <span>
                <strong style={{ fontWeight: 600 }}>
                  {formatMoney(
                    completion.earnings || 0,
                    completion.currency || 'USD',
                  )}
                </strong>{' '}
                {completion.currency}
              </span>
            </When>
          </Descriptions.Item>

          <Descriptions.Item label="Completed At">
            {formatISODate(completion.completedAt)}
          </Descriptions.Item>

          {completion.status === 'approved' && (
            <Descriptions.Item label="Approved At">
              {formatISODate(completion.completedAt)}
            </Descriptions.Item>
          )}

          {completion.status === 'rejected' && (
            <>
              <Descriptions.Item label="Rejected At">
                {formatISODate(completion.completedAt)}
              </Descriptions.Item>

              <Descriptions.Item label="Rejected Reason">
                {get(completion, 'rejectionArgs.rejectText', 'N/A')}
              </Descriptions.Item>
            </>
          )}

          <Descriptions.Item label="Mission">
            <Link to={`/mission/${completion.missionId}`} target={'_blank'}>
              {completion.mission?.title} <ExternalLink size={16} />
            </Link>
          </Descriptions.Item>

          <Descriptions.Item label="Campaign">
            <Link to={`/campaign/${completion.bundleId}`} target={'_blank'}>
              {completion.bundle?.name} <ExternalLink size={16} />
            </Link>
          </Descriptions.Item>

          <Descriptions.Item label="Campaign Type">
            {startCase(completion.bundle?.type)}
          </Descriptions.Item>

          <Descriptions.Item label="Brand">
            <Link to={`/brand/${completion.brandId}/manage`} target={'_blank'}>
              {completion.brand?.name} <ExternalLink size={16} />
            </Link>
          </Descriptions.Item>
        </Descriptions>

        <Divider />

        <Descriptions
          layout={descriptionLayout}
          title={'User Info'}
          size={'small'}
          column={2}
        >
          <Descriptions.Item label="User">
            <Space>
              <Avatar
                src={
                  completion.user.avatarUrl ||
                  getNameAvatarUrl(completion.user.fullName)
                }
                size={60}
              />
              <Space.Compact direction={'vertical'}>
                <Typography.Text strong>
                  {completion.user.fullName}
                </Typography.Text>
                <Typography.Text type={'secondary'}>
                  {completion.user.email}
                </Typography.Text>
              </Space.Compact>
            </Space>
          </Descriptions.Item>
          <Descriptions.Item label="Store">
            <Space.Compact direction={'vertical'}>
              <Typography.Text strong>{completion.store.name}</Typography.Text>
              <Typography.Text type={'secondary'}>
                {completion.store.address.formattedAddress}
              </Typography.Text>
            </Space.Compact>
          </Descriptions.Item>
        </Descriptions>
      </div>
    </OverviewContainer>
  );
};

export default Overview;
