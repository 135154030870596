import CryptoJS from 'crypto-js';
import { get, isEmpty } from 'lodash';

const storageKey = get(process.env, 'REACT_APP_STORAGE_KEY', 'endvrapp');
const APP_VERSION = '0.0.1';
const KEY = 'endvr:app';

/**
 * Set all app values
 * @param {Object} values
 */
export const setAllAppValues = (values: any) => {
  values['version'] = APP_VERSION;
  setItem(KEY, values);
};

/**
 * Set specific key app value
 * @param {string} key
 * @param values
 */
export const setAppValue = (key: string, values: any) => {
  let appValues = getAllAppValues();
  if (isEmpty(appValues)) {
    appValues = { version: APP_VERSION };
  }
  appValues[key] = values;
  setItem(KEY, appValues);
};

/**
 * Get all app values
 * @returns {Object}
 */
export const getAllAppValues = () => {
  return getItem(KEY);
};

/**
 * Get specific key app value
 * @param {string} key
 * @param {string|null} defaultValue
 * @returns {any}
 */
export const getAppValue = (key: string, defaultValue = null) => {
  const appValues = getAllAppValues();
  if (isEmpty(appValues)) {
    return defaultValue;
  }

  return appValues[key] || defaultValue;
};

export const removeAppValues = () => {
  localStorage.removeItem(KEY);
};

/* LOCAL METHODS */
export const setItem = (key: string, value: any) => {
  const encryptedData = CryptoJS.AES.encrypt(
    JSON.stringify(value),
    storageKey,
  ).toString();
  localStorage.setItem(key, encryptedData);
};

export const getItem = (key: string) => {
  const encryptedData = localStorage.getItem(key);
  if (!encryptedData) {
    return {};
  }
  const decryptedData = JSON.parse(
    CryptoJS.AES.decrypt(encryptedData, storageKey).toString(CryptoJS.enc.Utf8),
  );
  return decryptedData;
};

export const removeItem = (key: string) => {
  localStorage.removeItem(key);
};
