import { FC, useEffect, useState } from 'react';
import { Wrapper } from './styles';
import BrandPickerModal from '@/components/BrandPickerModal';
import { Case, Default, Switch, When } from 'react-if';
import { getBrandDetails } from '@/services/brand.service';
import { Avatar } from 'antd';
import { ShoppingOutlined } from '@ant-design/icons';

type Args = {
  value?: string;
  onChange?: (selected: string) => void;
  [key: string]: any;
};

const BrandForm: FC<Args> = ({ value = '', onChange = () => {} }) => {
  const loadSelectedBrand = async () => {
    if (!val) {
      setBrand(null);
      return;
    }

    const { brand } = await getBrandDetails(val);
    setBrand(brand || null);
  };

  const [brand, setBrand] = useState<{
    id: string;
    name: string;
    logoUrl: string;
  } | null>(null);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const [val, setVal] = useState<string>(value || '');

  const handleChange = (selected: string[]) => {
    if (selected.length === 0) {
      setVal('');
      onChange('');
      return;
    }

    setVal(selected[0]);
    onChange(selected[0]);
  };

  useEffect(() => {
    loadSelectedBrand();
  }, [val]);

  return (
    <>
      <Wrapper
        onClick={() => setModalOpen(true)}
        className={brand && val ? 'selected' : 'empty'}
        type={'button'}
      >
        <Switch>
          <Case condition={brand && val}>
            <Avatar
              src={brand?.logoUrl}
              icon={<ShoppingOutlined />}
              shape={'square'}
              size={48}
            />
            <span>{brand?.name}</span>
          </Case>
          <Default>
            <Avatar icon={<ShoppingOutlined />} shape={'square'} size={48} />
            <span>Select a brand</span>
          </Default>
        </Switch>
      </Wrapper>
      <BrandPickerModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        selected={[val]}
        onSelect={handleChange}
      />
    </>
  );
};

export default BrandForm;
