import styled from 'styled-components';

export const Container = styled.div`
  display: inline-flex;
  column-gap: 8px;

  button {
    display: flex;
    align-items: center;
    border: none;
    border-radius: 2px;
    margin-inline-start: 0px !important;

    &.approve {
      background-color: #52c41a;
      color: #fff;
    }

    &.reject {
      background-color: #ff4d4f;
      color: #fff;
    }

    &:hover {
      color: #f9f9f9 !important;
      border: none !important;
    }
  }
`;

export const ConfirmationPopupContainer = styled.div`
  width: 450px;
  max-width: 100%;
  border-top: 1px solid #e8e8e8;
  margin-top: 12px;
  padding-top: 16px;
`;
