import Layout from '@/components/Layout';
import PageHeader from '@/components/PageHeader';
import { useAudit, useTable } from '@/hooks';
import {
  Button,
  Space,
  Table,
  Input,
  Typography,
  Dropdown,
  MenuProps,
} from 'antd';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  ArrowRightOutlined,
  InfoOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { hookstate } from '@hookstate/core';
import { CompanyReadType } from '../types';
import { PiDotsThreeOutline, PiPencil } from 'react-icons/pi';
import { Container } from './styles';

import { ButtonLink } from '@/components/ButtonLink';

export type CompanyEditionDataState = {
  record: CompanyReadType;
};

export const companyEditionData = hookstate<CompanyEditionDataState>({
  record: {} as CompanyReadType,
});

const CompanyList = () => {
  const [filter, setFilter] = useState('');
  const { tableProps, refresh } = useTable<CompanyReadType>({
    service: 'brand/read',
    path: `admin/company/list`,
    params: {
      filter,
    },
    defaultSort: {
      field: 'name',
      order: 'ascend',
    },
    perPage: 10,
  });

  const audit = useAudit({
    resourceName: 'company-list',
  });

  useEffect(() => {
    audit.onAccess();
  }, []);

  const getTableMenuItems = (
    company: CompanyReadType,
  ): NonNullable<MenuProps['items']> => {
    const items: NonNullable<MenuProps['items']> = [
      {
        key: `company-menu-edit-${company.id}`,
        label: <Link to={`/company/${company.id}/edit`}>Edit</Link>,
        icon: <PiPencil size={20} />,
        onClick: () => {
          companyEditionData.record.set(company);
        },
      },
      {
        key: `company-menu-detail-${company.id}`,
        label: <Link to={`/company/${company.id}/detail`}>Detail</Link>,
        icon: <InfoOutlined size={20} />,
        onClick: () => {
          companyEditionData.record.set(company);
        },
      },
      {
        key: `company-menu-migration-${company.id}`,
        label: (
          <Link to={`/company/${company.id}/detail`}>Brand Migration</Link>
        ),
        icon: <ArrowRightOutlined size={20} />,
        disabled: true,
      },
    ];

    return items;
  };

  return (
    <Layout noCard={false}>
      <PageHeader noBackButton title="Companies">
        <Space>
          <Input.Search
            placeholder="Search by name, type, alias"
            allowClear
            onSearch={(value) => {
              audit.onClick({
                target: 'search-table',
              });
              setFilter(value);
            }}
            style={{ width: 260 }}
          />
          <ButtonLink
            to="/company/create"
            type="primary"
            icon={<PlusOutlined />}
          >
            Add Company
          </ButtonLink>
        </Space>
      </PageHeader>

      <Container>
        <Table {...tableProps} rowKey={'id'}>
          <Table.Column
            title="Name"
            dataIndex="name"
            key="name"
            sorter
            fixed="left"
            render={(text: any, record: CompanyReadType, index: number) => {
              return <Typography.Text ellipsis>{record.name}</Typography.Text>;
            }}
          />

          <Table.Column
            title="Type"
            dataIndex="type"
            key="type"
            align="center"
            sorter
            render={(text: any, record: CompanyReadType, index: number) => {
              return (
                <Typography.Text ellipsis>
                  {record.type === 'team' ? 'Brand' : 'Retailer'}
                </Typography.Text>
              );
            }}
          />
          <Table.Column
            title="Brands"
            dataIndex="brands"
            key="brands"
            align="center"
            sorter
            render={(_, record: CompanyReadType) => {
              return (
                <Typography.Text ellipsis>{record.totalBrands}</Typography.Text>
              );
            }}
          />
          <Table.Column
            title="Members"
            dataIndex="members"
            key="members"
            align="center"
            sorter
            render={(_, record: CompanyReadType) => {
              return (
                <Typography.Text ellipsis>
                  {record.totalMembers}
                </Typography.Text>
              );
            }}
          />
          <Table.Column
            title="Created At"
            dataIndex="createdAt"
            key="createdAt"
            align="center"
            sorter
            render={(_, record: CompanyReadType) => {
              return (
                <Typography.Text ellipsis>{record.createdAt}</Typography.Text>
              );
            }}
          />
          <Table.Column
            title="Last Update"
            dataIndex="updatedAt"
            key="updatedAt"
            align="center"
            sorter
            render={(_, record: CompanyReadType) => {
              return (
                <Typography.Text ellipsis>{record.updatedAt}</Typography.Text>
              );
            }}
          />

          <Table.Column
            title="Actions"
            dataIndex="actions"
            key="actions"
            align="right"
            fixed="right"
            render={(text: any, record: CompanyReadType, index: number) => {
              return (
                <Space>
                  <Dropdown menu={{ items: getTableMenuItems(record) }}>
                    <Button>
                      <PiDotsThreeOutline size={20} />
                    </Button>
                  </Dropdown>
                </Space>
              );
            }}
          />
        </Table>
      </Container>
    </Layout>
  );
};

export default CompanyList;
