import { handleError, httpGet } from './util/axios';

export const getMapDashboardPeriods = async () => {
  try {
    return httpGet('report/read', 'admin/dashboard-map-periods');
  } catch (error: any) {
    handleError(error);
  }
};

export const getDashboardData = async (category: string) => {
  try {
    return httpGet('report/read', `admin/dashboard-data?category=${category}`);
  } catch (error: any) {
    handleError(error);
  }
};
