import { GenericObject } from '@/types';
import { get, isEqual } from 'lodash';
import { DateTime } from 'luxon';

export const formatMoney = (amount: number | string, currency = 'USD') => {
  try {
    return new Intl.NumberFormat(navigator.language, {
      style: 'currency',
      currency,
    }).format(Number(amount) || 0);
  } catch (e) {
    return amount;
  }
};

export const formatMoneyWithoutCurrency = (amount: number | string) => {
  try {
    return new Intl.NumberFormat(navigator.language, {
      style: 'currency',
      currency: 'USD',
    })
      .format(Number(amount) || 0)
      .replace('$', '');
  } catch (e) {
    return amount;
  }
};

export const formatISODate = (
  date: string | any,
  format = 'LLL dd, yyyy hh:mm a',
) => {
  if (!date) return '-';

  return DateTime.fromISO(date).toFormat(format);
};

export const getObjectDiff = (
  obj1: GenericObject,
  obj2: GenericObject,
): GenericObject => {
  const diff = Object.keys(obj1).reduce((result, key) => {
    if (!obj2.hasOwnProperty(key)) {
      result.push(key);
    } else if (isEqual(obj1[key], obj2[key])) {
      const resultKeyIndex = result.indexOf(key);
      result.splice(resultKeyIndex, 1);
    }
    return result;
  }, Object.keys(obj2));

  let _diff: GenericObject = {};

  if (diff.length) {
    for (var key of diff) {
      _diff[key] = obj2[key];
    }
  }

  return _diff;
};

export const getNameAvatarUrl = (name: string) => {
  return `https://ui-avatars.com/api/?name=${name}&background=random`;
};

/**
 * Removes diacritics and does a compatibility normalization, which is ideal for
 * searching text.
 *
 * @see https://stackoverflow.com/questions/990904/remove-accents-diacritics-in-a-string-in-javascript
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/normalize#compatibility_normalization
 */
export function normalizeSearchString(text: string) {
  return text.normalize('NFKD').replace(/[\u0300-\u036f]/g, '');
}

export function getBaseAppUrl() {
  const env = get(process.env, 'REACT_APP_ENV', 'dev');
  return env === 'production'
    ? 'https://crm.endvr.io'
    : `https://crm.${env}.endvr.io`;
}
