import { FC, useEffect, useMemo, useState } from 'react';
import { SalesIncentiveCompletion } from '@/types';
import { AnswersContainer } from './styles';
import { find, get, map, orderBy, startCase } from 'lodash';
import { Space, Tag, InputNumber, Button, Select, notification } from 'antd';
import { Case, Default, Switch } from 'react-if';
import { getMissionItems } from '@/services/mission.service';
import { formatMoney } from '@/utils';
import { FaCheck } from 'react-icons/fa6';
import { useMutation } from '@tanstack/react-query';
import type { PinturaDefaultImageWriterResult } from '@pqina/pintura';

import {
  updateSalesIncentiveSalesAmount,
  updateSalesIncentiveMultipleChoiceSold,
  uploadCompletionReceiptImage,
} from '@/services/sales-incentive.service';

import { RedactableImage } from '@/components/RedactableImage';
import { MagnifiableImage } from '@/components/ImageMagnifier';

import { useSubmissionDialog } from '../dialog-hook';

type Args = {
  completion: SalesIncentiveCompletion;
};

const Answers: FC<Args> = ({ completion }) => {
  const submissionDialog = useSubmissionDialog();

  const [missionItems, setMissionItems] = useState<any[]>([]);

  useEffect(() => {
    loadMissionItems();
  }, []);

  const [isSalesAmountEditing, setIsEditing] = useState(false);
  const [
    isSurveyMultipleChoiceSoldEditing,
    setIsSurveyMultipleChoiceSoldEditing,
  ] = useState(false);
  const [number, setNumber] = useState<number>(0);
  const [selectedMultiChoiceId, setSelectedMultiChoiceId] =
    useState<string>('');
  const [selectedMultiChoiceValue, setSelectedMultiChoiceValue] =
    useState<string>('');

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleEditSurveyMultipleChoiceSold = () => {
    setIsSurveyMultipleChoiceSoldEditing(true);
  };

  const updateSalesAmount = (row: any) => {
    const payload = {
      amount: number,
      completionId: completion.id,
      missionItemId: row.item.id || '',
    };
    updateSalesIncentiveSalesAmount(payload);
    setNumber(number);
    setIsEditing(false);
  };

  const updateSurveyMultipleChoiceSold = (row: any) => {
    const payload = {
      completionId: completion.id,
      missionItemId: row.item.id || '',
      itemSold: selectedMultiChoiceId,
    };
    updateSalesIncentiveMultipleChoiceSold(payload);
    setIsSurveyMultipleChoiceSoldEditing(false);
  };

  const answers = useMemo(() => {
    if (!completion.answers || !missionItems.length) {
      return [];
    }

    const result = map(completion.answers, ({ missionItemId, ..._answer }) => {
      const item = find(missionItems, { id: missionItemId });
      let parsedAnswer: any = _answer.answer;
      let options = [];

      switch (_answer.type) {
        case 'survey_numeric_range':
          parsedAnswer = Number(_answer.answer) || 0;
          break;
        case 'enter_sales_amount':
          parsedAnswer = formatMoney(_answer.answer);
          setNumber(parseFloat(_answer.answer));
          break;
        case 'survey_like_dislike':
          parsedAnswer = startCase(_answer.answer);
          break;
        case 'survey_multiple_choice':
        case 'survey_multiple_choice_sold':
          options = get(item, 'survey.options', []);
          parsedAnswer = get(
            find(options, { id: _answer.answer }),
            'title',
            _answer.answer,
          );
          setSelectedMultiChoiceId(_answer.answer);
          setSelectedMultiChoiceValue(parsedAnswer);
          break;
        case 'quiz_multiple_choice_text':
          options = get(item, 'quiz.options', []);
          parsedAnswer = get(
            find(options, { id: _answer.answer }),
            'title',
            _answer.answer,
          );
          break;
        case 'quiz_multiple_choice_image':
          options = get(item, 'quiz.options', []);
          parsedAnswer = get(
            find(options, { id: _answer.answer }),
            'title',
            _answer.answer,
          );
          break;
      }

      return {
        ..._answer,
        parsedAnswer,
        item,
      };
    });

    return orderBy(result, 'item.order', 'asc');
  }, [completion.answers, missionItems]);

  const loadMissionItems = async () => {
    const result = await getMissionItems(completion.missionId);
    setMissionItems(result);
  };

  const {
    mutate: handleEditReceiptImage,
    isPending: isSavingEditedReceiptImage,
  } = useMutation({
    mutationFn: async (result: PinturaDefaultImageWriterResult) => {
      await uploadCompletionReceiptImage({
        completionId: completion.id,
        imageFile: result.dest,
      });
    },
    onSuccess: () => {
      notification.success({
        message: 'Success',
        description: 'Receipt image uploaded successfully',
      });
      submissionDialog.reloadCompletion();
    },
    onError: () => {
      notification.error({
        message: 'Error',
        description: 'Failed to upload receipt image',
      });
    },
  });

  return (
    <AnswersContainer>
      <div className="body">
        <div className="answers-list">
          {answers.map((row, index) => {
            let numericRangeOptions = [];

            if (row.type === 'survey_numeric_range') {
              const min = get(row.item, 'survey.min.value', 0);
              const max = get(row.item, 'survey.max.value', 0);
              numericRangeOptions = Array.from(
                { length: max - min + 1 },
                (_, i) => min + i,
              );
            }

            return (
              <div className="answer-item" key={`item-${row.type}-${index}`}>
                <div className="indicator-column">
                  <div className="indicator">
                    <span>{index + 1}</span>
                  </div>
                </div>
                <div className="data-column">
                  <div className="answer-title">
                    <Tag color="purple">{startCase(row.type)}</Tag>
                    <span className={'title'}>{row.item.title}</span>
                  </div>

                  <div className={`answer-data ${row.type}`}>
                    <Switch>
                      <Case condition={row.type === 'scan_image'}>
                        <RedactableImage
                          style={{ objectFit: 'contain' }}
                          src={row.parsedAnswer}
                          height={180}
                          alt="Receipt"
                          enableMagnifier
                          isSaving={isSavingEditedReceiptImage}
                          onEdit={handleEditReceiptImage}
                        />
                      </Case>
                      <Case
                        condition={[
                          'upload_image',
                          'quiz_multiple_choice_image',
                        ].includes(row.type)}
                      >
                        <MagnifiableImage
                          style={{ objectFit: 'contain' }}
                          src={row.parsedAnswer}
                          alt=""
                          height={180}
                          enablePreview
                        />
                      </Case>
                      <Case
                        condition={['enter_sales_amount'].includes(row.type)}
                      >
                        <span
                          style={{
                            display: 'inline-flex',
                            alignItems: 'center',
                            borderLeft: '2px solid #e3d7fa',
                            padding: '4px 0px 4px 8px',
                            fontSize: 14,
                            fontWeight: 500,
                          }}
                        >
                          {isSalesAmountEditing ? (
                            <div>
                              <InputNumber
                                min={1}
                                defaultValue={number}
                                onChange={(value) => {
                                  setNumber(value || 0);
                                }}
                              />
                              <Button
                                type="primary"
                                onClick={() => updateSalesAmount(row)}
                                style={{ borderRadius: 4, marginLeft: 8 }}
                              >
                                <FaCheck />
                              </Button>
                            </div>
                          ) : (
                            <span
                              onClick={() => {
                                handleEdit();
                              }}
                              style={{
                                cursor: 'pointer',
                                padding: 0,
                                margin: 0,
                              }}
                            >
                              {formatMoney(number)}
                            </span>
                          )}
                        </span>
                      </Case>
                      <Case condition={row.type === 'survey_numeric_range'}>
                        <Space.Compact>
                          {numericRangeOptions.map((option, index) => {
                            return (
                              <Tag
                                key={`option-${index}`}
                                color={
                                  option === row.parsedAnswer
                                    ? '#108ee9'
                                    : 'default'
                                }
                              >
                                {option}
                              </Tag>
                            );
                          })}
                        </Space.Compact>
                      </Case>
                      <Case
                        condition={row.type === 'survey_multiple_choice_sold'}
                      >
                        {isSurveyMultipleChoiceSoldEditing ? (
                          <div>
                            <Select
                              options={row?.item?.survey?.options?.map(
                                (option: { id: string; title: string }) => ({
                                  value: option.id,
                                  label: <span>{option.title}</span>,
                                }),
                              )}
                              style={{ width: '50%' }}
                              onChange={(value) => {
                                setSelectedMultiChoiceValue(
                                  get(
                                    find(row.item.survey.options, {
                                      id: value,
                                    }),
                                    'title',
                                    selectedMultiChoiceId,
                                  ),
                                );
                                setSelectedMultiChoiceId(value);
                              }}
                            />
                            <Button
                              type="primary"
                              onClick={() => {
                                updateSurveyMultipleChoiceSold(row);
                              }}
                              style={{ borderRadius: 4, marginLeft: 8 }}
                            >
                              <FaCheck />
                            </Button>
                          </div>
                        ) : (
                          <span
                            onClick={() => {
                              handleEditSurveyMultipleChoiceSold();
                            }}
                            style={{
                              cursor: 'pointer',
                              padding: 0,
                              margin: 0,
                            }}
                          >
                            {selectedMultiChoiceValue}
                          </span>
                        )}
                      </Case>
                      <Default>
                        <span
                          style={{
                            whiteSpace: 'pre-line',
                            borderLeft: '2px solid #e3d7fa',
                            padding: '4px 0px 4px 8px',
                            fontSize: 14,
                            fontWeight: 500,
                          }}
                        >
                          {row.parsedAnswer}
                        </span>
                      </Default>
                    </Switch>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </AnswersContainer>
  );
};

export default Answers;
