import { useAudit, useTable } from '@/hooks';
import { TabContainer } from './styles';
import { TableContainer } from '@/styles';
import { Button, Table, Typography, Form, Input, Space, Avatar } from 'antd';
import { formatISODate } from '@/utils';
import { PlusOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { useSession } from '@/store';
import { Brand } from '@/types';
import { get, startCase } from 'lodash';
import { BiUser } from 'react-icons/bi';

type TableData = {
  fullName: string;
  email: string;
  role: string;
  avatarUrl: string;
  isConfirmed: boolean;
  createdAt: string;
  updatedAt: string;
  language: 'en';
  profile: {
    id: string;
    name: string;
  };
  id: string;
};

type Params = {
  brand: Brand | any;
  onRefresh: () => void;
};

const WalletTab = ({ brand, onRefresh }: Params) => {
  const audit = useAudit({
    resourceName: 'brand-members-tab',
  });

  useEffect(() => {
    audit.onAccess();
  }, []);

  const session = useSession();
  const [form] = Form.useForm();
  const [filter, setFilter] = useState('');
  const { tableProps, refresh } = useTable<TableData>({
    service: 'user/read',
    path: `admin/web-user/list-by-brand/${brand.id}`,
    params: {
      filters: {
        text: filter,
      },
    },
    defaultSort: {
      field: 'fullName',
      order: 'ascend',
    },
  });

  return (
    <TabContainer>
      <TableContainer>
        <Table
          {...tableProps}
          rowKey={'id'}
          title={() => (
            <div className="table-header">
              <div className="title"></div>
              <div className="actions">
                <Space>
                  <Input.Search
                    placeholder="Search by name or email"
                    allowClear
                    onSearch={(value) => {
                      setFilter(value);
                    }}
                    style={{ width: 260 }}
                  />
                  <Button type="primary" icon={<PlusOutlined />} disabled>
                    Invite Member
                  </Button>
                </Space>
              </div>
            </div>
          )}
        >
          <Table.Column
            title="Name"
            dataIndex="fullName"
            key="fullName"
            sorter
            fixed="left"
            render={(text: any, record: TableData, index: number) => {
              return (
                <Space className="user-column">
                  <Avatar
                    src={record.avatarUrl}
                    style={{ width: 48, height: 48 }}
                    icon={<BiUser />}
                    shape="circle"
                    size={48}
                  />

                  <Space direction="vertical" className="info">
                    <Typography.Title level={5}>
                      {record.fullName}
                    </Typography.Title>
                    <Typography.Text copyable style={{ fontSize: 13 }}>
                      {record.email}
                    </Typography.Text>
                  </Space>
                </Space>
              );
            }}
          />

          <Table.Column
            title="Company Role"
            dataIndex="role"
            key="role"
            align="center"
            render={(role) => {if(role ==='-') return "-"; else return startCase(role);}}
          />
          <Table.Column
            title="Brand Profile"
            dataIndex="profile"
            key="role"
            align="center"
            render={(profile) => get(profile, 'name', '-')}
          />
          <Table.Column
            title="Registered At"
            dataIndex="createdAt"
            sorter
            defaultSortOrder={'descend'}
            align="center"
            render={(text) => {
              return (
                <div className="transaction-column-date">
                  <strong>{formatISODate(text, 'DD')}</strong>
                  <small>{formatISODate(text, 't')}</small>
                </div>
              );
            }}
          />
        </Table>
      </TableContainer>
    </TabContainer>
  );
};

export default WalletTab;
