import { Button, Divider, Modal, Radio, RadioChangeEvent, Typography } from "antd";
import { useEffect, useState } from "react";
import GroupPicker from "./GroupPicker";
import StorePicker from "./StorePicker";
import { Container, ModalFooter } from "./styles";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: ({ selectedIds, scope }: any) => void;
  type?: 'all' | 'store' | 'group' | string;
  brandId?: string;
  brandName?: string;
  selected?: string[];
};

const StoreGroupModalSelector = ({ isOpen, onClose, onConfirm, type = 'all', brandId, brandName, selected }: Props) => {
  const [scope, setScope] = useState(type);
  // const [selectedIds, setSelectedIds] = useState<string[]>([]);

  useEffect(() => {
    // setSelectedIds(selected || []);
    setScope(type);
  }
    , [selected, type]);

  const onChange = (e: RadioChangeEvent) => {
    setScope(e.target.value);
  };

  const handleOnConfirm = (scopeSelectedIds: string[]) => {
    // setSelectedIds(scopeSelectedIds);
    onConfirm({ selectedIds: scopeSelectedIds, scope: scope });
  }

  const handleOnClose = () => {
    // setSelectedIds([]);
    setScope('all');
    onClose();
  }

  return (
    <Modal
      title="Scope Selector"
      open={isOpen}
      centered
      footer={null}
      onCancel={handleOnClose}
      rootClassName="store-group-modal-selector"
    >
      <Container>
        <div>
          {brandName && <Typography.Title level={5}>{brandName}</Typography.Title>}
          <Divider style={{ marginBottom: 8 }} orientation="left" orientationMargin={0}>Visibility</Divider>
          <Radio.Group onChange={onChange} value={scope}>
            <Radio value={'all'}>All</Radio>
            <Radio value={'store'}>Stores</Radio>
            <Radio value={'group'}>Groups</Radio>
          </Radio.Group>
        </div>
        {
          scope === 'store' && (
            <StorePicker
              selected={type === 'store' ? selected || [] : []}
              brandId={brandId}
              onClose={handleOnClose}
              onConfirm={handleOnConfirm}
            />
          )
        }
        {
          scope === 'group' && (
            <GroupPicker
              selected={type === 'group' ? selected || [] : []}
              brandId={brandId}
              onClose={handleOnClose}
              onConfirm={handleOnConfirm}
            />
          )
        }
        {
          scope === 'all' && (
            <ModalFooter>
              <div />
              <div className="actions">
                <Button
                  onClick={handleOnClose}
                >
                  Cancel
                </Button>
                <Button
                  type="primary"
                  onClick={() => handleOnConfirm([])}
                >
                  Confirm
                </Button>
              </div>
            </ModalFooter>
          )
        }
      </Container>
    </Modal>
  );
}

export default StoreGroupModalSelector;