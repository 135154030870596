import { useAudit, useTable } from '@/hooks';
import { WalletTabContainer } from './styles';
import { TableContainer } from '@/styles';
import {
  Button,
  Space,
  Table,
  Modal,
  Form,
  notification,
  Typography,
  Popover,
  Input,
  Tag,
  Dropdown,
  MenuProps,
} from 'antd';
import { useEffect, useState } from 'react';
import {
  addIntoWhiteListDeviceUser,
  blockDeviceUser,
  removeFromWhiteListDeviceUser,
  unblockDeviceUser,
} from '@/services/mobile-user.service';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  InfoCircleFilled,
} from '@ant-design/icons';
import { isEmpty } from 'lodash';
import { Case, Default, Switch } from 'react-if';
import { PiDotsThreeOutline, PiPencil } from 'react-icons/pi';

import { useSession } from '@/store';

type TableData = {
  id: string;
  uid: string;
  userId: string;
  appVersion: string;
  createdAt: string;
  updatedAt: string;
  language: string;
  model: string;
  os: string;
  timezone: string;
  banned: {
    id: string;
    notes: string;
  };
  whitelisted: {
    id: string;
    notes: string;
  };
};

type Params = {
  userId: string;
  isHighRiskLevel: boolean;
  onRefresh: () => void;
};

const DeviceTab = ({ userId, isHighRiskLevel, onRefresh }: Params) => {
  const session = useSession();

  const { tableProps, refresh } = useTable<TableData>({
    service: 'user/read',
    path: `admin/mobile-device/${userId}/devices`,
    perPage: 10,
  });

  const [form] = Form.useForm();
  const [modalForm, setModalForm] = useState({
    modalOpen: false,
    loading: false,
    uid: '',
    userId,
    notes: '',
    modalType: '',
    modalTitle: '',
    executeOperation: '',
  });

  const audit = useAudit({
    resourceName: 'mobile-user-device-tab',
  });

  useEffect(() => {
    audit.onAccess();
  }, []);

  const onExecuteModal = async () => {
    setModalForm((prev) => ({ ...prev, loading: true }));

    const { uid, notes, modalType, executeOperation } = modalForm;

    if (modalType === 'banned' && executeOperation === 'write') {
      audit.onClick({ target: 'block-device-user' });
      const { status } = await blockDeviceUser({
        userId,
        uid,
        notes,
      });

      if (status) {
        notification.success({
          message: 'User device blocked successfully',
          placement: 'bottomRight',
          duration: 2,
        });
      } else {
        notification.error({
          message: 'Failed to block user device',
          placement: 'bottomRight',
          duration: 2,
        });
      }
    } else if (modalType === 'banned' && executeOperation === 'delete') {
      audit.onClick({ target: 'unblock-device-user' });
      const { status } = await unblockDeviceUser({
        userId,
        uid,
      });

      if (status) {
        notification.success({
          message: 'User device unblocked successfully',
          placement: 'bottomRight',
          duration: 2,
        });
      } else {
        notification.error({
          message: 'Failed to unblock user device',
          placement: 'bottomRight',
          duration: 2,
        });
      }
    } else if (modalType === 'whitelist' && executeOperation === 'write') {
      audit.onClick({ target: 'add-device-user-whitelist' });
      const { status } = await addIntoWhiteListDeviceUser({
        userId,
        uid,
        notes,
      });

      if (status) {
        notification.success({
          message: 'User device added to whitelist successfully',
          placement: 'bottomRight',
          duration: 2,
        });
      } else {
        notification.error({
          message: 'Failed to add user device to whitelist',
          placement: 'bottomRight',
          duration: 2,
        });
      }
    } else if (modalType === 'whitelist' && executeOperation === 'delete') {
      audit.onClick({ target: 'remove-device-user-whitelist' });
      const { status } = await removeFromWhiteListDeviceUser({
        userId,
        uid,
      });

      if (status) {
        notification.success({
          message: 'User device removed from whitelist successfully',
          placement: 'bottomRight',
          duration: 2,
        });
      } else {
        notification.error({
          message: 'Failed to remove user device from whitelist',
          placement: 'bottomRight',
          duration: 2,
        });
      }
    }

    setTimeout(() => {
      refresh();
      setModalForm((prev) => ({ ...prev, modalOpen: false, loading: true }));
    }, 500);
  };

  const getTableMenuItems = (record: any): NonNullable<MenuProps['items']> => {
    const isBanned = isEmpty(record.banned);
    const banned = isBanned ? 'Block' : 'Unblock';
    const iconBanned = isBanned ? (
      <CloseCircleOutlined size={20} />
    ) : (
      <CheckCircleOutlined size={20} />
    );

    const isWhiteList = isEmpty(record.whitelisted);
    const whiteList = isWhiteList
      ? 'Add into whitelist'
      : 'Remove from whitelist';
    const iconWhiteList = isWhiteList ? (
      <CheckCircleOutlined size={20} />
    ) : (
      <CloseCircleOutlined size={20} />
    );

    const items: NonNullable<MenuProps['items']> = [
      {
        key: `menu-edit-${record.banned.id}`,
        label: banned,
        icon: iconBanned,
        onClick: () =>
          setModalForm((prev) => ({
            ...prev,
            modalOpen: true,
            loading: false,
            userId: userId,
            uid: record.uid,
            notes: '',
            modalType: 'banned',
            modalTitle: isBanned
              ? 'Block this device for the selected user?'
              : 'Unblocking this device for the selected user',
            executeOperation: isBanned ? 'write' : 'delete',
          })),
      },
      {
        type: 'divider',
      },
      {
        key: `menu-edit-${record.whitelisted.id}`,
        label: whiteList,
        icon: iconWhiteList,
        onClick: () =>
          setModalForm((prev) => ({
            ...prev,
            modalOpen: true,
            loading: false,
            userId: userId,
            uid: record.uid,
            notes: '',
            modalType: 'whitelist',
            modalTitle: isWhiteList
              ? 'Add this device into whitelist'
              : 'Remove this device from whitelist',
            executeOperation: isWhiteList ? 'write' : 'delete',
          })),
      },
    ];

    return items;
  };

  return (
    <WalletTabContainer>
      <TableContainer>
        <Table {...tableProps} rowKey={'id'}>
          <Table.Column
            title="Uid"
            dataIndex="uid"
            key="uid"
            sorter
            render={(text: any, record: any, index: number) => (
              <Typography.Text style={{ whiteSpace: 'pre-line' }} copyable>
                {record.uid}
              </Typography.Text>
            )}
          />
          <Table.Column
            title="App Version"
            dataIndex="appVersion"
            key="appVersion"
            align="center"
            sorter
          />
          <Table.Column
            title="Language"
            dataIndex="language"
            key="language"
            align="center"
            sorter
            render={(text: any, record: any, index: number) => {
              return (
                <Typography.Text>
                  {record.language === 'en' ? 'English' : 'French'}
                </Typography.Text>
              );
            }}
          />
          <Table.Column
            title="Model"
            dataIndex="model"
            key="model"
            align="center"
            sorter
          />
          <Table.Column
            title="OS"
            dataIndex="os"
            key="os"
            align="center"
            sorter
          />
          <Table.Column
            title="Timezone"
            dataIndex="timezone"
            key="timezone"
            align="center"
            sorter
          />

          <Table.Column
            title="Banned?"
            dataIndex="banned"
            key="banned"
            align="center"
            sorter
            render={(text: any, record: any, index: number) => {
              return (
                <Switch>
                  <Case condition={isEmpty(record.banned)}>
                    <Tag color="success" bordered={false}>
                      No
                    </Tag>
                  </Case>
                  <Default>
                    <Tag color="error" bordered={false}>
                      Yes &nbsp;&nbsp;
                      {record?.banned[0]?.notes && (
                        <Popover content={record.banned[0].notes}>
                          <InfoCircleFilled />
                        </Popover>
                      )}
                    </Tag>
                  </Default>
                </Switch>
              );
            }}
          />

          <Table.Column
            title="Whitelisted?"
            dataIndex="whitelisted"
            key="whitelisted"
            align="center"
            sorter
            render={(text: any, record: any, index: number) => {
              return (
                <Switch>
                  <Case condition={!isEmpty(record.whitelisted)}>
                    <Tag color="success" bordered={false}>
                      Yes &nbsp;&nbsp;
                      {record?.whitelisted[0]?.notes && (
                        <Popover content={record.whitelisted[0].notes}>
                          <InfoCircleFilled />
                        </Popover>
                      )}
                    </Tag>
                  </Case>
                  <Default>
                    <Tag color="default" bordered={false}>
                      No
                    </Tag>
                  </Default>
                </Switch>
              );
            }}
          />

          {session.hasPermission('users.edit') && (
            <Table.Column
              title="Actions"
              dataIndex="actions"
              key="actions"
              align="right"
              render={(text: any, record: any, index: number) => {
                return (
                  <Space>
                    {!isHighRiskLevel && (
                      <Dropdown menu={{ items: getTableMenuItems(record) }}>
                        <Button>
                          <PiDotsThreeOutline size={20} />
                        </Button>
                      </Dropdown>
                    )}
                  </Space>
                );
              }}
            />
          )}
        </Table>
      </TableContainer>

      <Modal
        title={modalForm.modalTitle}
        open={modalForm.modalOpen}
        centered
        okButtonProps={{
          loading: modalForm.loading,
          disabled:
            modalForm.notes.length < 1 &&
            modalForm.executeOperation === 'write',
        }}
        afterOpenChange={(open) => {
          if (!open) {
            form.resetFields();
          }
        }}
        onOk={onExecuteModal}
        onCancel={() => {
          setModalForm((prev) => ({ ...prev, modalOpen: false, notes: '' }));
        }}
        destroyOnClose={true}
      >
        <Form layout="vertical" autoComplete="off" form={form}>
          {modalForm.executeOperation === 'write' &&
            (modalForm.modalType === 'banned' ||
              modalForm.modalType === 'whitelist') && (
              <Form.Item
                name="notes"
                label="Notes"
                rules={[{ required: true, message: 'Please enter notes' }]}
              >
                <Input.TextArea
                  placeholder="Please enter notes for this action."
                  value={modalForm.notes}
                  onChange={({ target }) =>
                    setModalForm((prev) => ({
                      ...prev,
                      notes: target?.value,
                    }))
                  }
                  rows={4}
                />
              </Form.Item>
            )}
        </Form>
      </Modal>
    </WalletTabContainer>
  );
};

export default DeviceTab;
