import { hookstate, useHookstate } from '@hookstate/core';
import { notification } from 'antd';

import {
  requestLoginVerificationCode,
  verifyLoginCode,
} from '@/services/authentication.service';
import {
  getCurrentUser,
  getCurrentUserPermissions,
} from '@/services/admin-user.service';
import { setAppValue, getAppValue } from '@/utils/storage';
import { get } from 'lodash';

type User = {
  id: string;
  email: string;
  fullName: string;
  roles: string[];
  sector?: string;
};

type Session = {
  user: User | null;
  permissions: string[];
  isLoggedIn: boolean;
  sessionLoaded?: boolean;
};

const sessionState = hookstate<Session>({
  user: null,
  isLoggedIn: false,
  permissions: [],
  sessionLoaded: false,
});

export const useSession = () => {
  const state = useHookstate(sessionState);

  const hasPermission = (permission: string, ...extraChecks: Boolean[]) => {
    if (permission === '*') {
      return true;
    }

    const _permission = permission.split('.');
    const _prefix = get(_permission, '[0]', null);
    const _suffix = get(_permission, '[1]', null);

    const userPermissions = state.get().permissions;

    const extraOk = extraChecks.every((check) => check);

    if (_suffix === '*') {
      const allPrefixes = userPermissions.map((p) =>
        get(p.split('.'), '[0]', null),
      );

      return allPrefixes.includes(_prefix) && extraOk;
    }

    const permOk =
      userPermissions.includes(permission) ||
      userPermissions.includes(`${_prefix}.edit`);

    return permOk && extraOk;
  };

  const loadSession = async () => {
    const token = getAppValue('accessToken');

    if (token) {
      try {
        const { user }: any = await getCurrentUser();
        const { permissions = [] }: any = await getCurrentUserPermissions();

        if (user) {
          state.merge({
            user,
            isLoggedIn: true,
            sessionLoaded: true,
            permissions,
          });
        } else {
          state.merge({
            user: null,
            isLoggedIn: false,
            sessionLoaded: true,
            permissions: [],
          });
        }

        return !!user;
      } catch (error: any) {
        console.error(`fail on loadSession => ${error}`);
      }
    }

    state.merge({
      user: null,
      isLoggedIn: false,
      sessionLoaded: true,
      permissions: [],
    });

    return false;
  };

  const requestVerificationCode = async (email: string) => {
    const { status, message }: any = await requestLoginVerificationCode(email);

    if (status) {
      notification.success({
        message,
        placement: 'bottomRight',
        duration: 2,
      });

      return true;
    }

    notification.error({
      message,
      placement: 'bottomRight',
      duration: 2,
    });

    return false;
  };

  const verifyCode = async (email: string, code: string) => {
    const { token, user, status }: any = await verifyLoginCode(email, code);

    if (status) {
      state.merge({ user, isLoggedIn: true });

      setAppValue('accessToken', token);
      setAppValue('user', user);

      notification.success({
        message: 'Login successful',
        placement: 'bottomRight',
        duration: 2,
      });

      return true;
    }

    notification.error({
      message: 'Login failed',
      placement: 'bottomRight',
      duration: 2,
    });

    return false;
  };

  const onLogout = () => {
    state.merge({ user: null, isLoggedIn: false, permissions: [] });

    setAppValue('accessToken', '');
  };

  return {
    state,
    requestVerificationCode,
    verifyCode,
    loadSession,
    hasPermission,
    onLogout,
  };
};
