import { useAudit, useTable } from '@/hooks';
import {
  Popover,
  Statistic,
  Table,
  Button,
  Row,
  Col,
  Input,
  Space,
  Typography,
  Dropdown,
  notification,
  MenuProps,
} from 'antd';
import { FC, useEffect, useMemo, useState } from 'react';
import { TableContainer } from '@/styles';
import { SiCodereview } from 'react-icons/si';
import { CgEye, CgSoftwareDownload } from 'react-icons/cg';
import { ListContainer } from './styles';
import { Link } from 'react-router-dom';
import { formatISODate } from '@/utils';
import { PiDotsThreeOutline } from 'react-icons/pi';
import { GrView } from 'react-icons/gr';
import { useSession } from '@/store';
import { resendJobToKafka, reactivateJob } from '@/services/queue.service';
import { MdSendAndArchive } from 'react-icons/md';

type Props = {
  status: string;
  listName?: string | undefined;
};

type TableData = {
  id: string;
  listName: string;
  status: string;
  duration: number;
  statusChangedAt: number;
  createdAt: number;
  updatedAt: number;
  data: object;
};

const StatusList: FC<Props> = ({ status, listName }) => {
  const session = useSession();
  const [filters, setFilters] = useState('');

  const scope = useMemo(() => {
    return listName ? 'list' : 'all';
  }, [listName]);

  const audit = useAudit({
    resourceName: 'dev-queue-list-status-job',
  });

  useEffect(() => {
    audit.onAccess();
  }, []);

  const { tableProps, refresh } = useTable<TableData>({
    service: 'queue/api',
    serviceKind: 'service',
    path:
      scope === 'list'
        ? `admin/list/${listName}/jobs/${status}`
        : `admin/job/${status}/list`,
    params: {
      filters: {
        text: filters,
      },
    },
    defaultSort: {
      field: 'statusChangedAt',
      order: 'descend',
    },
  });

  const onResend = async (jobId: string) => {
    const { status } = await resendJobToKafka(jobId);

    if (status) {
      notification.success({
        message: 'Success',
        description: 'Job added to Kafka producer queue.',
      });
    } else {
      notification.error({
        message: 'Error',
        description: 'Failed to add job to Kafka producer queue.',
      });
    }

    refresh();
  };

  const onReactivate = async (jobId: string) => {
    const { status } = await reactivateJob(jobId);

    if (status) {
      notification.success({
        message: 'Success',
        description: 'Job reactivated.',
      });
    } else {
      notification.error({
        message: 'Error',
        description: 'Failed to reactivate job.',
      });
    }

    refresh();
  };

  const getTableMenuItems = (
    job: TableData,
  ): NonNullable<MenuProps['items']> => {
    const items: NonNullable<MenuProps['items']> = [
      {
        key: `job-menu-view-${job.id}`,
        label: (
          <Link to={`/dev/queue/${job.listName}/job/${job.id}`}>View</Link>
        ),
        icon: <GrView size={20} />,
      },
    ];

    if (session.hasPermission('queue.edit')) {
      if (job.status === 'pending') {
        items.push({
          key: `job-menu-resend-${job.id}`,
          label: 'Resend to Kafka',
          icon: <MdSendAndArchive size={20} />,
          onClick: () => onResend(job.id),
        });
      }

      if (job.status !== 'pending') {
        items.push({
          key: `job-menu-reactivate-${job.id}`,
          label: 'Reactivate',
          icon: <CgSoftwareDownload size={20} />,
          onClick: () => onReactivate(job.id),
        });
      }
    }

    return items;
  };

  return (
    <ListContainer>
      <Row>
        <Col span={12} />
        <Col span={12}>
          <Input.Search
            placeholder="Search by job Id, list Name or data"
            allowClear
            onSearch={(value) => {
              setFilters(value);
            }}
            style={{ width: '100%' }}
          />
        </Col>
      </Row>
      <br />
      <TableContainer>
        <Table {...tableProps} rowKey={'id'}>
          <Table.Column
            title="Job"
            dataIndex="id"
            key="id"
            sorter
            render={(text: any, record: TableData, index: number) => {
              return (
                <Space direction="vertical">
                  <Typography.Text
                    strong
                    copyable={{
                      text,
                    }}
                  >
                    ID: {text}
                  </Typography.Text>
                  {scope === 'all' && (
                    <Typography.Text
                      // style={{ maxWidth: 200 }}
                      ellipsis
                      copyable
                    >
                      {record.listName}
                    </Typography.Text>
                  )}
                </Space>
              );
            }}
          />

          <Table.Column
            title="Date"
            dataIndex="statusChangedAt"
            key="statusChangedAt"
            align="center"
            sorter
            defaultSortOrder={'descend'}
            render={(text: any, record: TableData, index: number) => {
              if (!record.createdAt) return '-';

              return (
                <div className="process-field">
                  <div className="process-row">
                    <div className="process-label">Created: </div>
                    <div className="process-value">
                      {formatISODate(
                        new Date(record.createdAt * 1000).toISOString(),
                      )}
                    </div>
                  </div>
                  {record.statusChangedAt && (
                    <div className="process-row">
                      <div className="process-label">Processed: </div>
                      <div className="process-value">
                        {formatISODate(
                          new Date(record.statusChangedAt * 1000).toISOString(),
                        )}
                      </div>
                    </div>
                  )}
                </div>
              );
            }}
          />
          <Table.Column
            title=""
            dataIndex="data"
            key="data"
            align="center"
            render={(text: any, record: TableData, index: number) => {
              return (
                <Popover
                  title="Payload"
                  content={() => {
                    return (
                      <pre
                        style={{
                          backgroundColor: '#dbdbdb',
                          padding: 8,
                          borderRadius: 5,
                          maxWidth: 500,
                          maxHeight: 400,
                        }}
                      >
                        {JSON.stringify(text, null, 2)}
                      </pre>
                    );
                  }}
                >
                  <div className="preview-content">
                    <SiCodereview />
                  </div>
                </Popover>
              );
            }}
          />
          <Table.Column
            title=""
            dataIndex="actions"
            key="actions"
            align="right"
            render={(text: any, record: TableData, index: number) => {
              return (
                <Dropdown menu={{ items: getTableMenuItems(record) }}>
                  <Button>
                    <PiDotsThreeOutline size={20} />
                  </Button>
                </Dropdown>
              );
            }}
          />
        </Table>
      </TableContainer>
    </ListContainer>
  );
};

export default StatusList;
