import queryString from 'query-string';
import { handleError, httpGet } from './util/axios';

export const loadSummarizeReportKafka = async (navigate: any) => {
  try {
    const data = await httpGet('admin-portal', 'admin/summarize-report-kafka');
    return data;
  } catch (error: any) {
    handleError(error, navigate);
  }
};

export const loadTableReportKafka = async (
  navigate: any,
  filter: any,
  pagination: any,
) => {
  const paramsFilter = queryString.stringify(filter);
  const paramsPaginator = queryString.stringify(pagination);
  const params = paramsFilter
    ? `${paramsFilter}&${paramsPaginator}`
    : paramsPaginator;
  try {
    const data = await httpGet(
      'admin-portal',
      `admin/table-report-kafka?${params}`,
    );
    return data;
  } catch (error: any) {
    handleError(error, navigate);
  }
};
