import { FC } from 'react';
import { CompareWrapper } from './styles';
import { Col, Row, Tabs } from 'antd';
import Completion from './Completion';
import { useSubmissionDialog } from './dialog-hook';
import { SalesIncentiveCompletion } from '@/types';
import { formatISODate } from '@/utils';
import ApprovalButtons from '../ApprovalButtons';
import { When } from 'react-if';

const Compare: FC = () => {
  const submissionDialog = useSubmissionDialog();

  return (
    <CompareWrapper>
      <Row gutter={16}>
        <Col span={12}>
          <div className="main-header">
            <Tabs
              items={[
                {
                  key: submissionDialog.state.completion?.id as string,
                  label: (
                    <div className="tab-date-label">
                      <span>
                        {formatISODate(
                          submissionDialog.state.completion?.completedAt,
                          'DD',
                        )}
                      </span>
                      <span>
                        {formatISODate(
                          submissionDialog.state.completion?.completedAt,
                          'tt',
                        )}
                      </span>
                    </div>
                  ),
                },
              ]}
            />

            <When
              condition={
                submissionDialog.state.completion?.status === 'complete'
              }
            >
              <ApprovalButtons
                completionsId={[
                  submissionDialog.state.completion?.id as string,
                ]}
                onCompleted={() => {
                  submissionDialog.closeDialog();
                }}
              />
            </When>
          </div>
          <Completion
            completion={
              submissionDialog.state.completion as SalesIncentiveCompletion
            }
          />
        </Col>
        <Col span={12}>
          <div className="pane-header">
            <Tabs
              destroyInactiveTabPane
              items={submissionDialog.state.duplications.map((duplication) => ({
                key: `item-${duplication.id}`,
                label: (
                  <div className="tab-date-label">
                    <span>{formatISODate(duplication.completedAt, 'DD')}</span>
                    <span>{formatISODate(duplication.completedAt, 'tt')}</span>
                  </div>
                ),
                children: (
                  <Completion
                    completion={duplication as SalesIncentiveCompletion}
                  />
                ),
              }))}
            />
          </div>
        </Col>
      </Row>
    </CompareWrapper>
  );
};

export default Compare;
