import styled from 'styled-components';

export const RejectionContainer = styled.div`
  width: 350px;
  border-top: 1px solid #e8e8e8;
  margin-top: 12px;
  padding-top: 16px;
  margin-bottom: 24px;
`;

export const UserPageHeader = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  padding-top: 8px;
  margin-bottom: 32px;

  .main-info {
    display: flex;
    column-gap: 24px;
    align-items: flex-start;

    .avatar {
      width: 200px;

      .ant-avatar-square {
        border-radius: 10px;
      }
    }

    .info {
      width: calc(100% - 190px);
      display: flex;
      flex-direction: column;
      row-gap: 4px;

      .name-container {
        h2 {
          margin-bottom: 0;
        }
      }

      .ant-descriptions-row {
        > th {
          padding-bottom: 0;
        }
      }
    }
  }

  .additional-info {
    .ant-descriptions-view {
      .ant-descriptions-item {
        padding-bottom: 0;
      }
    }

    .earning-info {
      gap: 0 !important;
    }
  }
`;

export const WalletTabContainer = styled.div``;

export const AdvancedFilterContent = styled.div`
  width: 300px;
  padding-top: 8px;

  .ant-divider-horizontal {
    margin: 6px 0 0;
    color: #d6d6d6;

    svg {
      margin-top: 2px;
    }
  }

  .footer {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
  }
`;
