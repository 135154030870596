import { FC, useEffect, useMemo } from 'react';
import { Modal } from './styles';
import Completion from './Completion';
import ApprovalButtons from '../ApprovalButtons';
import { useConsumerSubmissionDialog } from './dialog-hook';
import { Button, Space } from 'antd';
import { CloseSquareOutlined } from '@ant-design/icons';
import { Switch, Case, Default } from 'react-if';
import Compare from './compare';
import { ConsumerCompletion } from '@/types';
import { useAudit } from '@/hooks';

type Args = {
  onClose: () => void;
  onRefresh?: () => void;
  disableApproval?: boolean;
};

const SubmissionDialog: FC<Args> = ({
  onClose,
  onRefresh,
  disableApproval = false,
}) => {
  const submissionDialog = useConsumerSubmissionDialog();
  const audit = useAudit({
    resourceName: 'consumer-approval-modal',
  });

  const showApprovalButtons = useMemo(() => {
    return (
      !disableApproval &&
      submissionDialog.state.completion?.status === 'complete' &&
      !submissionDialog.state.completion?.processing &&
      submissionDialog.state.mode === 'single'
    );
  }, [
    submissionDialog.state.completion?.status,
    submissionDialog.state.completion?.processing,
    submissionDialog.state.mode,
    disableApproval,
  ]);

  function handleGoToNextCompletion() {
    const currentCompletionIndex =
      submissionDialog.state.currentPageCompletionIds.findIndex(
        (id) => id === submissionDialog.state.completion?.id,
      );
    if (currentCompletionIndex === -1) {
      onClose();
      return;
    }

    const nextCompletionId =
      submissionDialog.state.currentPageCompletionIds[
        currentCompletionIndex + 1
      ];
    // If the current completion is the last one, do nothing
    if (!nextCompletionId) {
      onClose();
      return;
    }

    submissionDialog.openDialog({ id: nextCompletionId });
  }

  useEffect(() => {
    if (submissionDialog.state.open) {
      audit.onDetail({
        target: submissionDialog.state.mode,
        data: {
          completionId: submissionDialog.state.completion?.id,
        },
      });
    }
  }, [submissionDialog.state.open, submissionDialog.state.mode]);

  return (
    <Modal
      title={
        submissionDialog.state.mode === 'compare' ? (
          <Space>
            <span>Compare Completions</span>{' '}
            <Button
              icon={<CloseSquareOutlined />}
              type="dashed"
              onClick={() => submissionDialog.switchMode('single')}
            >
              Close compare
            </Button>
          </Space>
        ) : (
          <>Completion Details</>
        )
      }
      centered
      open={submissionDialog.state.open}
      width={submissionDialog.state.mode === 'compare' ? 1300 : 900}
      onCancel={onClose}
      destroyOnClose
      footer={
        showApprovalButtons && submissionDialog.state.completion?.id ? (
          <ApprovalButtons
            completionsId={[submissionDialog.state.completion?.id]}
            onCompleted={() => {
              // The order here is important
              submissionDialog.setProcessing();
              handleGoToNextCompletion();
              onRefresh?.();
            }}
          />
        ) : (
          <></>
        )
      }
    >
      <Switch>
        <Case condition={submissionDialog.state.mode === 'compare'}>
          <Compare />
        </Case>
        <Default>
          <Completion
            completion={submissionDialog.state.completion as ConsumerCompletion}
          />
        </Default>
      </Switch>
    </Modal>
  );
};

export default SubmissionDialog;
