import { FC, useState } from 'react';
import ManageDealerList from '@/pages/DealerImport/Manage/DealerList';
import { Button, message, Popconfirm, Select, Space } from 'antd';
import { ScoreFilter } from '@/pages/DealerImport/Manage/types';
import { BaseOptionType } from 'antd/es/select';
import { importStores } from '@/services/import.service';
import { scoreFilterOPtions } from '@/pages/DealerImport/Manage/contants';

type Props = {
  fileId: any;
  brandId: string;
  selected: string[];
  onChangePendingStores: (hasPending: boolean) => void;
};

const DealerImport: FC<Props> = ({
  fileId,
  brandId,
  selected,
  onChangePendingStores,
}) => {
  const [scoreFilter, setScoreFilter] = useState<ScoreFilter>('all');
  const [selectedStores, setSelectedStores] = useState<string[]>([]);
  const [refreshCount, setRefreshCount] = useState(0);

  const handleImportStores = async (
    strategy: Parameters<typeof importStores>[0]['strategy'],
  ) => {
    const { status } = await importStores({
      brandIds: [brandId],
      storeObjects: selectedStores.map((importedStoreId) => ({
        importedStoreId,
      })),
      strategy,
    });

    if (status) {
      // Clear the selected stores
      setSelectedStores([]);

      message.success('Stores were successfully sent to be processed');
      setRefreshCount((prev) => prev + 1);

      return;
    }

    message.error('Failed to send stores to be processed');
  };

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          columnGap: 8,
        }}
      >
        <Select
          style={{ flex: 1, textAlign: 'center' }}
          value={scoreFilter}
          options={scoreFilterOPtions}
          onChange={(value) => {
            setScoreFilter(value);
          }}
        />

        <Popconfirm
          title="Are you sure?"
          description="This will create new stores for all selected stores, even if there are similar stores in our database. Are you sure?"
          okText="Yes"
          cancelText="No"
          onConfirm={() => handleImportStores('createNew')}
        >
          <Button disabled={!selectedStores.length}>Create New Stores</Button>
        </Popconfirm>

        <Popconfirm
          overlayStyle={{ maxWidth: '30rem' }}
          title="Are you sure?"
          description="This will merge each store with its best match, even if the best match is completely different from the store. If any of the selected stores don't have any similar store, new stores will be created. Are you sure?"
          okText="Yes"
          cancelText="No"
          onConfirm={() => handleImportStores('useBestMatch')}
        >
          <Button disabled={!selectedStores.length} type="primary">
            Connect Stores to Best Matches
          </Button>
        </Popconfirm>
      </div>
      <ManageDealerList
        fileId={fileId}
        brandsId={[brandId]}
        actionFilter={['notImported']}
        onChangeSelection={(stores) => {
          setSelectedStores(stores.map((store) => store.id));
        }}
        scoreFilter={scoreFilter}
        refreshCount={refreshCount}
        onChangeTotalItems={(total) => {
          onChangePendingStores?.(total > 0);
        }}
      />
    </Space>
  );
};

export default DealerImport;
