import { Segmented } from 'antd';
import { ContentHeaderWrapper } from './styles';
import { BiTimeFive } from 'react-icons/bi';
import { FaCheck, FaPaperPlane, FaUsers } from 'react-icons/fa';
import { FC } from 'react';
import { MapData } from '.';
import { GrStackOverflow } from 'react-icons/gr';

type Props = {
  data: MapData;
  onSelect: (period: any) => void;
  selectedPeriod: number;
};

const ContentHeader: FC<Props> = ({ data, onSelect, selectedPeriod }) => {
  return (
    <ContentHeaderWrapper>
      <div className="content">
        <div className="filters">
          <span className="filter-title">From the last:</span>

          <div className="filter-options">
            <Segmented
              size="small"
              onChange={onSelect}
              value={selectedPeriod}
              options={[
                { label: '24 hours', value: 24, icon: <BiTimeFive /> },
                { label: '48 hours', value: 48, icon: <BiTimeFive /> },
                { label: '72 hours', value: 72, icon: <BiTimeFive /> },
              ]}
            />
          </div>
        </div>

        <div className="kpis">
          <div className="kpi">
            <div className="title">
              <div className="icon">
                <FaPaperPlane />
              </div>
              <div className="text">
                <span className="main">Total Campaigns</span>
              </div>
            </div>
            <div className="value">{data.totalActiveCampaigns}</div>
            <div className="time warn">Last {selectedPeriod}h</div>
          </div>
          <div className="kpi">
            <div className="title">
              <div className="icon">
                <FaUsers />
              </div>
              <div className="text">
                <span className="main">New Users</span>
              </div>
            </div>
            <div className="value">{data.newUsers}</div>
            <div className="time warn">Last {selectedPeriod}h</div>
          </div>

          <div className="kpi">
            <div className="title">
              <div className="icon">
                <GrStackOverflow />
              </div>
              <div className="text">
                <span className="main">Completions</span>
              </div>
            </div>
            <div className="value">{data.completionsTotals}</div>
            <div className="time warn">Last {selectedPeriod}h</div>
          </div>

          <div className="kpi">
            <div className="title">
              <div className="icon">
                <FaCheck />
              </div>
              <div className="text">
                <span className="main">RGA</span>
                <span className="sub">
                  Conversion Rate:{' '}
                  <span className="success">
                    {Number(data.completionsRgaPercent.toFixed(2))}%
                  </span>
                </span>
              </div>
            </div>
            <div className="value">{data.completionsRga}</div>
            <div className="time warn">Last {selectedPeriod}h</div>
          </div>
        </div>
      </div>
    </ContentHeaderWrapper>
  );
};

export default ContentHeader;
