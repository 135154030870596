import { useSession } from '@/store';
import {
  Drawer,
  Space,
  Button,
  Descriptions,
  Divider,
  Typography,
  Tag,
  Image,
  Popconfirm,
  Modal,
  Select,
} from 'antd';
import {
  DownloadOutlined,
  RotateLeftOutlined,
  RotateRightOutlined,
  SwapOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from '@ant-design/icons';
import { FC, useEffect, useState } from 'react';
import { When } from 'react-if';
import { useNavigate } from 'react-router-dom';
import { MobileUser } from '@/types';
import { getMobileUserDetails } from '@/services/mobile-user.service';
import { get, map, startCase } from 'lodash';
import { formatISODate, formatMoney } from '@/utils';
import { useAudit } from '@/hooks';
import { ToolbarWrapper } from '@/pages/MobileUser/List/styles';
import { Input } from 'antd';
import { rejectPoeReasons } from '@/pages/MobileUser/UsersPOEStatus/constants';
import { RxCross2, RxCheck } from "react-icons/rx";

const { TextArea } = Input;

type Props = {
  visible: boolean;
  onClose: () => void;
  userId: any;
  onChangeUserPoeStatus: (id: string, type: string, reason: string, rejectTag: string) => void;
};

//trigger

const DrawerPoeView: FC<Props> = ({
  visible,
  onClose,
  userId,
  onChangeUserPoeStatus,
}) => {
  const session = useSession();
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState<MobileUser | null>(null);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [reason, setReason] = useState('');
  const [rejectTag, setRejectTag] = useState('');

  const audit = useAudit({
    resourceName: 'mobile-user-list-poe-view',
  });

  const onDownload = (src: string) => {
    fetch(src)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.download = 'image.png';
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(url);
        link.remove();
      });
  };

  useEffect(() => {
    if (visible) {
      audit.onAccess();
      loadUserDetails();
    } else {
      setUserData(null);
    }
  }, [visible, userId]);

  const loadUserDetails = async () => {
    setLoading(true);
    const { user } = await getMobileUserDetails(userId);
    setUserData(user);

    setLoading(false);
  };

  return (
    <Drawer
      title="User PoE View"
      size="large"
      placement="right"
      onClose={onClose}
      open={visible}
      extra={
        <When condition={session.hasPermission('users.edit')}>
          <Space>
            <Popconfirm
              title="Are you certain you wish to approve this PoE?"
              description="This action cannot be undone."
              onConfirm={() => onChangeUserPoeStatus(userId, 'approve', '', '')}
              onCancel={() => {}}
              disabled={userData?.poeStatus === 'approved'}
              okText="Yes"
              cancelText="No"
            >
              <Button
                disabled={userData?.poeStatus === 'approved'}
                type="primary"
                loading={loading}
              >
                Approve PoE
              </Button>
            </Popconfirm>
            <Button
              type="default"
              loading={loading}
              onClick={() => setShowRejectModal(true)}
              disabled={userData?.poeStatus === 'rejected' || userData?.poeStatus === 'rejected_limit'}
            >
              Reject PoE
            </Button>
            {/*<Popconfirm*/}
            {/*  title="Are you certain you wish to reject this PoE?"*/}
            {/*  description="This action cannot be undone."*/}
            {/*  onConfirm={() => onChangeUserPoeStatus(userId, 'reject')}*/}
            {/*  onCancel={() => {}}*/}
            {/*  okText="Yes"*/}
            {/*  cancelText="No"*/}
            {/*>*/}
            {/*  <Button type="default" loading={loading}>*/}
            {/*    Reject PoE*/}
            {/*  </Button>*/}
            {/*</Popconfirm>*/}
          </Space>
        </When>
      }
    >
      <When condition={!!userData && visible}>
        <Descriptions
          title="User Info"
          column={{
            lg: 2,
          }}
        >
          <Descriptions.Item label="Name">
            {userData?.fullName}
          </Descriptions.Item>
          <Descriptions.Item label="Email">
            <Typography.Text copyable>{userData?.email}</Typography.Text>
          </Descriptions.Item>
          <Descriptions.Item label="Reference Code">
            <Typography.Text copyable>{userData?.referralCode}</Typography.Text>
          </Descriptions.Item>

          {userData?.createdAt && (
            <Descriptions.Item label="Registered At">
              {formatISODate(userData?.createdAt)}
            </Descriptions.Item>
          )}

          <Descriptions.Item label="Status">
            {userData?.isActive ? (
              <Tag color="green">Active</Tag>
            ) : (
              <Tag color="grey">Inactive</Tag>
            )}
          </Descriptions.Item>

          <Descriptions.Item label="Role">
            {startCase(userData?.role)}
          </Descriptions.Item>

          {userData?.lastActivityAt && (
            <Descriptions.Item label="Last Activity">
              {formatISODate(userData?.lastActivityAt)}
            </Descriptions.Item>
          )}
          <br/>
          <Descriptions.Item label="PoE status">
            {userData?.poeStatus === 'pending' && (
              <Tag color="blue">Pending</Tag>
            )}
            {userData?.poeStatus === 'approved' && (
              <Tag color="green">Approved</Tag>
            )}
            {userData?.poeStatus === 'rejected' && (
              <Tag color="red">Rejected</Tag>
            )}
          </Descriptions.Item>

          {userData?.poeStatus === 'rejected' && (
            <Descriptions.Item label="PoE Rejection Reason">
              {userData?.poeRejectedReason || '-'}
            </Descriptions.Item>
          )}
        </Descriptions>

        <Divider />

        <Descriptions
          title="Earnings"
          column={{
            lg: 2,
          }}
        >
          <Descriptions.Item label="Available">
            {formatMoney(get(userData, 'earnings', 0))}
          </Descriptions.Item>
          <Descriptions.Item label="Pending">
            {formatMoney(get(userData, 'pendingEarnings', 0))}
          </Descriptions.Item>
        </Descriptions>

        <Divider />

        <Descriptions
          title="Store"
          column={{
            lg: 2,
          }}
        >
          <Descriptions.Item label="Name">
            {get(userData, 'store.name', '-')}
          </Descriptions.Item>
          <Descriptions.Item label="Address">
            {get(userData, 'store.address', '-')}
          </Descriptions.Item>
        </Descriptions>

        <Divider />

        {userData?.pendingPoeSubmissionUrl && (
          <Descriptions
            title="Proof of Employment"
            column={{
              lg: 1,
            }}
          >
            <Descriptions.Item>
              <Image
                width={200}
                src={userData?.pendingPoeSubmissionUrl || ''}
                preview={{
                  toolbarRender: (
                    _,
                    {
                      transform: { scale },
                      actions: {
                        onFlipY,
                        onFlipX,
                        onRotateLeft,
                        onRotateRight,
                        onZoomOut,
                        onZoomIn,
                      },
                    },
                  ) => (
                    <ToolbarWrapper size={32}>
                      <DownloadOutlined
                        onClick={() =>
                          onDownload(userData?.pendingPoeSubmissionUrl || '')
                        }
                      />
                      <SwapOutlined rotate={90} onClick={onFlipY} />
                      <SwapOutlined onClick={onFlipX} />
                      <RotateLeftOutlined onClick={onRotateLeft} />
                      <RotateRightOutlined onClick={onRotateRight} />
                      <ZoomOutOutlined
                        disabled={scale === 1}
                        onClick={onZoomOut}
                      />
                      <ZoomInOutlined
                        disabled={scale === 50}
                        onClick={onZoomIn}
                      />
                    </ToolbarWrapper>
                  ),
                }}
              />
            </Descriptions.Item>
            {userData?.poeAnalysisStatus === 'processed' && (
              <Descriptions.Item>
                <div style={{maxWidth: "400px"}}>
                  <p><strong>Analysis Score: </strong>{userData.poeAnalysis.data.score}</p>
                  <p><strong>Document Type:</strong> {userData.poeAnalysis.data.documenType}</p>
                  <p><strong>Description:</strong> {userData.poeAnalysis.data.description}</p>
                  <p>{get(userData.poeAnalysis.data, 'isEmployeeDetected', false) ? <span style={{ color: 'green' }}> <RxCheck/> Employee Detected</span> : <span style={{ color: 'red' }}> <RxCross2/> Employee Not Detected</span>}</p>
                  <p>{get(userData.poeAnalysis.data, 'isEmployerNameDetected', false) ? <span style={{ color: 'green' }}><RxCheck/> Employer Name Detected</span> : <span style={{ color: 'red' }}><RxCross2/> Employer Name Not Detected</span>}</p>
                  <p>{get(userData.poeAnalysis.data, 'isEmployerAddressDetected', false) ? <span style={{ color: 'green' }}><RxCheck/> Employer Address Detected</span> : <span style={{ color: 'red' }}><RxCross2/> Employer Address  Not Detected</span>}</p>
                  <p><strong>Processed At: </strong> {formatISODate(userData.poeAnalysis.processedAt)}</p>
                </div>
              </Descriptions.Item>
            )}

          </Descriptions>
        )}
      </When>
      <Modal
        title="Are you certain you wish to reject this PoE?"
        open={showRejectModal}
        onOk={() => {
          onChangeUserPoeStatus(userId, 'reject', reason, rejectTag);
          setReason('');
          setRejectTag('');
          setShowRejectModal(false);
        }}
        destroyOnClose
        confirmLoading={loading}
        onCancel={() => {setShowRejectModal(false); setReason(''); setRejectTag('');}}
        okButtonProps={{ disabled: (reason.trim() === '' || rejectTag.trim() === '') }}
      >
        <p>This action cannot be undone.</p>
        <Select
          showSearch
          defaultActiveFirstOption={false}
          allowClear
          onClear={() => {setReason(''); setRejectTag('');}}
          placeholder={'Select a reason'}
          style={{ width: '100%', marginBottom: 8 }}
          onSelect={(selected, option) => 
            { 
              setReason(selected);
              const rejectTag = option?.rejectTag;
              if (rejectTag) {
                setRejectTag(rejectTag);
              }
            }
          }
        >
          {map(rejectPoeReasons, (reason, key) => (
            <Select.Option value={reason.suggest} key={`option-key-${key}`} rejectTag={reason.tag}>
              {reason.menu}
            </Select.Option>
          ))}
        </Select>
        <TextArea
          rows={4}
          placeholder="reason for rejection"
          required={true}
          onChange={(e) => {setReason(e.target.value)}}
          value={reason}
        />
      </Modal>
    </Drawer>
  );
};

export default DrawerPoeView;
