import { FC, useMemo } from 'react';
import { Case, Switch, When } from 'react-if';
import {
  Button,
  Col,
  Row,
  Timeline,
  Card,
  Typography,
  Popover,
  notification,
} from 'antd';
import { PremiumReviewContainer } from './styles';
import { find, get, isEmpty } from 'lodash';
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  SplitCellsOutlined,
} from '@ant-design/icons';
import ScanStatusTag from '../../ScanStatusTag';
import { useSubmissionDialog } from '../dialog-hook';
import { SalesIncentiveCompletion } from '@/types';
import { formatISODate } from '@/utils';
import ReactJson from 'react-json-view';
import { useMutation } from '@tanstack/react-query';
import type { PinturaDefaultImageWriterResult } from '@pqina/pintura';
import type { Entries } from 'type-fest';

import { uploadCompletionReceiptImage } from '@/services/sales-incentive.service';

import { RedactableImage } from '@/components/RedactableImage';

type Args = {
  completion: SalesIncentiveCompletion;
};

const PremiumReview: FC<Args> = ({ completion }) => {
  const submissionDialog = useSubmissionDialog();

  const receiptImageUrl = useMemo<string | null>(() => {
    const answer = find(completion.answers, { type: 'scan_image' });

    return get(answer, 'answer', null);
  }, [completion.answers]);

  const analysis = useMemo(() => {
    const scanData = completion.analysis?.data;
    const isDetected = get(scanData, 'isDetected', false);
    const idDetected = get(scanData, 'identifier.detected', false);
    const duplicated = get(scanData, 'identifier.duplicated', false) as boolean;
    const duplicationsId = get(
      scanData,
      'identifier.duplications',
      [],
    ) as string[];
    const dateDetected = get(scanData, 'date.detected', false);
    const dateValid = get(scanData, 'date.valid', false);
    const addressDetected = get(scanData, 'address.detected', false);
    const addressValid = get(scanData, 'address.matched', false);

    return {
      receipt: {
        detected: isDetected ? 'passed' : 'failed',
      },
      receiptId: {
        detected: idDetected ? 'passed' : 'failed',
        duplicated,
        duplicationsId,
      },
      date: {
        detected: dateDetected ? 'passed' : 'failed',
        valid: !dateDetected ? 'disabled' : dateValid ? 'passed' : 'failed',
      },
      address: {
        detected: addressDetected ? 'passed' : 'failed',
        valid: !addressDetected
          ? 'disabled'
          : addressValid
          ? 'passed'
          : 'failed',
      },
    } as const;
  }, [completion.analysis?.data]);

  const getColor = (status: 'passed' | 'failed' | 'disabled') => {
    switch (status) {
      case 'passed':
        return '#52c41a';
      case 'failed':
        return '#FF4D4F';
      case 'disabled':
        return 'default';
      default:
        return 'default';
    }
  };

  const getIcon = (status: 'passed' | 'failed' | 'disabled') => {
    switch (status) {
      case 'passed':
        return <CheckCircleOutlined style={{ fontSize: 22 }} />;
      case 'failed':
        return <ExclamationCircleOutlined style={{ fontSize: 22 }} />;
      case 'disabled':
        return <ExclamationCircleOutlined style={{ fontSize: 22 }} />;
      default:
        return <ExclamationCircleOutlined style={{ fontSize: 22 }} />;
    }
  };

  const premiumReviewTimelineItems = useMemo(() => {
    const analysisEntries = Object.entries(analysis) as Entries<
      typeof analysis
    >;

    return analysisEntries.map((entry) => {
      const result = { type: entry[0], ...entry[1] } as {
        [K in keyof typeof analysis]: (typeof analysis)[K] & { type: K };
      }[keyof typeof analysis];

      return {
        color: getColor(result.detected),
        dot: getIcon(result.detected),
        children: (
          <div className={`validation ${result.detected}`}>
            {result.type === 'receipt' && (
              <>
                {result.detected === 'passed' ? (
                  <div className="validation-title">Receipt detected</div>
                ) : (
                  <div className="validation-title">Receipt not detected</div>
                )}
              </>
            )}

            {result.type === 'receiptId' && (
              <>
                {result.detected === 'passed' ? (
                  <div className="validation-title">
                    Receipt number detected
                  </div>
                ) : (
                  <div className="validation-title">
                    Receipt number not detected
                  </div>
                )}

                {result.detected === 'passed' && (
                  <div
                    className={`validation-subtitle ${
                      result.duplicated ? 'failed' : 'passed'
                    }`}
                  >
                    <Typography.Text copyable strong>
                      {get(completion, 'analysis.data.identifier.value', '')}
                    </Typography.Text>

                    {result.duplicated &&
                      submissionDialog.state.mode === 'single' &&
                      submissionDialog.state?.completion?.id ===
                        completion.id && (
                        <Button
                          icon={<SplitCellsOutlined />}
                          type="primary"
                          className="compare"
                          size="small"
                          onClick={() => submissionDialog.switchMode('compare')}
                        >
                          Compare
                        </Button>
                      )}
                  </div>
                )}
              </>
            )}

            {result.type === 'address' && (
              <>
                {result.detected === 'passed' ? (
                  <div className="validation-title">
                    Dealer address detected
                  </div>
                ) : (
                  <div className="validation-title">
                    Dealer address not detected
                  </div>
                )}

                {result.detected === 'passed' && (
                  <div
                    className={`validation-subtitle ${result.valid}`}
                    style={{
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                    }}
                  >
                    <Typography.Text strong>
                      {result.valid === 'passed'
                        ? "Detected address matches user's store"
                        : "Detected address DOES NOT match user's store"}
                    </Typography.Text>
                    <div>
                      {[
                        get(completion, 'analysis.data.address.street', ''),
                        get(completion, 'analysis.data.address.state', ''),
                        get(completion, 'analysis.data.address.country', ''),
                      ]
                        .filter(Boolean)
                        .join(', ')}
                    </div>
                  </div>
                )}
              </>
            )}

            {result.type === 'date' && (
              <>
                {result.detected === 'passed' ? (
                  <div className="validation-title">Date detected</div>
                ) : (
                  <div className="validation-title">Date not detected</div>
                )}

                {result.detected === 'passed' && (
                  <div
                    className={`validation-subtitle ${result.valid}`}
                    style={{
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                    }}
                  >
                    <div>
                      Receipt Date:{' '}
                      {formatISODate(
                        get(completion, 'analysis.data.date.value', {}),
                      )}
                    </div>

                    {!!completion.bundle.launchedAt && (
                      <div>
                        Campaign Start Date:{' '}
                        {formatISODate(
                          get(completion, 'bundle.launchedAt', {}),
                        )}
                      </div>
                    )}

                    {!!completion.bundle.endDate && (
                      <div>
                        Campaign Finish Date:{' '}
                        {formatISODate(get(completion, 'bundle.endDate', {}))}
                      </div>
                    )}
                  </div>
                )}
              </>
            )}
          </div>
        ),
      };
    });
  }, [analysis, completion, submissionDialog]);

  const {
    mutate: handleEditReceiptImage,
    isPending: isSavingEditedReceiptImage,
  } = useMutation({
    mutationFn: async (result: PinturaDefaultImageWriterResult) => {
      await uploadCompletionReceiptImage({
        completionId: completion.id,
        imageFile: result.dest,
      });
    },
    onSuccess: () => {
      notification.success({
        message: 'Success',
        description: 'Receipt image uploaded successfully',
      });
      submissionDialog.reloadCompletion();
    },
    onError: () => {
      notification.error({
        message: 'Error',
        description: 'Failed to upload receipt image',
      });
    },
  });

  return (
    <PremiumReviewContainer>
      <div className="header">
        <span className="title">
          <span>Premium Review Result</span>
        </span>

        <ScanStatusTag completion={completion} />
      </div>
      <div className="body">
        <Switch>
          <Case
            condition={
              ['toReview', 'good'].includes(completion.scanState) &&
              !isEmpty(completion.analysis)
            }
          >
            <Row gutter={2}>
              <Col span={9}>
                <Card title="Receipt">
                  <RedactableImage
                    style={{ width: '100%' }}
                    src={receiptImageUrl || ''}
                    alt="Receipt"
                    enableMagnifier
                    isSaving={isSavingEditedReceiptImage}
                    onEdit={handleEditReceiptImage}
                  />
                </Card>
              </Col>
              <Col span={15}>
                <Card
                  title={
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <span>Analysis</span>

                      <When condition={!isEmpty(completion.rawAnalysis)}>
                        <Popover
                          trigger="click"
                          title="Response Payload"
                          content={
                            <div
                              style={{
                                width: '600px',
                                maxWidth: '100%',
                                height: '360px',
                                overflow: 'auto',
                              }}
                            >
                              <ReactJson
                                src={completion.rawAnalysis || {}}
                                theme="monokai"
                              />
                            </div>
                          }
                        >
                          <Button>Full Response</Button>
                        </Popover>
                      </When>
                    </div>
                  }
                >
                  <Timeline
                    className="premium-review-timeline"
                    items={premiumReviewTimelineItems}
                  />
                </Card>
              </Col>
            </Row>
          </Case>
          <Case condition={completion.scanState === 'pending'}>
            <div>Scan is pending</div>
          </Case>
          <Case condition={completion.scanState === 'failed'}>
            <div>Scan failed</div>
          </Case>
          <Case condition={completion.scanState === 'trying'}>
            <div>Scan is trying</div>
          </Case>
        </Switch>
      </div>
    </PremiumReviewContainer>
  );
};

export default PremiumReview;
