import styled from 'styled-components';

export const CommentsContainer = styled.div`
  .body {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    padding: 24px;
  }

  .comment-list {
    > div {
      padding: 16px 0;

      .comment {
        display: flex;
        flex-direction: column;
        row-gap: 4px;

        .comment-header {
          display: flex;
          align-items: center;
          font-weight: 400;
          font-size: 12px;
          column-gap: 8px;

          .comment-author {
            color: #848484;
            font-weight: 500;
          }

          .comment-date {
            font-size: 14px;

            color: #bfbfbf;
          }
        }

        .comment-body {
          font-size: 14px;
          color: #000000d9;
        }
      }
    }
  }
`;
