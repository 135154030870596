import { httpGet, httpPost, httpPut } from './util/axios';
import QueryString from 'qs';

export const saveCashOutComment = async (
  walletId: string,
  comment: string,
  type = 'retailer',
) => {
  return httpPost('user-wallet/write', `admin/cash-out/${walletId}/comment`, {
    comment,
    type,
  });
};

export const getCasOutComments = async (walletId: string) => {
  return httpGet('user-wallet/read', `admin/cash-out/${walletId}/comments`);
};

export const approveUserCashOut = async (ids: string[]) => {
  return httpPut('user-wallet/write', 'admin/cash-out/user/approve', {
    ids,
  });
};

export const rejectUserCashOut = async (ids: string[]) => {
  return httpPut('user-wallet/write', 'admin/cash-out/user/reject', {
    ids,
  });
};

export const getUserCashOutExportData = async ({ status, filters }: any) => {
  const query = QueryString.stringify({
    filters,
    status,
  });

  return httpGet('user-wallet/read', `admin/cash-out/user/export?=${query}`);
};

export const approveConsumerCashOut = async (ids: string[]) => {
  return httpPut('user-wallet/write', 'admin/cash-out/consumer/approve', {
    ids,
  });
};

export const rejectConsumerCashOut = async (ids: string[]) => {
  return httpPut('user-wallet/write', 'admin/cash-out/consumer/reject', {
    ids,
  });
};

export const getConsumerCashOutExportData = async ({
  status,
  filters,
}: any) => {
  const query = QueryString.stringify({
    filters,
    status,
  });

  return httpGet(
    'user-wallet/read',
    `admin/cash-out/consumer/export?=${query}`,
  );
};
