import { useSession } from '@/store';
import {
  Drawer,
  Space,
  Button,
  Descriptions,
  Divider,
  Typography,
  Tag,
} from 'antd';
import { FC, useEffect, useState, useRef } from 'react';
import { When } from 'react-if';
import { useNavigate } from 'react-router-dom';
import { MobileUser } from '@/types';
import {
  getMobileUserDetails,
  getReferralLink,
} from '@/services/mobile-user.service';
import { get, startCase } from 'lodash';
import { formatISODate, formatMoney } from '@/utils';
import { useAudit } from '@/hooks';
import { QRCodeCanvas } from 'qrcode.react';

import { ButtonLink } from '@/components/ButtonLink';

type Props = {
  visible: boolean;
  onClose: () => void;
  userId: any;
  onDeleteUser: (id: string) => void;
};

const DrawerQuickView: FC<Props> = ({
  visible,
  onClose,
  userId,
  onDeleteUser,
}) => {
  const session = useSession();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState<MobileUser | null>(null);

  const audit = useAudit({
    resourceName: 'mobile-user-list-quick-view',
  });

  useEffect(() => {
    if (visible) {
      audit.onAccess();
      loadUserDetails();
    } else {
      setUserData(null);
    }
  }, [visible, userId]);

  const loadUserDetails = async () => {
    setLoading(true);
    const { user } = await getMobileUserDetails(userId);
    if (user.referralLink === '') {
      user.referralLink = await getReferralLink(userId);
    }

    setUserData(user);

    setLoading(false);
  };

  const qrRef = useRef<HTMLDivElement | null>(null);

  const handleDownload = () => {
    if (qrRef?.current) {
      const canvas = qrRef.current.querySelector('canvas');
      if (canvas) {
        const pngUrl = canvas
          .toDataURL('image/png')
          .replace('image/png', 'image/octet-stream');

        const downloadLink = document.createElement('a');
        downloadLink.href = pngUrl;
        downloadLink.download = 'QRCode.png';
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      }
    }
  };

  return (
    <Drawer
      title="User Quick View"
      size="large"
      placement="right"
      onClose={onClose}
      open={visible}
      extra={
        <When condition={session.hasPermission('admin_user.edit')}>
          <Space>
            <ButtonLink to={`/mobile-user/${userId}/manage`} type="primary">
              View more details
            </ButtonLink>
          </Space>
        </When>
      }
    >
      <When condition={!!userData && visible}>
        <Descriptions
          title="User Info"
          column={{
            lg: 2,
          }}
        >
          <Descriptions.Item label="User Id">
            <Typography.Text copyable>{userData?.id}</Typography.Text>
          </Descriptions.Item>
          <Descriptions.Item label="Name">
            {userData?.fullName}
          </Descriptions.Item>
          <Descriptions.Item label="Email">
            <Typography.Text copyable>{userData?.email}</Typography.Text>
          </Descriptions.Item>
          <Descriptions.Item label="Reference Code">
            <Typography.Text copyable>{userData?.referralCode}</Typography.Text>
          </Descriptions.Item>

          {userData?.createdAt && (
            <Descriptions.Item label="Registered At">
              {formatISODate(userData?.createdAt)}
            </Descriptions.Item>
          )}

          <Descriptions.Item label="Status">
            {userData?.isActive ? (
              <Tag color="green">Active</Tag>
            ) : (
              <Tag color="grey">Inactive</Tag>
            )}
          </Descriptions.Item>

          <Descriptions.Item label="Confirmed">
            {userData?.isConfirmed ? (
              <Tag color="green">Confirmed</Tag>
            ) : (
              <Tag color="grey">Not Confirmed</Tag>
            )}
          </Descriptions.Item>

          <Descriptions.Item label="Role">
            {startCase(userData?.role)}
          </Descriptions.Item>

          {userData?.lastActivityAt && (
            <Descriptions.Item label="Last Activity">
              {formatISODate(userData?.lastActivityAt)}
            </Descriptions.Item>
          )}

          <Descriptions.Item label="Referral Link">
            <Typography.Text copyable>{userData?.referralLink}</Typography.Text>
          </Descriptions.Item>

          <Descriptions.Item>
            {userData?.referralLink && (
              <>
                <a onClick={handleDownload}>
                  <div ref={qrRef} style={{ marginLeft: '50px' }}>
                    <QRCodeCanvas value={userData?.referralLink} />
                  </div>
                </a>
              </>
            )}
          </Descriptions.Item>
        </Descriptions>

        <Divider />

        <Descriptions
          title="Earnings"
          column={{
            lg: 2,
          }}
        >
          <Descriptions.Item label="Available">
            {formatMoney(get(userData, 'earnings', 0))}
          </Descriptions.Item>
          <Descriptions.Item label="Pending">
            {formatMoney(get(userData, 'pendingEarnings', 0))}
          </Descriptions.Item>
        </Descriptions>

        <Divider />

        <Descriptions
          title="Store"
          column={{
            lg: 2,
          }}
        >
          <Descriptions.Item label="Name">
            {get(userData, 'store.name', '-')}
          </Descriptions.Item>
          <Descriptions.Item label="Address">
            {get(userData, 'store.address', '-')}
          </Descriptions.Item>
        </Descriptions>
      </When>
    </Drawer>
  );
};

export default DrawerQuickView;
