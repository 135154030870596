import { handleError, httpGet, httpPut, httpPost } from './util/axios';

export const notifyRetailers = async (type: string, bundleId: string) => {
  return httpPost('campaign/write', 'admin/notify-retailers', {
    type,
    bundleId,
  });
};

export const getCampaignApprovalDetails = async (campaignId: string) => {
  return httpGet(
    'campaign/read',
    `admin/approval-details?campaignId=${campaignId}`,
  );
};

export const saveCampaignReview = async ({
  bundleId,
  status,
  rejectionReasons,
}: any) => {
  return httpPut('campaign/write', `admin/save-campaign-review`, {
    status,
    bundleId,
    rejectionReasons,
  });
};

export const saveCampaignPartialReview = async ({
  bundleId,
  reviewEntity,
  reviewEntityId,
  reviewPayload,
}: any) => {
  return httpPut('campaign/write', `admin/save-campaign-partial-review`, {
    bundleId,
    reviewEntity,
    reviewEntityId,
    reviewPayload,
  });
};
