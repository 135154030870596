export const rejectionReasons = [
  { value: 'inappropriate_images', label: 'Inappropriate images' },
  {
    value: 'offensive_non_existent_names',
    label: 'Offensive or Non-Existent Names',
  },
  { value: 'false_information', label: 'False Information' },
  { value: 'violation_guidelines', label: 'Violation of Guidelines' },
  { value: 'other', label: 'Other' },
];
