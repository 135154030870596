import { loadTableReportKafka } from '@/services/admin.portal.service';
import { Button, Pagination, PaginationProps, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useNavigate } from 'react-router-dom';

export interface ReportKafkaDlqRowData {
  id?: number;
  message?: string;
  errorDetails?: string;
  createdAt: string;
  topic: string;
  groupId: string;
  clientId: string;
  status: string;
}

interface ReportKafkaDlqDataTableProps {
  externalDataSource: any[];
  pageSize: number;
  pageNumber: number;
  totalItems: number;
  setRowData: (rowData: any) => void;
  setModalVisible: (visible: boolean) => void;
  setPageSize: (pageSize: number) => void;
  setPageNumber: (pageNumber: number) => void;
  setExternalDataSource: (dataSource: []) => void;
  setTotalItems: (totalItems: number) => void;
}

const ReportKafkaDlqDataTable = ({
  externalDataSource,
  pageSize,
  pageNumber,
  totalItems,
  setRowData,
  setModalVisible,
  setPageSize,
  setPageNumber,
  setExternalDataSource,
  setTotalItems,
}: ReportKafkaDlqDataTableProps) => {
  const navigate = useNavigate();
  const dataSource: ReportKafkaDlqRowData[] = externalDataSource.map(
    (item) => ({
      ...item,
    }),
  );
  const columns: ColumnsType<ReportKafkaDlqRowData> = [
    {
      title: 'Created at',
      dataIndex: 'createdAt',
      key: 'createdAt',
      filters: Array.from(
        new Set(dataSource.map((item) => item.createdAt)),
      ).map((createdAt) => ({
        text: createdAt,
        value: createdAt,
      })),
      filterSearch: true,
      onFilter: (value: any, record) => record.createdAt.indexOf(value) === 0,
      sorter: (a: any, b: any) => a.topic.localeCompare(b.topic),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Topic',
      dataIndex: 'topic',
      key: 'topic',
      filters: Array.from(new Set(dataSource.map((item) => item.topic))).map(
        (topic) => ({
          text: topic,
          value: topic,
        }),
      ),
      filterSearch: true,
      onFilter: (value: any, record) => record.topic.indexOf(value) === 0,
      sorter: (a: any, b: any) => a.topic.localeCompare(b.topic),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Group Id',
      dataIndex: 'groupId',
      key: 'groupId',
      filters: Array.from(new Set(dataSource.map((item) => item.groupId))).map(
        (groupId) => ({
          text: groupId,
          value: groupId,
        }),
      ),
      filterSearch: true,
      onFilter: (value: any, record) => record.groupId.indexOf(value) === 0,
      sorter: (a: any, b: any) => a.groupId.localeCompare(b.groupId),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Client Id',
      dataIndex: 'clientId',
      key: 'clientId',
      filters: Array.from(new Set(dataSource.map((item) => item.clientId))).map(
        (clientId) => ({
          text: clientId,
          value: clientId,
        }),
      ),
      filterSearch: true,
      onFilter: (value: any, record) => record.clientId.indexOf(value) === 0,
      sorter: (a: any, b: any) => a.clientId.localeCompare(b.clientId),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      filters: Array.from(new Set(dataSource.map((item) => item.status))).map(
        (status) => ({
          text: status,
          value: status,
        }),
      ),
      filterSearch: true,
      onFilter: (value: any, record) => record.status.indexOf(value) === 0,
      sorter: (a: any, b: any) => a.status.localeCompare(b.status),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text: string, record: any) => (
        <Button onClick={() => handleRowClick(record)}>View Details</Button>
      ),
    },
  ];

  const handlePaginationChange: PaginationProps['onChange'] = async (
    pageNumber,
    pageSize,
  ) => {
    const dataSource = await loadTableReportKafka(navigate, null, {
      currentPage: pageNumber,
      perPage: pageSize,
    });
    setExternalDataSource(dataSource.items);
    setTotalItems(dataSource._meta.totalCount);
    setPageNumber(dataSource._meta.currentPage);
    setPageSize(dataSource._meta.perPage);
  };

  const handleRowClick = (record: any) => {
    setRowData(record);
    setModalVisible(true);
  };

  return (
    <>
      <Table dataSource={dataSource} columns={columns} pagination={false} />
      <Pagination
        total={totalItems}
        showTotal={(total, range) => {
          return `Total ${total} items`;
        }}
        onChange={handlePaginationChange}
        defaultPageSize={pageSize}
        defaultCurrent={pageNumber}
        showQuickJumper
        showSizeChanger
      />
    </>
  );
};
export default ReportKafkaDlqDataTable;
