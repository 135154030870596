import queryString from 'query-string';
import { handleError, httpGet, httpPost } from './util/axios';

export const createDraftConnector = async () => {
  try {
    return httpPost('integration', `admin/connector/create-draft`);
  } catch (error: any) {
    handleError(error);
  }
};

export const updateConnector = async (id: string, params: any) => {
  try {
    return httpPost(`integration`, `admin/connector/${id}/update`, params);
  } catch (error: any) {
    handleError(error);
  }
};

export const getConnectorDetails = async (id: string) => {
  try {
    return httpGet(`integration`, `admin/connector/${id}/details`);
  } catch (error: any) {
    handleError(error);
  }
};

export const getAllConnectors = async () => {
  try {
    const query = queryString.stringify({
      page: 1,
      perPage: 1000,
    });

    const { items = [] } = await httpGet(
      `integration`,
      `admin/connector/list?${query}`,
    );

    return items;
  } catch (error: any) {
    handleError(error);
  }
};
