import BrandPickerModal from '@/components/BrandPickerModal';
import Layout from '@/components/Layout';
import PageHeader from '@/components/PageHeader';
import { useTable } from '@/hooks';
import {
  getImportFileDetails,
  importStores,
  updateFileImportedStoresStatus,
} from '@/services/import.service';
import { PlusSquareOutlined, ReloadOutlined } from '@ant-design/icons';
import { useQuery } from '@tanstack/react-query';
import { Button, Flex, Input, Popconfirm, Select, Tag, message } from 'antd';
import { BaseOptionType } from 'antd/es/select';
import { FC, useMemo, useState } from 'react';
import { When } from 'react-if';
import { useParams } from 'react-router-dom';
import { DataContainer } from '../List/styles';
import { BrandCell } from './BrandCell';
import {
  actionFilters,
  refreshDataMessages,
  scoreFilterOPtions,
} from './contants';
import DealerList from './DealerList';
import ImportedStoreModal from './ImportedStoreModal';
import { Map } from './Map';
import { BrandsContainer, FiltersContainer, ImportFileDetails } from './styles';
import {
  ActionFilter,
  ImportedStoreModalData,
  ScoreFilter,
  StoreProps,
} from './types';

// TODO: Add audit
const DealerImportManage: FC = () => {
  const { id = '' } = useParams<{ id: string }>();
  const [brandsToConnectWith, setBrandsToConnectWith] = useState<any[]>([]);
  const [isBrandModalOpen, setIsBrandModalOpen] = useState(false);
  const [importedStoreModalData, setImportedStoreModalData] =
    useState<ImportedStoreModalData | null>(null);
  const [selectedStores, setSelectedStores] = useState<StoreProps[]>([]);

  const [filters, setFilters] = useState<{
    text: string;
    action: readonly ActionFilter[];
    score: ScoreFilter;
  }>({
    text: '',
    action: actionFilters,
    score: 'all',
  });

  const { tableProps, refresh } = useTable<StoreProps>({
    service: 'import',
    path: `admin/store-list`,
    params: {
      fileId: id,
      filters,
    },
    perPage: 10,
  });

  const { data: importFileDetails, refetch: refetchImportFileDetails } =
    useQuery({
      queryKey: ['import-file-details', id],
      queryFn: async () => {
        const result = await getImportFileDetails(id);
        if (!result.status) {
          throw new Error(result.message);
        }
        return result.data;
      },
    });

  const idsOfBrandsToConnectWith = useMemo(() => {
    return brandsToConnectWith.map((brand) => brand.id);
  }, [brandsToConnectWith]);

  const handleBrandLink = (selectedBrands: any[]) => {
    const allBrands = [...brandsToConnectWith, ...selectedBrands];
    setBrandsToConnectWith(allBrands);
  };

  const removeBrand = (brandId: string) => {
    setBrandsToConnectWith((brands) =>
      brands.filter((brand) => brand.id !== brandId),
    );
    console.log('removeBrand', brandId);
  };

  const handleImportStores = async (
    strategy: Parameters<typeof importStores>[0]['strategy'],
  ) => {
    const { status } = await importStores({
      brandIds: idsOfBrandsToConnectWith,
      storeObjects: selectedStores.map((store) => ({
        importedStoreId: store.id,
      })),
      strategy,
    });

    if (status) {
      // Clear the selected stores
      setSelectedStores([]);

      message.success('Stores were successfully sent to be processed');
      refresh();

      return;
    }

    message.error('Failed to send stores to be processed');
  };

  const updateStoresStatus = () => {
    updateFileImportedStoresStatus(id);
    refetchImportFileDetails();
    message.success('Update store data request sent');
    setTimeout(() => {
      refresh();
    }, 2000);
  };

  function onUpdateStoreAddress() {
    setImportedStoreModalData(null);
    // Refetch related data
    refetchImportFileDetails();
    refresh();
  }

  function onImportStore() {
    setImportedStoreModalData(null);
    // Refetch related data
    refetchImportFileDetails();
    refresh();
  }

  return (
    <Layout>
      <PageHeader title="Dealer Import Detail">
        <When condition={!!tableProps.dataSource}>
          <Flex gap="small">
            <Popconfirm
              title={refreshDataMessages.title}
              description={refreshDataMessages.description.plural}
              okText="Yes"
              cancelText="No"
              onConfirm={updateStoresStatus}
            >
              <Button icon={<ReloadOutlined />}>
                {refreshDataMessages.button}
              </Button>
            </Popconfirm>

            <Button
              onClick={() => setIsBrandModalOpen(true)}
              type="default"
              icon={<PlusSquareOutlined />}
            >
              Add Brand to be Linked
            </Button>

            <Popconfirm
              title="Are you sure?"
              description="This will create new stores for all selected stores, even if there are similar stores in our database. Are you sure?"
              okText="Yes"
              cancelText="No"
              onConfirm={() => handleImportStores('createNew')}
            >
              <Button
                disabled={!brandsToConnectWith.length || !selectedStores.length}
                type="primary"
              >
                Create New Stores
              </Button>
            </Popconfirm>

            <Popconfirm
              overlayStyle={{ maxWidth: '30rem' }}
              title="Are you sure?"
              description="This will merge each store with its best match, even if the best match is completely different from the store. If any of the selected stores don't have any similar store, new stores will be created. Are you sure?"
              okText="Yes"
              cancelText="No"
              onConfirm={() => handleImportStores('useBestMatch')}
            >
              <Button
                disabled={!brandsToConnectWith.length || !selectedStores.length}
                type="primary"
              >
                Connect Stores to Best Matches
              </Button>
            </Popconfirm>
          </Flex>
          {/* <Flex gap="2px" vertical style={{ marginTop: 8 }}>
            <Typography.Text strong> Progress </Typography.Text>
            <Progress percent={Math.round(progress)} />
          </Flex> */}
        </When>
      </PageHeader>

      {importFileDetails && (
        <ImportFileDetails>
          <div>
            <strong>File name:</strong> {importFileDetails.fileName}
          </div>

          <div>
            <Tag color="error">
              <strong>Stores with address not found:</strong>{' '}
              {importFileDetails.storesWithAddressNotFound}
            </Tag>

            <Tag color="error">
              <strong>Stores with score &lt; 0.5:</strong>{' '}
              {importFileDetails.storesWithLowScore}
            </Tag>

            <Tag color="warning">
              <strong>Stores with score &ge; 0.5 and &lt; 0.75:</strong>{' '}
              {importFileDetails.storesWithMediumScore}
            </Tag>

            <Tag color="success">
              <strong>Stores with score &ge; 0.75:</strong>{' '}
              {importFileDetails.storesWithHighScore}
            </Tag>
            <Tag color="success">
              <strong>Stores with no conflicts:</strong>{' '}
              {importFileDetails.storesWithNoConflict}
            </Tag>
          </div>
        </ImportFileDetails>
      )}

      <BrandsContainer>
        {brandsToConnectWith.map((brand) => (
          <BrandCell
            key={brand.id}
            brand={brand}
            onRemove={(brandId) => removeBrand(brandId)}
          />
        ))}
      </BrandsContainer>

      <FiltersContainer>
        <Input.Search
          placeholder="Search by store name or address"
          allowClear
          onSearch={(value) => {
            setFilters((currentValue) => ({ ...currentValue, text: value }));
          }}
        />

        <Select
          style={{ minWidth: '20rem', fontSize: '0.625rem' }}
          mode="multiple"
          value={filters.action}
          options={
            [
              { label: 'Not Imported Yet', value: 'notImported' },
              {
                label: 'Connected to Existing Store',
                value: 'connected_existing',
              },
              { label: 'Created', value: 'created' },
              { label: 'Importing', value: 'importing' },
            ] satisfies (BaseOptionType & { value: ActionFilter })[]
          }
          onChange={(value) => {
            setFilters((filters) => ({ ...filters, action: value }));
          }}
        />

        <Select
          style={{ minWidth: '13.5rem', textAlign: 'center' }}
          value={filters.score}
          options={scoreFilterOPtions}
          onChange={(value) => {
            setFilters((filters) => ({ ...filters, score: value }));
          }}
        />
      </FiltersContainer>

      <DataContainer>
        <DealerList
          fileId={id}
          brandsId={brandsToConnectWith}
          onChangeSelection={setSelectedStores}
          scoreFilter={filters.score}
          textFilter={filters.text}
          actionFilter={filters.action}
          fullPage
        />

        <When condition={!tableProps.loading}>
          <div className="map">
            <Map stores={tableProps.dataSource} />
          </div>
        </When>
      </DataContainer>
      <BrandPickerModal
        open={isBrandModalOpen}
        onClose={() => setIsBrandModalOpen(false)}
        selected={idsOfBrandsToConnectWith}
        multiple
        onSelect={handleBrandLink}
        hideInactive={false}
        removeSelected={true}
        onSelectBrands={(brands) => handleBrandLink(brands)}
      />

      {/* TODO: Disable this modal if the store was already created or connected
      to an existing store. Also disable the actions within the modal in the
      back-end */}
      <ImportedStoreModal
        data={importedStoreModalData}
        idsOfBrandsToConnectWith={idsOfBrandsToConnectWith}
        scoreWeights={importFileDetails?.scoreWeights}
        onUpdateStoreAddress={onUpdateStoreAddress}
        onImportStore={onImportStore}
        onCancel={() => setImportedStoreModalData(null)}
      />
    </Layout>
  );
};

export default DealerImportManage;
