export function validateMediaUrl(type: 'image' | 'video', url: string) {
  return new Promise<boolean>((resolve) => {
    const element = document.createElement(type === 'image' ? 'img' : 'video');
    const loadEventName = type === 'image' ? 'load' : 'loadedmetadata';

    function handleLoad() {
      resolve(true);
      cleanup();
    }

    function handleError() {
      resolve(false);
      cleanup();
    }

    function cleanup() {
      element.removeEventListener(loadEventName, handleLoad);
      element.removeEventListener('error', handleError);
    }

    element.addEventListener(loadEventName, handleLoad);
    element.addEventListener('error', handleError);
    element.src = url;
  });
}
