import { TableContainer } from '@/styles';
import styled from 'styled-components';

export const Container = styled(TableContainer)`
  .user-row {
    .info {
      gap: 0 !important;

      h5 {
        margin-bottom: 0;
      }
    }
  }

  .store-row {
    .info {
      gap: 0 !important;
      width: 250px;

      .address {
        font-size: 12px;
        max-width: 250px;
      }
    }
  }
`;
