import { httpGet, httpPost } from './util/axios';

export const saveCompletionComment = async (
  completionId: string,
  comment: string,
) => {
  return httpPost(
    'completion/write',
    `admin/consumer/completion/${completionId}/comment`,
    {
      comment,
    },
  );
};

export const getCompletionComments = async (completionId: string) => {
  return httpGet(
    'completion/read',
    `admin/consumer/completion/${completionId}/comment`,
  );
};

export const saveCampaignApprovalNote = async (
  campaignId: string,
  note: string,
) => {
  return httpPost(
    'completion/write',
    `admin/consumer/campaign/${campaignId}/approval-note`,
    {
      note,
    },
  );
};

export const requestCompletionApprovals = async (payload: {
  completionsId: string[];
  status: string;
  rejectionArgs?: any;
  lang?: string;
}) => {
  return httpPost(
    'completion/write',
    `admin/consumer/completion/request-approvals`,
    payload,
  );
};

export const getPendingCount = async () => {
  return httpGet('completion/read', `admin/consumer/completion/pending-count`);
};

export const getTotalPremiumReview = async () => {
  return httpGet(
    'completion/read',
    `admin/consumer/completion/total-premium-review`,
  );
};

export const requestPremiumReview = async () => {
  return httpPost(
    'completion/write',
    `admin/consumer/completion/request-premium-review`,
    {},
  );
};

export const getCompletionsById = async (completionsId: string[]) => {
  return httpGet(
    'completion/read',
    `admin/consumer/completion/get-many?completionsId=${completionsId.join(
      ',',
    )}`,
  );
};
