import { FC, useState } from 'react';
import {
  Alert,
  Button,
  Form,
  Input,
  notification,
  Popconfirm,
  Select,
} from 'antd';
import { ThumbsDown, ThumbsUp } from 'lucide-react';
import { ConfirmationPopupContainer, Container } from './styles';
import { rejectReasons } from '../constants';
import { map } from 'lodash';
import {
  approveManagerSubmissions,
  rejectManagerSubmissions,
} from '@/services/sales-incentive.service';
import { useAudit } from '@/hooks';
import { useSession } from '@/store';

type Args = {
  completionsId: string[];
  onCompleted?: () => void;
};

const ApprovalButtons: FC<Args> = ({ completionsId, onCompleted }) => {
  const session = useSession();
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState({
    reject: false,
    approve: false,
  });

  const audit = useAudit({
    resourceName: 'manager-submission-approval-actions',
  });

  const onRejectConfirmed = async (values: any) => {
    setLoading({ reject: true, approve: false });

    let result;
    try {
      result = await rejectManagerSubmissions({
        submissionsIds: completionsId,
        rejectionArgs: values,
      });
    } catch (error) {}

    setLoading({ reject: false, approve: false });

    if (!result || result.status === false) {
      notification.error({
        message: 'Error',
        description: 'Error rejecting completion(s).',
      });
      return;
    }

    setOpen(false);
    form.resetFields();
    onCompleted?.();
    audit.onUpdate({
      target: 'completion',
      data: {
        status: 'rejected',
        rejectionArgs: values,
        completionsId,
      },
    });

    if (result.failed === 0) {
      notification.success({
        message: 'Success',
        description: 'Submission(s) rejected successfully.',
      });
    } else {
      notification.warning({
        message: 'Partial Success',
        description: `Only ${result.success} of ${completionsId.length} submissions were rejected. Please try again later.`,
      });
    }
  };

  const onApproveConfirmed = async () => {
    setLoading({ reject: false, approve: true });

    let result;
    try {
      result = await approveManagerSubmissions({
        submissionsIds: completionsId,
      });
    } catch (error) {}

    setLoading({ reject: false, approve: false });

    if (!result || result.status === false) {
      notification.error({
        message: 'Error',
        description: 'Error approving completion(s).',
      });
      return;
    }

    setOpen(false);
    form.resetFields();
    onCompleted?.();
    audit.onUpdate({
      target: 'completion',
      data: {
        status: 'approved',
        completionsId,
      },
    });

    if (result.failed === 0) {
      notification.success({
        message: 'Success',
        description: 'Submission(s) approved successfully.',
      });
    } else {
      notification.warning({
        message: 'Partial Success',
        description: `Only ${result.success} of ${completionsId.length} submissions were approved. Please try again later.`,
      });
    }
  };

  if (!session.hasPermission('sales_incentive_completion.edit')) {
    return (
      <Alert
        message="You do not have permission to approve completions"
        type="error"
        style={{
          textAlign: 'center',
        }}
      />
    );
  }

  return (
    <Container>
      <Popconfirm
        open={open}
        overlayInnerStyle={{ padding: 24, paddingTop: 16 }}
        okText={
          completionsId.length > 1
            ? `Reject ${completionsId.length} Completions`
            : 'Reject Completion'
        }
        cancelButtonProps={{ size: 'large' }}
        okButtonProps={{
          danger: true,
          disabled: !form.validateFields,
          size: 'large',
          loading: loading.reject,
        }}
        onConfirm={() => {
          form.submit();
        }}
        onCancel={() => setOpen(false)}
        title="Reject Confirmation"
        description={
          <ConfirmationPopupContainer>
            <Form form={form} onFinish={onRejectConfirmed}>
              <Form.Item
                name="rejectBy"
                rules={[{ required: true, message: 'Please select a reason' }]}
              >
                <Select
                  placeholder="Select a reason"
                  allowClear
                  showSearch
                  onChange={(value: keyof typeof rejectReasons) => {
                    form.setFieldsValue({
                      rejectText: rejectReasons[value].suggest,
                    });
                  }}
                >
                  {map(rejectReasons, (reason, key) => (
                    <Select.Option value={key} key={`option-key-${key}`}>
                      {reason.menu}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="rejectText"
                rules={[
                  { required: true, message: 'Please enter the description' },
                ]}
              >
                <Input.TextArea rows={4} maxLength={150} showCount />
              </Form.Item>
            </Form>
          </ConfirmationPopupContainer>
        }
        icon={false}
      >
        <Button
          className="reject"
          size="large"
          icon={<ThumbsDown size={16} />}
          loading={loading.reject}
          onClick={() => {
            form.resetFields();
            setOpen(true);
          }}
        >
          Reject
        </Button>
      </Popconfirm>
      <Button
        className="approve"
        size="large"
        icon={<ThumbsUp size={16} />}
        loading={loading.approve}
        onClick={onApproveConfirmed}
      >
        {completionsId.length > 1
          ? `Approve ${completionsId.length} Completions`
          : 'Approve Completion'}
      </Button>
    </Container>
  );
};

export default ApprovalButtons;
