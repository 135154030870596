import { get } from 'lodash';
import MapGL, { Source, Layer, AttributionControl } from 'react-map-gl';
import { heatmapLayer } from './map-style';
import { useEffect, useMemo, useState } from 'react';
import Layout from '@/components/Layout';
import { Container } from './styles';
import { MapProvider } from 'react-map-gl';
import BottomKpis from './bottom-kpis';
import ContentHeader from './content-header';
import { getMapDashboardPeriods } from '@/services/report.service';
import { useFullscreen, useToggle } from 'react-use';
import { useRef } from 'react';
import { BsFullscreen, BsFullscreenExit } from 'react-icons/bs';
import { useAudit } from '@/hooks';

const mapboxKey = get(process.env, 'REACT_APP_MAPBOX_TOKEN', '');

export type MapData = {
  periodHours: number;
  campaignAffectedStores: number;
  campaignAffectedUsers: number;
  completionsLocations: Array<[number, number]>;
  completionsRga: number;
  completionsRgaPercent: number;
  completionsTotals: number;
  newUsers: number;
  queueTotalActiveLists: number;
  queueTotalProcessedJobs: number;
  totalActiveCampaigns: number;
  totalCampaignsLaunched: number;
};

const MapDashboard = () => {
  const ref = useRef(null);
  const [show, toggle] = useToggle(false);
  const isFullscreen = useFullscreen(ref, show, {
    onClose: () => toggle(false),
  });
  const [data, setData] = useState<MapData[]>([]);
  const [selectedPeriod, setSelectedPeriod] = useState(0);
  const [selectedData, setSelectedData] = useState<MapData>({
    periodHours: 0,
    campaignAffectedStores: 0,
    campaignAffectedUsers: 0,
    completionsLocations: [],
    completionsRga: 0,
    completionsRgaPercent: 0,
    completionsTotals: 0,
    newUsers: 0,
    queueTotalActiveLists: 0,
    queueTotalProcessedJobs: 0,
    totalActiveCampaigns: 0,
    totalCampaignsLaunched: 0,
  });

  const audit = useAudit({
    resourceName: 'map-dashboard',
  });

  const mapLocations = useMemo(() => {
    return {
      type: 'FeatureCollection',
      features: selectedData.completionsLocations.map((item, i) => ({
        type: 'Feature',
        properties: {
          id: `item-${item[0]}-${item[1]}-${i}`,
        },
        geometry: {
          type: 'Point',
          coordinates: [item[0], item[1]],
        },
      })),
    };
  }, [selectedData.completionsLocations]);

  useEffect(() => {
    audit.onAccess();
    loadData();
  }, []);

  useEffect(() => {
    onSelectPeriod();
  }, [selectedPeriod]);

  const onSelectPeriod = () => {
    setSelectedPeriod(selectedPeriod);
    const periodData: any = data.find(
      (item: any) => item.periodHours === Number(selectedPeriod),
    );

    if (!periodData) {
      return;
    }

    setSelectedData((prev) => ({
      ...prev,
      ...periodData,
    }));
  };

  const loadData = async () => {
    const { periods } = await getMapDashboardPeriods();

    setData(periods);

    setTimeout(() => setSelectedPeriod(24), 0);
  };

  return (
    <Layout noCard>
      <Container ref={ref}>
        <MapProvider>
          <MapGL
            attributionControl={false}
            initialViewState={{
              latitude: 40,
              longitude: 40,
              zoom: 1.5,
            }}
            mapStyle="mapbox://styles/mapbox/dark-v9"
            mapboxAccessToken={mapboxKey}
            style={{
              width: '100%',
              height: 'calc(100vh - 160px)',
              maxHeight: '100%',
            }}
          >
            {mapLocations && (
              <Source type="geojson" data={mapLocations as any}>
                <Layer {...heatmapLayer} />
              </Source>
            )}
          </MapGL>
        </MapProvider>
        <button className="full-screen-btn" onClick={() => toggle()}>
          {isFullscreen ? <BsFullscreenExit /> : <BsFullscreen />}
        </button>
        <ContentHeader
          data={selectedData}
          onSelect={setSelectedPeriod}
          selectedPeriod={selectedPeriod}
        />
        <BottomKpis data={selectedData} selectedPeriod={selectedPeriod} />
      </Container>
    </Layout>
  );
};

export default MapDashboard;
