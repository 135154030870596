import React, { useState, useEffect } from 'react';
import { Card, Col, Row } from 'antd';
import { getDashboardData } from '@/services/report.service';
import { getPoeAnalysisPendingProcessingCount } from '@/services/mobile-user.service';

const UserPoeStatistics: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [statistics, setStatistics] = useState({
    poeApprovedLastDay: 0,
    poeApprovedLastWeek: 0,
    poeApprovedLastMonth: 0,
    newLastDay: 0,
    newLastWeek: 0,
    newLastMonth: 0,
  });

  const [pendingProcessingCount, setPendingProcessingCount] = useState({status:false, pending:0, processing:0});

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    setLoading(true);
    try {
      const res1 = await getDashboardData('poe-dashboard');
      setStatistics((prev) => ({
        ...prev,
        ...res1.data,
      }));
  
      const res2 = await getPoeAnalysisPendingProcessingCount();
      setPendingProcessingCount((prev) => ({
        ...prev,
        ...res2,
      }));
  
      setLoading(false);
    } catch (error) {
      console.error('Error loading data:', error);
      setLoading(false);
    }
  
  };
  const gridStyle: React.CSSProperties = {
    width: '25%',
    textAlign: 'center',
    padding: '15px 0',
  };
  const gridStyle1: React.CSSProperties = {
    width: '50%',
    textAlign: 'center',
    padding: '15px 0',
  };

  return (
    <div className="site-statistic-demo-card">
      <Row gutter={16}>
      <Col span={12}>
        <Card title="User POE" loading={loading} size="small" headStyle={{ textAlign: 'center', margin:0 }}>
          <Card.Grid hoverable={false} style={gridStyle}></Card.Grid>
          <Card.Grid hoverable={false} style={gridStyle}>Last Day:</Card.Grid>
          <Card.Grid hoverable={false} style={gridStyle}>Last Week:</Card.Grid>
          <Card.Grid hoverable={false} style={gridStyle}>Last Month:</Card.Grid>
          <Card.Grid hoverable={false} style={gridStyle}>New Users</Card.Grid>
          <Card.Grid hoverable={false} style={gridStyle}><strong>{statistics.newLastDay}</strong></Card.Grid>
          <Card.Grid hoverable={false} style={gridStyle}><strong>{statistics.newLastWeek}</strong></Card.Grid>
          <Card.Grid hoverable={false} style={gridStyle}><strong>{statistics.newLastMonth}</strong></Card.Grid>
          <Card.Grid hoverable={false} style={gridStyle}>POE Approved</Card.Grid>
          <Card.Grid hoverable={false} style={gridStyle}><strong>{statistics.poeApprovedLastDay}</strong></Card.Grid>
          <Card.Grid hoverable={false} style={gridStyle}><strong>{statistics.poeApprovedLastWeek}</strong></Card.Grid>
          <Card.Grid hoverable={false} style={gridStyle}><strong>{statistics.poeApprovedLastMonth}</strong></Card.Grid>
        </Card>
      </Col>
      <Col span={8}>
        <Card title="Analysis Status" loading={loading} size="small" headStyle={{ textAlign: 'center', margin:0 }}>
          <Card.Grid hoverable={false} style={gridStyle1}>Pending</Card.Grid>
          <Card.Grid hoverable={false} style={gridStyle1}><strong>{pendingProcessingCount.pending}</strong></Card.Grid>
          <Card.Grid hoverable={false} style={gridStyle1}>Processing</Card.Grid>
          <Card.Grid hoverable={false} style={gridStyle1}><strong>{pendingProcessingCount.processing}</strong></Card.Grid>
        </Card>
      </Col>
      </Row>
    </div>
  );
};

export default UserPoeStatistics;
