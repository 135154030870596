import { FC, ReactNode, useState } from 'react';
import {
  Layout,
  Menu,
  Affix,
  theme,
  MenuProps,
  Space,
  notification,
} from 'antd';
import { FaSignOutAlt } from 'react-icons/fa';
import { Dropdown, Avatar } from 'antd';
import { RightMenu, Container } from './styles';
import Sidebar from './Sidebar';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { UserOutlined } from '@ant-design/icons';
import { useSession } from '@/store/session';

type Props = {
  children: ReactNode;
  restrictPermissions?: string[];
  noCard?: boolean;
};

const AppLayout: FC<Props> = ({ children, noCard = false }) => {
  const session = useSession();
  const navigate = useNavigate();
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const items: MenuProps['items'] = [
    {
      key: 'user-menu-key-1',
      label: 'Logout',
      icon: <FaSignOutAlt />,
      danger: true,
      onClick: () => session.onLogout(),
    },
  ];

  return (
    <Container>
      <Affix offsetTop={0}>
        <Layout.Header
          className="layout-header"
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <Link to={'/'} className="logo" />
          <Menu
            theme="dark"
            mode="horizontal"
            defaultSelectedKeys={['2']}
            items={[]}
          />

          <RightMenu>
            <Dropdown placement="bottomRight" arrow menu={{ items }}>
              <a onClick={(e) => e.preventDefault()}>
                <Space>
                  <Avatar icon={<UserOutlined />} />
                </Space>
              </a>
            </Dropdown>
          </RightMenu>
        </Layout.Header>
      </Affix>
      <Layout className="layout-body">
        <Layout.Sider
          collapsible
          collapsed={sidebarCollapsed}
          onCollapse={(value) => setSidebarCollapsed(value)}
          width={236}
          style={{ background: colorBgContainer }}
          className="layout-sider"
        >
          <Sidebar />
        </Layout.Sider>

        <Layout className="layout-container" id="app-container">
          <div className="container-wrapper">
            {noCard && children}

            {!noCard && (
              <Layout.Content
                className="layout-content"
                style={{
                  background: colorBgContainer,
                }}
              >
                {children}
              </Layout.Content>
            )}

            <Layout.Footer className="layout-footer">
              © {new Date().getFullYear()} ENDVR, a division of NewKnow Inc.
            </Layout.Footer>
          </div>
        </Layout>
      </Layout>
    </Container>
  );
};

export default AppLayout;
