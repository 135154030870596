import { type FC } from 'react';
import { DetailedManagerSubmission } from '@/types';
import { Container } from './styles';
import { Collapse, Typography } from 'antd';
import Overview from './Overview';
import Answers from './Answers';

type Args = {
  completion: DetailedManagerSubmission;
};

const Completion: FC<Args> = ({ completion }) => {
  if (!completion) {
    return null;
  }

  const elements = [
    {
      label: 'Overview',
      key: 'overview',
      children: <Overview completion={completion} />,
    },
    {
      label: 'Answers',
      key: 'answers',
      children: <Answers completion={completion} />,
    },
  ];

  return (
    <Container>
      <div className="header">
        <span className="campaign-name">{completion.mission.title}</span>
        <Typography.Paragraph
          style={{ whiteSpace: 'pre-wrap' }}
          ellipsis={{ expandable: true }}
        >
          {completion.mission.description}
        </Typography.Paragraph>
      </div>

      <Collapse
        defaultActiveKey={elements.map((element) => element.key)}
        size="small"
        bordered={true}
        accordion={false}
        destroyInactivePanel
        items={elements}
      />
    </Container>
  );
};

export default Completion;
