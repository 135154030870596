import { FC, useMemo } from 'react';
import { ConsumerCompletion } from '@/types';
import { OverviewContainer } from './styles';
import StatusTag from './StatusTag';
import { formatISODate, formatMoney } from '@/utils';
import { Descriptions, Divider, Typography } from 'antd';
import { get } from 'lodash';
import { Link } from 'react-router-dom';
import { ExternalLink } from 'lucide-react';
import { When } from 'react-if';
import { useConsumerSubmissionDialog } from '../dialog-hook';

type Args = {
  completion: ConsumerCompletion;
};

const Overview: FC<Args> = ({ completion }) => {
  const submissionDialog = useConsumerSubmissionDialog();

  const descriptionLayout = useMemo(() => {
    return submissionDialog.state.mode === 'compare'
      ? 'vertical'
      : 'horizontal';
  }, [submissionDialog.state.mode]);

  return (
    <OverviewContainer>
      <div className="body">
        <Descriptions
          layout={descriptionLayout}
          title={'Completion'}
          size={'small'}
          column={2}
        >
          <Descriptions.Item label="ID">
            <Typography.Text copyable>{completion.id}</Typography.Text>
          </Descriptions.Item>
          <Descriptions.Item label="Status">
            <StatusTag
              status={completion.status}
              processing={completion.processing}
              size={'small'}
            />
          </Descriptions.Item>

          <Descriptions.Item label="Earnings">
            <When condition={!completion.earnings}>
              <span>N/A</span>
            </When>
            <When condition={completion.earnings}>
              <span>
                <strong style={{ fontWeight: 600 }}>
                  {formatMoney(completion.earnings || 0, completion.currency)}
                </strong>{' '}
                {completion.currency}
              </span>
            </When>
          </Descriptions.Item>

          <Descriptions.Item label="Completed At">
            {formatISODate(completion.completedAt)}
          </Descriptions.Item>

          {completion.status === 'approved' && (
            <Descriptions.Item label="Approved At">
              {formatISODate(completion.completedAt)}
            </Descriptions.Item>
          )}

          {completion.status === 'rejected' && (
            <>
              <Descriptions.Item label="Rejected At">
                {formatISODate(completion.completedAt)}
              </Descriptions.Item>

              <Descriptions.Item label="Rejected Reason">
                {get(completion, 'rejectionArgs.rejectText', 'N/A')}
              </Descriptions.Item>
            </>
          )}

          <Descriptions.Item label="Mission">
            <Link to={`/mission/${completion.missionId}`} target={'_blank'}>
              {completion.mission?.title} <ExternalLink size={16} />
            </Link>
          </Descriptions.Item>

          <Descriptions.Item label="Campaign">
            <Link to={`/campaign/${completion.bundleId}`} target={'_blank'}>
              {completion.bundle?.name} <ExternalLink size={16} />
            </Link>
          </Descriptions.Item>

          <Descriptions.Item label="Brand">
            <Link to={`/brand/${completion.brandId}/manage`} target={'_blank'}>
              {completion.brand?.name} <ExternalLink size={16} />
            </Link>
          </Descriptions.Item>
        </Descriptions>

        <Divider />

        <Descriptions
          layout={descriptionLayout}
          title={'Consumer'}
          size={'small'}
          column={2}
        >
          <Descriptions.Item label="Full Name">
            {completion.consumer?.fullName || 'N/A'}
          </Descriptions.Item>
          <Descriptions.Item label="Phone Number">
            {completion.consumer?.phoneNumber}
          </Descriptions.Item>
          <Descriptions.Item label="Email">
            {completion.consumer?.email || 'N/A'}
          </Descriptions.Item>
        </Descriptions>
      </div>
    </OverviewContainer>
  );
};

export default Overview;
