import {
  Button,
  Checkbox,
  Form,
  Input,
  InputNumber,
  Radio,
  Select,
  Space,
} from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { When } from 'react-if';

type Props = {
  formValues: any;
  [key: string]: any;
};

const ApiTokenPartial = ({ formValues }: Props) => {
  return (
    <>
      <Form.Item
        name={['apiToken', 'apiHost']}
        label="API Host"
        rules={[{ required: true }]}
        wrapperCol={{ span: 8 }}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name={['apiToken', 'type']}
        label="Token Type"
        rules={[{ required: true }]}
        wrapperCol={{ span: 3 }}
      >
        <Select placeholder={'Select one'}>
          <Select.Option value="bearer">Bearer</Select.Option>
          <Select.Option value="custom">Custom</Select.Option>
        </Select>
      </Form.Item>

      <When condition={formValues.apiToken?.type === 'custom'}>
        <Form.Item
          name={['apiToken', 'customHeaderTokenFieldName']}
          label="Header Token Name"
          rules={[{ required: true }]}
          wrapperCol={{ span: 8 }}
          help={'The name of the header field that contains the token'}
        >
          <Input placeholder="X-Shopify-Access-Token" />
        </Form.Item>
      </When>

      <Form.Item label="Auth Fields" shouldUpdate={true}>
        {() => (
          <Form.List
            name={['apiToken', 'authFields']}
            rules={[
              {
                validator: async (_, names) => {
                  if (!names || names.length < 1) {
                    return Promise.reject(new Error('At least one field'));
                  }
                },
              },
            ]}
          >
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space
                    key={key}
                    style={{ display: 'flex', marginBottom: 8 }}
                    align="start"
                  >
                    <Form.Item
                      {...restField}
                      name={[name, 'key']}
                      rules={[{ required: true, message: 'Missing key' }]}
                    >
                      <Input placeholder="Key" />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, 'label']}
                      rules={[{ required: true, message: 'Missing label' }]}
                    >
                      <Input placeholder="Label" />
                    </Form.Item>
                    <Form.Item {...restField} name={[name, 'helpText']}>
                      <Input.TextArea placeholder="Help" rows={3} />
                    </Form.Item>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Space>
                ))}
                <Form.Item wrapperCol={{ span: 11 }}>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add Field
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        )}
      </Form.Item>
    </>
  );
};

export default ApiTokenPartial;
