import PageHeader from '@/components/PageHeader';
import {
  Avatar,
  Button,
  Col,
  Divider,
  Form,
  Input,
  notification,
  Radio,
  Row,
  Select,
  Space,
  Switch as SwitchInput,
  Tag,
  Typography,
} from 'antd';
import Autocomplete from 'react-google-autocomplete';
import { BrandList, FormContainer } from './styles';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useAudit } from '@/hooks';
import { getBrandsByIds, getIndustries } from '@/services/brand.service';
import Layout from '@/components/Layout';
import LoadingPage from '@/pages/Loading';
import CropUpload from '@/components/CropUpload';
import { get, groupBy, map } from 'lodash';
import currencies from '@/data/countries-currencies';
import { getStoreFormData } from '@/services/store.service';
import * as process from 'process';
import { parseGoogleResult } from '@/utils/address';
import FormBrandSelection from '@/components/FormBrandSelection';
import { When } from 'react-if';
import { ExternalLink } from 'lucide-react';
import { PlusOutlined } from '@ant-design/icons';
import { IoPlanetOutline } from 'react-icons/io5';
import { useQuery } from '@tanstack/react-query';
import { updateStore } from '@/services/store.service';

import BrandPickerModal from '@/components/BrandPickerModal';

type FormData = {
  id: string;
  isDeleted: boolean;
  name: string;
  isHidden: boolean;
  isExclusive: boolean;
  isPrivate: boolean;
  isRestricted: boolean;
  isDraft: boolean;
  isAdminDraft: boolean;
  createdAt: string;
  updatedAt: string;
  managerId: string;
  address: {
    street: string;
    city: string;
    state: string;
    country: string;
    countryCode: string;
    zip: string;
    formattedAddress: string;
    lat: number;
    lng: number;
  };
  industries: string[];
  keywords: string;
  location: {
    type: string;
    coordinates: number[];
  };
  nameKeywords: string;
  shopUrl: string;
  currency: string;
  logoUrl: string;
  brandOwnerId: any;
  isLocked: boolean;
  isPoeExempt: boolean;
  smartReviewAnalysis: {
    provider: string;
    modelId: string;
  };
};

const googleKey = get(process.env, 'REACT_APP_GOOGLE_API_KEY', '');

const BrandEdit = () => {
  const navigate = useNavigate();
  const { storeId } = useParams<{ storeId: string }>();
  const [pageLoaded, setPageLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [industries, setIndustries] = useState<{ id: string; name: string }[]>(
    [],
  );
  const [form] = Form.useForm();
  const [formData, setFormData] = useState<FormData | any>(null);
  const [selectedBrandIds, setSelectedBrandIds] = useState<string[]>([]);
  const [isApprovedBrandsModalOpen, setIsApprovedBrandsModalOpen] =
    useState(false);

  const { data: selectedBrands } = useQuery({
    queryKey: ['get-brands-by-ids', selectedBrandIds],
    queryFn: async () => {
      if (selectedBrandIds.length === 0) return [];
      const result = await getBrandsByIds(selectedBrandIds);
      if (!result || result.status === false) return null;
      return result.brands;
    },
    enabled: formData?.isDraft,
  });

  const onFinish = async (values: FormData) => {
    if (!storeId) return;
    setLoading(true);

    if (!values.isPrivate) {
      values.isLocked = false;
      values.brandOwnerId = null;
    }

    const result = await updateStore(storeId, {
      ...values,
      brandIds: formData.isDraft ? selectedBrandIds : undefined,
    });

    if (!result || result.status === false) {
      notification.error({
        message: 'Error',
        description:
          result?.message || 'Failed to update store for unknown reasons',
      });
      setLoading(false);
      return;
    }

    if (result.status === 'partial') {
      notification.warning({
        message: 'Partial Success',
        description: result.message,
      });
    } else {
      notification.success({
        message: 'Success',
        description: `Store ${values.name} has been updated successfully!`,
      });
    }

    setLoading(false);

    audit.onUpdate({ target: 'save', data: values, entityId: storeId });
    navigate('/store');
  };

  const audit = useAudit({
    resourceName: 'store-edit',
  });

  const loadIndustries = async () => {
    const { industries } = await getIndustries();

    setIndustries(industries);
  };

  const loadFormData = async () => {
    if (!storeId) return;

    const { store } = await getStoreFormData(storeId);

    if (!store) {
      navigate('/store');
    }

    setFormData(store);

    setTimeout(() => {
      setPageLoaded(true);
    }, 10);

    await loadIndustries();
  };

  useEffect(() => {
    audit.onAccess();
    loadFormData();
  }, []);

  if (!pageLoaded || !formData) {
    return <LoadingPage />;
  }

  return (
    <Layout>
      <PageHeader
        pageName={'Edit Store'}
        title={
          <Space>
            <span>Edit Store</span>
            <When condition={formData.managerId}>
              <Tag color="orange">
                Managed by{' '}
                <Link
                  to={`/company/${formData.managerId}/detail`}
                  style={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    gap: 4,
                  }}
                >
                  Retailer Company <ExternalLink size={14} />
                </Link>
              </Tag>
            </When>
          </Space>
        }
      />

      <FormContainer>
        <Form
          form={form}
          layout="vertical"
          autoComplete="off"
          onFinish={onFinish}
          initialValues={formData}
          disabled={loading}
          onValuesChange={(changedFields, allFields) => {
            setFormData((prev: FormData) => ({
              ...prev,
              ...allFields,
            }));
          }}
        >
          <Row gutter={32} style={{ alignItems: 'flex-start' }}>
            <Col span={14}>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    label="Name"
                    name="name"
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item
                    name="currency"
                    label="Currency"
                    rules={[{ required: true }]}
                  >
                    <Select showSearch placeholder="Select a currency">
                      {map(
                        groupBy(currencies, 'currency'),
                        (data, currency) => (
                          <Select.Option
                            key={`option-${currency}`}
                            value={currency}
                          >
                            <Space>
                              <Avatar
                                size={22}
                                src={`https://raw.githubusercontent.com/Lissy93/currency-flags/master/assets/flags_svg/${currency.toLowerCase()}.svg`}
                                shape="square"
                              />
                              {currency}
                            </Space>
                          </Select.Option>
                        ),
                      )}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name="industries" label="Industry">
                    <Select
                      mode="multiple"
                      maxTagCount="responsive"
                      placeholder="Select a industry"
                    >
                      {industries.map((industry) => (
                        <Select.Option
                          key={`option-${industry.id}`}
                          value={industry.id}
                        >
                          {industry.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={18}>
                  <Form.Item
                    label="Shop URL"
                    name="shopUrl"
                    rules={[
                      {
                        type: 'url',
                      },
                    ]}
                  >
                    <Input placeholder={'http://storeurl.com'} />
                  </Form.Item>
                </Col>
              </Row>

              <Divider orientation="left" orientationMargin="0">
                Store Address
              </Divider>

              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item name={'storeSearch'}>
                    <Autocomplete
                      apiKey={googleKey}
                      style={{ width: '100%' }}
                      id="search-store"
                      placeholder={'Search address'}
                      onPlaceSelected={(place) => {
                        const address = parseGoogleResult(place);
                        const location = {
                          type: 'Point',
                          coordinates: [address.lng, address.lat],
                        };

                        form.setFieldsValue({
                          address,
                          location,
                        });
                      }}
                      options={{
                        types: ['address'],
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row
                gutter={16}
                style={{ borderTop: '1px dashed #ebebeb', paddingTop: 10 }}
              >
                <Col span={16}>
                  <Form.Item
                    name={['address', 'street']}
                    rules={[{ required: true }]}
                    label="Street"
                  >
                    <Input />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    name={['address', 'zip']}
                    rules={[{ required: true }]}
                    label="ZipCode"
                  >
                    <Input readOnly />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={10}>
                  <Form.Item
                    name={['address', 'city']}
                    rules={[{ required: true }]}
                    label="City"
                  >
                    <Input disabled />
                  </Form.Item>
                </Col>

                <Col span={7}>
                  <Form.Item
                    name={['address', 'lat']}
                    rules={[{ required: true }]}
                    label="Latitude"
                  >
                    <Input disabled />
                  </Form.Item>
                </Col>

                <Col span={7}>
                  <Form.Item
                    name={['address', 'lng']}
                    rules={[{ required: true }]}
                    label="Longidude"
                  >
                    <Input disabled />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name={['address', 'country']}
                    rules={[{ required: true }]}
                    label="Country"
                  >
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name={['address', 'state']}
                    rules={[{ required: true }]}
                    label="State"
                  >
                    <Input disabled />
                  </Form.Item>
                </Col>
              </Row>

              {formData.isDraft && (
                <Row gutter={16}>
                  <Col span={24}>
                    <Divider orientation="left" orientationMargin="0">
                      Brands
                    </Divider>

                    <Row>
                      <Button
                        type="primary"
                        icon={<PlusOutlined />}
                        onClick={() => setIsApprovedBrandsModalOpen(true)}
                      >
                        Select Brands
                      </Button>

                      <BrandPickerModal
                        open={isApprovedBrandsModalOpen}
                        selected={selectedBrandIds}
                        multiple
                        forceTypeFilter="brand"
                        onSelect={(brandIds) => {
                          setSelectedBrandIds(brandIds);
                        }}
                        onClose={() => setIsApprovedBrandsModalOpen(false)}
                      />
                    </Row>

                    <Typography.Text
                      style={{ display: 'block', marginTop: 16 }}
                      strong
                    >
                      Selected Brands
                    </Typography.Text>

                    <BrandList>
                      {selectedBrands?.map((brand) => (
                        <Row key={brand.id}>
                          <Avatar
                            src={brand.logoUrl}
                            style={{ marginRight: 10 }}
                            size={44}
                            shape="square"
                            icon={<IoPlanetOutline />}
                          />
                          <Typography.Text
                            className="name"
                            ellipsis
                            strong
                            style={{ fontSize: 16, lineHeight: '22px' }}
                          >
                            {brand.name}
                          </Typography.Text>
                        </Row>
                      ))}
                    </BrandList>
                  </Col>
                </Row>
              )}

              <Row gutter={16}>
                <Col span={24}>
                  <Divider orientation="left" orientationMargin="0">
                    Private Store
                  </Divider>

                  <Row gutter={16}>
                    <Col span={10}>
                      <Form.Item
                        name="isPrivate"
                        valuePropName="checked"
                        help="Store will be owned by a specific brand selected"
                      >
                        <SwitchInput
                          checkedChildren="Enabled"
                          unCheckedChildren="Disabled"
                        />
                      </Form.Item>
                    </Col>

                    <When condition={formData.isPrivate}>
                      <Col span={14}>
                        <Form.Item
                          name="isLocked"
                          valuePropName="checked"
                          help="User will have to request access to the store and the owner brand will have to approve it"
                        >
                          <SwitchInput
                            checkedChildren="Store Locked"
                            unCheckedChildren="Store Unlocked"
                          />
                        </Form.Item>
                      </Col>
                    </When>
                  </Row>
                </Col>
              </Row>

              {/*<Row gutter={16}>*/}
              {/*  <Col span={24}>*/}
              {/*    <Divider orientation="left" orientationMargin="0">*/}
              {/*      Exclusive Store*/}
              {/*    </Divider>*/}

              {/*    <Form.Item*/}
              {/*      name="isExclusive"*/}
              {/*      valuePropName="checked"*/}
              {/*      help="The store will only be accessible to the brand owner, removing all other brand connections."*/}
              {/*    >*/}
              {/*      <SwitchInput*/}
              {/*        checkedChildren="Enabled"*/}
              {/*        unCheckedChildren="Disabled"*/}
              {/*      />*/}
              {/*    </Form.Item>*/}
              {/*  </Col>*/}
              {/*</Row>*/}

              <Divider orientation="left" orientationMargin="0">
                Hidden Store
              </Divider>

              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item
                    name="isHidden"
                    valuePropName="checked"
                    help="Store will not be showing in the app search"
                  >
                    <SwitchInput
                      checkedChildren="Enabled"
                      unCheckedChildren="Disabled"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Divider orientation="left" orientationMargin="0">
                Restrict Data
              </Divider>

              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item
                    name="isRestricted"
                    valuePropName="checked"
                    help="Store will ONLY see campaigns from ENDVR Brand"
                  >
                    <SwitchInput
                      checkedChildren="Enabled"
                      unCheckedChildren="Disabled"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Divider orientation="left" orientationMargin="0">
                Smart Review Analysis
              </Divider>

              <Form.Item
                name={['smartReviewAnalysis', 'provider']}
                label="Provider"
                rules={[{ required: false }]}
              >
                <Radio.Group buttonStyle="solid">
                  <Radio.Button value="verify">Verify</Radio.Button>
                  <Radio.Button value="azure">Azure</Radio.Button>
                  <Radio.Button value="hive">Hive</Radio.Button>
                </Radio.Group>
              </Form.Item>

              <When
                condition={formData.smartReviewAnalysis?.provider === 'azure'}
              >
                <Form.Item
                  name={['smartReviewAnalysis', 'modelId']}
                  label="Model ID"
                  rules={[{ required: true }]}
                  wrapperCol={{ span: 12 }}
                >
                  <Input />
                </Form.Item>
              </When>
            </Col>

            <Col span={10}>
              {(formData.isPrivate || formData.isExclusive) && (
                <Form.Item
                  name="brandOwnerId"
                  label={'Brand Owner'}
                  rules={[
                    {
                      required: true,
                      message: 'Please select a brand owner!',
                    },
                  ]}
                >
                  <FormBrandSelection />
                </Form.Item>
              )}

              <Form.Item
                name="logoUrl"
                label="Logo"
                valuePropName="value"
                rules={[
                  {
                    required: true,
                    message: 'Please upload and crop the image!',
                  },
                ]}
              >
                <CropUpload />
              </Form.Item>
            </Col>
          </Row>
          <br />
          <br />

          <Form.Item>
            <Button
              type="primary"
              size={'large'}
              htmlType="submit"
              loading={loading}
            >
              Save Store
            </Button>
          </Form.Item>
        </Form>
      </FormContainer>
    </Layout>
  );
};

export default BrandEdit;
