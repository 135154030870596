import { Tag, Popover, Descriptions } from 'antd';
import { isString } from 'lodash';

type Args = {
  items:
    | string[]
    | {
        label: string;
        color: string;
      }[];
  max?: number;
};

const TagsRender = ({ items, max = 3 }: Args) => {
  const directTags = items
    .slice(0, max)
    .map((item, index) => <Tag key={index} color={isString(item) ? 'default' : item.color}>
      {isString(item) ? item : item.label}
    </Tag>);

  const extraTags = items
    .slice(max)
    .map((item, index) => <Tag style={{ width: 36 }} key={index + max}>
      {isString(item) ? item : item.label}
    </Tag>);

  const popoverContent = (
    <div
      style={{
        maxWidth: 300,
        display: 'flex',
        flexWrap: 'wrap',
        columnGap: 4,
        rowGap: 6,
      }}
    >
      {extraTags}
    </div>
  );

  return (
    <>
      {directTags}
      {extraTags.length > 0 && (
        <Popover content={popoverContent}>
          <Tag>+{extraTags.length} ...</Tag>
        </Popover>
      )}
    </>
  );
};

export default TagsRender;
