export const levelOptions = [
  { text: 'Basic', value: 'basic', label: 'Basic' },
  { text: 'Pro', value: 'pro', label: 'Pro' },
  { text: 'Enterprise', value: 'enterprise', label: 'Enterprise' },
];

export const typeOptions: any = [
  { text: 'Retail Ops', value: 'general', label: 'Retail Ops' },
  { text: 'Sales Incentive', value: 'contest', label: 'Sales Incentive' },
  { text: 'Digital Learning', value: 'learning', label: 'Digital Learning' },
  {
    text: 'Consumer Cash-Back',
    value: 'consumer',
    label: 'Consumer Cash-Back',
  },
  {
    text: 'Newsfeed',
    value: 'newsfeed',
    label: 'Newsfeed',
  },
];
