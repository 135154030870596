import QueryString from 'qs';
import { httpGet, httpPost, httpPut } from './util/axios';

export const getDetails = async (id: string) => {
  return httpGet('user/read', `admin/web-user/details?userId=${id}`);
};

export const changeDefaultProfile = async (
  userId: string,
  profileType: string,
) => {
  return httpPut(
    'user/write',
    `admin/web-user/${userId}/change-default-profile`,
    {
      profileType,
    },
  );
};

export const getUserBrands = async ({
  userId = '',
  meta = {},
  filters = {},
}) => {
  const query = QueryString.stringify({
    userId,
    filters,
    ...meta,
  });

  return httpGet('user/read', `admin/web-user/brands?${query}&cache=no`);
};

export const unlinkBrand = async (userId: string, brandId: string) => {
  return httpPost('user/write', `admin/web-user/${userId}/unlink-brand`, {
    brandId,
  });
};

export const changeBrandProfile = async ({
  userId,
  brandId,
  profileType,
}: any) => {
  return httpPost(
    'user/write',
    `admin/web-user/${userId}/change-brand-profile`,
    {
      brandId,
      profileType,
    },
  );
};

export const deleteUser = async (userId: string) => {
  return httpPost('user/write', `admin/web-user/${userId}/delete`, {});
};

export const updateBrandScope = async ({
  userId,
  brandId,
  scope,
  pks,
}: {
  userId: string;
  brandId: string;
  scope: string;
  pks: string[];
}) => {
  return httpPost('user/write', `admin/web-user/${userId}/update-brand-scope`, {
    brandId,
    scope,
    pks,
  });
};

export const linkBrands = async (
  userId: string,
  brandIds: string[],
  profileType: string,
) => {
  return httpPost('user/write', `admin/web-user/${userId}/link-brands`, {
    brandIds,
    profileType,
  });
};
