import { Timeline } from 'antd';
import styled from 'styled-components';

export const StyledTimeline = styled(Timeline)`
  padding: 20px 30px 20px 20px;
  width: 100%;
  box-sizing: border-box;
`;

export const TimeLineEntry = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 18px;

  > .header {
    display: flex;
    align-items: center;
    column-gap: 8px;

    .date-title {
      font-size: 12px;
      display: flex;
      height: 22px;
      align-items: center;
      color: #e67777;
      font-weight: 700;
      /* color: #a9a9a9; */
    }

    .id-title {
      font-size: 12px;
    }
  }

  > .description {
    display: flex;
    align-items: center;
    column-gap: 4px;
  }
`;
