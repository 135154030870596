import { FC, useEffect, useState } from 'react';
import './index.css';
import Layout from '@/components/Layout';
import { Form } from 'antd';
import PageHeader from '@/components/PageHeader';
import ReportKafkaDlqCards from './Components/Cards';
import ReportKafkaDlqModal from './Components/Modal';
import ReportKafkaDlqDataTable, {
  ReportKafkaDlqRowData,
} from './Components/DataTable';
import {
  loadSummarizeReportKafka,
  loadTableReportKafka,
} from '@/services/admin.portal.service';
import { useNavigate } from 'react-router-dom';
import { useAudit } from '@/hooks';

const ReportKafkaDlqPage: FC = () => {
  const [topicData, setTopicData] = useState([]);
  const [topicTotal, setTopicTotal] = useState(0);
  const [groupIdData, setGroupIdData] = useState([]);
  const [groupIdTotal, setGroupIdTotal] = useState(0);
  const [clientIdData, setClientIdData] = useState([]);
  const [clientIdTotal, setClientIdTotal] = useState(0);
  const [statusFailedData, setStatusFailedData] = useState([]);
  const [statusFailedTotal, setStatusFailedTotal] = useState(0);
  const [statusReprocessedData, setStatusReprocessedData] = useState([]);
  const [statusReprocessedTotal, setStatusReprocessedTotal] = useState(0);

  const [modalVisible, setModalVisible] = useState(false);
  const [rowData, setRowData] = useState<ReportKafkaDlqRowData | null>(null);
  const [externalDataSource, setExternalDataSource] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);

  const navigate = useNavigate();

  const audit = useAudit({
    resourceName: 'messaging-controller',
  });

  const updateTable = async () => {
    const dataSource = await loadTableReportKafka(navigate, null, {
      currentePage: pageNumber,
      perPage: pageSize,
    });
    setExternalDataSource(dataSource.items);
    setTotalItems(dataSource._meta.totalCount);
    setPageNumber(dataSource._meta.currentPage);
    setPageSize(dataSource._meta.perPage);
  };
  const fetchOptions = async () => {
    try {
      const preparationReportKafkaData = await loadSummarizeReportKafka(
        navigate,
      );
      setTopicData(preparationReportKafkaData.topic.data);
      setTopicTotal(preparationReportKafkaData.topic.total);
      setGroupIdData(preparationReportKafkaData.groupId.data);
      setGroupIdTotal(preparationReportKafkaData.groupId.total);
      setClientIdData(preparationReportKafkaData.clientId.data);
      setClientIdTotal(preparationReportKafkaData.clientId.total);
      setStatusFailedData(preparationReportKafkaData.statusFailed.data);
      setStatusFailedTotal(preparationReportKafkaData.statusFailed.total);
      setStatusReprocessedData(
        preparationReportKafkaData.statusReprocessed.data,
      );
      setStatusReprocessedTotal(
        preparationReportKafkaData.statusReprocessed.total,
      );
      await updateTable();
    } catch (error) {
      console.error('Error fetching select options:', error);
    }
  };

  useEffect(() => {
    audit.onAccess();
    fetchOptions();
  }, []);

  const onFinish = async (fieldsValue: any) => {
    console.log('Success:', fieldsValue);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Layout>
      <PageHeader noBackButton title="Messaging Controller"></PageHeader>
      <ReportKafkaDlqCards
        topicTotal={topicTotal}
        groupIdTotal={groupIdTotal}
        clientIdTotal={clientIdTotal}
        statusFailedTotal={statusFailedTotal}
        statusReprocessedTotal={statusReprocessedTotal}
      />
      <ReportKafkaDlqDataTable
        externalDataSource={externalDataSource}
        pageNumber={pageNumber}
        pageSize={pageSize}
        totalItems={totalItems}
        setModalVisible={setModalVisible}
        setRowData={setRowData}
        setPageSize={setPageSize}
        setExternalDataSource={setExternalDataSource}
        setPageNumber={setPageNumber}
        setTotalItems={setTotalItems}
      />
      <Form
        name="time_related_controls"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        style={{ maxWidth: 600 }}
        className="login-form"
      ></Form>
      <ReportKafkaDlqModal
        modalVisible={modalVisible}
        rowData={rowData}
        setModalVisible={setModalVisible}
      />
    </Layout>
  );
};

export default ReportKafkaDlqPage;
