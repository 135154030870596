import { Button, type ButtonProps } from 'antd';
import { forwardRef } from 'react';
import { Link, useHref, useLinkClickHandler } from 'react-router-dom';

export type ButtonLinkProps = Omit<ButtonProps, 'href'> &
  Pick<
    React.ComponentProps<typeof Link>,
    | 'to'
    | 'replace'
    | 'state'
    | 'target'
    | 'preventScrollReset'
    | 'relative'
    | 'unstable_viewTransition'
  >;

/** A button that uses the `Button` component from Ant Design, but with the
 * ability to use a `to` prop to navigate to a different page.
 *
 * Note that the `Button` component from Ant Design already provides an `href`
 * prop, but it should not be used, as it doesn't use the React Router DOM API
 * for navigating.
 */
export const ButtonLink = forwardRef<
  HTMLButtonElement | HTMLAnchorElement,
  ButtonLinkProps
>(
  (
    {
      to,
      replace,
      state,
      target,
      preventScrollReset,
      relative,
      unstable_viewTransition,
      onClick,
      ...props
    },
    ref,
  ) => {
    const href = useHref(to);
    const handleClick = useLinkClickHandler(to, {
      replace,
      state,
      target,
      preventScrollReset,
      relative,
      unstable_viewTransition,
    });

    return (
      <Button
        href={href}
        target={target}
        onClick={(event) => {
          onClick?.(event);
          if (!event.defaultPrevented) {
            handleClick(event as React.MouseEvent<HTMLAnchorElement>);
          }
        }}
        {...props}
        ref={ref}
      />
    );
  },
);
