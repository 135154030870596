import { useAudit } from '@/hooks';
import { httpGet } from '@/services/util/axios';
import {
  Drawer,
  Descriptions,
  Pagination,
  TimelineItemProps,
  Typography,
  Popover,
  Space,
  Button,
  DatePicker,
  Select,
} from 'antd';
import { useEffect, useState } from 'react';
import { Case, When, Switch, Default } from 'react-if';
import QueryString from 'qs';
import { StyledTimeline, TimeLineEntry } from './styles';
import { SiCodereview } from 'react-icons/si';
import { startCase } from 'lodash';
import { RangePickerProps } from 'antd/es/date-picker';

interface UserActivitiesProps {
  setUserActivityOpen: (userActivityOpen: boolean) => void;
  setActivityUserId: (activityUserId: any) => void;
  setActivityUserName: (activityUserName: any) => void;
  userActivityOpen: boolean;
  activityUserId?: string | null;
  activityUserName?: string | null;
}

const UserActivities = ({
  setActivityUserId,
  activityUserId,
  setUserActivityOpen,
  userActivityOpen,
  setActivityUserName,
  activityUserName,
}: UserActivitiesProps) => {
  const audit = useAudit({
    resourceName: 'user-activities',
  });

  const currentPageDefault = 1;
  const pageSizeDefault = 10;
  let index = 0;
  const [auditItens, setAuditItens] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(pageSizeDefault);
  const [total, setTotal] = useState(currentPageDefault);
  const [pages, setPages] = useState(0);
  const [filters, setFilters] = useState({
    dateStart: '',
    dateEnd: '',
    type: '',
    userId: activityUserId,
  });

  useEffect(() => {
    if (userActivityOpen) {
      audit.onAccess();
      loadAuditDataDefault();
    }
  }, [userActivityOpen]);

  useEffect(() => {
    loadAuditData(currentPage);
  }, [JSON.stringify(filters)]);

  const loadAuditDataDefault = async () => {
    loadAuditData(1);
  };

  const loadAuditData = async (currentPage = 1) => {
    const query = QueryString.stringify({
      currentPage,
      perPage,
      sort: {
        field: 'date',
        order: 'desc',
      },
      filters,
    });
    const { items = [], _meta = {} }: any = await httpGet(
      'user/read',
      `admin/admin-user/register-audit?${query}`,
    );
    setAuditItens(items);
    setTotal(_meta.totalCount);
    setPages(_meta.pageCount);
    setCurrentPage(_meta.currentPage);
    setPerPage(_meta.perPage);
  };

  const changePage = (page: number) => {
    setCurrentPage(page);
    loadAuditData(page);
  };

  const getTimelineItemColor = (action: string): string => {
    switch (action) {
      case 'delete':
        return 'red';
      case 'create':
      case 'update':
        return 'blue';
      default:
        return 'green';
    }
  };

  const getTimelineData = (auditItem: any): React.ReactNode => {
    const auditAction = auditItem.action;
    return (
      <TimeLineEntry>
        <div className="header">
          <Typography.Text className="date-title">
            {auditItem.date}
          </Typography.Text>
          <span>-</span>
          <Typography.Text copyable className="id-title">
            <strong>ID: </strong>
            {auditItem.id}
          </Typography.Text>
        </div>

        <div className="description">
          <Switch>
            <Case condition={auditAction === 'access'}>
              Accessed the <strong>{startCase(auditItem.resourceName)}</strong>{' '}
              page
            </Case>
            <Case condition={auditAction === 'click'}>
              Clicked on the{' '}
              <strong>{startCase(auditItem.resourceName)}</strong> page using
              the <strong>{startCase(auditItem.target)}</strong> component
            </Case>
            <Case condition={auditAction === 'create'}>
              Registered ID <strong>{auditItem.entityId}</strong> on the{' '}
              <strong>{startCase(auditItem.resourceName)}</strong> page using
              the <strong>{auditItem.target}</strong> component.
              <When condition={auditItem.data}>
                <Popover
                  title="Payload"
                  content={() => {
                    return (
                      <code
                        style={{
                          backgroundColor: '#dbdbdb',
                          padding: 8,
                          borderRadius: 5,
                          maxWidth: 500,
                          maxHeight: 400,
                        }}
                      >
                        {JSON.stringify(auditItem.data, null, 2)}
                      </code>
                    );
                  }}
                >
                  <Button shape="circle" className="preview-content">
                    <SiCodereview />
                  </Button>
                </Popover>
              </When>
            </Case>
            <Case condition={auditAction === 'update'}>
              Updated record <strong>{auditItem.entityId}</strong> on the{' '}
              <strong>{startCase(auditItem.resourceName)}</strong> page using
              the <strong>{auditItem.target}</strong> component
              <When condition={auditItem.data}>
                <Popover
                  title="Payload"
                  content={() => {
                    return (
                      <code
                        style={{
                          backgroundColor: '#dbdbdb',
                          padding: 8,
                          borderRadius: 5,
                          maxWidth: 500,
                          maxHeight: 400,
                        }}
                      >
                        {JSON.stringify(auditItem.data, null, 2)}
                      </code>
                    );
                  }}
                >
                  <div className="preview-content">
                    <SiCodereview />
                  </div>
                </Popover>
              </When>
            </Case>
            <Case condition={auditAction === 'delete'}>
              Deleted record <strong>{auditItem.entityId}</strong> on the{' '}
              <strong>{startCase(auditItem.resourceName)}</strong> page using
              the <strong>{auditItem.target}</strong> component
              <When condition={auditItem.data}>
                <Popover
                  title="Payload"
                  content={() => {
                    return (
                      <code
                        style={{
                          backgroundColor: '#dbdbdb',
                          padding: 8,
                          borderRadius: 5,
                          maxWidth: 500,
                          maxHeight: 400,
                        }}
                      >
                        {JSON.stringify(auditItem.data, null, 2)}
                      </code>
                    );
                  }}
                >
                  <div className="preview-content">
                    <SiCodereview />
                  </div>
                </Popover>
              </When>
            </Case>

            <Default>
              Executed [auditAction.action] on the {auditItem.resourceName} page
              using the {auditItem.target} target
            </Default>
          </Switch>
        </div>
      </TimeLineEntry>
    );
  };

  const getTimelineItems = (): TimelineItemProps[] => {
    const items: TimelineItemProps[] = [];

    auditItens.map((auditItem: any) => {
      items.push({
        key: `user-audit-time-line-preview-${index++}`,
        position: 'left',
        color: getTimelineItemColor(auditItem.action || 'access'),
        children: getTimelineData(auditItem),
      });
    });

    return items;
  };

  const onChangeDateFilter = (
    value: RangePickerProps['value'],
    dateString: [string, string] | string,
  ) => {
    setFilters((prev) => ({
      ...prev,
      dateStart: dateString[0],
      dateEnd: dateString[1],
    }));
  };

  return (
    <Drawer
      title={`${activityUserName} activities`}
      extra={
        <Space>
          <Typography.Text strong>Filters:</Typography.Text>
          <DatePicker.RangePicker onChange={onChangeDateFilter} />

          <Select
            allowClear
            showSearch
            style={{ width: 200 }}
            placeholder="Search a type"
            optionFilterProp="children"
            onChange={(value) =>
              setFilters((prev) => ({ ...prev, type: value }))
            }
            filterOption={(input, option) =>
              (option?.label ?? '').includes(input)
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? '')
                .toLowerCase()
                .localeCompare((optionB?.label ?? '').toLowerCase())
            }
            options={[
              {
                value: '',
                label: '',
              },
              {
                value: 'access',
                label: startCase('access'),
              },
              {
                value: 'click',
                label: startCase('click'),
              },
              {
                value: 'create',
                label: startCase('create'),
              },
              {
                value: 'update',
                label: startCase('update'),
              },
              {
                value: 'delete',
                label: startCase('delete'),
              },
            ]}
          />
        </Space>
      }
      size="large"
      width="50%"
      placement="right"
      onClose={() => {
        setAuditItens([]);
        setCurrentPage(currentPageDefault);
        setPerPage(pageSizeDefault);
        setActivityUserId(null);
        setActivityUserName(null);
        setUserActivityOpen(false);
      }}
      open={userActivityOpen}
    >
      <When condition={userActivityOpen && activityUserId}>
        {!auditItens || auditItens.length === 0 ? (
          <Descriptions.Item label="">
            This user has no activity registered
          </Descriptions.Item>
        ) : null}
        {auditItens.length > 0 ? (
          <>
            <StyledTimeline mode="left" items={getTimelineItems()} />
            <Pagination
              total={total}
              onChange={changePage}
              showSizeChanger={false}
              defaultPageSize={perPage}
              defaultCurrent={currentPage}
            />
          </>
        ) : null}
      </When>
    </Drawer>
  );
};

export default UserActivities;
