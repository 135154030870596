import StoreGroupModalSelector from "@/components/StoreGroupModalSelector";
import LoadingPage from "@/pages/Loading";
import { changeBrandProfile, getUserBrands, linkBrands, unlinkBrand, updateBrandScope } from "@/services/web-user.service";
import { WebUser } from "@/types";
import { Button, Col, Input, Pagination, Row, Typography, message } from "antd";
import { useEffect, useMemo, useState } from "react";
import BrandItem from "./components/BrandItem";
import { ConfirmProfileModal } from "./components/ConfirmProfileModal";
import { ConfirmUnlinkModal } from "./components/ConfirmUnlinkModal";
import { BrandTabContainer, BrandTabItemContainer } from "./styles";
import BrandPickerModal from "@/components/BrandPickerModal";
import { useAudit } from "@/hooks";
import { map } from "lodash";

type Props = {
  user: WebUser;
};

export type BrandProps = {
  id: string;
  name: string;
  logoUrl: string;
  segment: string;
  isInactive: boolean;
  profile: string;
  scope: any
}

export function BrandTab({ user }: Props) {
  const [filters, setFilters] = useState({
    text: '',
  });

  const audit = useAudit({
    resourceName: 'web-user-brand-tab',
  });

  const [meta, setMeta] = useState({
    totalCount: 0,
    perPage: 10,
    currentPage: 1,
    pageCount: 0,
  });

  const [page, setPage] = useState(1);
  const [brands, setBrands] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [unlinkModal, setUnlinkModal] = useState({
    isOpen: false,
    brand: null as BrandProps | null,
  });
  const [profileModal, setprofileModal] = useState({
    isOpen: false,
    brand: null as BrandProps | null,
    newProfile: '',
    oldProfile: ''
  });

  const [storeGroupModal, setStoreGroupModal] = useState({
    isOpen: false,
    brandId: '',
    brandName: '',
    scopeType: 'all',
    scopePks: []
  });

  const [brandModal, setBrandModal] = useState(false);

  const loadBrands = async () => {
    setLoading(true);
    const { items = [] as BrandProps[], _meta = {} } = await getUserBrands({
      userId: user.id, filters, meta: {
        ...meta,
        currentPage: page,
      },
    });
    setMeta((prev) => ({ ...prev, totalCount: _meta.totalCount, pageCount: _meta.pageCount }));
    setBrands(items);
    setLoading(false);
  }

  useEffect(() => {
    loadBrands();
  }, [filters, page]);

  const connectedBrandIds = useMemo(() => map(user.brands, 'id'), [user.brands]);

  const handleUnlink = async (brandId: string) => {
    audit.onUpdate({ target: 'unlink-brand', entityId: brandId });
    const { status } = await unlinkBrand(user.id, brandId);
    if (status) {
      message.success('Brand unlinked successfully');
      loadBrands();
    } else {
      message.error('Failed to unlink brand');
    }
  }

  const handleChangeBrandProfile = async (brandId: string, profile: string) => {
    audit.onUpdate({ target: 'change-brand-profile', entityId: brandId, data: { profile } });
    setprofileModal({
      isOpen: false,
      brand: null,
      newProfile: '',
      oldProfile: ''
    });
    const { status } = await changeBrandProfile({ userId: user.id, brandId, profileType: profile });
    if (status) {
      message.success('Brand Profile changed successfully');
      loadBrands();
    } else {
      message.error('Failed to change brand profile');
    }
  }

  const handleUpdateBrandScope = async (brandId: string, scope: string, pks: string[]) => {
    audit.onUpdate({ target: 'update-brand-scope', entityId: brandId, data: { scope } });
    setStoreGroupModal(
      {
        isOpen: false,
        brandId: '',
        brandName: '',
        scopeType: 'all',
        scopePks: []
      }
    );
    const { status } = await updateBrandScope({ userId: user.id, brandId, scope, pks });
    if (status) {
      message.success('Brand visibility updated successfully');
      loadBrands();
    } else {
      message.error('Failed to update brand visibility');
    }
  }

  const handleBrandLink = async (brandIds: string[]) => {
    audit.onCreate({ target: 'link-brand', data: { brandIds } });
    const newSelected = brandIds.filter((id) => !connectedBrandIds.includes(id));
    setBrandModal(false);
    const { status } = await linkBrands(user.id, newSelected, user.defaultProfile || 'Viewer');
    if (status) {
      message.success('Brand linked successfully');
      loadBrands();
    } else {
      message.error('Failed to link brand');
    }
  }

  return (
    <BrandTabContainer>
      <Row justify="end" style={{ marginBottom: 16 }}>
        <Button disabled={user?.isDeleted} onClick={() => setBrandModal(true)} type="primary">
          Link Brand
        </Button>
      </Row>
      <Row>
        <Col style={{ width: '100%' }}>
          <Input.Search
            placeholder="Brand Name"
            allowClear
            onSearch={(value) => {
              setFilters((prev) => ({
                ...prev,
                text: value,
              }));
              setPage(1);
            }}
            style={{ width: '100%' }}
          />
        </Col>
      </Row>
      {loading ? <LoadingPage /> : (
        <BrandTabItemContainer>
          {brands.map((brand) => (
            <BrandItem
              key={brand.id}
              brand={brand}
              onProfileChange={({ newProfile, oldProfile }: any) => {
                setprofileModal({
                  isOpen: true,
                  brand: brand,
                  newProfile,
                  oldProfile
                });
              }}
              onUnlinkClick={() => {
                setUnlinkModal({
                  isOpen: true,
                  brand,
                });
              }}
              onStoreGroupClick={() => {
                setStoreGroupModal({
                  isOpen: true,
                  brandId: brand.id,
                  brandName: brand.name,
                  scopeType: brand?.scope?.type || 'all',
                  scopePks: brand?.scope?.pks || []
                });
              }}
            />
          ))}
          {brands.length === 0 && (
            <div style={{ textAlign: 'center', width: '100%' }}>
              <Typography.Text type="secondary">No brands found</Typography.Text>
            </div>
          )}
        </BrandTabItemContainer>
      )}

      {brands.length > 0 && (
        <Pagination
          total={meta.totalCount}
          pageSize={meta.perPage}
          style={{ textAlign: 'right', marginTop: 16 }}
          current={page}
          onChange={(page) => {
            setPage(page);
          }}
        />
      )
      }
      <ConfirmProfileModal
        isOpen={profileModal.isOpen}
        onClose={() => setprofileModal({
          isOpen: false,
          brand: null,
          newProfile: '',
          oldProfile: ''
        })}
        onConfirm={() => {
          handleChangeBrandProfile(profileModal.brand?.id || '', profileModal.newProfile);

        }}
        brand={profileModal.brand}
        oldProfile={profileModal.oldProfile}
        newProfile={profileModal.newProfile}
        userName={user.fullName}
      />
      <ConfirmUnlinkModal
        isOpen={unlinkModal.isOpen}
        onClose={() => setUnlinkModal({
          isOpen: false,
          brand: null,
        })}
        onConfirm={() => {
          handleUnlink(unlinkModal.brand?.id || '');
          setUnlinkModal({
            isOpen: false,
            brand: null,
          });
        }}
        brand={unlinkModal.brand}
        user={user}
      />
      <StoreGroupModalSelector
        brandId={storeGroupModal.brandId}
        brandName={storeGroupModal.brandName}
        isOpen={storeGroupModal.isOpen}
        onConfirm={({ selectedIds, scope }) => {
          handleUpdateBrandScope(storeGroupModal.brandId, scope, selectedIds);
        }}
        type={storeGroupModal.scopeType}
        selected={storeGroupModal.scopePks}
        onClose={() => setStoreGroupModal(
          {
            isOpen: false,
            brandId: '',
            brandName: '',
            scopeType: 'all',
            scopePks: []
          }
        )} />
      <BrandPickerModal
        open={brandModal}
        onClose={() => setBrandModal(false)}
        selected={connectedBrandIds}
        multiple
        onSelect={handleBrandLink}
        hideInactive={false}
        removeSelected={true}
      />

    </BrandTabContainer>
  );
}