import Layout from '@/components/Layout';
import PageHeader from '@/components/PageHeader';
import { Avatar, Button, Input, Space, Table, Tag } from 'antd';
import { useAudit, useTable } from '@/hooks';
import { TableContainer } from '@/styles';
import { useEffect, useState } from 'react';
import { ConnectorInfo } from './styles';
import CreateButton from '../CreateButton';

import { ButtonLink } from '@/components/ButtonLink';

type TableData = {
  id: string;
  name: string;
  key: string;
  logoUrl: string;
  isActive: boolean;
  createdAt: number;
  updatedAt: number;
};

const Page = () => {
  const [status, setStatus] = useState('approved');
  const [filter, setFilter] = useState('');
  const [resendLoading, setResendLoading] = useState(false);
  const { tableProps, refresh } = useTable<TableData>({
    service: 'integration',
    path: `admin/connector/list`,
    params: {
      status,
      filters: {
        text: filter,
      },
    },
    defaultSort: {
      field: 'lastActivityAt',
      order: 'descend',
    },
  });

  const audit = useAudit({
    resourceName: 'integration-list',
  });

  useEffect(() => {
    audit.onAccess();
  }, []);

  return (
    <Layout>
      <PageHeader noBackButton title="Connectors List">
        <Space>
          <Input.Search
            placeholder="Search by list name"
            allowClear
            onSearch={(value) => {
              setFilter(value);
            }}
            style={{ width: 260 }}
          />
          <CreateButton />
        </Space>
      </PageHeader>

      <br />
      {/* <Tabs onChange={(activeKey: string) => setStatus(activeKey)}>
        <Tabs.TabPane tab="Approved" key="approved" />
        <Tabs.TabPane tab="Pending" key="pending" />
        <Tabs.TabPane tab="Rejected" key="rejected" />
      </Tabs> */}

      <TableContainer>
        <Table
          {...tableProps}
          rowKey={'id'}
          // onRow={(record, index) => {
          //   return {
          //     onClick: (event) => {
          //       navigate(`/dev/queue/${record.name}`);
          //     }, // click row
          //   };
          // }}
        >
          <Table.Column
            title="Connector"
            dataIndex="name"
            key="name"
            sorter
            render={(text, record: TableData) => {
              return (
                <ConnectorInfo>
                  <Avatar size={50} src={record.logoUrl} shape="square" />
                  <div className="details">
                    <div className="name">{record.name}</div>
                    <div className="key">{record.key}</div>
                  </div>
                </ConnectorInfo>
              );
            }}
          />

          <Table.Column
            title="Status"
            dataIndex="isActive"
            key="isActive"
            align="center"
            filterMultiple={false}
            filters={[
              {
                text: 'Active',
                value: true,
              },
              {
                text: 'Inactive',
                value: false,
              },
            ]}
            sorter
            render={(text: any, record: TableData, index: number) => {
              return record.isActive ? (
                <Tag color="green">Active</Tag>
              ) : (
                <Tag color="grey">Inactive</Tag>
              );
            }}
          />

          <Table.Column
            title=""
            dataIndex="actions"
            key="actions"
            align="right"
            render={(text: any, record: TableData, index: number) => {
              return (
                <Space>
                  <ButtonLink
                    to={`/integration/connector/${record.id}/edit`}
                    size="small"
                  >
                    Edit
                  </ButtonLink>
                </Space>
              );
            }}
          />
        </Table>
      </TableContainer>
    </Layout>
  );
};

export default Page;
