import Layout from '@/components/Layout';
import PageHeader from '@/components/PageHeader';
import { useAudit, useTable } from '@/hooks';
import {
  Button,
  Space,
  Table,
  Tag,
  notification,
  Input,
  Avatar,
  Typography,
  Dropdown,
  Row,
  Col,
  Divider,
  Drawer,
  Popconfirm,
  Popover,
  MenuProps,
} from 'antd';
import { useEffect, useState, Key } from 'react';
import {
  changeUserPoeStatus,
  deleteAdminUser,
} from '@/services/admin-user.service';
import { requestPremiumReviewPOE } from '@/services/mobile-user.service';
import { get } from 'lodash';
import { useSession } from '@/store';
import { BiUser } from 'react-icons/bi';
import { PiDotsThreeOutline } from 'react-icons/pi';
import { Container } from './styles';
import { GrTooltip, GrView } from 'react-icons/gr';
import { GoVerified } from 'react-icons/go';
import { PoeAnalysisStatus } from '@/types';
import { Case, Switch } from 'react-if';
import { SiStackedit } from 'react-icons/si';
import DrawerPoeView from '@/pages/MobileUser/List/DrawerPoeView';
import DrawerQuickView from '@/pages/MobileUser/List/DrawerQuickView';
import UserPoeStatistics from './UserPoeStatistics';
import ApprovalButtons from './ApprovalButtons';
import { BsUpcScan } from 'react-icons/bs';
import { RxCross2, RxCheck } from 'react-icons/rx';

import { ButtonLink } from '@/components/ButtonLink';

const UsersPoeList = () => {
  // @ts-ignore
  const ContentTooltip = ({ poeRecord }) => {
    const data = get(poeRecord, 'data', null);
    if (data === null) {
      return <strong>Description not found...</strong>;
    }

    return (
      <div style={{ maxWidth: '400px' }}>
        <p>
          <strong>Description:</strong> {data.description}
        </p>
        <p>
          <strong>Document Type: </strong> {data.documenType}
        </p>
        <p>
          {get(data, 'isEmployeeDetected', false) ? (
            <span style={{ color: 'green' }}>
              <RxCheck /> Employee Detected
            </span>
          ) : (
            <span style={{ color: 'red' }}>
              <RxCross2 /> Employee Not Detected
            </span>
          )}
        </p>
        <p>
          {get(data, 'isEmployerNameDetected', false) ? (
            <span style={{ color: 'green' }}>
              <RxCheck /> Employer Name Detected
            </span>
          ) : (
            <span style={{ color: 'red' }}>
              <RxCross2 /> Employer Name Not Detected
            </span>
          )}
        </p>
        <p>
          {get(data, 'isEmployerAddressDetected', false) ? (
            <span style={{ color: 'green' }}>
              <RxCheck /> Employer Address Detected
            </span>
          ) : (
            <span style={{ color: 'red' }}>
              <RxCross2 /> Employer Address Not Detected
            </span>
          )}
        </p>
      </div>
    );
  };

  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);

  useEffect(() => {
    setSelectedRowKeys([]);
  }, []);

  const appContainer = () => {
    return document.getElementById('app-container') as HTMLElement;
  };

  const session = useSession();
  const [filters, setFilters] = useState({
    text: '',
    storeId: '',
    isDeleted: 'N',
  });
  const [userPreviewOpen, setUserPreviewOpen] = useState(false);
  const [userPoePreviewOpen, setUserPoePreviewOpen] = useState(false);

  const { tableProps, refresh } = useTable<PoeAnalysisStatus>({
    service: 'user/read',
    path: `admin/mobile-user/poe-status-user-list`,
    params: {
      filters,
    },
    perPage: 10,
  });

  const [previewUserId, setPreviewUserId] = useState<string | null>(null);

  const audit = useAudit({
    resourceName: 'mobile-user',
  });

  useEffect(() => {
    audit.onAccess();
  }, []);

  const onDeleteUser = async (id: string | undefined) => {
    if (!id) return;

    const { status } = await deleteAdminUser(id);

    if (status) {
      notification.success({
        message: 'User deleted successfully',
        placement: 'bottomRight',
        duration: 2,
      });

      refresh();

      return;
    }

    notification.error({
      message: 'Failed to delete user',
      placement: 'bottomRight',
      duration: 2,
    });
  };

  const onChangeUserPoeStatus = async (
    id: string,
    type: string,
    reason: string = '',
    rejectTag: string,
  ) => {
    setUserPoePreviewOpen(false);
    setPreviewUserId(null);
    const { result } = await changeUserPoeStatus(id, type, reason, rejectTag);

    if (result?.status) {
      audit.onClick({ target: `${type}-user-poe` });
      refresh();

      notification.success({
        message: `User PoE request has been ${
          type === 'approve' ? 'approved' : 'rejected'
        }.`,
        placement: 'bottomRight',
        duration: 2,
      });

      refresh();

      return;
    }

    notification.error({
      message: `Failed to ${type} user PoE`,
      placement: 'bottomRight',
      duration: 2,
    });
  };

  const onViewUser = async (user: PoeAnalysisStatus) => {
    setPreviewUserId(user.id);
    setUserPreviewOpen(true);
  };

  const onViewUserPoe = async (user: PoeAnalysisStatus) => {
    setPreviewUserId(user.id);
    setUserPoePreviewOpen(true);
  };

  const getTableMenuItems = (
    user: PoeAnalysisStatus,
  ): NonNullable<MenuProps['items']> => {
    const items: NonNullable<MenuProps['items']> = [
      {
        key: `user-menu-preview-${user.id}`,
        label: 'Quick View',
        icon: <GrView size={20} />,
        onClick: () => {
          audit.onClick({
            target: 'quick-view',
            data: {
              userId: user.id,
            },
          });
          onViewUser(user);
        },
      },
    ];

    if (session.hasPermission('users.edit')) {
      items.push({
        key: `user-menu-approval-preview-${user.id}`,
        label: 'PoE View',
        icon: <GoVerified size={20} />,
        onClick: () => {
          audit.onClick({
            target: 'poe-view',
            data: {
              userId: user.id,
            },
          });
          onViewUserPoe(user);
        },
      });
    }

    return items;
  };

  const analyzePoeDocuments = async () => {
    const { result } = await requestPremiumReviewPOE();
    const { totalCompletions } = result;
    if (totalCompletions > 0) {
      notification.success({
        message: `${totalCompletions} POE Document(s) have been added to the queue.`,
      });
    } else {
      notification.warning({
        message: 'No POE Documents were added to the queue.',
      });
    }
    refresh();
  };

  return (
    <Layout noCard={false}>
      <PageHeader noBackButton title="POE Status for Mobile Users" />

      <UserPoeStatistics />
      <Container>
        <Space direction="vertical" size={16} style={{ width: '100%' }}>
          <Divider />
          <Row gutter={24}>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Input.Search
                placeholder="Search by User ID, Name, Email, Store Name or Referral Code"
                allowClear
                onSearch={(value) => {
                  setFilters((prev) => ({
                    ...prev,
                    text: value,
                  }));
                  audit.onClick({
                    target: 'search-table',
                  });
                }}
                style={{ width: '100%' }}
              />
            </Col>
            {/* <Col xs={24} sm={6} md={6} lg={6}>
            <Popconfirm
                title={`Do you confirm the Premium Review request for the POE Documents?`}
                description="After confirming, the POE Documents will go into a processing queue."
                onConfirm={analyzePoeDocuments}
                okText="Confirm"
                cancelText="Cancel"
              >
                  <Button
                    type={'primary'}
                    >
                    Analyze POE Documents &nbsp;&nbsp;
                    <BsUpcScan/>
                  </Button>
              </Popconfirm>
            </Col> */}
          </Row>
          <Table
            {...tableProps}
            rowKey={'id'}
            rowSelection={{
              selectedRowKeys,
              onChange: (keys) => {
                setSelectedRowKeys(keys);
              },
            }}
          >
            <Table.Column
              title="Name"
              dataIndex="fullName"
              key="fullName"
              sorter
              fixed="left"
              align="left"
              render={(text: any, record: PoeAnalysisStatus, index: number) => {
                return (
                  <Space className="user-row">
                    <Avatar
                      src={record.avatarUrl}
                      style={{ width: 48, height: 48 }}
                      icon={<BiUser />}
                      shape="circle"
                      size={48}
                    />

                    <Space direction="vertical" className="info">
                      <Typography.Title level={5}>
                        {record.fullName || (
                          <span style={{ color: '#999' }}>(name not set)</span>
                        )}
                      </Typography.Title>
                      <Typography.Text
                        copyable
                        style={{
                          fontSize: 13,
                          maxWidth: '210px',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {record.email}
                      </Typography.Text>
                      {record.phoneNumber && (
                        <Typography.Text
                          // ellipsis={{ tooltip: true }}
                          copyable
                          style={{
                            fontSize: 13,
                            maxWidth: '210px',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          {record.phoneNumber}
                        </Typography.Text>
                      )}
                    </Space>
                  </Space>
                );
              }}
            />

            <Table.Column
              title="Confirmed"
              dataIndex="isConfirmed"
              key="isConfirmed"
              align="center"
              sorter
              render={(text: any, record: PoeAnalysisStatus, index: number) => {
                return record.isConfirmed ? (
                  <Tag color="blue">Confirmed</Tag>
                ) : (
                  <Tag color="grey">Not Confirmed</Tag>
                );
              }}
            />
            <Table.Column
              title="Risk Level"
              dataIndex="riskLevel"
              key="riskLevel"
              align="center"
              sorter
              filters={[
                {
                  text: 'Low',
                  value: 'low',
                },
                {
                  text: 'Medium',
                  value: 'medium',
                },
                {
                  text: 'High',
                  value: 'high',
                },
              ]}
              render={(text: any, record: PoeAnalysisStatus, index: number) => {
                return (
                  <Switch>
                    <Case condition={text === 'low'}>
                      <Tag color="success">Low</Tag>
                    </Case>
                    <Case condition={text === 'medium'}>
                      <Tag color="orange">Medium</Tag>
                    </Case>
                    <Case condition={text === 'high'}>
                      <Tag color="red">High</Tag>
                    </Case>
                  </Switch>
                );
              }}
            />
            <Table.Column
              title="POE Smart Review"
              dataIndex="poeAnalysisStatus"
              key="poeAnalysisStatus"
              align="center"
              sorter
              filters={[
                {
                  text: 'Processed',
                  value: 'processed',
                },
                {
                  text: 'Pending',
                  value: 'pending',
                },
              ]}
              render={(text: any, record: PoeAnalysisStatus, index: number) => {
                return (
                  <Switch>
                    <Case condition={text === 'processed'}>
                      <Tag color="blue">Processed</Tag>
                    </Case>
                    <Case condition={text === 'pending'}>
                      <Tag color="orange">Pending</Tag>
                    </Case>
                  </Switch>
                );
              }}
            />
            <Table.Column
              title="PoE Analysis Score"
              align="center"
              sorter
              dataIndex="score"
              key="score"
              filters={[
                {
                  text: 'All',
                  value: 0,
                },
                {
                  text: 'Score ≥ 80',
                  value: 80,
                },
                {
                  text: 'Score ≥ 60',
                  value: 60,
                },
                {
                  text: 'Score ≥ 40',
                  value: 40,
                },
                {
                  text: 'Score ≥ 20',
                  value: 21,
                },
              ]}
              filterMultiple={false}
              render={(text: any, record: PoeAnalysisStatus, index: number) => {
                if (record.poeAnalysisStatus === 'pending') {
                  return <Tag color={'orange'}>Pending</Tag>;
                }
                const score = Number(record.score);
                let tagColor = '';
                if (score >= 80) {
                  tagColor = 'green';
                } else if (score >= 60) {
                  tagColor = 'blue';
                } else if (score >= 40) {
                  tagColor = 'yellow';
                } else if (score >= 20) {
                  tagColor = 'orange';
                } else {
                  tagColor = 'red';
                }
                return (
                  <div>
                    <Tag color={tagColor}>{score}</Tag>&nbsp;
                    <Popover
                      content={
                        <ContentTooltip poeRecord={record.lastPoeAnalysis} />
                      }
                    >
                      <GrTooltip />
                    </Popover>
                  </div>
                );
              }}
            />

            <Table.Column
              title="Mission Approval %"
              dataIndex="missionCompletionsApprovalScore"
              key="missionCompletionsApprovalScore"
              align="center"
              sorter
              render={(text: any, record: PoeAnalysisStatus, index: number) => {
                if (record.poeAnalysisStatus === 'pending') {
                  return <Tag color={'orange'}>Pending</Tag>;
                }
                const missionCompletionsApprovalScore = Number(
                  record.missionCompletionsApprovalScore,
                );
                let tagColor = '';
                if (missionCompletionsApprovalScore === -1) {
                  return <Tag>New User</Tag>;
                } else if (missionCompletionsApprovalScore >= 80) {
                  tagColor = 'green';
                } else if (missionCompletionsApprovalScore >= 60) {
                  tagColor = 'blue';
                } else if (missionCompletionsApprovalScore >= 40) {
                  tagColor = 'yellow';
                } else if (missionCompletionsApprovalScore >= 20) {
                  tagColor = 'orange';
                } else {
                  tagColor = 'red';
                }
                return (
                  <Tag color={tagColor}>{missionCompletionsApprovalScore}</Tag>
                );
              }}
            />
            <Table.Column
              title="Store"
              dataIndex="store.name"
              key="store.name"
              align="center"
              sorter
              render={(_, record: PoeAnalysisStatus) => {
                const store = get(record, 'store', null);

                if (!store || !store.name) return '-';

                return (
                  <Space className="store-row">
                    <Space direction="vertical" className="info">
                      <Typography.Text ellipsis strong copyable>
                        {store.name}
                      </Typography.Text>
                      <Typography.Text
                        ellipsis={{ tooltip: true }}
                        className="address"
                      >
                        {get(store, 'address')}
                      </Typography.Text>
                    </Space>
                  </Space>
                );
              }}
            />

            <Table.Column
              title="Actions"
              dataIndex="actions"
              key="actions"
              align="right"
              fixed="right"
              render={(text: any, record: PoeAnalysisStatus, index: number) => {
                return (
                  <Space>
                    <ButtonLink
                      to={`/mobile-user/${record.id}/manage`}
                      icon={<SiStackedit size={20} />}
                      style={{ alignItems: 'center', display: 'flex' }}
                    >
                      Manage
                    </ButtonLink>
                    <Dropdown menu={{ items: getTableMenuItems(record) }}>
                      <Button>
                        <PiDotsThreeOutline size={20} />
                      </Button>
                    </Dropdown>
                  </Space>
                );
              }}
            />
          </Table>
        </Space>
      </Container>
      <Drawer
        rootClassName={'table-footer-drawer'}
        mask={false}
        closable={false}
        open={selectedRowKeys.length > 0}
        placement={'bottom'}
        getContainer={appContainer}
        height={74}
      >
        <div>
          <Typography.Text strong className="selected-count">
            {selectedRowKeys.length} selected
          </Typography.Text>
          <Button
            type={'link'}
            onClick={() => {
              setSelectedRowKeys([]);
            }}
          >
            Clear Selection
          </Button>
        </div>
        <ApprovalButtons
          userIds={selectedRowKeys as string[]}
          onCompleted={() => {
            refresh();
            setSelectedRowKeys([]);
          }}
        />
      </Drawer>

      <DrawerQuickView
        visible={userPreviewOpen}
        userId={previewUserId}
        onClose={() => {
          setUserPreviewOpen(false);
          setPreviewUserId(null);
        }}
        onDeleteUser={onDeleteUser}
      />
      <DrawerPoeView
        visible={userPoePreviewOpen}
        userId={previewUserId}
        onClose={() => {
          setUserPoePreviewOpen(false);
          setPreviewUserId(null);
        }}
        onChangeUserPoeStatus={onChangeUserPoeStatus}
      />
    </Layout>
  );
};

export default UsersPoeList;
