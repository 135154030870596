import { handleError, httpGet, httpPost, httpPut } from './util/axios';
import { type CompanyReadType } from '@/pages/Company/types';

export const createCompany = async (data: any) => {
  try {
    return httpPost('brand/write', `admin/company`, data);
  } catch (error: any) {
    handleError(error);
  }
};

export const updateCompany = async (
  id: string | undefined,
  data: CompanyReadType,
) => {
  try {
    return (await httpPut(
      'brand/write',
      `admin/company/${id}`,
      data,
    )) as CompanyReadType;
  } catch (error: any) {
    handleError(error);
  }
};

export const getCompanyDetails = async (id: string) => {
  try {
    return (await httpGet('brand/read', `admin/company/${id}/details`)) as {
      company: CompanyReadType;
    };
  } catch (error: any) {
    handleError(error);
  }
};

/** Used for getting the ID, name, and email of all members of a company when
 * changing a company's owner */
export const getAllCompanyMembers = async (companyId: string) => {
  try {
    return (await httpGet(
      'brand/read',
      `admin/company/${companyId}/all-members`,
    )) as { id: string; fullName: string; email: string; role: string }[];
  } catch (error: any) {
    handleError(error);
  }
};

/** Not used for listing the companies, but specifically for the 'brand create'
 * form */
export const getNonRetailerCompanies = async () => {
  try {
    return (await httpGet(
      'brand/read',
      'admin/company/get-all-non-retailer',
    )) as { id: string; label?: string }[];
  } catch (error: any) {
    handleError(error);
  }
};
