import { useAudit, useTable } from '@/hooks';
import { TabContainer } from './styles';
import { TableContainer } from '@/styles';
import {
  Col,
  Button,
  Card,
  Row,
  Table,
  Typography,
  Modal,
  Form,
  InputNumber,
  Input,
  Radio,
  Alert,
  notification,
  Badge,
  Popover,
} from 'antd';
import { formatISODate, formatMoney } from '@/utils';
import { FaMoneyBillTransfer } from 'react-icons/fa6';
import { Case, Default, Switch, When } from 'react-if';
import { InfoCircleFilled } from '@ant-design/icons';
import { useEffect, useMemo, useState } from 'react';
import { Brand } from '@/types';
import { get, startCase } from 'lodash';
import { BiMoneyWithdraw } from 'react-icons/bi';
import { ImDownload2 } from 'react-icons/im';
import { MdEditSquare } from 'react-icons/md';
import { FaRocket } from 'react-icons/fa';
import { HiReceiptRefund } from 'react-icons/hi2';
import { EditOutlined } from '@ant-design/icons';
import { saveBrandWalletAjustment } from '@/services/brand.service';

type TableData = {
  id: string;
  type: string;
  status: string;
  paymentMethod: string;
  annotations: string;
  brandId: string;
  amount: number;
  params: object;
  createdAt: string;
  updatedAt: string;
};

type Params = {
  brand: Brand | any;
  onRefresh: () => void;
};

const WalletTab = ({ brand, onRefresh }: Params) => {
  const [form] = Form.useForm();
  const { tableProps, refresh } = useTable<TableData>({
    service: 'brand/read',
    path: `admin/wallet/transactions-list`,
    params: {
      brandId: brand.id,
    },
    defaultSort: {
      field: 'createdAt',
      order: 'descend',
    },
  });

  const [transactionForm, setTransationForm] = useState({
    open: false,
    loading: false,
    type: 'add', // add | remove
    amount: 0,
    annotations: '',
  });

  const audit = useAudit({
    resourceName: 'brand-wallet-transaction-tab',
  });

  useEffect(() => {
    audit.onAccess();
  }, []);

  const transactionFormValid = useMemo(() => {
    const amountValid = transactionForm.amount !== 0;
    const annotationsValid = transactionForm.annotations.length >= 10;

    return amountValid && annotationsValid;
  }, [transactionForm.amount, transactionForm.annotations]);

  const onSaveWalletAdjustment = async () => {
    if (!transactionFormValid) return;

    setTransationForm((prev) => ({ ...prev, loading: true }));

    const { status = false } = await saveBrandWalletAjustment({
      brandId: brand.id,
      operationType: transactionForm.type,
      amount: transactionForm.amount,
      annotations: transactionForm.annotations,
    });

    if (status) {
      refresh();
      onRefresh();

      notification.success({
        message: 'Processing Underway',
        description: `The initiated action is being processed. Due to the asynchronous nature of this operation, it may take some time for the processing to be completed. To see the changes, please refresh the page once some time has passed.`,
        placement: 'top',
        duration: 10,
      });
    } else {
      notification.error({
        message: 'Error',
        description: 'Something went wrong, please try again.',
        placement: 'top',
        duration: 10,
      });
    }

    setTransationForm((prev) => ({ ...prev, open: false, loading: false }));
  };

  return (
    <TabContainer>
      <Row gutter={16}>
        <Col span={12}>
          <Card bordered={false} className="card-wallet-totals">
            <>
              <div className="node-title">
                <i className="far fa-dollar-sign" />
                <span>Total Balance</span>
              </div>
              <div className="node-amount">
                <div className="main">{formatMoney(brand.wallet.balance)}</div>
                <div className="pending">
                  <span>Pending</span>
                  <strong>{formatMoney(brand.wallet.pending)}</strong>
                </div>
              </div>
              <div className="node-footer">
                <div className="actions">
                  <Button
                    icon={<EditOutlined />}
                    type="primary"
                    className="add-money"
                    size="small"
                    onClick={() => {
                      setTransationForm((prev) => ({
                        ...prev,
                        open: true,
                        type: 'add',
                        annotations: '',
                        amount: 0,
                        loading: false,
                      }));
                    }}
                  >
                    Edit Balance
                  </Button>
                </div>
              </div>
            </>
          </Card>
        </Col>
        <Col span={12}>
          <Card bordered={false} className="card-wallet-totals">
            <div className="node-title">
              <i className="fas fa-rocket" />
              <span>Deposited in live campaigns</span>
            </div>
            <div className="node-amount">
              {formatMoney(brand.campaignDeposited.total)}
            </div>
            <div className="node-footer">
              <div className="kpis">
                <div>
                  <span>Earned</span>
                  <strong>{formatMoney(brand.campaignDeposited.earned)}</strong>
                </div>

                <div>
                  <span>Left</span>
                  <strong>{formatMoney(brand.campaignDeposited.left)}</strong>
                </div>
              </div>
            </div>
          </Card>
        </Col>
      </Row>
      <br />
      <TableContainer>
        <Table
          {...tableProps}
          rowKey={'id'}
          title={() => (
            <div className="table-header">
              <div className="title">Transactions</div>
            </div>
          )}
        >
          <Table.Column
            title="Event"
            dataIndex="type"
            sorter
            filters={[
              {
                text: 'Withdraw',
                value: 'withdraw',
              },
              {
                text: 'Campaign Budget',
                value: 'payment',
              },
              {
                text: 'Transfer Sent',
                value: 'transfer_sent',
              },
              {
                text: 'Transfer Received',
                value: 'transfer_received',
              },
              {
                text: 'Adjustment',
                value: 'adjustment',
              },
              {
                text: 'Add Money',
                value: 'deposit',
              },
              {
                text: 'Returned to Balance',
                value: 'refund',
              },
            ]}
            render={(_, item: TableData) => {
              return (
                <div className="transaction-column-type">
                  <Switch>
                    <Case condition={item.type === 'adjustment'}>
                      <div className="icon">
                        <MdEditSquare />
                      </div>
                      <div className="info">
                        <span>Remove Money</span>
                        <span>
                          By: {get(item, 'params.addedBy', 'Undefined')}
                        </span>
                      </div>
                    </Case>
                    <Case condition={item.type === 'deposit'}>
                      <div className="icon">
                        <ImDownload2 />
                      </div>
                      <div className="info">
                        <span>Add Money</span>
                        <Switch>
                          <Case
                            condition={item.paymentMethod === 'admin-deposit'}
                          >
                            <span>
                              By: {get(item, 'params.addedBy', 'Undefined')}
                            </span>
                          </Case>
                          <Default>
                            <span>Balance</span>
                          </Default>
                        </Switch>
                      </div>
                    </Case>
                    <Case condition={item.type === 'withdraw'}>
                      <div className="icon">
                        <BiMoneyWithdraw />
                      </div>

                      <div className="info">
                        <span>Withdraw</span>
                        <span>
                          <Switch>
                            <Case
                              condition={item.paymentMethod === 'wire-transfer'}
                            >
                              {get(item, 'params.bankInfo.accountName', '-')}
                            </Case>
                            <Case condition={item.paymentMethod === 'transfer'}>
                              Transfer
                            </Case>
                          </Switch>
                        </span>
                      </div>
                    </Case>
                    <Case condition={item.type === 'payment'}>
                      <div className="icon">
                        <FaRocket />
                      </div>
                      <div className="info">
                        <span>Campaign Budget</span>
                        <span>{get(item, 'params.bundleName', '-')}</span>
                      </div>
                    </Case>
                    <Case condition={item.type === 'refund'}>
                      <div className="icon">
                        <HiReceiptRefund />
                      </div>
                      <div className="info">
                        <span>Returned to Balance</span>
                        <span>{get(item, 'params.bundleName', '-')}</span>
                      </div>
                    </Case>
                    <Case condition={item.type === 'transfer_sent'}>
                      <div className="icon">
                        <FaMoneyBillTransfer />
                      </div>
                      <div className="info">
                        <span>Transfer Sent</span>
                        <span>{get(item, 'params.toBrand', '-')}</span>
                      </div>
                    </Case>
                    <Case condition={item.type === 'transfer_received'}>
                      <div className="icon">
                        <FaMoneyBillTransfer />
                      </div>

                      <div className="info">
                        <span>Transfer received</span>
                        <span>Balance</span>
                      </div>
                    </Case>
                    <Default>{item.type}</Default>
                  </Switch>
                  {item.annotations && (
                    <Popover content={item.annotations}>
                      <div className="icon-info">
                        <InfoCircleFilled />
                      </div>
                    </Popover>
                  )}
                </div>
              );
            }}
          />
          <Table.Column
            title="Status"
            dataIndex="status"
            sorter
            align="center"
            render={(_, record: TableData) => {
              return (
                <div className="transaction-column-status">
                  <Switch>
                    <Case condition={record.status === 'pending'}>
                      <Badge status="warning" text="Pending" />
                    </Case>
                    <Case condition={record.status === 'approved'}>
                      <Badge status="success" text="Approved" />
                    </Case>
                    <Case condition={record.status === 'done'}>
                      <Badge status="success" text="Done" />
                    </Case>
                    <Case condition={record.status === 'rejected'}>
                      <Badge status="error" text="Rejected" />
                    </Case>
                    <Case condition={record.status === 'failed'}>
                      <Badge status="error" text="Failed" />
                    </Case>
                    <Default>
                      <Badge status="default" text={record.status} />
                    </Default>
                  </Switch>
                </div>
              );
            }}
          />
          <Table.Column
            title="Date"
            dataIndex="createdAt"
            sorter
            defaultSortOrder={'descend'}
            align="center"
            render={(text) => {
              return (
                <div className="transaction-column-date">
                  <strong>{formatISODate(text, 'DD')}</strong>
                  <small>{formatISODate(text, 't')}</small>
                </div>
              );
            }}
          />
          <Table.Column
            title="Method"
            dataIndex="paymentMethod"
            sorter
            align="center"
            render={(_, record: TableData) => {
              return (
                <div className="transaction-column-method">
                  <Switch>
                    <Case condition={record.paymentMethod === 'credit-card'}>
                      <span>
                        <strong>
                          {startCase(get(record, 'params.card.brand'))}
                        </strong>{' '}
                        <span>****{get(record, 'params.card.last4')}</span>
                      </span>
                    </Case>
                    <Case condition={record.paymentMethod === 'paypal'}>
                      <span>
                        <strong>PayPal</strong>
                      </span>
                    </Case>
                    <Case condition={record.paymentMethod === 'check'}>
                      <span>
                        <strong>Check</strong>
                      </span>
                    </Case>
                    <Case condition={record.paymentMethod === 'balance'}>
                      <span>
                        <strong>Balance</strong>
                      </span>
                    </Case>
                    <Case condition={record.paymentMethod === 'wire-transfer'}>
                      <span>
                        <strong>ACH/Wire Transfer</strong>
                      </span>
                    </Case>
                    <Case condition={record.paymentMethod === 'admin-deposit'}>
                      <div className="transaction-column-date">
                        <strong>Adjustment</strong>
                        <small>CRM Panel</small>
                      </div>
                    </Case>
                    <Case
                      condition={
                        record.paymentMethod === 'transfer' &&
                        record.type === 'transfer_sent'
                      }
                    >
                      <span>
                        <strong>Balance</strong>
                      </span>
                    </Case>
                    <Case
                      condition={
                        record.paymentMethod === 'transfer' &&
                        record.type === 'transfer_received'
                      }
                    >
                      <span>
                        <strong>{`Send by: ${get(
                          record,
                          'params.fromBrand',
                        )}`}</strong>
                      </span>
                    </Case>
                    <Default>
                      <span>
                        <strong>-</strong>
                      </span>
                    </Default>
                  </Switch>
                </div>
              );
            }}
          />

          <Table.Column
            title="Amount"
            dataIndex="amount"
            key="amount"
            align="right"
            fixed="right"
            sorter
            render={(text, record: TableData) => {
              const amount = formatMoney(text, 'USD');
              return (
                <Typography.Text
                  strong
                  type={record.amount >= 0 ? 'success' : 'danger'}
                >
                  {amount}
                </Typography.Text>
              );
            }}
          />
        </Table>
      </TableContainer>

      <Modal
        title="Edit Balance"
        open={transactionForm.open}
        centered
        okButtonProps={{
          disabled: !transactionFormValid,
          loading: transactionForm.loading,
        }}
        afterOpenChange={(open) => {
          if (!open) {
            form.resetFields();
          }
        }}
        okText="Save Adjustment"
        onOk={onSaveWalletAdjustment}
        onCancel={() => setTransationForm((prev) => ({ ...prev, open: false }))}
      >
        <br />

        <Form
          layout="horizontal"
          autoComplete="off"
          form={form}
          labelCol={{ span: 6 }}
        >
          <Form.Item
            name="operationType"
            label="Operation Type"
            rules={[{ required: true }]}
          >
            <Radio.Group
              buttonStyle="solid"
              defaultValue="add"
              onChange={(e) => {
                setTransationForm((prev) => ({
                  ...prev,
                  type: e.target.value,
                }));
              }}
            >
              <Radio.Button value="add">Increase Budget</Radio.Button>
              <Radio.Button value="remove">Decrease Budget</Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Form.Item name="amount" label="Amount" rules={[{ required: true }]}>
            <InputNumber
              value={transactionForm.amount}
              min={1}
              onChange={(value: number | null) =>
                setTransationForm((prev) => ({
                  ...prev,
                  amount: value || 0,
                }))
              }
              style={{ width: '100%' }}
            />
          </Form.Item>

          <Form.Item
            name="annotations"
            label="Reason"
            help="This comment will be displayed to the end user."
            rules={[
              { required: true },
              {
                min: 10,
                message: 'Please enter at least 10 characters.',
              },
            ]}
          >
            <Input.TextArea
              minLength={10}
              showCount
              value={transactionForm.annotations}
              onChange={({ target }) =>
                setTransationForm((prev) => ({
                  ...prev,
                  annotations: target?.value,
                }))
              }
              rows={4}
            />
          </Form.Item>
        </Form>

        <When condition={transactionFormValid}>
          <Alert
            message={`By continuing you will be ${
              transactionForm.type === 'add' ? 'increase' : 'decrease'
            } ${formatMoney(transactionForm.amount)} to brand ${
              brand.name
            }'s wallet balance.`}
            type="warning"
            showIcon
          />
        </When>
      </Modal>
    </TabContainer>
  );
};

export default WalletTab;
