export const rejectReasons = {
  duplicated: {
    menu: 'Duplicate receipt',
    title: 'The receipt is a duplicate',
    suggest: 'This receipt was submitted before!',
  },
  notReadable: {
    menu: 'Receipt not readable',
    title: 'The receipt is not entirely readable',
    suggest: 'Couldn\'t read full receipt details"',
  },
  wrongStore: {
    menu: 'Receipt from wrong store',
    title: 'The receipt is not from your store',
    suggest: 'Receipt from non-eligible store, sorry!',
  },
  invalidDate: {
    menu: 'Invalid date',
    title: 'The receipt is outside the date range of the contest',
    suggest: 'Submit receipts only from contest start date',
  },
  ineligibleProduct: {
    menu: 'Ineligible product',
    title: 'The product is ineligible ',
    suggest: 'Sadly, this product is not part of that contest!',
  },
  productNotFound: {
    menu: 'Product not found',
    title: 'The product was not found on the receipt',
    suggest: 'Oh! We could not find the item on the receipt.',
  },
  other: {
    menu: 'Other',
    title: 'Other',
    suggest: '',
  },
};
