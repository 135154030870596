import Layout from '@/components/Layout';
import PageHeader from '@/components/PageHeader';

import { CompanyPageHeader } from '../Migration/styles';
import { Avatar, Badge, Button, Typography, notification } from 'antd';
import { useEffect, useState } from 'react';

import { useAudit } from '@/hooks';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { UserOutlined } from '@ant-design/icons';
import { getCompanyDetails } from '@/services/company.service';
import { CompanyReadType, CompanyTypeEnum } from '../types';
import AssociatedDataCompanyTab from '../Components/Tab';

const CompanyDetail = () => {
  const navigate = useNavigate();
  const [companyDetailedRecord, setCompanyDetailedRecord] =
    useState<CompanyReadType>({
      id: '',
      name: '',
      type: CompanyTypeEnum.TEAM,
      alias: '',
    });

  const { id } = useParams<{ id: string }>();
  const [currentTab, setCurrentTab] = useState('tab-brands');

  const audit = useAudit({
    resourceName: 'company-detail',
  });

  useEffect(() => {
    audit.onAccess();
    loadDetails();
  }, []);

  const loadDetails = async () => {
    if (!id) return;

    const result = await getCompanyDetails(id);

    if (!result) {
      notification.error({
        message: 'Error',
        description: 'Company not found on server',
      });
      navigate('/company');
      return;
    }
    setCompanyDetailedRecord(result.company);
  };

  const cancelDetail = () => {
    audit.onClick({ target: 'cancel-detail' });
    navigate('/company');
  };

  return (
    <Layout>
      <PageHeader title="Company Detail" />
      <CompanyPageHeader>
        <div className="main-info">
          <div className="avatar">
            <Badge.Ribbon
              color={companyDetailedRecord?.isDeleted ? 'red' : 'green'}
              text={
                companyDetailedRecord?.isDeleted
                  ? 'Deleted Company'
                  : 'Active Company'
              }
            >
              <Link to={`/brand/${companyDetailedRecord?.id}/manage`}>
                <Avatar
                  size={166}
                  src={
                    !companyDetailedRecord?.isDeleted
                      ? '' //companyDetailedRecord.managedBrand?.logoUrl
                      : 'null'
                  }
                  shape="square"
                  icon={<UserOutlined />}
                />
              </Link>
            </Badge.Ribbon>
          </div>
          <div className="info">
            <strong>COMPANY INFORMATIONS:</strong>
            <div className="sm-info-row">
              <div>
                <Typography.Text strong>IDENTIFIER: </Typography.Text>
                <Typography.Text copyable>
                  {companyDetailedRecord?.id}
                </Typography.Text>
              </div>
              <div>
                <Typography.Text strong>NAME: </Typography.Text>
                <Typography.Text>{companyDetailedRecord.name}</Typography.Text>
              </div>
              <div>
                <Typography.Text strong>ALIAS: </Typography.Text>
                <Typography.Text>{companyDetailedRecord.alias}</Typography.Text>
              </div>
              <div>
                <Typography.Text strong>TYPE: </Typography.Text>
                <Typography.Text>
                  {companyDetailedRecord.type === 'team'
                    ? 'EFB (Brand)'
                    : 'EFR (Retailer)'}
                </Typography.Text>
              </div>
            </div>
            <div className="sm-info-row">
              <div>
                <Typography.Text strong>CREATED AT: </Typography.Text>
                <Typography.Text>
                  {companyDetailedRecord?.createdAt}
                </Typography.Text>
              </div>
              <div>
                <Typography.Text strong>LAST ACTIVITY: </Typography.Text>
                <Typography.Text>
                  {companyDetailedRecord.updatedAt}
                </Typography.Text>
              </div>
            </div>
            <div className="sm-info-row"></div>
          </div>
        </div>
      </CompanyPageHeader>

      <AssociatedDataCompanyTab
        id={id}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
      />

      <div style={{ textAlign: 'right', marginTop: 32 }}>
        <Button type="primary" onClick={cancelDetail}>
          Back
        </Button>
      </div>
    </Layout>
  );
};

export default CompanyDetail;
