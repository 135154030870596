import { httpGet, httpPost } from './util/axios';

export const getCountries = async () => {
  return httpGet('admin-portal', 'country/all');
};

export const saveCountryPreference = async ({
  countryId,
  incomeLimitActive = false,
  incomeLimit = 0,
  currency = '',
  taxIncomeFileUrl = '',
}: any) => {
  return httpPost('admin-portal', 'country/save-preference', {
    countryId,
    incomeLimitActive,
    incomeLimit,
    currency,
    taxIncomeFileUrl,
  });
};
