import { Card, Table, Typography } from 'antd';

import { useTable } from '@/hooks';
import { CompanyReadType } from '@/pages/Company/types';
import { Link } from 'react-router-dom';

interface BrandCompanyTableProps {
  id: string | null | undefined;
}

const BrandCompanyTable = ({ id }: BrandCompanyTableProps) => {
  const { tableProps, refresh } = useTable<CompanyReadType>({
    service: 'brand/read',
    path: `admin/company/${id}/brands`,
    defaultSort: {
      field: 'name',
      order: 'ascend',
    },
    perPage: 10,
  });

  return (
    <Table {...tableProps} rowKey={'id'}>
      <Table.Column
        title="Logo"
        dataIndex="logo"
        key="logo"
        sorter
        fixed="left"
        render={(text: any, record: any, index: number) => {
          return (
            <Link
              style={{ display: 'block', width: 'min-content' }}
              to={`/brand/${record?.id}/manage`}
            >
              <Card
                hoverable
                style={{ width: 50, height: 50 }}
                cover={
                  <img
                    alt={record.name}
                    src={record.logoUrl}
                    style={{ objectFit: 'cover' }}
                  />
                }
              />
            </Link>
          );
        }}
      />
      <Table.Column
        title="Identifier"
        dataIndex="identifier"
        key="identifier"
        sorter
        fixed="left"
        render={(text: any, record: any, index: number) => {
          return (
            <Typography.Text ellipsis copyable>
              {record.id}
            </Typography.Text>
          );
        }}
      />
      <Table.Column
        title="Name"
        dataIndex="name"
        key="name"
        sorter
        fixed="left"
        render={(text: any, record: any, index: number) => {
          return <Typography.Text ellipsis>{record.name}</Typography.Text>;
        }}
      />

      <Table.Column
        title="Type"
        dataIndex="type"
        key="type"
        align="center"
        sorter
        render={(text: any, record: any, index: number) => {
          return (
            <Typography.Text ellipsis>
              {record.type === 'team' ? 'Brand' : 'Retailer'}
            </Typography.Text>
          );
        }}
      />
      <Table.Column
        title="Language"
        dataIndex="language"
        key="language"
        align="center"
        sorter
        render={(text: any, record: any, index: number) => {
          return (
            <Typography.Text>
              {record.language === 'en' ? 'English' : 'French'}
            </Typography.Text>
          );
        }}
      />
      <Table.Column
        title="Hidden"
        dataIndex="hidden"
        key="hidden"
        align="center"
        sorter
        render={(text: any, record: any, index: number) => {
          return (
            <Typography.Text>{record.hidden ? 'Yes' : 'No'}</Typography.Text>
          );
        }}
      />
      <Table.Column
        title="Notify All Users"
        dataIndex="notifyAllUsers"
        key="notifyAllUsers"
        align="center"
        sorter
        render={(text: any, record: any, index: number) => {
          return (
            <Typography.Text>
              {record.notifyAllUsers ? 'Yes' : 'No'}
            </Typography.Text>
          );
        }}
      />
    </Table>
  );
};

export default BrandCompanyTable;
