import { useAudit, useTable } from '@/hooks'
import { insertWalletAdjustment } from '@/services/mobile-user.service'
import { useSession } from '@/store'
import { TableContainer } from '@/styles'
import { formatISODate, formatMoney } from '@/utils'
import {
  CheckOutlined,
  ClockCircleOutlined,
  InfoCircleFilled,
  PlusOutlined,
} from '@ant-design/icons'
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  notification,
  Popover,
  Row,
  Space,
  Statistic,
  Table,
  Tag,
  Typography,
} from 'antd'
import { startCase } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { LuExternalLink } from 'react-icons/lu'
import { Case, Switch, When } from 'react-if'
import { Link } from 'react-router-dom'
import { WalletTabContainer } from './styles'

type TableData = {
  id: string;
  createdAt: string;
  type: string;
  mission?: {
    id: string;
    title: string;
    type: string;
  };
  relatedData: {
    cashOutEmail: string;
    venmoCode: string;
  };
  cashOutType: string;
  amount: number;
  currency: string;
  isPending: boolean;
  isPaid: boolean;
  annotations: string;
};

type Params = {
  userId: string;
  earnings: number;
  pendingEarnings: number;
  isHighRiskLevel: boolean;
  onRefresh: () => void;
};

const WalletTab = ({
  userId,
  earnings,
  pendingEarnings,
  isHighRiskLevel,
  onRefresh,
}: Params) => {
  const session = useSession();
  const [form] = Form.useForm();
  const { tableProps, refresh } = useTable<TableData>({
    service: 'user-wallet/read',
    serviceKind: 'service',
    path: `admin/user-transaction/list`,
    params: {
      userId,
    },
    defaultSort: {
      field: 'createdAt',
      order: 'descend',
    },
  });

  const [transactionForm, setTransationForm] = useState({
    open: false,
    loading: false,
    amount: 0,
    annotations: '',
  });

  const audit = useAudit({
    resourceName: 'mobile-user-wallet-tab',
  });

  useEffect(() => {
    audit.onAccess();
  }, []);

  const transactionFormValid = useMemo(() => {
    const amountValid = transactionForm.amount !== 0;
    const annotationsValid = transactionForm.annotations.length > 0;

    return amountValid && annotationsValid;
  }, [transactionForm.amount, transactionForm.annotations]);

  const onSaveTransaction = async () => {
    if (!transactionFormValid) return;

    const { amount, annotations } = transactionForm;

    setTransationForm((prev) => ({ ...prev, loading: true }));

    const { status } = await insertWalletAdjustment({
      userId,
      amount,
      annotations,
    });

    if (status) {
      notification.success({
        message: 'Transaction inserted successfully',
        placement: 'bottomRight',
        duration: 2,
      });
    } else {
      notification.error({
        message: 'Failed to insert transaction',
        placement: 'bottomRight',
        duration: 2,
      });
    }

    setTimeout(() => {
      refresh();

      onRefresh();

      setTransationForm((prev) => ({ ...prev, open: false, loading: true }));
    }, 500);
  };

  return (
    <WalletTabContainer>
      <Row gutter={16}>
        <Col span={6}>
          <Card bordered={false}>
            <Statistic
              title="Available Earnings"
              value={formatMoney(earnings, 'USD')}
              valueStyle={{ color: earnings > 0 ? '#3f8600' : '#868585' }}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card bordered={false}>
            <Statistic
              title="Pending Earnings"
              value={formatMoney(pendingEarnings, 'USD')}
              valueStyle={{ color: '#868585' }}
            />
          </Card>
        </Col>
      </Row>
      <br />
      <TableContainer>
        <Table
          {...tableProps}
          rowKey={'id'}
          title={() => (
            <div className="table-header">
              <div className="title">Transactions</div>
              <When
                condition={
                  session.hasPermission('users.edit') && !isHighRiskLevel
                }
              >
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={() => {
                    setTransationForm((prev) => ({
                      ...prev,
                      open: true,
                      amount: 0,
                      annotations: '',
                      loading: false,
                    }));
                  }}
                >
                  Insert Transaction
                </Button>
              </When>
            </div>
          )}
        >
          <Table.Column
            title="Date"
            dataIndex="createdAt"
            key="createdAt"
            sorter
            defaultSortOrder={'descend'}
            render={(text) => formatISODate(text)}
          />
          <Table.Column
            title="Type / Status"
            dataIndex="type"
            key="type"
            align="center"
            filters={[
              {
                text: 'Mission earn',
                value: 'earn',
                children: [
                  {
                    text: 'Pending',
                    value: 'earn-pending',
                  },
                  {
                    text: 'Approved',
                    value: 'earn-approved',
                  },
                ],
              },
              {
                text: 'Withdraw',
                value: 'withdrawal',
                children: [
                  {
                    text: 'Pending',
                    value: 'withdrawal-pending',
                  },
                  {
                    text: 'Paid',
                    value: 'withdrawal-paid',
                  },
                ],
              },
              {
                text: 'Adjustment',
                value: 'adjustment',
              },
            ]}
            filterMode="tree"
            sorter
            render={(text, record: TableData) => {
              return (
                <>
                  <Switch>
                    <Case condition={record.type === 'earn'}>
                      <Space direction="horizontal" size="small" align="center">
                        <Tag color="purple">Mission earn</Tag>
                        {record.isPending ? (
                          <Tag icon={<ClockCircleOutlined />} color="default">
                            pending
                          </Tag>
                        ) : (
                          <Tag icon={<CheckOutlined />} color="green">
                            approved
                          </Tag>
                        )}
                      </Space>
                    </Case>
                    <Case condition={record.type === 'withdrawal'}>
                      <Tag color="blue">Withdraw</Tag>

                      {!record.isPaid ? (
                        <Tag icon={<ClockCircleOutlined />} color="default">
                          pending
                        </Tag>
                      ) : (
                        <Tag icon={<CheckOutlined />} color="green">
                          paid
                        </Tag>
                      )}
                    </Case>
                    <Case condition={record.type === 'adjustment'}>
                      <Tag color="warning">Adjustment</Tag>
                    </Case>

                    <Case condition={record.type === 'earn_referral'}>
                      <Tag color="geekblue">Referral earn</Tag>
                    </Case>
                  </Switch>
                  {record.annotations && (
                    <Popover content={record.annotations}>
                      <div className="icon-info">
                        <InfoCircleFilled />
                      </div>
                    </Popover>
                  )}
                </>
              );
            }}
          />
          <Table.Column
            dataIndex="method"
            title={'Receive Method & Identifier'}
            align={'center'}
            filters={[
              { text: 'Interac', value: 'interac' },
              { text: 'PayPal', value: 'paypal' },
              { text: 'Venmo', value: 'venmo' },
            ]}
            sorter
            render={(_, record: TableData) => {
              if (!record.cashOutType) return <span></span>;
              return (
                <Space.Compact className="user-row" direction={'vertical'}>
                  <Typography.Text strong>
                    {startCase(record.cashOutType)}
                  </Typography.Text>

                  <Typography.Text code type={'secondary'} copyable>
                    {record.relatedData.cashOutEmail ??
                      record.relatedData.venmoCode}
                  </Typography.Text>
                </Space.Compact>
              );
            }}
          />
          <Table.Column
            title="Mission"
            dataIndex="missionId"
            key="missionId"
            align="center"
            render={(_, record: TableData) => {
              if (!record?.mission?.id) return <div>-</div>;

              return (
                <Link to={`mission/${record.mission.id}/overview`}>
                  {record?.mission?.title || '-'} <LuExternalLink />
                </Link>
              );
            }}
          />
          <Table.Column
            title="Amount"
            dataIndex="amount"
            key="amount"
            align="right"
            fixed="right"
            sorter
            render={(text, record: TableData) => {
              const amount = formatMoney(text, record.currency);
              return (
                <Switch>
                  <Case
                    condition={['earn', 'earn_referral'].includes(record.type)}
                  >
                    <Typography.Text strong type="success">
                      {amount}
                    </Typography.Text>
                  </Case>
                  <Case condition={record.type === 'withdrawal'}>
                    <Typography.Text strong type="danger">
                      -{amount}
                    </Typography.Text>
                  </Case>
                  <Case condition={record.type === 'adjustment'}>
                    <Typography.Text
                      strong
                      type={record.amount >= 0 ? 'success' : 'danger'}
                    >
                      {amount}
                    </Typography.Text>
                  </Case>
                </Switch>
              );
            }}
          />
        </Table>
      </TableContainer>

      <Modal
        title="Insert Adjustment"
        open={transactionForm.open}
        centered
        okButtonProps={{
          disabled: !transactionFormValid,
          loading: transactionForm.loading,
        }}
        afterOpenChange={(open) => {
          if (!open) {
            form.resetFields();
          }
        }}
        okText="Insert Adjustment"
        onOk={onSaveTransaction}
        onCancel={() => setTransationForm((prev) => ({ ...prev, open: false }))}
      >
        <Form layout="vertical" autoComplete="off" form={form}>
          <Form.Item name="amount" label="Amount" rules={[{ required: true }]}>
            <InputNumber
              value={transactionForm.amount}
              onChange={(value: number | null) =>
                setTransationForm((prev) => ({
                  ...prev,
                  amount: value || 0,
                }))
              }
              style={{ width: '100%' }}
            />
          </Form.Item>

          <Form.Item
            name="annotations"
            label="Annotations"
            rules={[{ required: true, min: 5 }]}
          >
            <Input.TextArea
              value={transactionForm.annotations}
              onChange={({ target }) =>
                setTransationForm((prev) => ({
                  ...prev,
                  annotations: target?.value,
                }))
              }
              rows={4}
            />
          </Form.Item>
        </Form>
      </Modal>
    </WalletTabContainer>
  );
};

export default WalletTab;
