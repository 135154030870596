import styled from 'styled-components';
import { Modal as CoreModal } from 'antd';

export const Modal = styled(CoreModal)``;

export const CompareWrapper = styled.div`
  .main-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 68px;
    margin-bottom: 16px;

    .ant-tabs-nav {
      margin-bottom: 0;
    }
  }

  .tab-date-label {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    > span {
      &:first-child {
        font-size: 16px;
      }

      &:last-child {
        font-size: 12px;
      }
    }
  }
`;
