import { FC, Key, useEffect, useState } from 'react';
import { Container } from './styles';
import xlsx from 'json-as-xlsx';
import {
  Avatar,
  Badge,
  Button,
  Divider,
  Drawer,
  Input,
  notification,
  Space,
  Table,
  Typography,
} from 'antd';
import { DownloadOutlined, WechatOutlined } from '@ant-design/icons';
import { useTable } from '@/hooks';
import { TableContainer } from '@/styles';
import { BiUser } from 'react-icons/bi';
import { formatISODate, formatMoney } from '@/utils';
import { startCase } from 'lodash';
import ApprovalButtons from './approval-buttons';
import { TableCommentButton } from '@/pages/MissionApproval/Consumer/styles';
import CommentModal from '@/pages/CashOutRequest/CommentModal';
import { getConsumerCashOutExportData } from '@/services/cash-out.service';

type ConsumerCashoutRequest = {
  id: string;
  consumerId: string;
  amount: 2;
  createdAt: string;
  currency: string;
  status: 'pending' | 'approved' | 'rejected';
  method: string;
  methodIdentifier: string;
  updatedAt: string;
  consumer: {
    id: string;
    fullName: string;
    email: string;
    phoneNumber: string;
    avatarUrl: string;
  };
  totalComments: number;
  userId: string;
};

const Component: FC = () => {
  const [commentDialog, setCommentDialog] = useState({
    open: false,
    walletId: '',
  });
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
  const [status, setStatus] = useState<'pending' | 'approved' | 'rejected'>(
    'pending',
  );
  const [textFilter, setTextFilter] = useState('');
  const { tableProps, tableParams, refresh } = useTable<ConsumerCashoutRequest>(
    {
      service: 'user-wallet/read',
      path: `admin/cash-out/consumer/list`,
      params: {
        status,
        filters: {
          text: textFilter,
        },
      },
      defaultSort: {
        field: 'createdAt',
        order: 'descend',
      },
    },
  );

  useEffect(() => {
    if (selectedRowKeys.length > 0) {
      setSelectedRowKeys([]);
    }
  }, [status]);

  const amountColumnRenderer = (_: any, record: ConsumerCashoutRequest) => {
    return (
      <Space className="user-row">
        <Typography.Text strong>
          {formatMoney(record.amount, record.currency)} {record.currency}
        </Typography.Text>
      </Space>
    );
  };

  const onExport = async () => {
    const { data } = await getConsumerCashOutExportData({
      status,
      filters: {
        text: textFilter,
        ...(tableParams.filters || {}),
      },
    });

    if (!data || !data.length) {
      notification.error({
        message: 'Error',
        description: 'There is no data to export',
      });
      return;
    }

    const options = [
      {
        sheet: 'Export Data',
        columns: [
          { label: 'ID', value: 'id' },
          { label: 'Consumer ID', value: 'consumerId' },
          { label: 'Consumer Name', value: 'consumer.fullName' },
          { label: 'Consumer Email', value: 'consumer.email' },
          { label: 'Consumer Phone Number', value: 'consumer.phoneNumber' },
          { label: 'Amount', value: 'amount' },
          { label: 'Currency', value: 'currency' },
          { label: 'Receive Method', value: 'method' },
          { label: 'Receive Identifier', value: 'methodIdentifier' },
          { label: 'Status', value: 'status' },
          { label: 'Requested At', value: 'createdAt' },
          { label: 'Approved At', value: 'approvedAt' },
          { label: 'Rejected At', value: 'rejectedAt' },
        ],
        content: data,
      },
    ];

    const settings = {
      fileName: `cash-out-report-${status}-${Date.now()}`,
    };

    xlsx(options, settings, () => {
      notification.success({
        message: 'Success',
        description: 'Data exported successfully',
      });
    });
  };

  const appContainer = () => {
    return document.getElementById('app-container') as HTMLElement;
  };

  return (
    <>
      <Container>
        <div className="table-filter-header">
          <Space>
            <Button
              type={status === 'pending' ? 'primary' : 'default'}
              onClick={() => setStatus('pending')}
            >
              Pending
            </Button>
            <Button
              type={status === 'approved' ? 'primary' : 'default'}
              onClick={() => setStatus('approved')}
            >
              Approved
            </Button>
            <Button
              type={status === 'rejected' ? 'primary' : 'default'}
              onClick={() => setStatus('rejected')}
            >
              Rejected
            </Button>
          </Space>

          <Space>
            <Input.Search
              className="search-input"
              placeholder="Search by consumer (name, email or phone number)"
              allowClear
              onSearch={setTextFilter}
            />

            <Divider type="vertical" orientationMargin={0.01} />

            <Button
              onClick={onExport}
              ghost
              type={'primary'}
              icon={<DownloadOutlined />}
            >
              Export
            </Button>
          </Space>
        </div>

        <Divider style={{ margin: '-10px  0 8px' }} />

        <TableContainer>
          <Table
            {...tableProps}
            tableLayout={'fixed'}
            rowKey={'id'}
            rowSelection={
              status !== 'pending'
                ? undefined
                : {
                    selectedRowKeys,
                    onChange: (keys) => {
                      setSelectedRowKeys(keys);
                    },
                  }
            }
          >
            <Table.Column
              dataIndex="consumer.fullName"
              title={'Consumer'}
              sorter
              render={(_, record: ConsumerCashoutRequest) => {
                return (
                  <Space className="user-row">
                    <Avatar
                      style={{ width: 48, height: 48 }}
                      icon={<BiUser />}
                      shape="circle"
                      size={48}
                    />

                    <Space.Compact
                      direction="vertical"
                      className="info"
                      size={'small'}
                    >
                      <Typography.Text strong>
                        {record.consumer.fullName || (
                          <span style={{ color: '#999' }}>(name not set)</span>
                        )}
                      </Typography.Text>
                      {record.consumer.email && (
                        <Typography.Text type={'secondary'} copyable>
                          {record.consumer.email}
                        </Typography.Text>
                      )}

                      {record.consumer.phoneNumber && (
                        <Typography.Text type={'secondary'} copyable>
                          {record.consumer.phoneNumber}
                        </Typography.Text>
                      )}
                    </Space.Compact>
                  </Space>
                );
              }}
            />

            <Table.Column
              dataIndex="amount"
              title={'Amount'}
              align={'center'}
              sorter
              width={150}
              render={amountColumnRenderer}
            />

            <Table.Column
              dataIndex="method"
              title={'Receive Method & Identifier'}
              align={'center'}
              filters={[
                { text: 'Interac', value: 'interac' },
                { text: 'PayPal', value: 'paypal' },
                { text: 'Venmo', value: 'venmo' },
              ]}
              sorter
              render={(_, record: ConsumerCashoutRequest) => {
                if (!record.method) return <span>(method not set)</span>;
                return (
                  <Space.Compact className="user-row" direction={'vertical'}>
                    <Typography.Text strong>
                      {startCase(record.method)}
                    </Typography.Text>

                    <Typography.Text code type={'secondary'} copyable>
                      {record.methodIdentifier}
                    </Typography.Text>
                  </Space.Compact>
                );
              }}
            />

            <Table.Column
              dataIndex="createdAt"
              title={'Requested At'}
              align={'center'}
              sorter
              width={180}
              render={(_, record: ConsumerCashoutRequest) => {
                return (
                  <Space.Compact className="user-row" direction={'vertical'}>
                    <Typography.Text strong>
                      {formatISODate(record.createdAt, 'DD')}
                    </Typography.Text>
                    <Typography.Text type={'secondary'}>
                      {formatISODate(record.createdAt, 'ttt')}
                    </Typography.Text>
                  </Space.Compact>
                );
              }}
            />

            {status === 'approved' && (
              <Table.Column
                dataIndex="approvedAt"
                title={'Approved At'}
                align={'center'}
                sorter
                width={180}
                render={(value, record: ConsumerCashoutRequest) => {
                  return (
                    <Space.Compact className="user-row" direction={'vertical'}>
                      <Typography.Text strong>
                        {formatISODate(value, 'DD')}
                      </Typography.Text>
                      <Typography.Text type={'secondary'}>
                        {formatISODate(value, 'ttt')}
                      </Typography.Text>
                    </Space.Compact>
                  );
                }}
              />
            )}

            {status === 'rejected' && (
              <Table.Column
                dataIndex="rejectedAt"
                title={'Rejected At'}
                align={'center'}
                sorter
                width={180}
                render={(value, record: ConsumerCashoutRequest) => {
                  return (
                    <Space.Compact className="user-row" direction={'vertical'}>
                      <Typography.Text strong>
                        {formatISODate(value, 'DD')}
                      </Typography.Text>
                      <Typography.Text type={'secondary'}>
                        {formatISODate(value, 'ttt')}
                      </Typography.Text>
                    </Space.Compact>
                  );
                }}
              />
            )}

            <Table.Column
              width={80}
              render={(_, record: ConsumerCashoutRequest) => (
                <TableCommentButton
                  type="text"
                  icon={
                    <Badge count={record.totalComments}>
                      <WechatOutlined />
                    </Badge>
                  }
                  onClick={(e) => {
                    e.stopPropagation();

                    setCommentDialog({
                      open: true,
                      walletId: record.id,
                    });
                  }}
                />
              )}
            />

            {status === 'pending' && (
              <Table.Column
                align={'right'}
                width={110}
                render={(_, record: ConsumerCashoutRequest) => {
                  return (
                    <ApprovalButtons
                      afterChange={() => {
                        if (selectedRowKeys.length > 0) {
                          setSelectedRowKeys([]);
                        }

                        refresh();
                      }}
                      size={'small'}
                      ids={[record.id]}
                      disabled={selectedRowKeys.length > 0}
                    />
                  );
                }}
              />
            )}
          </Table>
        </TableContainer>
      </Container>

      <Drawer
        rootClassName={'table-footer-drawer'}
        mask={false}
        closable={false}
        open={selectedRowKeys.length > 0}
        placement={'bottom'}
        getContainer={appContainer}
        height={74}
      >
        <div>
          <Typography.Text strong className="selected-count">
            {selectedRowKeys.length} selected
          </Typography.Text>

          <Button
            type={'link'}
            onClick={() => {
              setSelectedRowKeys([]);
            }}
          >
            Clear Selection
          </Button>
        </div>

        <ApprovalButtons
          afterChange={() => {
            if (selectedRowKeys.length > 0) {
              setSelectedRowKeys([]);
            }

            refresh();
          }}
          size={'large'}
          ids={selectedRowKeys as string[]}
          disabled={false}
        />
      </Drawer>

      <CommentModal
        open={commentDialog.open}
        onClose={() => setCommentDialog({ open: false, walletId: '' })}
        walletId={commentDialog.walletId}
        type={'consumer'}
      />
    </>
  );
};

export default Component;
