import { Container } from './styles';
import { useAudit } from '@/hooks';
import { useEffect } from 'react';

/*
 * Page Reference: John Candy - National Lampoon's Vacation
 * https://www.youtube.com/watch?v=E4Eim2gNczY&t=9s
 */

const NotAuthorizedPage = () => {
  const audit = useAudit({
    resourceName: 'not-authorized-page',
  });

  useEffect(() => {
    audit.onAccess();
  }, []);

  return (
    <Container>
      <div className="container">
        <h1>Sorry folks, page is forbidden.</h1>
        <p>The moose out front shoulda told ya.</p>

        <a href="/">Go back to home</a>
      </div>
    </Container>
  );
};

export default NotAuthorizedPage;
