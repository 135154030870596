import styled from 'styled-components';
import { TableContainer } from '@/styles';

export const Container = styled(TableContainer)`
  .user-row {
    .info {
      gap: 0 !important;
      width: 300px;

      .address {
        font-size: 12px;
        max-width: 300px;
      }
    }
  }
`;
