import { Modal, Space, Typography } from "antd";
import { on } from "events";
import { BrandProps } from "../BrandTab";

type Props = {
  isOpen: boolean;
  brand: BrandProps | null;
  onClose: () => void;
  onConfirm: () => void;
  userName: string;
  oldProfile: string;
  newProfile: string;
};

export function ConfirmProfileModal(
  {
    isOpen,
    brand,
    onClose,
    onConfirm,
    userName,
    oldProfile,
    newProfile
  }: Props) {
  return (
    <Modal
      title={`Confirm Profile Change for ${brand?.name}`}
      open={isOpen}
      centered
      okButtonProps={{ type: 'default', danger: true }}
      cancelButtonProps={{ type: 'primary' }}
      okText="Confirm"
      onOk={onConfirm}
      onCancel={onClose}
    >
      <Space direction="vertical" size={8} style={{ paddingTop: 24, paddingBottom: 24 }}>
        <Typography.Text>
          You’re about to change <b>{userName}</b> profile. Make sure to confirm this action or to cancel if this is a mistake.
        </Typography.Text>
        <Typography.Text>
          Current Profile: <b>{oldProfile}</b>
        </Typography.Text>
        <Typography.Text>
          New Profile: <b>{newProfile}</b>
        </Typography.Text>
      </Space>
    </Modal>
  );
}