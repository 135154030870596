import { FC } from 'react';
import Layout from '@/components/Layout';
import PageHeader from '@/components/PageHeader';
import { useAudit, useTable } from '@/hooks';
import { handleError } from '@/services/util/axios';
import { TableContainer } from '@/styles';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Space,
  Table,
  Tag,
  notification,
  Input,
  Dropdown,
  Modal,
  Typography,
  MenuProps,
} from 'antd';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  createBlankAdminUser,
  deleteAdminUser,
} from '@/services/admin-user.service';
import { sectors } from '@/data/admin-user';
import { useSession } from '@/store';
import { GrView } from 'react-icons/gr';
import { PiDotsThreeOutline, PiPencil } from 'react-icons/pi';
import UserDetails from './UserDetails';
import { LuActivity } from 'react-icons/lu';
import { When } from 'react-if';
import UserActivities from './UserActivities';

type TableData = {
  id: string;
  fullName: string;
  email: string;
  isActive: boolean;
  phoneNumber: string;
  blockedAdminPanelLogin: boolean;
  isMasterUser: boolean;
  hasAdminAccess: boolean;
  lastAccessAt: number;
  sector: string;
  permissions: string[];
};

const AdminUserList: FC = () => {
  const navigate = useNavigate();
  const session = useSession();
  const [filter, setFilter] = useState('');
  const [userPreviewOpen, setUserPreviewOpen] = useState(false);
  const [userActivityOpen, setUserActivityOpen] = useState(false);
  const [activityUserId, setActivityUserId] = useState<string | null>(null);
  const [activityUserName, setActivityUserName] = useState<string | null>(null);
  const { tableProps, refresh } = useTable<TableData>({
    service: 'user/read',
    path: `admin/admin-user/list`,
    params: {
      filter,
    },
    defaultSort: {
      field: 'fullName',
      order: 'ascend',
    },
  });

  const [previewUser, setPreviewUser] = useState<TableData | null>(null);
  const [popconfirmVisible, setPopconfirmVisible] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState('');

  const audit = useAudit({
    resourceName: 'admin-user-list',
  });

  useEffect(() => {
    audit.onAccess();
  }, []);

  const onCreateUser = async () => {
    const { user } = await createBlankAdminUser();

    if (!user) return handleError('Failed to create user');

    audit.onCreate({ target: 'add-user', data: user, entityId: user.id });

    navigate(`/admin-user/${user.id}/edit`);
  };

  const onDeleteUser = async (id: string | undefined) => {
    if (!id) return;

    const { status } = await deleteAdminUser(id);

    if (status) {
      audit.onDelete({ target: 'delete', entityId: id });
      notification.success({
        message: 'User deleted successfully',
        placement: 'bottomRight',
        duration: 2,
      });
      refresh();

      setSelectedRowId('');
      setPopconfirmVisible(false);
      return;
    }
    notification.error({
      message: 'Failed to delete user',
      placement: 'bottomRight',
      duration: 2,
    });
  };

  const onViewUser = async (user: TableData) => {
    setPreviewUser(user);
    setUserPreviewOpen(true);
  };

  const onActivityUser = async (user: TableData) => {
    setActivityUserId(user.id);
    setActivityUserName(user.fullName);
    setUserActivityOpen(true);
  };

  const onCancelDelete = () => {
    setSelectedRowId('');
    setPopconfirmVisible(false);
  };

  const getTableMenuItems = (
    record: TableData,
  ): NonNullable<MenuProps['items']> => {
    const items: NonNullable<MenuProps['items']> = [
      {
        key: `user-menu-preview-${record.id}`,
        label: 'Quick View',
        icon: <GrView size={20} />,
        onClick: () => {
          onViewUser(record);
        },
      },
    ];
    if (session.hasPermission('admin_user.edit')) {
      items.push({
        key: `user-menu-activity-${record.id}`,
        label: 'User Activity',
        icon: <LuActivity size={20} />,
        onClick: () => {
          onActivityUser(record);
        },
      });
      items.push({
        key: `user-menu-edit-${record.id}`,
        label: <Link to={`/admin-user/${record.id}/edit`}>Edit</Link>,
        icon: <PiPencil size={20} />,
      });
      items.push({
        key: `user-menu-delete-${record.id}`,
        label: 'Delete',
        icon: <DeleteOutlined size={20} />,
        onClick: () => {
          setSelectedRowId(record.id);
          setPopconfirmVisible(true);
        },
      });
    }
    return items;
  };

  return (
    <Layout>
      <Modal
        title="Delete User"
        open={popconfirmVisible}
        onOk={() => onDeleteUser(selectedRowId)}
        onCancel={() => onCancelDelete()}
        okText="Yes, I'm sure"
        cancelText="Cancel"
      >
        Are you sure to delete this user?
      </Modal>
      <PageHeader noBackButton title="Admin Users">
        <Space>
          <Input.Search
            placeholder="Search by..."
            allowClear
            onSearch={(value) => {
              audit.onClick({
                target: 'search-table',
              });
              setFilter(value);
            }}
            style={{ width: 260 }}
          />
          <Button type="primary" icon={<PlusOutlined />} onClick={onCreateUser}>
            Add User
          </Button>
        </Space>
      </PageHeader>
      <TableContainer>
        <Table
          {...tableProps}
          rowKey={'id'}
          // onRow={(record, index) => {
          //   return {
          //     onClick: (event) => {
          //       navigate(`/admin-user/update/${record.id}`);
          //     }, // click row
          //   };
          // }}
        >
          <Table.Column
            title="Name"
            dataIndex="fullName"
            key="fullName"
            sorter
            render={(text) => text || '-'}
          />
          <Table.Column
            title="Email"
            dataIndex="email"
            key="email"
            align="left"
            sorter
            render={(text: any, record: TableData, index: number) => {
              if (!record.email) return '-';
              return (
                <Typography.Text copyable style={{ fontSize: 13 }}>
                  {record.email}
                </Typography.Text>
              );
            }}
          />
          <Table.Column
            title="Status"
            dataIndex="isActive"
            key="isActive"
            align="center"
            sorter
            render={(text: any, record: TableData, index: number) => {
              return record.isActive ? (
                <Tag color="green">Active</Tag>
              ) : (
                <Tag color="grey">Inactive</Tag>
              );
            }}
          />
          <Table.Column
            title="Sector"
            dataIndex="sector"
            key="sector"
            align="center"
            sorter
            render={(text) => sectors.find((s) => s.id === text)?.name || '-'}
          />

          <Table.Column
            title="Actions"
            dataIndex="actions"
            key="actions"
            align="right"
            fixed="right"
            render={(text: any, record: TableData, index: number) => {
              return (
                <Space className="table-actions" size="middle">
                  <Dropdown menu={{ items: getTableMenuItems(record) }}>
                    <Button>
                      <PiDotsThreeOutline size={20} />
                    </Button>
                  </Dropdown>
                </Space>
              );
            }}
          />
        </Table>
      </TableContainer>
      <UserDetails
        setPreviewUser={setPreviewUser}
        setUserPreviewOpen={setUserPreviewOpen}
        onDeleteUser={onDeleteUser}
        userPreviewOpen={userPreviewOpen}
        previewUser={previewUser}
      />
      <When condition={userActivityOpen && activityUserId}>
        <UserActivities
          activityUserId={activityUserId}
          setActivityUserId={setActivityUserId}
          userActivityOpen={userActivityOpen}
          setUserActivityOpen={setUserActivityOpen}
          setActivityUserName={setActivityUserName}
          activityUserName={activityUserName}
        />
      </When>
    </Layout>
  );
};

export default AdminUserList;
