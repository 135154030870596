import Layout from '@/components/Layout';
import { useAudit } from '@/hooks';
import { useEffect } from 'react';

const GeneralError = () => {
  const audit = useAudit({
    resourceName: 'error-page',
  });

  useEffect(() => {
    audit.onAccess();
  }, []);
  return (
    <Layout>
      <div>
        <h1>Something get wrong</h1>
        <p>An error happens.</p>
      </div>
    </Layout>
  );
};

export default GeneralError;
