import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 12px;
  margin-bottom: 24px;

  .back-button {
    border: 0;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    padding: 0;
    background: transparent;
    cursor: pointer;

    svg {
      font-size: 24px;
    }

    &:hover {
      svg {
        fill: #9383c2;
      }
    }
  }

  > h1 {
    margin-bottom: 0;
    color: #6f56bc;
    font-size: 20px;
    font-weight: 500;
    margin-right: auto;
  }
`;
