import { type FC } from 'react';
import type { DetailedManagerSubmission } from '@/types';
import { OverviewContainer } from './styles';
import StatusTag from './StatusTag';
import {
  formatISODate,
  formatMoneyWithoutCurrency,
  getNameAvatarUrl,
} from '@/utils';
import { Avatar, Descriptions, Divider, Space, Typography } from 'antd';
import { startCase } from 'lodash';
import { Link } from 'react-router-dom';
import { ExternalLink } from 'lucide-react';

type Args = {
  completion: DetailedManagerSubmission;
};

const Overview: FC<Args> = ({ completion }) => {
  return (
    <OverviewContainer>
      <div className="body">
        <Descriptions title="Submission" size="small" column={2}>
          <Descriptions.Item label="ID">
            <Typography.Text copyable>{completion.form._id}</Typography.Text>
          </Descriptions.Item>
          <Descriptions.Item label="Status">
            <StatusTag status={completion.form.status} size="small" />
          </Descriptions.Item>

          <Descriptions.Item label="Currency">
            {completion.store.currency}
          </Descriptions.Item>
          <Descriptions.Item label="Reward per entry">
            <strong style={{ fontWeight: 600 }}>
              {formatMoneyWithoutCurrency(completion.rewardMoney)}
            </strong>
          </Descriptions.Item>
          <Descriptions.Item label="Total Rewards">
            <strong style={{ fontWeight: 600 }}>
              {formatMoneyWithoutCurrency(completion.form.earnings || 0)}
            </strong>
          </Descriptions.Item>
          <Descriptions.Item label="Total remaining budget">
            <strong style={{ fontWeight: 600 }}>
              {formatMoneyWithoutCurrency(completion.totalRemainingBudget)}
            </strong>
          </Descriptions.Item>

          <Descriptions.Item label="Submitted At">
            {formatISODate(completion.form.submittedAt)}
          </Descriptions.Item>

          {completion.form.status === 'approved' && (
            <Descriptions.Item label="Approved At">
              {formatISODate(completion.form.approvedAt)}
            </Descriptions.Item>
          )}

          {completion.form.status === 'rejected' && (
            <>
              <Descriptions.Item label="Rejected At">
                {formatISODate(completion.form.rejectedAt)}
              </Descriptions.Item>

              <Descriptions.Item label="Rejected Reason">
                {completion.form.rejectionArgs.rejectText || 'N/A'}
              </Descriptions.Item>
            </>
          )}

          <Descriptions.Item label="Mission">
            <Link to={`/mission/${completion.mission._id}`} target="_blank">
              {completion.mission.title} <ExternalLink size={16} />
            </Link>
          </Descriptions.Item>

          <Descriptions.Item label="Campaign">
            <Link to={`/campaign/${completion.bundle._id}`} target="_blank">
              {completion.bundle.name} <ExternalLink size={16} />
            </Link>
          </Descriptions.Item>

          <Descriptions.Item label="Campaign Type">
            {startCase(completion.bundle.type)}
          </Descriptions.Item>

          <Descriptions.Item label="Brand">
            <Link to={`/brand/${completion.brand._id}/manage`} target="_blank">
              {completion.brand.name} <ExternalLink size={16} />
            </Link>
          </Descriptions.Item>
        </Descriptions>

        <Divider />

        <Descriptions title="Creator Info" size="small" column={2}>
          <Descriptions.Item label="Creator">
            <Space>
              <Avatar
                src={
                  completion.creator.avatarUrl ||
                  getNameAvatarUrl(completion.creator.fullName)
                }
                size={60}
              />
              <Space.Compact direction="vertical">
                <Typography.Text strong>
                  {completion.creator.fullName}
                </Typography.Text>
                <Typography.Text type="secondary">
                  {completion.creator.email}
                </Typography.Text>
              </Space.Compact>
            </Space>
          </Descriptions.Item>
          <Descriptions.Item label="Store">
            <Space.Compact direction="vertical">
              <Typography.Text strong>{completion.store.name}</Typography.Text>
              <Typography.Text type="secondary">
                {completion.store.address.formattedAddress}
              </Typography.Text>
            </Space.Compact>
          </Descriptions.Item>
        </Descriptions>
      </div>
    </OverviewContainer>
  );
};

export default Overview;
