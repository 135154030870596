import {
  Button,
  Checkbox,
  Collapse,
  Form,
  Input,
  Switch as SwitchInput,
  Radio,
  notification,
} from 'antd';
import Layout from '@/components/Layout';
import PageHeader from '@/components/PageHeader';
import CropUpload from '@/components/CropUpload';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import {
  getConnectorDetails,
  updateConnector,
} from '@/services/integration-connector.service';
import { useEffect, useState } from 'react';
import { get } from 'lodash';
import { Switch, Case } from 'react-if';
import OauthPartial from '@/pages/Integration/connector/Edit/partials/OauthPartial';
import ApiTokenPartial from '@/pages/Integration/connector/Edit/partials/ApiTokenPartial';

const EditPage = () => {
  const { id = '' } = useParams();
  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState<any>({
    authMethod: 'oauth',
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadConnector();
  }, []);

  const loadConnector = async () => {
    const connector = await getConnectorDetails(id);

    form.setFieldsValue(connector);
    setFormValues((prev: any) => ({ ...prev, ...connector }));
  };

  const onFinish = async (values: any) => {
    setLoading(true);
    const { status } = await updateConnector(id, values);

    if (status) {
      notification.success({
        message: 'Success',
        description: 'Connector updated successfully',
      });
    } else {
      notification.error({
        message: 'Error',
        description: 'Failed to update connector',
      });
    }

    setLoading(false);
  };

  return (
    <Layout>
      <PageHeader title="Edit Connector" />
      <Form
        layout="horizontal"
        wrapperCol={{ span: 18 }}
        labelCol={{ span: 4 }}
        onFinish={onFinish}
        form={form}
        onValuesChange={(changedValues, allValues) => {
          setFormValues((prev: any) => ({ ...prev, ...allValues }));
        }}
      >
        <fieldset>
          <legend>Details</legend>

          <Form.Item
            name="name"
            label="Name"
            rules={[{ required: true }]}
            wrapperCol={{ span: 8 }}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="key"
            label="Identifier"
            rules={[{ required: true }]}
            wrapperCol={{ span: 8 }}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="logoUrl"
            label="Connector Logo"
            valuePropName="value"
            rules={[
              { required: true, message: 'Please upload and crop the image!' },
            ]}
          >
            <CropUpload />
          </Form.Item>

          <Form.Item
            label="Is Active"
            name="isActive"
            valuePropName="checked"
            help="If this integration is inactive, no operations will be processed"
          >
            <SwitchInput />
          </Form.Item>
        </fieldset>

        <fieldset>
          <legend>Authorization</legend>
          <Form.Item
            name="authMethod"
            label="Method"
            rules={[{ required: true }]}
          >
            <Radio.Group buttonStyle="solid">
              <Radio.Button value="oauth">Oauth</Radio.Button>
              <Radio.Button disabled value="apiToken">
                API Token
              </Radio.Button>
              <Radio.Button disabled value="basicAuth">
                Basic Auth
              </Radio.Button>
            </Radio.Group>
          </Form.Item>

          <Switch>
            <Case condition={formValues.authMethod === 'oauth'}>
              <OauthPartial formValues={formValues} />
            </Case>
            <Case condition={formValues.authMethod === 'apiToken'}>
              <ApiTokenPartial formValues={formValues} />
            </Case>
          </Switch>
        </fieldset>
        <Form.Item wrapperCol={{ offset: 4, span: 14 }}>
          <Button type="primary" htmlType="submit" loading={loading}>
            Save Connector Changes
          </Button>
        </Form.Item>
      </Form>
    </Layout>
  );
};

export default EditPage;
