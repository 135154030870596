import { useAudit } from '@/hooks';
import {
  getMobilePayments,
  saveMobilePayments,
} from '@/services/user-wallet.service';
import { MobilePayments } from '@/types';
import { Form, notification } from 'antd';
import { useEffect, useState } from 'react';

export function useSettingsController() {
  const [mobilePayments, setMobilePayments] = useState<MobilePayments>();
  const [loadingPayments, setLoadingPayments] = useState(false);
  const [savingPayments, setSavingPayments] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [currentTab, setCurrentTab] = useState('tab-payments');

  const [paymentForm] = Form.useForm();

  const audit = useAudit({
    resourceName: 'general-settings',
  });

  useEffect(() => {
    audit.onAccess();
    loadMobilePayments();
  }, []);

  const loadMobilePayments = async () => {
    setLoadingPayments(true);
    const { result } = await getMobilePayments();

    setMobilePayments(result);
    setTimeout(() => {
      setLoadingPayments(false);
    }, 0);
    paymentForm.setFieldsValue({ payments: result });
  };

  const onFinishPaymentForm = async (values: any) => {
    setSavingPayments(true);
    const { status } = await saveMobilePayments(values.payments);
    setSavingPayments(false);
    if (!status) {
      notification.error({
        message: 'Error',
        description:
          'Failed to update payment settings. Please try again later.',
      });
      return;
    }
    audit.onUpdate({ target: 'save', data: values });
    setHasChanges(false);

    notification.success({
      message: 'Success',
      description: 'Payment settings updated successfully.',
    });
  };

  const paymentFormChanged = () => {
    setHasChanges(true);
  };

  const cancelPaymentForm = () => {
    paymentForm.resetFields();
    setHasChanges(false);
  };

  return {
    audit,
    mobilePayments,
    loadingPayments,
    paymentForm,
    onFinishPaymentForm,
    paymentFormChanged,
    hasChanges,
    savingPayments,
    cancelPaymentForm,
    setCurrentTab,
    currentTab,
  };
}
