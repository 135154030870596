import {
  createDefaultImageReader,
  createDefaultImageScrambler,
  createDefaultImageWriter,
  locale_en_gb,
  markup_editor_locale_en_gb,
  PinturaEditorOptions,
  plugin_redact,
  plugin_redact_locale_en_gb,
  setPlugins,
} from '@pqina/pintura';

// Load the plugins
setPlugins(plugin_redact);

export const pinturaEditorRedactConfig = {
  // This will read the image data
  imageReader: createDefaultImageReader(),
  // This will write the output image
  imageWriter: createDefaultImageWriter(),
  // Necessary for the redact plugin
  imageScrambler: createDefaultImageScrambler(),
  // The icons and labels to use in the user interface
  locale: {
    ...locale_en_gb,
    ...markup_editor_locale_en_gb,
    ...plugin_redact_locale_en_gb,
  },
  // The plugins to use
  utils: ['redact'],
} satisfies PinturaEditorOptions;
