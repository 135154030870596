import styled from 'styled-components';

export const StorePageHeader = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  padding-top: 8px;
  margin-bottom: 32px;

  .main-info {
    display: flex;
    column-gap: 24px;
    align-items: flex-start;

    .avatar {
      width: 200px;

      .ant-avatar-square {
        border-radius: 10px;
      }
    }

    .info {
      width: calc(100% - 190px);
      display: flex;
      flex-direction: column;
      row-gap: 4px;

      .name-container {
        h2 {
          margin-bottom: 0;
        }
      }

      .ant-descriptions-row {
        > th {
          padding-bottom: 0;
        }
      }
    }
  }

  .additional-info {
    .ant-descriptions-view {
      .ant-descriptions-item {
        padding-bottom: 0;
      }
    }

    .earning-info {
      gap: 0 !important;
    }
  }
`;
