import styled from 'styled-components';

export const BrandInfoContainer = styled.div`
  display: flex;
  align-items: flex-end;

  .ant-avatar {
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .info {
    display: flex;
    flex-direction: column;
    row-gap: 6px;
    justify-content: center;
    align-items: flex-start;

    .name {
      font-size: 20px;
      font-weight: 600;
      //line-height: 120%;
    }
  }
`;
