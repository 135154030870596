import { useCallback, useRef, useState } from 'react';

type SetStateFn<T> = (prevState?: T) => T;

/**
 * A hook that creates a state and a ref that are synced with each other.
 *
 * Note that the ref is **readonly**, so the only way to mutate the state is
 * through the set state function.
 */
export function useStateWithRef<T>(initialValue: T) {
  const [value, internalSetValue] = useState(initialValue);
  const valueRef = useRef(initialValue);

  const setValue = useCallback((value: React.SetStateAction<T>) => {
    if (typeof value !== 'function') {
      internalSetValue(value);
      valueRef.current = value;
      return;
    }

    const setter = value as SetStateFn<T>;
    internalSetValue((currentValue) => {
      const nextValue = setter(currentValue);
      valueRef.current = nextValue;
      return nextValue;
    });
  }, []);

  return [
    value,
    // Returning the ref as readonly to prevent accidental mutations
    valueRef as Readonly<React.MutableRefObject<T>>,
    setValue,
  ] as const;
}
