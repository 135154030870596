import {
  Button,
  Checkbox,
  Form,
  Input,
  InputNumber,
  Select,
  Space,
} from 'antd';
import { get } from 'lodash';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

type Props = {
  formValues: any;
  [key: string]: any;
};

const OauthPartial = ({ formValues }: Props) => {
  return (
    <>
      <Form.Item
        name={['oauth', 'tokenHost']}
        label="Token Host"
        rules={[{ required: true }]}
        wrapperCol={{ span: 8 }}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Authorize Host Equal"
        name={['oauth', 'sameTokenAndAuthorizeHost']}
        valuePropName="checked"
        help="Connector uses same host for token and authorize"
      >
        <Checkbox />
      </Form.Item>

      <Form.Item
        shouldUpdate={(prevValues, currentValues) => {
          const prev = get(prevValues, 'oauth.sameTokenAndAuthorizeHost');
          const curr = get(currentValues, 'oauth.sameTokenAndAuthorizeHost');

          return prev !== curr;
        }}
        label="Authorize Host"
        wrapperCol={{ span: 8 }}
      >
        {({ getFieldValue }) =>
          !getFieldValue('oauth')?.sameTokenAndAuthorizeHost ? (
            <Form.Item
              name={['oauth', 'authorizeHost']}
              rules={[
                {
                  required: true,
                  message: 'Please input authorize host!',
                },
              ]}
              noStyle
            >
              <Input />
            </Form.Item>
          ) : (
            <span style={{ color: '#ccc' }}>
              (Same as <strong>Token Host</strong>)
            </span>
          )
        }
      </Form.Item>

      <Form.Item
        name={['oauth', 'authorizePath']}
        label="Authorize Path"
        rules={[{ required: true }]}
        help="Path to get authorization Code"
        wrapperCol={{ span: 8 }}
      >
        <Input placeholder="/oauth/authorize" />
      </Form.Item>

      <Form.Item
        name={['oauth', 'tokenPath']}
        label="Token Path"
        rules={[{ required: true }]}
        help="Path to get accessToken"
        wrapperCol={{ span: 8 }}
      >
        <Input placeholder="/oauth/token" />
      </Form.Item>

      <Form.Item
        label="Token Content Type"
        name={['oauth', 'tokenRequestContentType']}
        wrapperCol={{ span: 8 }}
      >
        <Select defaultValue="application/json">
          <Select.Option value="application/x-www-form-urlencoded">
            Form Urlencoded
          </Select.Option>
          <Select.Option value="application/json">JSON</Select.Option>
        </Select>
      </Form.Item>

      <Form.Item
        name={['oauth', 'clientId']}
        label="Client ID"
        rules={[{ required: true }]}
        wrapperCol={{ span: 8 }}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name={['oauth', 'clientSecret']}
        label="Client Secret"
        rules={[{ required: true }]}
        wrapperCol={{ span: 8 }}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Has Refresh Strategy"
        name={['oauth', 'hasRefreshStrategy']}
        valuePropName="checked"
      >
        <Checkbox />
      </Form.Item>

      <Form.Item
        shouldUpdate={(prevValues, currentValues) => {
          const prev = get(prevValues, 'oauth.hasRefreshStrategy');
          const curr = get(currentValues, 'oauth.hasRefreshStrategy');

          return prev !== curr;
        }}
        label="Expire Days Term"
      >
        {({ getFieldValue }) =>
          !getFieldValue('oauth')?.hasRefreshStrategy ? (
            <Form.Item
              name={['oauth', 'expireDaysTerm']}
              rules={[
                {
                  required: true,
                  message: 'Please input expire days term!',
                },
              ]}
              noStyle
            >
              <InputNumber min={1} />
            </Form.Item>
          ) : (
            <span style={{ color: '#ccc' }}>(Managed by service)</span>
          )
        }
      </Form.Item>

      <Form.Item label="Scope" shouldUpdate={true}>
        {() => (
          <Form.List
            name={['oauth', 'scope']}
            // rules={[
            //   {
            //     validator: async (_, names) => {
            //       if (!names || names.length < 1) {
            //         return Promise.reject(new Error('At least one scope'));
            //       }
            //     },
            //   },
            // ]}
          >
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, fieldKey, ...restField }) => (
                  <Space
                    key={key}
                    style={{ display: 'flex', marginBottom: 8 }}
                    align="baseline"
                  >
                    <Form.Item
                      {...restField}
                      name={[name]}
                      rules={[{ required: true, message: 'Missing scope' }]}
                      wrapperCol={{ span: 24 }}
                    >
                      <Input placeholder="Scope" />
                    </Form.Item>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Space>
                ))}
                <Form.Item wrapperCol={{ span: 11 }}>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add Scope Item
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        )}
      </Form.Item>
    </>
  );
};

export default OauthPartial;
