import { Container } from './styles';
import { Link } from 'react-router-dom';

const NotFoundPage = () => {
  return (
    <Container>
      <div className="error">
        <div className="_404">404</div>
        <div className="page-not-found">
          <div>Page not found!</div>
          <Link to={'/'}>Back to home</Link>
        </div>
      </div>

      <div className="stars">
        <div className="star">
          <div className="ellipse-42"></div>
          <div className="ellipse-41"></div>
        </div>
        <div className="star">
          <div className="ellipse-422"></div>
          <div className="ellipse-412"></div>
        </div>
        <div className="star">
          <div className="ellipse-423"></div>
          <div className="ellipse-413"></div>
        </div>
        <div className="star">
          <div className="ellipse-424"></div>
          <div className="ellipse-414"></div>
        </div>
        <div className="star">
          <div className="ellipse-425"></div>
          <div className="ellipse-415"></div>
        </div>
        <div className="star">
          <div className="ellipse-426"></div>
          <div className="ellipse-416"></div>
        </div>
        <div className="star">
          <div className="ellipse-427"></div>
          <div className="ellipse-417"></div>
        </div>
        <div className="star">
          <div className="ellipse-428"></div>
          <div className="ellipse-418"></div>
        </div>
        <div className="star">
          <div className="ellipse-429"></div>
          <div className="ellipse-419"></div>
        </div>
        <div className="star">
          <div className="ellipse-4210"></div>
          <div className="ellipse-4110"></div>
        </div>
        <div className="star">
          <div className="ellipse-4211"></div>
          <div className="ellipse-4111"></div>
        </div>
        <div className="star">
          <div className="ellipse-4212"></div>
          <div className="ellipse-4112"></div>
        </div>
        <div className="star">
          <div className="ellipse-4213"></div>
          <div className="ellipse-4113"></div>
        </div>
        <div className="star">
          <div className="ellipse-4214"></div>
          <div className="ellipse-4114"></div>
        </div>
        <div className="star">
          <div className="ellipse-4215"></div>
          <div className="ellipse-4115"></div>
        </div>
        <div className="star">
          <div className="ellipse-4216"></div>
          <div className="ellipse-4116"></div>
        </div>
        <div className="star">
          <div className="ellipse-4217"></div>
          <div className="ellipse-4117"></div>
        </div>
        <div className="star">
          <div className="ellipse-4218"></div>
          <div className="ellipse-4118"></div>
        </div>
        <div className="star">
          <div className="ellipse-4219"></div>
          <div className="ellipse-4119"></div>
        </div>
        <div className="star">
          <div className="ellipse-4220"></div>
          <div className="ellipse-4120"></div>
        </div>
        <div className="star">
          <div className="ellipse-4221"></div>
          <div className="ellipse-4121"></div>
        </div>
        <div className="star">
          <div className="ellipse-4222"></div>
          <div className="ellipse-4122"></div>
        </div>
        <div className="star">
          <div className="ellipse-4223"></div>
          <div className="ellipse-4123"></div>
        </div>
        <div className="star">
          <div className="ellipse-4224"></div>
          <div className="ellipse-4124"></div>
        </div>
        <div className="star">
          <div className="ellipse-4225"></div>
          <div className="ellipse-4125"></div>
        </div>
        <div className="star">
          <div className="ellipse-4226"></div>
          <div className="ellipse-4126"></div>
        </div>
        <div className="star">
          <div className="ellipse-4227"></div>
          <div className="ellipse-4127"></div>
        </div>
        <div className="star">
          <div className="ellipse-4228"></div>
          <div className="ellipse-4128"></div>
        </div>
        <div className="star">
          <div className="ellipse-4229"></div>
          <div className="ellipse-4129"></div>
        </div>
        <div className="star">
          <div className="ellipse-4230"></div>
          <div className="ellipse-4130"></div>
        </div>
        <div className="star">
          <div className="ellipse-4231"></div>
          <div className="ellipse-4131"></div>
        </div>
        <div className="star">
          <div className="ellipse-4232"></div>
          <div className="ellipse-4132"></div>
        </div>
      </div>
      <div className="other-side">
        <svg
          className="fog-1"
          width="464"
          height="644"
          viewBox="0 0 464 644"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.477314 306C8.90184 235.792 71.509 220.69 125.477 175C152.064 152.491 170.478 144.185 196.477 121C242.381 80.0646 236.811 -9.66666 298.477 1.00001C360.144 11.6667 423.404 -10.2108 453.977 45C488.888 108.043 419.477 102.5 375.477 212C331.477 321.5 435.836 304.314 433.477 370C431.976 411.814 404.184 431.159 403.977 473C403.704 528.305 476.521 554.997 453.977 605.5C426.483 667.094 282.477 626 282.477 626C230.644 636 66.7746 676.04 59.4773 588C55.2001 536.396 121.096 524.595 125.477 473C132.371 391.829 -9.22814 386.883 0.477314 306Z"
            fill="#E5E5E5"
            fillOpacity="0.06"
          />
        </svg>

        <div className="earth">
          <svg
            className="vector"
            width="154"
            height="152"
            viewBox="0 0 154 152"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M70.191 0.375941C10.9257 5.95926 -20.976 76.1114 18.2821 124.013C36.4243 146.233 66.72 157.224 96.5806 149.58C112.578 145.481 126.41 136.384 136.263 124.548C179.293 73.1343 138.76 -6.1242 70.191 0.375941Z"
              fill="url(#paint0_radial_3_92)"
            />
            <defs>
              <radialGradient
                id="paint0_radial_3_92"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(77.4742 76.0029) scale(65.9981 65.6176)"
              >
                <stop stopColor="#90DD6F" />
                <stop offset="1" stopColor="#B8FFA9" stopOpacity="0" />
              </radialGradient>
            </defs>
          </svg>

          <div className="group">
            <svg
              className="vector2"
              width="111"
              height="111"
              viewBox="0 0 111 111"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M55.3919 110.028C34.5558 110.028 16.4033 98.5519 7.00577 81.6063C-13.7267 44.2433 14.8698 -1.11345 56.6145 0.215418C83.1699 0.751088 105.602 20.2103 109.881 46.0872C115.496 79.4842 89.542 110.028 55.3919 110.028Z"
                fill="url(#paint0_radial_3_94)"
              />
              <defs>
                <radialGradient
                  id="paint0_radial_3_94"
                  cx="0"
                  cy="0"
                  r="1"
                  gradientUnits="userSpaceOnUse"
                  gradientTransform="translate(58.134 33.6208) scale(67.0302 66.6438)"
                >
                  <stop stopColor="#00B3DC" />
                  <stop offset="1" stopColor="#056DB0" />
                </radialGradient>
              </defs>
            </svg>

            <svg
              className="group2"
              width="94"
              height="78"
              viewBox="0 0 94 78"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g opacity="0.75">
                <path
                  d="M67.3263 31.9911C65.0365 31.4245 64.4045 34.2574 65.6996 34.8961C66.9948 35.545 70.2482 37.4817 70.9009 36.1837C71.5537 34.8961 69.927 32.6401 67.3263 31.9911Z"
                  fill="#056DB1"
                />
                <path
                  d="M90.0688 8.0919C87.8515 8.59667 85.1991 9.70921 87.4681 11.3265C89.7476 12.9438 93.3117 12.2948 93.6433 10.3582C93.9645 8.41124 94.2961 7.12358 90.0688 8.0919Z"
                  fill="#056DB1"
                />
                <path
                  d="M45.5577 60.0931C40.7916 57.2499 33.86 56.2197 33.2073 59.1247C32.5546 62.0297 37.1031 66.552 42.6255 65.903C48.1583 65.2643 48.8111 62.0297 45.5577 60.0931Z"
                  fill="#056DB1"
                />
                <path
                  d="M61.0994 75.7717C56.7995 77.0388 52.2821 77.8011 47.6093 77.9762C48.6764 71.2494 55.1107 73.3715 58.5505 74.2986C59.5556 74.5768 60.3948 75.1227 61.0994 75.7717Z"
                  fill="#056DB1"
                />
                <path
                  d="M15.3448 59.7737C17.4481 60.6905 18.9194 56.2198 15.9975 52.3465C13.0757 48.4732 11.7702 43.6212 8.19565 42.6529C4.62109 41.6846 2.6732 54.2831 15.3448 59.7737Z"
                  fill="#056DB1"
                />
                <path
                  d="M1.04654 43.9405C-0.103534 44.1466 -0.580141 46.5262 2.02049 46.8455C4.62111 47.1751 4.62111 43.3018 1.04654 43.9405Z"
                  fill="#056DB1"
                />
                <path
                  d="M29.9642 30.7035C27.6744 30.4974 25.4157 31.6718 25.7369 34.2574C26.0581 36.8431 29.6327 34.2574 31.2594 34.2574C32.8861 34.2574 33.5388 31.0228 29.9642 30.7035Z"
                  fill="#056DB1"
                />
                <path
                  d="M52.3856 0.664664C50.417 -0.262456 50.1062 3.89929 51.7329 4.53798C53.3596 5.18696 57.9081 3.24 52.3856 0.664664Z"
                  fill="#056DB1"
                />
              </g>
            </svg>

            <svg
              className="group3"
              width="111"
              height="104"
              viewBox="0 0 111 104"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M41.5909 53.6589C30.546 55.5955 28.9193 55.9149 18.8484 64.3208C12.1862 69.8732 5.23394 68.0807 1.29674 66.2265C0.561107 62.6416 0.167358 58.9229 0.167358 55.1114C0.167358 39.4945 6.72593 25.392 17.2528 15.3997C19.4182 16.6771 21.6562 18.4077 23.3969 20.7152C28.2666 27.1741 27.2926 29.7598 41.5909 37.187C55.8788 44.6143 52.6358 51.7222 41.5909 53.6589Z"
                fill="url(#paint0_linear_3_104)"
              />
              <path
                d="M105.311 31.583C95.1162 29.4609 88.1639 40.9263 81.222 40.0919C73.0989 39.1236 75.3783 27.174 68.5504 14.5755C65.1312 8.27107 64.8929 3.90331 65.5042 1.12195C83.1491 4.35657 97.8411 15.9353 105.311 31.583Z"
                fill="url(#paint1_linear_3_104)"
              />
              <path
                d="M110.627 55.1113C110.627 76.4248 98.4214 94.895 80.5796 104.001C76.5077 97.6043 83.1078 92.8554 90.9718 87.9004C100.722 81.7609 91.6246 79.1752 93.2409 69.1623C94.8676 59.1494 88.6924 58.5004 91.6142 50.1049C93.6968 44.1095 104.555 49.7958 110.596 53.5455C110.616 54.0606 110.627 54.5859 110.627 55.1113Z"
                fill="url(#paint2_linear_3_104)"
              />
              <path
                d="M58.8109 71.748C52.9051 73.5713 49.7139 66.5767 42.2435 68.194C34.7732 69.8113 28.598 83.0485 38.669 82.7292C48.7399 82.4099 51.3405 81.1119 58.8109 86.2831C66.2812 91.4544 75.057 87.9005 77.6577 79.8242C80.2479 71.748 78.6212 65.6084 58.8109 71.748Z"
                fill="url(#paint3_linear_3_104)"
              />
              <path
                d="M71.151 47.5192C68.0427 48.5082 67.2553 50.1049 68.2292 52.0415C69.2032 53.9782 73.0989 53.6588 75.0468 56.5638C76.9947 59.4688 81.8747 63.0227 83.1698 56.5638C84.4753 50.1049 78.3002 45.2529 71.151 47.5192Z"
                fill="url(#paint4_linear_3_104)"
              />
              <path
                opacity="0.5"
                d="M59.7849 30.7178C59.2047 35.0238 55.2364 30.7178 48.74 29.4301C42.2436 28.1425 39.643 24.2589 42.5648 21.6732C45.4867 19.0876 49.3824 17.151 53.2886 19.407C57.1843 21.6732 60.4273 25.8762 59.7849 30.7178Z"
                fill="#056DB1"
              />
              <path
                d="M52.9569 5.20138C44.8338 5.20138 40.9381 9.72367 33.4678 12.3093C29.0125 13.8442 25.4897 12.0621 23.3657 10.3727C32.4006 3.96522 43.4558 0.194946 55.4021 0.194946C55.8166 0.194946 56.2207 0.194935 56.6247 0.215538C57.1946 2.54364 56.9874 5.20138 52.9569 5.20138Z"
                fill="url(#paint5_linear_3_104)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_3_104"
                  x1="19.1026"
                  y1="-29.553"
                  x2="31.628"
                  y2="128.406"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#F2F236" />
                  <stop offset="1" stopColor="#F2F236" stopOpacity="0" />
                </linearGradient>
                <linearGradient
                  id="paint1_linear_3_104"
                  x1="81.0801"
                  y1="-34.4677"
                  x2="93.6056"
                  y2="123.491"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#F2F236" />
                  <stop offset="1" stopColor="#F2F236" stopOpacity="0" />
                </linearGradient>
                <linearGradient
                  id="paint2_linear_3_104"
                  x1="86.0012"
                  y1="-34.8579"
                  x2="98.5267"
                  y2="123.101"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#F2F236" />
                  <stop offset="1" stopColor="#F2F236" stopOpacity="0" />
                </linearGradient>
                <linearGradient
                  id="paint3_linear_3_104"
                  x1="47.3001"
                  y1="-31.7891"
                  x2="59.8255"
                  y2="126.17"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#F2F236" />
                  <stop offset="1" stopColor="#F2F236" stopOpacity="0" />
                </linearGradient>
                <linearGradient
                  id="paint4_linear_3_104"
                  x1="68.6866"
                  y1="-33.4851"
                  x2="81.2121"
                  y2="124.474"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#F2F236" />
                  <stop offset="1" stopColor="#F2F236" stopOpacity="0" />
                </linearGradient>
                <linearGradient
                  id="paint5_linear_3_104"
                  x1="37.1386"
                  y1="-30.9834"
                  x2="49.664"
                  y2="126.975"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#F2F236" />
                  <stop offset="1" stopColor="#F2F236" stopOpacity="0" />
                </linearGradient>
              </defs>
            </svg>

            <svg
              className="vector18"
              width="108"
              height="75"
              viewBox="0 0 108 75"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.45"
                d="M107.995 35.9127C103.488 49.871 93.5101 61.3982 80.5795 68.0014C77.5645 69.5363 74.394 70.8033 71.0992 71.7819C66.7994 73.049 62.2819 73.8113 57.6091 73.9864C56.8734 74.0173 56.1378 74.0276 55.3918 74.0276C34.5557 74.0276 16.4031 62.552 7.00566 45.6063C4.37396 40.8677 2.4261 35.6964 1.29674 30.2264C0.561107 26.6415 0.167358 22.9227 0.167358 19.1112C0.167358 12.4772 1.34853 6.12126 3.51399 0.239197C9.76171 18.7919 24.06 42.7322 56.5626 47.1721C62.9036 48.0374 68.8301 48.1507 74.3629 47.6768C87.9359 46.4922 99.1051 41.7742 107.995 35.9127Z"
                fill="url(#paint0_linear_3_112)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_3_112"
                  x1="46.9965"
                  y1="90.2036"
                  x2="55.1866"
                  y2="15.0956"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#00ACD8" />
                  <stop offset="1" stopColor="#0983BD" />
                </linearGradient>
              </defs>
            </svg>

            <svg
              className="vector19"
              width="104"
              height="62"
              viewBox="0 0 104 62"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.45"
                d="M103.627 6.11127C103.627 11.9727 102.705 17.6179 100.995 22.9127C96.4879 36.8711 86.5102 48.3982 73.5796 55.0014C70.5645 56.5363 67.3941 57.8034 64.0993 58.782C59.7994 60.049 55.282 60.8113 50.6092 60.9865C49.8735 61.0174 49.1379 61.0277 48.3919 61.0277C27.5558 61.0277 9.40322 49.552 0.0057373 32.6063C9.55864 36.5105 20.9351 39.1786 34.4252 39.7039C47.5112 40.219 58.3696 38.2102 67.363 34.6666C86.6967 27.0642 97.4722 12.3539 103.316 0.239502C103.471 1.65078 103.565 3.09299 103.606 4.53517C103.616 5.06054 103.627 5.58591 103.627 6.11127Z"
                fill="url(#paint0_linear_3_113)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_3_113"
                  x1="45.5664"
                  y1="73.9752"
                  x2="54.7203"
                  y2="-4.52619"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#056EB1" />
                  <stop offset="1" stopColor="#00A9D7" stopOpacity="0.3" />
                </linearGradient>
              </defs>
            </svg>

            <svg
              className="vector20"
              width="90"
              height="47"
              viewBox="0 0 90 47"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M89.8911 46.118C89.8911 46.118 82.0271 17.4803 45.867 8.05462C24.1916 2.39919 9.14733 7.49835 0.578735 12.4842C27.9423 -9.72548 69.7907 -0.907547 85.3115 31.5829C88.9172 39.1131 89.6321 45.0673 89.8911 46.118Z"
                fill="url(#paint0_linear_3_114)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_3_114"
                  x1="75.5048"
                  y1="-30.0497"
                  x2="41.0602"
                  y2="38.2676"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#E2FFB5" />
                  <stop offset="1" stopColor="#FFFFB3" stopOpacity="0" />
                </linearGradient>
              </defs>
            </svg>
          </div>
        </div>
        <div className="astronaut">
          <div className="group-77">
            <div className="rectangle-335"></div>
            <div className="rectangle-336"></div>
            <div className="rectangle-337"></div>
            <div className="ellipse-35"></div>
            <div className="ellipse-36"></div>
            <div className="ellipse-37"></div>
            <div className="ellipse-38"></div>
          </div>
          <div className="group-78">
            <div className="rectangle-338"></div>
            <div className="ellipse-40"></div>
          </div>
          <div className="group-79">
            <div className="rectangle-3382"></div>
            <div className="ellipse-402"></div>
          </div>
          <div className="group-75">
            <svg
              className="rectangle-334"
              width="115"
              height="115"
              viewBox="0 0 115 115"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M114.824 46.1814L45.12 0.39563L5.72177 60.3693C2.68944 64.9852 3.97331 71.1852 8.58938 74.2173L18.5245 80.7433C23.1406 83.7755 29.3409 82.4915 32.3732 77.8756L51.0101 49.5057C53.7993 45.2599 59.5023 44.0789 63.7483 46.8679C67.9942 49.6569 69.1751 55.3597 66.3859 59.6055L47.749 87.9754C44.7167 92.5913 46.0005 98.7913 50.6166 101.823L61.5768 109.023C66.1929 112.055 72.3931 110.771 75.4255 106.155L114.824 46.1814Z"
                fill="#D6D6D6"
              />
            </svg>

            <div className="rectangle-328"></div>
            <div className="rectangle-330"></div>
            <div className="rectangle-331"></div>
            <div className="rectangle-332"></div>
            <div className="rectangle-329"></div>
            <div className="group-74">
              <div className="rectangle-322"></div>
              <div className="rectangle-323"></div>
              <div className="ellipse-27"></div>
              <svg
                className="subtract"
                width="133"
                height="117"
                viewBox="0 0 133 117"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M132.545 64.8796C114.112 89.8482 79.1492 96.3216 52.8926 79.0746C26.636 61.8276 18.6927 27.1708 34.289 0.338789C33.6451 1.21106 33.0213 2.1059 32.4188 3.0231C14.569 30.1948 22.1277 66.6916 49.3015 84.5411C76.4754 102.391 112.974 94.8335 130.824 67.6618C131.427 66.7447 132 65.8169 132.545 64.8796Z"
                  fill="#DDDDDD"
                />
              </svg>

              <svg
                className="subtract2"
                width="134"
                height="118"
                viewBox="0 0 134 118"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M132.954 65.3464C114.521 90.315 79.5582 96.7884 53.3016 79.5414C27.045 62.2944 19.1017 27.6377 34.6979 0.805695C34.054 1.67792 33.4303 2.57272 32.8278 3.48987C14.978 30.6615 22.5366 67.1584 49.7105 85.0079C76.8843 102.857 113.383 95.3003 131.233 68.1286C131.835 67.2115 132.409 66.2837 132.954 65.3464Z"
                  fill="#D6D6D6"
                />
              </svg>

              <svg
                className="vector-188"
                width="138"
                height="131"
                viewBox="0 0 138 131"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M129.157 68.7902C120.387 46.4008 75.4994 14.9591 46.8781 7.89715C25.4455 24.1422 14.1372 48.5332 23.9712 74.0388C29.4317 88.2012 37.0011 95.0813 50.0196 102.887C59.651 108.662 66.0708 111.61 77.2941 111.999C103.641 112.912 125.861 84.2321 129.157 68.7902Z"
                  fill="black"
                />
              </svg>

              <div className="rectangle-324"></div>
              <div className="rectangle-325"></div>
              <svg
                className="mask-group"
                width="138"
                height="131"
                viewBox="0 0 138 131"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <mask
                  id="mask0_3_147"
                  style={{ maskType: 'alpha' }}
                  maskUnits="userSpaceOnUse"
                  x="20"
                  y="7"
                  width="110"
                  height="106"
                >
                  <path
                    d="M129.157 68.7915C120.387 46.402 75.4994 14.9604 46.8781 7.8984C25.4455 24.1435 14.1372 48.5345 23.9712 74.04C29.4317 88.2024 37.0011 95.0826 50.0196 102.888C59.651 108.663 66.0708 111.611 77.2941 112C103.641 112.914 125.861 84.2333 129.157 68.7915Z"
                    fill="black"
                  />
                </mask>
                <g mask="url(#mask0_3_147)">
                  <rect
                    width="16.3516"
                    height="90.6049"
                    transform="matrix(0.369765 0.929126 -0.929149 0.369707 90.7614 13.331)"
                    fill="#484848"
                  />
                  <rect
                    width="9.22658"
                    height="95.5158"
                    transform="matrix(0.369764 0.929126 -0.929149 0.369707 107.674 35.5317)"
                    fill="#484848"
                  />
                </g>
              </svg>

              <div className="ellipse-30"></div>
              <div className="ellipse-31"></div>
              <div className="ellipse-32"></div>
              <div className="ellipse-33"></div>
              <div className="ellipse-34"></div>
            </div>
            <div className="rectangle-333"></div>
            <svg
              className="vector-190"
              width="21"
              height="22"
              viewBox="0 0 21 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20.6287 7.6341L9.67203 8.99606L15.7001 11.733L0.717083 14.1183"
                stroke="#D2CB15"
              />
            </svg>
          </div>
        </div>
      </div>
      <div className="one-side">
        <div className="moon">
          <svg
            className="vector21"
            width="832"
            height="832"
            viewBox="0 0 832 832"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M376.374 2.06051C54.0719 32.6477 -119.361 416.578 94.0949 678.794C192.706 800.437 357.456 860.622 519.878 818.733C606.912 796.269 682.088 746.538 735.664 681.761C969.661 400.26 749.287 -33.5422 376.374 2.06051Z"
              fill="url(#paint0_radial_3_17)"
            />
            <defs>
              <radialGradient
                id="paint0_radial_3_17"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(416.007 415.977) scale(384.93 385.218)"
              >
                <stop stopColor="#F8F8F8" />
                <stop offset="1" stopColor="white" stopOpacity="0" />
              </radialGradient>
            </defs>
          </svg>

          <div className="group">
            <svg
              className="vector22"
              width="644"
              height="644"
              viewBox="0 0 644 644"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M643.359 322.236C643.359 341.026 641.735 359.463 638.629 377.335C636.935 386.942 634.888 396.408 632.417 405.732C618.724 456.876 592.747 502.933 557.877 540.655C545.242 554.359 531.407 566.933 516.513 578.236C502.819 588.691 488.207 598.086 472.89 606.28C452.137 617.371 430.044 626.201 406.891 632.558C379.856 639.975 351.409 643.861 322.045 643.861C308.563 643.861 295.292 643.013 282.304 641.388C173.458 627.967 81.4124 560.011 34.4012 465.706C16.0484 428.973 4.54278 388.284 1.50752 345.264C0.942823 337.635 0.6604 329.935 0.6604 322.236C0.6604 273.706 11.3897 227.719 30.5895 186.465C47.7422 149.591 71.7419 116.531 100.824 88.9107C127.718 63.3389 159.059 42.3588 193.576 27.3124C232.893 10.1468 276.375 0.610382 321.974 0.610382C460.537 0.610382 578.63 88.3456 623.735 211.401C634.959 242.059 641.664 274.836 643.076 309.096C643.288 313.405 643.359 317.785 643.359 322.236Z"
                fill="url(#paint0_radial_3_19)"
              />
              <defs>
                <radialGradient
                  id="paint0_radial_3_19"
                  cx="0"
                  cy="0"
                  r="1"
                  gradientUnits="userSpaceOnUse"
                  gradientTransform="translate(307.817 151.962) scale(490.729 491.096)"
                >
                  <stop stopColor="#E8E8E8" />
                  <stop offset="1" stopColor="#DFDFDF" />
                </radialGradient>
              </defs>
            </svg>

            <svg
              className="group4"
              width="602"
              height="607"
              viewBox="0 0 602 607"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M33.3314 428.706C31.1431 424.326 29.0255 419.805 27.0491 415.284C12.4375 382.295 3.26117 346.48 0.508263 308.829C0.508263 308.617 0.508194 308.476 0.437607 308.264C6.93165 307.416 15.8257 310.383 22.3197 328.679C30.7196 352.485 69.825 410.481 33.3314 428.706Z"
                fill="#BCBCBC"
              />
              <path
                d="M285.753 374.03C285.329 376.644 284.411 379.116 283.07 381.377C272.835 399.743 237.188 408.785 197.448 401.933C162.86 395.929 135.26 379.54 126.366 361.739C123.755 356.512 122.766 351.214 123.684 345.916C127.707 322.746 166.601 317.165 211.354 325.006C256.106 332.706 289.776 350.86 285.753 374.03Z"
                fill="#BCBCBC"
              />
              <path
                d="M190.53 220.882L190.459 220.953C181.565 234.374 153.824 222.083 121.566 200.962C95.2365 183.725 76.4602 166.56 75.1897 153.491C74.9073 150.524 75.5427 147.699 77.1662 145.156C86.0602 131.593 121.354 130.675 153.683 151.867C186.012 173.059 199.424 207.319 190.53 220.882Z"
                fill="#BCBCBC"
              />
              <path
                d="M546.572 418.958C520.525 440.715 489.326 446.295 476.973 431.39C476.338 430.613 475.773 429.836 475.279 428.989C465.82 413.518 477.114 385.757 501.82 365.2C526.949 344.22 556.807 338.286 570.007 351.214C570.501 351.708 570.995 352.203 571.419 352.697C583.772 367.602 572.69 397.271 546.572 418.958Z"
                fill="#BCBCBC"
              />
              <path
                d="M358.668 240.591C332.339 261.43 305.587 272.944 290.269 253.589C288.787 251.752 287.587 249.774 286.528 247.655C276.787 228.017 286.387 199.549 310.245 180.688C336.574 159.849 372.504 150.03 396.997 181.041C397.139 181.253 397.35 181.465 397.421 181.677C411.821 200.821 384.786 219.964 358.668 240.591Z"
                fill="#BCBCBC"
              />
              <path
                d="M253.493 531.629C249.681 535.302 242.128 537.351 230.269 536.786C186.788 534.878 139.565 481.898 152.764 464.874C152.976 464.591 153.188 464.379 153.4 464.167C167.094 448.697 201.399 476.529 218.905 485.713C247.775 500.83 263.587 521.951 253.493 531.629Z"
                fill="#BCBCBC"
              />
              <path
                d="M424.032 492.565C424.103 502.595 410.48 513.192 389.021 514.11C349.775 515.805 327.398 508.247 322.104 494.26C321.54 492.776 321.187 491.222 320.975 489.527C319.069 472.503 354.998 475.399 373.915 474.41C409.421 472.503 423.962 482.251 424.032 492.565Z"
                fill="#BCBCBC"
              />
              <path
                d="M542.971 266.869C538.666 273.298 530.336 277.677 519.536 277.677C485.513 277.677 440.126 253.094 445.843 230.348C446.478 227.735 447.467 225.474 448.596 223.637C457.349 209.509 479.443 216.361 500.69 220.882C543.183 229.924 552.289 253.094 542.971 266.869Z"
                fill="#BCBCBC"
              />
              <path
                d="M432.502 99.8048C432.079 98.8158 431.867 97.6856 431.726 96.4847C430.879 86.807 440.408 72.6789 458.973 67.5928C471.043 64.2727 487.349 64.6965 497.443 70.9129C501.89 73.6679 505.066 77.4824 506.266 82.7098C509.089 95.7076 438.502 114.851 432.502 99.8048Z"
                fill="#BCBCBC"
              />
              <path
                d="M238.529 69.7122C236.129 71.125 233.306 70.7012 230.271 67.6636C209.447 46.8248 207.612 12.7761 218.906 7.12493C223.353 4.86444 227.235 4.15804 230.553 4.51125C253.141 7.0543 250.67 62.3656 238.529 69.7122Z"
                fill="#BCBCBC"
              />
              <path
                d="M595.065 188.741C593.583 189.447 591.818 189.589 589.912 189.094C582.006 187.046 571.701 174.895 568.595 156.6C566.83 145.862 572.195 135.549 579.183 132.299C586.947 128.626 596.759 133.712 600.712 156.6C603.041 169.597 602.688 184.926 595.065 188.741Z"
                fill="#BCBCBC"
              />
              <path
                d="M556.878 503.655C532.526 530.004 503.797 552.256 471.891 569.209C468.291 567.443 465.044 564.83 463.774 561.086C462.644 557.765 463.138 553.527 466.597 548.158C479.797 527.32 493.067 495.178 523.279 493.271C532.102 492.706 539.09 493.765 544.667 495.743C550.031 497.721 553.984 500.547 556.878 503.655Z"
                fill="#BCBCBC"
              />
              <path
                d="M405.821 595.488C378.786 602.905 350.339 606.79 320.975 606.79C307.493 606.79 294.222 605.942 281.234 604.318C281.234 600.503 281.94 596.406 284.27 592.945C286.528 589.624 290.27 586.94 296.411 585.951C319.069 582.137 330.434 567.02 362.551 568.927C379.21 569.916 390.857 578.039 398.127 585.598C401.727 589.413 404.198 592.945 405.821 595.488Z"
                fill="#BCBCBC"
              />
              <path
                d="M364.739 9.45596C363.115 17.5089 349.492 20.4052 342.927 17.3677C341.515 16.7319 340.457 15.8136 339.892 14.6833C336.08 7.12481 360.997 -7.00324 364.456 5.21752C364.88 6.77161 364.95 8.18443 364.739 9.45596Z"
                fill="#BCBCBC"
              />
            </svg>

            <svg
              className="group5"
              width="606"
              height="493"
              viewBox="0 0 606 493"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M229.197 347.64C220.727 343.401 214.092 340.081 215.998 357.105C217.903 374.13 231.103 377.944 242.468 377.944C253.762 377.944 236.75 351.454 229.197 347.64Z"
                fill="#9B9B9B"
              />
              <path
                d="M72.2827 336.337C64.7298 334.43 64.7299 347.71 59.0829 360.92C53.4359 374.13 60.9887 383.596 72.2827 383.596C83.6473 383.596 87.3884 340.081 72.2827 336.337Z"
                fill="#9B9B9B"
              />
              <path
                d="M38.2592 166.023C30.9887 162.703 23.1535 158.465 21.2476 166.023C19.3418 173.582 36.3533 194.421 45.812 194.421C55.2707 194.421 59.0119 175.489 38.2592 166.023Z"
                fill="#9B9B9B"
              />
              <path
                d="M605.358 198.235C605.358 217.026 603.734 235.463 600.629 253.335C597.876 252.275 595.052 250.368 592.088 247.401C573.17 228.47 587.499 201.626 592.088 192.514C597.17 182.341 602.252 183.26 605.076 185.026C605.287 189.405 605.358 193.785 605.358 198.235Z"
                fill="#9B9B9B"
              />
              <path
                d="M410.678 219.004C399.737 217.661 393.666 226.562 410.678 234.121C427.689 241.75 425.783 220.911 410.678 219.004Z"
                fill="#9B9B9B"
              />
              <path
                d="M4.23602 133.882C-0.846275 136.425 -1.41092 147.092 11.7889 145.255C24.9888 143.348 15.53 128.231 4.23602 133.882Z"
                fill="#9B9B9B"
              />
              <path
                d="M272.68 1.50235C267.033 3.40964 259.481 9.06086 272.68 10.9681C285.88 12.8048 278.327 -0.404938 272.68 1.50235Z"
                fill="#9B9B9B"
              />
              <path
                d="M304.797 1.50232C297.88 -2.52418 297.244 16.6193 302.891 24.1778C308.538 31.7363 327.456 14.712 304.797 1.50232Z"
                fill="#9B9B9B"
              />
              <path
                d="M365.29 449.856C361.196 450.774 359.643 457.415 370.937 461.229C382.301 464.973 382.301 446.042 365.29 449.856Z"
                fill="#9B9B9B"
              />
              <path
                d="M200.822 481.997C195.175 483.905 189.458 487.649 200.822 491.463C212.187 495.207 206.469 480.09 200.822 481.997Z"
                fill="#9B9B9B"
              />
              <path
                d="M210.281 203.887C200.681 204.381 221.645 217.096 227.292 219.004C232.939 220.911 249.951 201.979 210.281 203.887Z"
                fill="#9B9B9B"
              />
              <path
                d="M520.3 154.721C515.571 158.394 524.112 179.304 531.665 177.397C539.218 175.489 537.312 141.441 520.3 154.721Z"
                fill="#9B9B9B"
              />
            </svg>

            <svg
              className="group6"
              width="590"
              height="607"
              viewBox="0 0 590 607"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M405.257 374.313C404.41 377.563 403.351 380.741 402.292 383.567C396.786 398.331 364.245 404.123 355.634 397.342C355.422 397.2 355.21 396.989 354.998 396.777C347.446 389.218 354.787 370.216 358.81 360.821C364.457 347.611 392.833 338.145 402.292 341.889C409.915 345.068 408.927 360.538 405.257 374.313Z"
                fill="#BCBCBC"
              />
              <path
                d="M75.1897 153.562C74.9073 150.595 75.5427 147.77 77.1662 145.226C86.0602 131.664 121.354 130.745 153.683 151.937C186.012 173.129 199.424 207.39 190.53 220.953L190.459 221.023C190.953 212.759 187.494 201.174 175.424 184.997C143.942 142.966 86.5542 128.485 75.1897 153.562Z"
                fill="#9B9B9B"
              />
              <path
                d="M285.753 374.03C285.329 376.644 284.411 379.116 283.07 381.377C282.294 378.127 275.306 352.626 243.471 341.96C210.436 330.94 165.966 321.121 139.496 341.96C131.025 348.6 127.425 355.452 126.366 361.668C123.755 356.441 122.766 351.143 123.684 345.845C127.707 322.675 166.601 317.094 211.354 324.935C256.106 332.706 289.776 350.86 285.753 374.03Z"
                fill="#9B9B9B"
              />
              <path
                d="M337.986 175.461C302.834 187.611 288.01 222.083 286.528 247.655C276.787 228.017 286.387 199.549 310.245 180.688C336.574 159.849 372.504 150.03 396.997 181.041C397.139 181.253 397.35 181.465 397.421 181.677C386.833 173.483 367.562 165.289 337.986 175.461Z"
                fill="#9B9B9B"
              />
              <path
                d="M405.257 374.313C404.904 360.609 401.515 345.139 387.186 349.518C372.504 354.039 355.845 380.671 355.704 397.342C355.493 397.2 355.281 396.989 355.069 396.777C347.516 389.218 354.857 370.216 358.881 360.821C364.528 347.611 392.904 338.145 402.363 341.889C409.916 345.068 408.927 360.538 405.257 374.313Z"
                fill="#9B9B9B"
              />
              <path
                d="M542.972 266.869C547.278 241.933 507.325 233.88 487.349 226.604C467.585 219.399 454.032 221.942 448.526 223.637C457.279 209.509 479.373 216.361 500.62 220.882C543.184 229.924 552.29 253.094 542.972 266.869Z"
                fill="#9B9B9B"
              />
              <path
                d="M432.504 99.8049C432.08 98.8159 431.868 97.6857 431.727 96.4848C443.656 104.396 474.221 97.9682 491.091 90.3391C503.162 84.8291 501.185 76.7761 497.444 70.9836C501.891 73.7386 505.067 77.5532 506.267 82.7806C509.091 95.7077 438.504 114.851 432.504 99.8049Z"
                fill="#9B9B9B"
              />
              <path
                d="M238.529 69.7122C236.129 71.125 233.306 70.7012 230.271 67.6636C209.447 46.8248 207.612 12.7761 218.906 7.12493C223.353 4.86444 227.235 4.15804 230.553 4.51125C225.965 5.35893 218.906 9.38542 218.906 26.0565C218.906 45.2707 228.153 61.0235 238.529 69.7122Z"
                fill="#9B9B9B"
              />
              <path
                d="M364.739 9.45596C364.103 6.2065 361.562 3.09832 353.162 5.21752C346.315 6.91289 343.704 12.1403 342.927 17.3677C341.515 16.7319 340.457 15.8136 339.892 14.6833C336.08 7.12481 360.997 -7.00324 364.456 5.21752C364.88 6.77161 364.95 8.18443 364.739 9.45596Z"
                fill="#9B9B9B"
              />
              <path
                d="M253.493 531.629C251.869 521.457 242.552 511.567 226.458 502.737C205.635 491.364 190.529 480.061 177.329 470.596C170.411 465.651 160.882 464.309 153.4 464.167C167.094 448.697 201.399 476.529 218.905 485.713C247.775 500.83 263.587 521.951 253.493 531.629Z"
                fill="#9B9B9B"
              />
              <path
                d="M424.032 492.565C420.997 485.006 411.821 481.898 396.574 481.898H343.634C323.375 481.898 322.104 493.624 322.104 494.189C321.54 492.706 321.187 491.152 320.975 489.456C319.069 472.432 354.998 475.328 373.915 474.339C409.421 472.503 423.962 482.251 424.032 492.565Z"
                fill="#9B9B9B"
              />
              <path
                d="M398.127 585.669C401.727 589.342 404.198 592.945 405.821 595.488C378.786 602.905 350.339 606.79 320.975 606.79C307.493 606.79 294.222 605.942 281.234 604.318C281.234 600.503 281.94 596.406 284.27 592.945C347.092 607.779 383.304 594.004 398.127 585.669Z"
                fill="#9B9B9B"
              />
              <path
                d="M556.877 503.655C532.525 530.004 503.795 552.256 471.89 569.21C468.29 567.444 465.043 564.83 463.772 561.086C516.501 541.307 539.301 505.492 544.665 495.814C550.03 497.722 553.983 500.547 556.877 503.655Z"
                fill="#9B9B9B"
              />
              <path
                d="M570.007 351.214C565.772 349.73 535.631 341.324 500.549 381.66C482.832 402.075 476.691 417.898 475.279 428.989C465.82 413.518 477.114 385.757 501.82 365.2C526.949 344.291 556.807 338.286 570.007 351.214Z"
                fill="#9B9B9B"
              />
              <path
                d="M589.912 189.023C582.006 186.975 571.701 174.825 568.595 156.529C566.83 145.792 572.195 135.478 579.183 132.229C576.148 136.255 573.324 142.401 574.242 150.878C575.865 164.582 581.018 178.286 589.912 189.023Z"
                fill="#9B9B9B"
              />
              <path
                d="M33.3314 428.706C31.1431 424.326 29.0255 419.805 27.0491 415.284C36.0843 407.09 44.9783 392.114 33.6843 368.45C19.708 339.134 12.9316 313.915 0.508263 308.829C0.508263 308.617 0.508194 308.476 0.437607 308.264C6.93165 307.416 15.8257 310.383 22.3197 328.679C30.7196 352.485 69.825 410.481 33.3314 428.706Z"
                fill="#9B9B9B"
              />
            </svg>

            <svg
              className="vector65"
              width="517"
              height="556"
              viewBox="0 0 517 556"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.45"
                d="M516.513 490.236C502.819 500.691 488.207 510.086 472.89 518.28C452.137 529.371 430.044 538.201 406.891 544.558C379.856 551.975 351.409 555.861 322.045 555.861C308.563 555.861 295.292 555.013 282.304 553.388C173.458 539.967 81.4124 472.011 34.4012 377.706C16.0484 340.973 4.54278 300.284 1.50752 257.264C0.942823 249.635 0.6604 241.935 0.6604 234.236C0.6604 185.706 11.3897 139.719 30.5895 98.4649C47.7422 61.5907 71.7419 28.531 100.824 0.910706C11.1779 428.779 340.892 488.046 516.513 490.236Z"
                fill="url(#paint0_linear_3_65)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_3_65"
                  x1="259.897"
                  y1="699.403"
                  x2="256.757"
                  y2="-97.6437"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop />
                  <stop offset="1" stopOpacity="0.2" />
                </linearGradient>
              </defs>
            </svg>

            <svg
              className="vector66"
              width="451"
              height="406"
              viewBox="0 0 451 406"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M450.359 322.236C450.359 341.026 448.735 359.463 445.63 377.335C443.936 386.942 441.888 396.408 439.418 405.732C428.265 147.048 253.208 -0.0253747 0.576263 27.3124C39.8934 10.1468 83.3752 0.610382 128.975 0.610382C267.538 0.610382 385.63 88.3456 430.736 211.401C441.959 242.059 448.665 274.836 450.077 309.096C450.288 313.406 450.359 317.785 450.359 322.236Z"
                fill="url(#paint0_linear_3_66)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_3_66"
                  x1="414.847"
                  y1="0.468418"
                  x2="219.166"
                  y2="217.117"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#B7B7B7" />
                  <stop
                    offset="0.557292"
                    stopColor="#AEAEAE"
                    stopOpacity="0.2"
                  />
                </linearGradient>
              </defs>
            </svg>
          </div>
        </div>
        <svg
          className="vector-191"
          width="481"
          height="709"
          viewBox="0 0 481 709"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M363.5 199.5C309 122 184.113 -47.5 59.1125 13.5C-65.8876 74.5 46 53.5 50 126C54 198.5 60.5001 215 41.5 259C4.28893 345.173 2.22468 451.5 36.6123 499C71 546.5 289.612 719 361.112 708.5C432.612 698 418.112 574 376.612 508.5C335.112 443 152.61 533.47 263.613 434C302.113 399.5 395.112 471 418.112 471C441.112 471 521.863 414.73 453.112 319C379.5 216.5 418 277 363.5 199.5Z"
            fill="#E5E5E5"
            fillOpacity="0.06"
          />
        </svg>
      </div>
    </Container>
  );
};

export default NotFoundPage;
