import BrandPickerModal from '@/components/BrandPickerModal';
import { useAudit, useTable } from '@/hooks';
import { revertRejection } from '@/services/sales-incentive.service';
import { TableContainer } from '@/styles';
import { Brand, SalesIncentiveCompletion } from '@/types';
import { formatISODate, getNameAvatarUrl } from '@/utils';
import { FilterOutlined, PlusOutlined, UndoOutlined } from '@ant-design/icons';
import {
  Avatar,
  Badge,
  Button,
  DatePicker,
  Divider,
  Drawer,
  Input,
  Modal,
  Popover,
  Space,
  Table,
  Typography,
  notification,
} from 'antd';
import dayjs from 'dayjs';
import { get, values } from 'lodash';
import { FC, Key, useEffect, useState } from 'react';
import { Case, Switch, When } from 'react-if';
import ApprovalButtons from './ApprovalButtons';
import ScanStatusTag from './ScanStatusTag';
import {
  AdvancedFilterContent,
  CommentModal,
  Container,
  TableDateFilterContent,
} from './styles';
import SubmissionDialog from './SubmissionDialog';
import Comments from './SubmissionDialog/Completion/Comments';
import { useSubmissionDialog } from './SubmissionDialog/dialog-hook';

type Args = {
  refreshCount: () => void;
};

const Approval: FC<Args> = ({ refreshCount }) => {
  const submissionDialog = useSubmissionDialog();

  const [status, setStatus] = useState('complete');
  const [textFilter, setTextFilter] = useState('');
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
  const [onFilter, setOnFilter] = useState(false);

  const [commentDialog, setCommentDialog] = useState({
    open: false,
    completionId: '',
  });

  const [brandFilterModal, setBrandFilterModal] = useState({
    open: false,
    brand: null as Brand | null,
    brandId: '',
  });

  const [advancedFilterModal, setAdvancedFilterModal] = useState({
    open: false,
    active: {
      completionId: '',
      missionId: '',
      campaignId: '',
      userId: '',
      storeId: '',
    },
    temp: {
      completionId: '',
      missionId: '',
      campaignId: '',
      userId: '',
      storeId: '',
    },
  });

  const [dateFilter, setDateFilter] = useState<any>({
    temp: {
      startDate: '',
      endDate: '',
    },
    //last 30 days by default
    value: {
      startDate: dayjs().subtract(30, 'days').format('YYYY-MM-DD'),
      endDate: dayjs().format('YYYY-MM-DD'),
    },
    componentCount: 0,
  });

  const audit = useAudit({
    resourceName: 'sales-incentive-approval-list',
  });

  const { tableProps, refresh } = useTable<SalesIncentiveCompletion>({
    service: 'completion/read',
    path: `admin/sales-incentive/list`,
    params: {
      status,
      filters: {
        text: textFilter,
        brandId: brandFilterModal.brandId,
        date: dateFilter.value,
        ...advancedFilterModal.active,
      },
    },
    defaultSort: {
      field: 'fullName',
      order: 'ascend',
    },
  });

  useEffect(() => {
    audit.onAccess();
  }, []);

  useEffect(() => {
    setSelectedRowKeys([]);
  }, [status]);

  const appContainer = () => {
    return document.getElementById('app-container') as HTMLElement;
  };

  const [revertModal, setRevertModal] = useState({
    open: false,
    completionId: '',
  });

  const revertSubmission = async () => {
    const completionId = revertModal.completionId;
    setRevertModal({ open: false, completionId: '' });

    if (completionId === '') {
      notification.error({
        message: 'Error',
        description: 'Please select a submission to revert.',
      });
      return;
    }

    const { status } = await revertRejection(completionId);
    if (status) {
      refresh();
      notification.success({
        message: 'Success',
        description: `Rejection reverted successfully for id ${completionId}. Processing might take a while.`,
      });
    } else {
      notification.error({
        message: 'Error',
        description: `Revert failed for id ${completionId}`,
      });
    }
    return;
  };

  useEffect(() => {
    const currentPageCompletionIds =
      tableProps.dataSource?.map((completion) => completion.id) || [];
    submissionDialog.setCurrentPageCompletionIds(currentPageCompletionIds);
    /* Do not include the `submissionDialog` dependency here, as we don't want
    to trigger the effect when its state changes */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableProps.dataSource]);

  return (
    <>
      <Container>
        <div className="table-filter-header">
          <Space>
            <Button
              type={status === 'complete' ? 'primary' : 'default'}
              onClick={() => setStatus('complete')}
            >
              Pending
            </Button>
            <Button
              type={status === 'approved' ? 'primary' : 'default'}
              onClick={() => setStatus('approved')}
            >
              Approved
            </Button>
            <Button
              type={status === 'rejected' ? 'primary' : 'default'}
              onClick={() => setStatus('rejected')}
            >
              Rejected
            </Button>

            <Button
              type={status === 'processing' ? 'primary' : 'default'}
              onClick={() => setStatus('processing')}
            >
              Processing
            </Button>
          </Space>

          <Space>
            <When condition={!!brandFilterModal.brand}>
              <Button
                title="Filter by Brand"
                onClick={() =>
                  setBrandFilterModal((prev) => ({ ...prev, open: true }))
                }
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
                icon={!brandFilterModal.brand ? <FilterOutlined /> : undefined}
              >
                <Space>
                  <Avatar
                    src={brandFilterModal.brand?.logoUrl}
                    size={20}
                    shape="square"
                  />
                  <Typography.Text strong style={{ fontSize: 12 }}>
                    {brandFilterModal.brand?.name}
                  </Typography.Text>

                  <Button
                    type={'link'}
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      setBrandFilterModal((prev) => ({
                        ...prev,
                        brand: null,
                        brandId: '',
                      }));
                    }}
                  >
                    Clear
                  </Button>
                </Space>
              </Button>
            </When>
            <When condition={!brandFilterModal.brand}>
              <Button
                onClick={() =>
                  setBrandFilterModal((prev) => ({ ...prev, open: true }))
                }
                icon={<FilterOutlined />}
              >
                Filter by Brand
              </Button>
            </When>

            <Popover
              content={
                <AdvancedFilterContent>
                  <section>
                    <Typography.Text strong>Completion ID</Typography.Text>
                    <Input
                      placeholder="e.g.: 5d49f2eed3e0963f3e9e3242"
                      value={advancedFilterModal.temp.completionId}
                      onChange={(e) =>
                        setAdvancedFilterModal((prev) => ({
                          ...prev,
                          temp: {
                            ...prev.temp,
                            completionId: e.target.value,
                          },
                        }))
                      }
                    />
                  </section>

                  <Divider>
                    <PlusOutlined />
                  </Divider>

                  <section>
                    <Typography.Text strong>Campaign ID</Typography.Text>
                    <Input
                      placeholder="e.g.: 5d49f2eed3e0963f3e9e3242"
                      value={advancedFilterModal.temp.campaignId}
                      onChange={(e) =>
                        setAdvancedFilterModal((prev) => ({
                          ...prev,
                          temp: {
                            ...prev.temp,
                            campaignId: e.target.value,
                          },
                        }))
                      }
                    />
                  </section>

                  <Divider>
                    <PlusOutlined />
                  </Divider>

                  <section>
                    <Typography.Text strong>Mission ID</Typography.Text>
                    <Input
                      placeholder="e.g.: 5d49f2eed3e0963f3e9e3242"
                      value={advancedFilterModal.temp.missionId}
                      onChange={(e) =>
                        setAdvancedFilterModal((prev) => ({
                          ...prev,
                          temp: {
                            ...prev.temp,
                            missionId: e.target.value,
                          },
                        }))
                      }
                    />
                  </section>

                  <Divider>
                    <PlusOutlined />
                  </Divider>

                  <section>
                    <Typography.Text strong>User ID</Typography.Text>
                    <Input
                      placeholder="e.g.: 5d49f2eed3e0963f3e9e3242"
                      value={advancedFilterModal.temp.userId}
                      onChange={(e) =>
                        setAdvancedFilterModal((prev) => ({
                          ...prev,
                          temp: {
                            ...prev.temp,
                            userId: e.target.value,
                          },
                        }))
                      }
                    />
                  </section>

                  <Divider>
                    <PlusOutlined />
                  </Divider>

                  <section>
                    <Typography.Text strong>Store ID</Typography.Text>
                    <Input
                      placeholder="e.g.: 5d49f2eed3e0963f3e9e3242"
                      value={advancedFilterModal.temp.storeId}
                      onChange={(e) =>
                        setAdvancedFilterModal((prev) => ({
                          ...prev,
                          temp: {
                            ...prev.temp,
                            storeId: e.target.value,
                          },
                        }))
                      }
                    />
                  </section>

                  <div className="footer">
                    <Button
                      type={'link'}
                      onClick={() => {
                        setAdvancedFilterModal((prev) => ({
                          ...prev,
                          open: false,
                          temp: {
                            completionId: '',
                            missionId: '',
                            campaignId: '',
                            userId: '',
                            storeId: '',
                          },
                          active: {
                            completionId: '',
                            missionId: '',
                            campaignId: '',
                            userId: '',
                            storeId: '',
                          },
                        }));
                      }}
                    >
                      Reset
                    </Button>
                    <Button
                      type="primary"
                      onClick={() => {
                        setAdvancedFilterModal((prev) => ({
                          ...prev,
                          open: false,
                          active: { ...prev.temp },
                        }));
                      }}
                    >
                      Apply
                    </Button>
                  </div>
                </AdvancedFilterContent>
              }
              trigger="click"
              open={advancedFilterModal.open}
              onOpenChange={(open) => {
                setAdvancedFilterModal((prev) => ({ ...prev, open }));
              }}
            >
              <Badge
                dot={true}
                count={
                  values(advancedFilterModal.active).filter(Boolean).length
                }
              >
                <Button icon={<FilterOutlined />}>Filter by ID's</Button>
              </Badge>
            </Popover>

            <Input.Search
              className="search-input"
              placeholder="Search by campain, user or mission name"
              allowClear
              onSearch={setTextFilter}
            />
          </Space>
        </div>

        <TableContainer>
          <Table
            {...tableProps}
            rowKey={'id'}
            className={'clickable'}
            onRow={(record: SalesIncentiveCompletion) => ({
              onClick: () => submissionDialog.openDialog(record),
            })}
            rowSelection={
              status !== 'complete'
                ? undefined
                : {
                    selectedRowKeys,
                    onChange: (keys) => {
                      setSelectedRowKeys(keys);
                    },
                  }
            }
          >
            <Table.Column
              title="User"
              dataIndex={'user.fullName'}
              sorter
              render={(_, record: SalesIncentiveCompletion) => (
                <Space>
                  <Avatar
                    src={
                      record.user.avatarUrl ||
                      getNameAvatarUrl(record.user.fullName)
                    }
                    size={60}
                  />
                  <Space.Compact direction={'vertical'}>
                    <Typography.Text strong>
                      {record.user.fullName}
                    </Typography.Text>
                    <Typography.Text type={'secondary'}>
                      {record.user.email}
                    </Typography.Text>
                  </Space.Compact>
                </Space>
              )}
            />

            <Table.Column
              title="Brand"
              dataIndex={'brand.name'}
              sorter
              render={(_, record: SalesIncentiveCompletion) => {
                if (!record.brand) {
                  return <span className="inactive-text">(not set)</span>;
                }

                return (
                  <Space>
                    <Avatar
                      src={record.brand.logoUrl}
                      size={50}
                      shape="square"
                    />
                    <Space.Compact direction={'vertical'}>
                      <Typography.Text strong>
                        {record.brand.name}
                      </Typography.Text>
                    </Space.Compact>
                  </Space>
                );
              }}
            />

            <Table.Column
              title="Type"
              dataIndex={['bundle.type']}
              sorter
              filters={[
                { text: 'Retail Ops (General)', value: 'general' },
                { text: 'Sales Incentive (Contest)', value: 'contest' },
              ]}
              render={(_, record: SalesIncentiveCompletion) => (
                <Switch>
                  <Case condition={record.bundle.type === 'general'}>
                    Retail Ops (General)
                  </Case>
                  <Case condition={record.bundle.type === 'learning'}>
                    Digital Learning (Learning)
                  </Case>
                  <Case condition={record.bundle.type === 'contest'}>
                    Sales Incentive (Contest)
                  </Case>
                </Switch>
              )}
            />

            <Table.Column
              title="Smart Review"
              dataIndex={['scanState']}
              sorter
              filterMode="tree"
              filters={[
                {
                  text: 'Processor',
                  value: 'processor',
                  children: [
                    { text: 'Verify', value: 'processor-verify' },
                    { text: 'Azure', value: 'processor-azure' },
                    { text: 'Hive', value: 'processor-hive' },
                  ],
                },
                {
                  text: 'Smart Review Status',
                  value: 'status',
                  children: [
                    { text: 'Take a look', value: 'status-toReview' },
                    { text: 'Looks good', value: 'status-good' },
                    { text: 'Failed', value: 'status-failed' },
                    { text: 'In Queue', value: 'status-inQueue' },
                    { text: 'Not Requested', value: 'status-notRequested' },
                  ],
                },
              ]}
              render={(_, record: SalesIncentiveCompletion) => {
                if (record.bundle.type !== 'contest' || !record.hasScan) {
                  return <span className="inactive-text">(Not available)</span>;
                }

                return <ScanStatusTag completion={record} />;
              }}
            />

            <Table.Column
              title="Campaign"
              dataIndex={'bundle.name'}
              sorter
              render={(_, record: SalesIncentiveCompletion) =>
                get(
                  record,
                  'bundle.name',
                  <span className="inactive-text">(not set)</span>,
                )
              }
            />

            <Table.Column
              title="Store"
              dataIndex={'user.fullName'}
              sorter
              render={(_, record: SalesIncentiveCompletion) => (
                <Space.Compact direction={'vertical'}>
                  <Typography.Text strong>{record.store.name}</Typography.Text>
                  <Typography.Text type={'secondary'}>
                    {record.store.address.formattedAddress}
                  </Typography.Text>
                </Space.Compact>
              )}
            />

            <Table.Column
              title="Date & time"
              dataIndex={['completedAt']}
              filterDropdownOpen={onFilter}
              onFilterDropdownOpenChange={(open) => {
                if (open) setOnFilter(true);
              }}
              sorter
              render={(value) => formatISODate(value)}
              filterDropdown={() => {
                return (
                  <TableDateFilterContent>
                    <div className="title-filter">Filter by date range</div>
                    <DatePicker.RangePicker
                      key={`datepicker-range-${dateFilter.componentCount}`}
                      onChange={(dates, datesString: string[]) => {
                        setDateFilter((prev: any) => ({
                          ...prev,
                          temp: {
                            startDate: datesString[0],
                            endDate: datesString[1],
                          },
                        }));
                      }}
                      defaultValue={[
                        dayjs(dateFilter.value.startDate),
                        dayjs(dateFilter.value.endDate),
                      ]}
                    />

                    <div className="actions">
                      <Button
                        size="small"
                        onClick={() => {
                          setDateFilter((prev: any) => ({
                            ...prev,
                            temp: {
                              startDate: '',
                              endDate: '',
                            },
                            value: {
                              startDate: '',
                              endDate: '',
                            },
                            componentCount: prev.componentCount + 1,
                          }));
                        }}
                      >
                        Reset
                      </Button>
                      <Button
                        size="small"
                        type="primary"
                        onClick={() => {
                          setDateFilter((prev: any) => ({
                            ...prev,
                            value: prev.temp,
                          }));
                          setOnFilter(false);
                        }}
                      >
                        Apply
                      </Button>
                    </div>
                  </TableDateFilterContent>
                );
              }}
            />

            {status === 'rejected' && (
              <Table.Column
                title="Action"
                key="action"
                render={(_, record: any) => (
                  <Button
                    icon={<UndoOutlined size={20} />}
                    onClick={(e) => {
                      setRevertModal({ open: true, completionId: record.id });
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                    style={{ alignItems: 'center', display: 'flex' }}
                  >
                    Revert
                  </Button>
                )}
              />
            )}

            {/* <Table.Column
              render={(_, record: SalesIncentiveCompletion) => (
                <TableCommentButton
                  type="text"
                  icon={<WechatOutlined />}
                  onClick={(e) => {
                    e.stopPropagation();

                    setCommentDialog({
                      open: true,
                      completionId: record.id,
                    });
                  }}
                />
              )}
            /> */}
          </Table>
        </TableContainer>
      </Container>
      <SubmissionDialog
        onClose={() => submissionDialog.closeDialog()}
        disableApproval={selectedRowKeys.length > 0}
        onRefresh={() => {
          setTimeout(() => refresh(), 100);
        }}
      />

      <Modal
        title="Revert Rejection"
        open={revertModal.open}
        onOk={() => revertSubmission()}
        onCancel={() => {
          setRevertModal({ open: false, completionId: '' });
        }}
      >
        <Typography.Text>
          <strong>Are you sure you want to Revert Rejection?</strong>
          <br />
          This action will send the completion in Pending status waiting for
          Approval/Rejection.
        </Typography.Text>
      </Modal>

      <BrandPickerModal
        open={brandFilterModal.open}
        onClose={() =>
          setBrandFilterModal((prev) => ({ ...prev, open: false }))
        }
        selected={[brandFilterModal.brandId]}
        multiple={false}
        onSelect={(brandsId, brands) => {
          setBrandFilterModal((prev) => ({
            ...prev,
            brand: brands[0] || null,
            brandId: brandsId[0] || '',
          }));
        }}
        removeSelected={false}
      />
      <CommentModal
        title={'Submission Comments'}
        open={commentDialog.open}
        onCancel={() => setCommentDialog({ open: false, completionId: '' })}
        destroyOnClose
        footer={null}
        width={580}
        centered
      >
        {commentDialog.open && commentDialog.completionId && (
          <Comments completionId={commentDialog.completionId} />
        )}
      </CommentModal>
      <Drawer
        rootClassName={'table-footer-drawer'}
        mask={false}
        closable={false}
        open={selectedRowKeys.length > 0}
        placement={'bottom'}
        getContainer={appContainer}
        height={74}
      >
        <div>
          <Typography.Text strong className="selected-count">
            {selectedRowKeys.length} selected
          </Typography.Text>
          <Button
            type={'link'}
            onClick={() => {
              setSelectedRowKeys([]);
            }}
          >
            Clear Selection
          </Button>
        </div>
        <ApprovalButtons
          completionsId={selectedRowKeys as string[]}
          onCompleted={() => {
            refresh();
            refreshCount();
          }}
        />
      </Drawer>
    </>
  );
};

export default Approval;
