import React, { useEffect, useState } from 'react';
import { Spin } from 'antd';
import axios from 'axios';

interface LoadingBlockerProps {
  children: React.ReactNode;
}

const LoadingBlocker: React.FC<LoadingBlockerProps> = ({ children }) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Add an interceptor to axios to track requests
    const requestInterceptor = axios.interceptors.request.use(
      (config) => {
        setLoading(true);
        return config;
      },
      (error) => {
        setLoading(false);
        return Promise.reject(error);
      },
    );

    // Add an interceptor to axios to track responses
    const responseInterceptor = axios.interceptors.response.use(
      (response) => {
        setLoading(false);
        return response;
      },
      (error) => {
        setLoading(false);
        return Promise.reject(error);
      },
    );

    // Cleanup interceptors on component unmount
    return () => {
      axios.interceptors.request.eject(requestInterceptor);
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, []);

  return (
    <>
      <Spin spinning={loading}>{children}</Spin>
    </>
  );
};

export default LoadingBlocker;
