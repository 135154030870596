import Layout from '@/components/Layout';
import PageHeader from '@/components/PageHeader';
import { Badge, Button, Col, Row, Statistic, Tabs } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import { useEffect, useMemo, useState } from 'react';
import { Container } from './styles';
import { useParams, useNavigate } from 'react-router-dom';
import { getListDetails } from '@/services/queue.service';
import { DateTime } from 'luxon';
import StatusList from '../Job/list';
import { useAudit } from '@/hooks';

export const ListDetails = () => {
  const [status, setStatus] = useState('pending');
  const navigate = useNavigate();
  const { id } = useParams();
  const [counter, setCounter] = useState(0);

  const [details, setDetails] = useState({
    id: '',
    name: '',
    averageDuration: 0,
    totalFailedJobs: 0,
    totalJobs: 0,
    totalPendingJobs: 0,
    totalSuccessJobs: 0,
    lastActivityAt: 0,
    totalRetryingJobs: 0,
  });

  if (!id) {
    navigate('/dev/queue');
  }

  const audit = useAudit({
    resourceName: 'dev-queue-list-details',
  });

  useEffect(() => {
    audit.onAccess();
    loadDetails(id);
  }, []);

  const refresh = () => {
    setCounter((prev) => prev + 1);
    loadDetails(id);
  };

  const lastActivity = useMemo(() => {
    let lastActivity: any = '-';
    if (details.lastActivityAt) {
      let date = new Date(details.lastActivityAt * 1000).toISOString();
      lastActivity = DateTime.fromISO(date).toRelative();
    }

    return lastActivity;
  }, [details.lastActivityAt]);

  const loadDetails = async (id: string | undefined) => {
    if (!id) {
      return;
    }

    const data = await getListDetails(navigate, id);

    setDetails((prev) => ({
      ...prev,
      ...data,
    }));
  };

  return (
    <Layout>
      <PageHeader title={details.name}>
        <Button type="primary" icon={<ReloadOutlined />} onClick={refresh}>
          Refresh
        </Button>
      </PageHeader>

      <Row gutter={16}>
        <Col span={5}>
          <Statistic title="Total Jobs" value={details.totalJobs} />
        </Col>

        <Col span={5}>
          <Statistic
            title="Average Duration"
            value={`${Number(details.averageDuration).toFixed(5)}ms`}
          />
        </Col>

        <Col span={5}>
          <Statistic title="Last Activity" value={lastActivity} />
        </Col>

        <Col span={5}>
          <Statistic title="Total Retrying Jobs" value={details.totalRetryingJobs} />
        </Col>
      </Row>

      <br style={{ marginBottom: 6 }} />

      <Container>
        <Tabs onChange={(activeKey: string) => setStatus(activeKey)}>
          <Tabs.TabPane
            tab={
              <div>
                <span>Pending</span>{' '}
                <Badge
                  count={details.totalPendingJobs}
                  showZero
                  overflowCount={999999}
                  color={status === 'pending' ? '#6f56bc' : 'grey'}
                />
              </div>
            }
            key="pending"
          />
          <Tabs.TabPane
            tab={
              <div>
                <span>Success</span>{' '}
                <Badge
                  count={details.totalSuccessJobs}
                  showZero
                  overflowCount={999999}
                  color={status === 'success' ? '#6f56bc' : 'grey'}
                />
              </div>
            }
            key="success"
          />
          <Tabs.TabPane
            tab={
              <div>
                <span>Failed</span>{' '}
                <Badge
                  count={details.totalFailedJobs}
                  showZero
                  overflowCount={999999}
                  color={status === 'failed' ? '#6f56bc' : 'grey'}
                />
              </div>
            }
            key="failed"
          />
        </Tabs>

        <StatusList
          status={status}
          listName={id}
          key={`list-${status}-${id}`}
        />
      </Container>
    </Layout>
  );
};

export default ListDetails;
