import { FC, useEffect, useState } from 'react';
import Layout from '@/components/Layout';
import PageHeader from '@/components/PageHeader';
import { Badge, Space, Tabs } from 'antd';

import Consumer from './Consumer';
import SalesIncentive from './SalesIncentive';
import ManagerSubmissions from './ManagerSubmissions';

import { getPendingCount } from '@/services/consumer.service';
import { getStatusSummary, getManagerSubmissionsStatusSummary } from '@/services/sales-incentive.service';
import { useSession } from '@/store';

const MissionApprovalList: FC = () => {
  const session = useSession();
  const [activeTab, setActiveTab] = useState('sales-incentive');
  const [pendingCount, setPendingCount] = useState({
    consumer: 0,
    salesIncentive: 0,
    managerSubmissions: 0,
  });

  const loadPendingCount = async () => {
    await Promise.all([
      loadConsumerPendingCount(),
      loadSalesIncentivePendingCount(),
      loadManagerSubmissionsPendingCount(),
    ]);
  };

  const loadConsumerPendingCount = async () => {
    try {
      const { total = 0 } = await getPendingCount();

      setPendingCount((prev) => ({
        ...prev,
        consumer: total,
      }));
    } catch (e) {}
  };

  const loadManagerSubmissionsPendingCount = async () => {
    try {
      const summary = await getManagerSubmissionsStatusSummary();

      setPendingCount((prev) => ({
        ...prev,
        managerSubmissions: summary.complete,
      }));
    } catch (e) {}
  }


  const loadSalesIncentivePendingCount = async () => {
    try {
      const { summary = {} } = await getStatusSummary();

      setPendingCount((prev) => ({
        ...prev,
        salesIncentive: summary.complete,
      }));
    } catch (e) {}
  };

  useEffect(() => {
    loadPendingCount();
  }, []);

  return (
    <Layout>
      <PageHeader noBackButton title="Missions Approval" />

      <Tabs
        defaultActiveKey={'sales-incentive'}
        activeKey={activeTab}
        onChange={(key) => setActiveTab(key)}
        destroyInactiveTabPane
        type="card"
        items={[
          {
            key: 'sales-incentive',
            label: (
              <Space>
                <span>Retail Completion</span>
                <Badge
                  count={pendingCount.salesIncentive}
                  overflowCount={9999}
                  color={
                    activeTab === 'sales-incentive' ? '#B5A7DC' : '#BFBFBF'
                  }
                  showZero
                />
              </Space>
            ),
            children: <SalesIncentive refreshCount={loadPendingCount} />,
            disabled: !session.hasPermission('sales_incentive_completion.view'),
          },
          {
            key: 'manager-submissions',
            label: (
              <Space>
                <span>Manager Submissions</span>
                <Badge
                  count={pendingCount.managerSubmissions}
                  overflowCount={9999}
                  color={
                    activeTab === 'manager-submissions' ? '#B5A7DC' : '#BFBFBF'
                  }
                  showZero
                />
              </Space>
            ),
            children: <ManagerSubmissions refreshCount={loadPendingCount} />,
            disabled: !session.hasPermission('sales_incentive_completion.view'),
          },
          {
            key: 'consumer-marketing',
            label: (
              <Space>
                <span>Consumer Marketing</span>
                <Badge
                  count={pendingCount.consumer}
                  overflowCount={9999}
                  color={
                    activeTab === 'consumer-marketing' ? '#B5A7DC' : '#BFBFBF'
                  }
                  showZero
                />
              </Space>
            ),
            children: <Consumer refreshCount={loadPendingCount} />,
            disabled: !session.hasPermission('consumer_completion.view'),
          },
        ]}
      />
    </Layout>
  );
};

export default MissionApprovalList;
