import React, { createContext, useState, useContext, ReactNode } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

export interface ConfirmationContextType {
  confirmationResult: firebase.auth.ConfirmationResult | null;
  setConfirmationResult: React.Dispatch<
    React.SetStateAction<firebase.auth.ConfirmationResult | null>
  >;
}

const ConfirmationContext = createContext<ConfirmationContextType | null>(null);

export const useConfirmation = (): ConfirmationContextType => {
  const context = useContext(ConfirmationContext);
  if (!context) {
    throw new Error(
      'useConfirmation must be used within a ConfirmationProvider',
    );
  }
  return context;
};

interface ConfirmationProviderProps {
  children: ReactNode;
}

export const ConfirmationProvider: React.FC<ConfirmationProviderProps> = ({
  children,
}) => {
  const [confirmationResult, setConfirmationResult] =
    useState<firebase.auth.ConfirmationResult | null>(null);

  return (
    <ConfirmationContext.Provider
      value={{ confirmationResult, setConfirmationResult }}
    >
      {children}
    </ConfirmationContext.Provider>
  );
};
