import {
  uploadStoresList,
  getList,
  uploadStoreFileOnlyWithCsv,
} from '@/services/import.service';
import { useSession } from '@/store';
import { InboxOutlined } from '@ant-design/icons';
import {
  Button,
  Progress,
  Select,
  Table,
  Tag,
  Upload,
  UploadProps,
  notification,
} from 'antd';
import { FC, useEffect, useState } from 'react';
import { When } from 'react-if';
import LoadingPage from '../../Loading';
import { TableContainer, TabsContainer } from './styles';
import styled from 'styled-components';
import { useMutation } from '@tanstack/react-query';

const { Dragger } = Upload;

type FileProps = {
  _id: string;
  adminUser: string;
  adminUserId: string;
  createdAt: string;
  fileUrl: string;
  fileName: string;
  hiveRequestId: string;
  hiveRequestStatus: string;
  progress?: number;
};

type UploadType = 'ai' | 'template';

const ImportTab: FC = () => {
  const session = useSession();
  const [list, setList] = useState([] as FileProps[]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedUploadType, setSelectedUploadType] =
    useState<UploadType>('ai');

  useEffect(() => {
    getFileList();
  }, []);

  const getFileList = async () => {
    setIsLoading(true);
    const filters = {
      status: 'pending',
    };

    const { status, items } = await getList(filters);
    if (status) {
      setList(items);
    }

    setIsLoading(false);
  };

  const { mutate: handleUploadFile, isPending: isUploading } = useMutation({
    mutationFn: async ({ file, type }: { file: Blob; type: UploadType }) => {
      const formData = new FormData();
      formData.append('file', file);

      let result;
      if (type === 'ai') {
        result = await uploadStoresList(formData);
      } else {
        result = await uploadStoreFileOnlyWithCsv(formData);
      }

      if (!result.status) {
        throw new Error(result.message);
      }
    },
    onSuccess: async () => {
      await getFileList();
    },
    onError: (error) => {
      notification.error({ message: 'Error', description: error.message });
    },
  });

  const props: UploadProps = {
    name: 'file',
    multiple: false,
    showUploadList: false,
    accept: '.xlsx, .xls, .csv',
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
    customRequest: async ({ onSuccess, onError, file, onProgress }) => {
      handleUploadFile({ file: file as Blob, type: selectedUploadType });
    },
  };

  return (
    <TabsContainer>
      <When condition={isUploading}>
        <LoadingPage />
      </When>

      <When condition={!isUploading && session.hasPermission('import.edit')}>
        <UploadHeader>
          {selectedUploadType === 'template' && (
            <Button
              type="primary"
              href="https://endvr.nyc3.cdn.digitaloceanspaces.com/uploads/endvr-stores-upload-template.xlsx"
            >
              Click here to Download Template
            </Button>
          )}
          <Select
            style={{ minWidth: '11.5rem', textAlign: 'center' }}
            value={selectedUploadType}
            options={[
              { label: 'Upload using AI', value: 'ai' },
              { label: 'Upload using template', value: 'template' },
            ]}
            onChange={(value) => setSelectedUploadType(value)}
          />
        </UploadHeader>

        <div className="upload-area">
          <Dragger {...props} style={{ height: 300, background: '#fff' }}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
            <p className="ant-upload-hint">
              Supported file types: .xlsx, .xls, .csv
            </p>
          </Dragger>
        </div>
      </When>
      <TableContainer>
        <Table
          className="table"
          dataSource={list}
          rowKey="_id"
          loading={isLoading}
        >
          <Table.Column<FileProps>
            title="File Name"
            dataIndex="fileName"
            key="fileName"
            render={(text, record) => {
              const { fileName, fileUrl } = record;
              return (
                <a href={fileUrl} target="_blank" rel="noreferrer">
                  {fileName}
                </a>
              );
            }}
          />
          <Table.Column<FileProps>
            title="Status"
            dataIndex="hiveRequestStatus"
            align="center"
            render={(hiveRequestStatus) => {
              return (
                <>
                  <When condition={hiveRequestStatus === 'pending'}>
                    <Tag color="warning">Pending</Tag>
                  </When>
                  <When condition={hiveRequestStatus === 'success'}>
                    <Tag color="success">Success</Tag>
                  </When>
                  <When condition={hiveRequestStatus === 'error'}>
                    <Tag color="error">Error</Tag>
                  </When>
                </>
              );
            }}
          />
          <Table.Column<FileProps>
            title="Requested User"
            dataIndex="adminUser"
            align="center"
          />
          <Table.Column<FileProps>
            title="Progress"
            dataIndex="progress"
            align="center"
            render={(progress) => {
              return <Progress percent={Math.round(progress || 0)} />;
            }}
          />
          <Table.Column<FileProps>
            title="Created At"
            dataIndex="createdAt"
            align="right"
            render={(createdAt) => {
              return new Date(createdAt).toLocaleString();
            }}
          />
        </Table>
      </TableContainer>
    </TabsContainer>
  );
};

const UploadHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
`;

export default ImportTab;
