import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;

  .table-filter-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .search-input {
      width: 400px;
    }
  }

  .nested-table-container {
    width: 100%;
    //display: flex;
    //flex-direction: column;
    //align-items: center;
    //
    //table {
    //  //width: 90%;
    //}
  }
`;

export const ApprovalButtonsContainer = styled.div`
  display: inline-flex;
  align-items: center;
  column-gap: 8px;

  button {
    display: flex;
    column-gap: 8px;
    align-items: center;

    .ant-btn-icon {
      font-size: 18px;
    }
  }
`;
