import { useTable } from '@/hooks';
import { Store } from '@/types';
import { Avatar, Button, Input, Space, Table, Typography } from 'antd';
import { Key, useEffect, useState } from 'react';
import { IoPlanetOutline } from 'react-icons/io5';
import { ModalFooter, PickerContainer, TableContainer } from './styles';
import { set } from 'lodash';

type StorePickerProps = {
  selected: string[];
  brandId?: string;
  onClose: () => void;
  onConfirm: (selectedIds: string[]) => void;
};

const StorePicker = ({
  selected,
  brandId,
  onClose,
  onConfirm,
}: StorePickerProps) => {
  const [filter, setFilter] = useState('');
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);

  useEffect(() => {
    setSelectedRowKeys(selected);
  }, [selected]);

  const { tableProps } = useTable<Store>({
    service: 'store/read',
    path: `admin/list`,
    perPage: 5,
    params: {
      status: 'approved',
      collection: 'stores',
      selected,
      filters: {
        text: filter,
        brandId: brandId,
      },
    },
  });

  const handleOnConfirm = () => {
    onConfirm(selectedRowKeys.map((key) => key.toString()));
    setSelectedRowKeys([]);
  };

  return (
    <PickerContainer>
      <Input.Search
        placeholder="Search by name"
        allowClear
        onSearch={(value) => {
          setFilter(value);
        }}
        style={{ width: '100%', marginBottom: 24 }}
      />

      <StorePickerPrimitive
        tableProps={tableProps}
        multiple
        selectedRowKeys={selectedRowKeys}
        setSelectedRowKeys={setSelectedRowKeys}
      />

      <ModalFooter>
        <span>{selectedRowKeys.length} selected</span>
        <div className="actions">
          <Button
            onClick={() => {
              setSelectedRowKeys([]);
              onClose();
            }}
          >
            Cancel
          </Button>
          <Button type="primary" onClick={handleOnConfirm}>
            Confirm
          </Button>
        </div>
      </ModalFooter>
    </PickerContainer>
  );
};

type StorePickerPrimitiveProps = {
  tableProps: ReturnType<typeof useTable<Store>>['tableProps'];
} & (
  | {
      multiple: true;
      selectedRowKeys: React.Key[];
      setSelectedRowKeys: (keys: React.SetStateAction<React.Key[]>) => void;
    }
  | {
      multiple?: false;
      selectedRowKeys: React.Key | undefined;
      setSelectedRowKeys: (
        key: React.Key | undefined,
        row: Store | undefined,
      ) => void;
    }
);

export const StorePickerPrimitive = ({
  tableProps,
  multiple,
  selectedRowKeys,
  setSelectedRowKeys,
}: StorePickerPrimitiveProps) => {
  return (
    <TableContainer>
      <Table
        {...tableProps}
        pagination={{
          ...tableProps.pagination,
          showSizeChanger: false,
        }}
        rowKey="id"
        size="small"
        onRow={(record) => ({
          onClick: () => {
            if (!multiple) {
              setSelectedRowKeys(record.id, record);
              return;
            }

            if (selectedRowKeys.includes(record.id)) {
              setSelectedRowKeys((prev) =>
                prev.filter((key) => key !== record.id),
              );
            } else {
              setSelectedRowKeys([...selectedRowKeys, record.id]);
            }
          },
        })}
        rowSelection={{
          selectedRowKeys: multiple
            ? selectedRowKeys
            : selectedRowKeys !== undefined
            ? [selectedRowKeys]
            : [],
          type: multiple ? 'checkbox' : 'radio',
          preserveSelectedRowKeys: true,
          hideSelectAll: false,
          onChange: (keys, rows) => {
            if (multiple) {
              setSelectedRowKeys(keys);
              return;
            }
            setSelectedRowKeys(keys[0], rows[0]);
          },
        }}
      >
        <Table.Column
          title="Stores"
          dataIndex="name"
          key="name"
          sorter
          render={(text, record: Store) => (
            <Space.Compact>
              <Avatar
                src={record.logoUrl}
                style={{ marginRight: 10 }}
                size={44}
                shape="square"
                icon={<IoPlanetOutline />}
              />

              <Space.Compact
                direction="vertical"
                style={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  justifyContent: 'center',
                }}
              >
                <Typography.Text
                  className="name"
                  ellipsis
                  strong
                  style={{ fontSize: 16, lineHeight: '22px' }}
                >
                  {record.name}
                </Typography.Text>
              </Space.Compact>
            </Space.Compact>
          )}
        />
      </Table>
    </TableContainer>
  );
};

export default StorePicker;
