import { IAddress } from '@/types';
import { httpGet, httpPost, httpPut } from './util/axios';

export const getImportFileDetails = async (fileId: string): Promise<any> => {
  if (!fileId) {
    return {
      status: false,
    };
  }

  return (await httpGet('import', `admin/import-file-details/${fileId}`)) as
    | { status: false; message: string }
    | {
        status: true;
        data: {
          id: string;
          fileName: string;
          storesWithAddressNotFound: number;
          storesWithLowScore: number;
          storesWithMediumScore: number;
          storesWithHighScore: number;
          storesWithNoConflict: number;
          /** This is not specific to an import file. It is only included here
           * to guarantee the back-end and front-end have the same weights. */
          scoreWeights: Record<string, number>;
        };
      };
};

export const getCampaignStoresImportResult = async (fileId: string) => {
  return httpGet(
    'import',
    `admin/campaign-import-result?importFileId=${fileId}`,
  );
};

export const uploadStoresList = async (data: FormData) => {
  return (await httpPost('import', `admin/upload-stores-file`, data)) as
    | { status: false; message: string }
    | { status: true };
};

export const uploadStoreFileOnlyWithCsv = async (data: FormData) => {
  return (await httpPost(
    'import',
    `admin/upload-store-file-only-with-csv`,
    data,
  )) as { status: false; message: string } | { status: true };
};

export const getList = async (filters: any) => {
  return httpPost('import', `admin/list`, filters);
};

export const importStores = async ({
  brandIds,
  storeObjects,
  strategy,
}: {
  brandIds: string[];
  storeObjects: {
    importedStoreId: string;
    storeId?: string;
  }[];
  strategy?: 'useReceived' | 'useBestMatch' | 'createNew';
}) => {
  return (await httpPost('import', `admin/request-import-stores`, {
    brandIds,
    storeObjects,
    strategy,
  })) as { status: false; message: string } | { status: true };
};

export const importSingleStore = async ({
  brandIds,
  storeObject,
}: {
  brandIds: string[];
  storeObject: {
    importedStoreId: string;
    storeId?: string;
  };
}) => {
  return (await httpPost('import', `admin/request-import-single-store`, {
    brandIds,
    storeObject,
  })) as { status: false; message: string } | { status: true };
};

export const setSimilarStores = async ({
  id,
  name,
  address,
}: {
  id: string;
  name: string;
  address: IAddress;
}) => {
  return (await httpPost('import', `admin/set-similar-stores`, {
    id,
    name,
    address,
  })) as { status: false; message: string } | { status: true };
};

export const updateFileImportedStoresStatus = async (fileId: string) => {
  return httpPut(
    'import',
    `admin/update-file-imported-stores-status?fileId=${fileId}`,
  );
};
