import Layout from '@/components/Layout';
import PageHeader from '@/components/PageHeader';
import { Tabs } from 'antd';
import { FC, useState } from 'react';
import ImportTab from './ImportTab';
import { Container, TabsControlContainer } from './styles';
import ProcessedTab from './ProcessedTab';

const DealerImport: FC = () => {
  const [tab, setTab] = useState('tab-processing');

  return (
    <Layout>
      <PageHeader noBackButton title="Dealer Import" />
      <Container>
        <TabsControlContainer>
          <Tabs
            items={[
              {
                key: 'tab-processing',
                label: 'Ai Processing',
              },
              {
                key: 'tab-processed',
                label: 'Processed',
              },
            ]}
            onChange={(tab: string) => setTab(tab)}
          />
        </TabsControlContainer>
        {tab === 'tab-processing' && <ImportTab />}
        {tab === 'tab-processed' && <ProcessedTab />}
      </Container>
    </Layout>
  );
};

export default DealerImport;
