import { createDraftConnector } from '@/services/integration-connector.service';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Popconfirm } from 'antd';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const CreateButton = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const showPopconfirm = () => {
    setOpen(true);
  };

  const handleOk = async () => {
    setConfirmLoading(true);

    const { id } = await createDraftConnector();

    setTimeout(() => {
      setOpen(false);
      setConfirmLoading(false);

      setTimeout(() => navigate(`/integration/connector/${id}/edit`), 300);
    }, 1000);
  };

  const handleCancel = () => {
    console.log('Clicked cancel button');
    setOpen(false);
  };

  return (
    <Popconfirm
      title="Create a new connector?"
      description={
        <div style={{ maxWidth: 300, fontSize: 12 }}>
          If confirmed, a draft connector will be created and you will be
          directed to the edit page, the record will only be visible and
          available for use when it is fully configured
        </div>
      }
      open={open}
      onConfirm={handleOk}
      okButtonProps={{ loading: confirmLoading }}
      onCancel={handleCancel}
    >
      <Button type="primary" icon={<PlusOutlined />} onClick={showPopconfirm}>
        Create Connector
      </Button>
    </Popconfirm>
  );
};

export default CreateButton;
