import { TableContainer } from '@/styles';
import styled from 'styled-components';
import { Alert, Space, Switch } from 'antd';

export const Container = styled(TableContainer)`
  .user-row {
    .info {
      gap: 0 !important;

      h5 {
        margin-bottom: 0;
      }
    }
  }

  .store-row {
    .info {
      gap: 0 !important;
      width: 300px;

      .address {
        font-size: 12px;
        max-width: 300px;
      }
    }
  }
`;

export const RiskLevelSwitch = styled(Switch)`
  height: 40px;

  .ant-switch-inner {
    height: auto;
    padding: 8px;
    width: 150px;

    > span {
      font-size: 16px !important;
      font-weight: bold;
    }
  }

  .ant-switch-handle {
    top: 5px;
    inset-inline-start: 5px;
    width: 30px;
    height: 30px;

    &::before {
      border-radius: 15px;
    }
  }

  background: #44a844;

  &.ant-switch-checked {
    background: #e27e7e !important;

    .ant-switch-handle {
      inset-inline-start: calc(100% - 36px);
    }
  }
`;

export const RiskLevelHighAlert = styled(Alert)`
  width: 100%;

  .ant-alert-description {
    ul {
      padding-left: 18px;
    }
  }
`;

export const ToolbarWrapper = styled(Space)`
  position: fixed;
  bottom: 32px;
  left: 50%;
  padding: 16px 24px;
  color: #fff;
  font-size: 20px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 100px;
  transform: translateX(-50%);

  .toolbar-wrapper .anticon {
    padding: 12px;
    cursor: pointer;
  }

  .toolbar-wrapper .anticon[disabled] {
    cursor: not-allowed;
    opacity: 0.3;
  }

  .toolbar-wrapper .anticon:hover {
    opacity: 0.3;
  }
`;
