import { useAudit, useTable } from '@/hooks';
import { TableContainer } from '@/styles';
import {
  Button,
  Table,
  Typography,
  Form,
  Input,
  Space,
  Avatar,
  notification,
  Modal,
} from 'antd';
import { formatISODate } from '@/utils';
import { CheckOutlined, PlusOutlined, StopOutlined } from '@ant-design/icons';
import { useEffect, useLayoutEffect, useState } from 'react';
import { useSession } from '@/store';
import { SiStackedit } from 'react-icons/si';
import { Unlink } from 'lucide-react';

import {
  updateBrandsAssignment,
  type UpdateBrandsAssignmentAction,
} from '@/services/store.service';
import { useStateWithRef } from '@/hooks/use-state-with-ref';

import { ButtonLink } from '@/components/ButtonLink';
import BrandPickerModal from '@/components/BrandPickerModal';

type TableData = {
  id: string;
  name: string;
  logoUrl: string;
  status: string;
  connectedAt: string;
  storeBrandId: string;
  totalLiveMission: {
    contest: number;
    general: number;
    learning: number;
  };
  /** Whether the brand is the owner of the store. */
  isOwnerBrand: boolean;
};

type Params = {
  storeId: string;
  isExclusiveStore: boolean;
};

const BrandsTab = ({ storeId, isExclusiveStore }: Params) => {
  const audit = useAudit({
    resourceName: 'store-brands-tab',
  });

  const session = useSession();
  const [form] = Form.useForm();
  const [filter, setFilter] = useState('');
  const [status, statusRef, setStatus] = useStateWithRef<
    'approved' | 'pending' | 'blocked'
  >('approved');
  /* The status of the brands loaded in the table, which is used to determine
  which buttons are visible (approve/block buttons) */
  const [loadedBrandsStatus, setLoadedBrandsStatus] = useState<
    'approved' | 'pending' | 'blocked'
  >('approved');

  const { tableProps, refresh } = useTable<TableData>({
    service: 'store/read',
    path: `admin/${storeId}/brand-list`,
    params: {
      status,
      filters: {
        text: filter,
      },
    },
    defaultSort: {
      field: 'brandName',
      order: 'ascend',
    },
  });

  const [assignBrandsModalOpen, setAssignBrandsModalOpen] = useState(false);

  const [updateBrandAssignmentModalData, setUpdateBrandAssignmentModalData] =
    useState<{
      open: boolean;
      brandId: string;
      action: UpdateBrandsAssignmentAction;
    }>({ open: false, brandId: '', action: 'approve' });

  const onUpdateBrandsAssignment = async (
    brandIds: string[],
    action: UpdateBrandsAssignmentAction | undefined,
  ) => {
    /* The action is always provided when the `storeId` prop is provided to the
    modal, which is the case for this component. */
    if (!action) return;

    let result;
    try {
      result = await updateBrandsAssignment({ action, storeId, brandIds });
    } catch (error) {}

    if (!result || result.status === false) {
      notification.error({
        message: 'Error',
        description:
          result && 'message' in result
            ? result.message
            : 'Could not update brand(s) assignment for unknown reasons',
      });
      return;
    }

    const pastTenseActions = {
      approve: 'approved',
      block: 'blocked',
      unlink: 'unlinked',
    } satisfies Record<UpdateBrandsAssignmentAction, string>;

    if (result.failed === 0) {
      notification.success({
        message: 'Success',
        description: `Brand(s) ${pastTenseActions[action]} successfully`,
      });
    } else {
      notification.warning({
        message: 'Partial Success',
        description: `Only ${result.success} of ${brandIds.length} brands were ${pastTenseActions[action]}. Please try again later.`,
      });
    }

    audit.onUpdate({
      target: 'update-brands-assignment',
      data: {
        storeId,
        brandIds,
        action,
      },
    });

    setAssignBrandsModalOpen(false);
    refresh();
  };

  useEffect(() => {
    audit.onAccess();
  }, []);

  // Update the status of the brands loaded in the table when the table data changes
  useLayoutEffect(() => {
    setLoadedBrandsStatus(statusRef.current);
  }, [statusRef, tableProps.dataSource]);

  return (
    <div>
      <TableContainer>
        <Table
          {...tableProps}
          rowKey="id"
          title={() => (
            <div className="table-header">
              <Space className="title">
                <Button
                  onClick={() => {
                    setStatus('approved');
                  }}
                  type={status === 'approved' ? 'primary' : 'default'}
                >
                  Approved
                </Button>
                <Button
                  onClick={() => {
                    setStatus('pending');
                  }}
                  type={status === 'pending' ? 'primary' : 'default'}
                >
                  Pending
                </Button>
                <Button
                  onClick={() => {
                    setStatus('blocked');
                  }}
                  type={status === 'blocked' ? 'primary' : 'default'}
                >
                  Blocked
                </Button>
              </Space>
              <div className="actions">
                <Space>
                  <Input.Search
                    placeholder="Search by name or email"
                    allowClear
                    onSearch={(value) => {
                      setFilter(value);
                    }}
                    style={{ width: 260 }}
                  />
                  <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={() => setAssignBrandsModalOpen(true)}
                  >
                    Assign brands
                  </Button>
                  <BrandPickerModal
                    open={assignBrandsModalOpen}
                    selected={[]}
                    storeId={storeId}
                    multiple
                    forceTypeFilter="brand"
                    onSelect={(brandIds, _brands, action) => {
                      onUpdateBrandsAssignment(brandIds, action);
                    }}
                    onClose={() => setAssignBrandsModalOpen(false)}
                  />
                </Space>
              </div>
            </div>
          )}
        >
          <Table.Column
            title="Brand Name"
            dataIndex="brandName"
            key="name"
            sorter
            fixed="left"
            render={(text, record: TableData) => (
              <Typography.Text strong>{record.name}</Typography.Text>
            )}
          />

          <Table.Column
            dataIndex={'totalLiveMission.contest'}
            title="Total Live Contest"
            sorter
            align="left"
            render={(text, record: TableData) => (
              <Space.Compact direction={'vertical'}>
                <Typography.Text>
                  <strong>Contest: </strong> {record.totalLiveMission.contest}
                </Typography.Text>
                <Typography.Text>
                  <strong>General: </strong> {record.totalLiveMission.general}
                </Typography.Text>
                <Typography.Text>
                  <strong>Learning: </strong> {record.totalLiveMission.learning}
                </Typography.Text>
              </Space.Compact>
            )}
          />

          <Table.Column
            title="Connected At"
            dataIndex="connectedAt"
            sorter
            defaultSortOrder="descend"
            align="center"
            render={(text) => {
              return (
                <Space.Compact
                  direction={'vertical'}
                  className="transaction-column-date"
                >
                  <strong>{formatISODate(text, 'DD')}</strong>
                  <small>{formatISODate(text, 't')}</small>
                </Space.Compact>
              );
            }}
          />

          <Table.Column
            dataIndex="actions"
            align="right"
            render={(text, record: TableData) => {
              const canUpdateBrandAssignment =
                !record.isOwnerBrand && !isExclusiveStore;

              const shouldShowApproveButton =
                canUpdateBrandAssignment &&
                (loadedBrandsStatus === 'blocked' ||
                  loadedBrandsStatus === 'pending');
              const shouldShowBlockButton =
                canUpdateBrandAssignment &&
                (loadedBrandsStatus === 'approved' ||
                  loadedBrandsStatus === 'pending');
              const shouldShowUnlinkButton = canUpdateBrandAssignment;

              return (
                <Space>
                  {record.isOwnerBrand && (
                    <strong style={{ marginRight: '1rem' }}>Owner brand</strong>
                  )}

                  {shouldShowApproveButton && (
                    <Button
                      style={{ color: '#52c41a' /* green */ }}
                      type="text"
                      icon={<CheckOutlined />}
                      onClick={() => {
                        setUpdateBrandAssignmentModalData({
                          open: true,
                          brandId: record.id,
                          action: 'approve',
                        });
                      }}
                    >
                      Approve
                    </Button>
                  )}

                  {shouldShowBlockButton && (
                    <Button
                      type="text"
                      icon={<StopOutlined />}
                      danger
                      onClick={() => {
                        setUpdateBrandAssignmentModalData({
                          open: true,
                          brandId: record.id,
                          action: 'block',
                        });
                      }}
                    >
                      Block
                    </Button>
                  )}

                  {shouldShowUnlinkButton && (
                    <Button
                      style={{ color: '#1677ff' /* blue */ }}
                      type="text"
                      icon={<Unlink size={14} />}
                      onClick={() => {
                        setUpdateBrandAssignmentModalData({
                          open: true,
                          brandId: record.id,
                          action: 'unlink',
                        });
                      }}
                    >
                      Unlink
                    </Button>
                  )}

                  <ButtonLink
                    to={`/brand/${record.id}/manage`}
                    icon={<SiStackedit size={20} />}
                    style={{ alignItems: 'center', display: 'flex' }}
                  >
                    Manage
                  </ButtonLink>
                </Space>
              );
            }}
          />
        </Table>
      </TableContainer>

      <UpdateBrandAssignmentModal
        open={updateBrandAssignmentModalData.open}
        action={updateBrandAssignmentModalData.action}
        onConfirm={() => {
          onUpdateBrandsAssignment(
            [updateBrandAssignmentModalData.brandId],
            updateBrandAssignmentModalData.action,
          );
          setUpdateBrandAssignmentModalData((current) => ({
            ...current,
            open: false,
          }));
        }}
        onCancel={() => {
          setUpdateBrandAssignmentModalData((current) => ({
            ...current,
            open: false,
          }));
        }}
      />
    </div>
  );
};

const UpdateBrandAssignmentModal = ({
  open,
  action,
  onConfirm,
  onCancel,
}: {
  open: boolean;
  action: UpdateBrandsAssignmentAction;
  onConfirm: () => void;
  onCancel: () => void;
}) => {
  const titleByAction = {
    approve: 'Approve Brand',
    block: 'Block Brand',
    unlink: 'Unlink Brand',
  } satisfies Record<UpdateBrandsAssignmentAction, string>;

  const descriptionByAction = {
    approve: 'Are you sure you want to approve this brand?',
    block: 'Are you sure you want to block this brand?',
    unlink: 'Are you sure you want to unlink this brand?',
  } satisfies Record<UpdateBrandsAssignmentAction, string>;

  return (
    <Modal
      title={titleByAction[action]}
      open={open}
      okText="Confirm"
      centered
      destroyOnClose
      onOk={onConfirm}
      onCancel={onCancel}
    >
      <p>{descriptionByAction[action]}</p>
    </Modal>
  );
};

export default BrandsTab;
