import { useEffect, useState } from 'react';
import { Container } from './styles';
import { Form, Input, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { MailOutlined } from '@ant-design/icons';
import { useSession } from '@/store/session';
import { Case, Switch } from 'react-if';
import { BsAsterisk } from 'react-icons/bs';

const Login = () => {
  const [email, setEmail] = useState('');
  const navigate = useNavigate();

  const session = useSession();

  const [isLoading, setLoading] = useState(false);
  const [step, setStep] = useState<'email' | 'code'>('email');

  const sessionSatate = session.state.get();

  useEffect(() => {
    session.loadSession();
  }, []);

  useEffect(() => {
    if (sessionSatate.isLoggedIn && sessionSatate.sessionLoaded) {
      navigate('/dashboard');
    }
  }, [sessionSatate.isLoggedIn]);

  const onFinishEmail = async (values: any) => {
    setLoading(true);
    setEmail(values.email);
    const status = await session.requestVerificationCode(values.email);

    if (status) {
      setStep('code');
    }

    setLoading(false);
  };

  const onFinishCode = async (values: any) => {
    setLoading(true);

    const status = await session.verifyCode(email, values.code);

    setLoading(false);
    if (status) {
      session.loadSession();
      navigate('/dashboard');
      return;
    }

    setStep('email');
    setEmail('');
  };

  return (
    <Container>
      <div className="logo" />
      <div className="form-container">
        <Switch>
          <Case condition={step === 'email'}>
            <div className="form-header">Sign in with your email address</div>
            <Form
              name="email-form"
              className="login-form"
              onFinish={onFinishEmail}
            >
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: 'Please input your email!',
                  },
                  { type: 'email', message: 'Invalid email' },
                ]}
              >
                <Input prefix={<MailOutlined />} placeholder="Email" />
              </Form.Item>

              <Form.Item className="button-container">
                <Button
                  type="default"
                  htmlType="submit"
                  block
                  loading={isLoading}
                >
                  Request your verification code
                </Button>
              </Form.Item>
            </Form>
          </Case>
          <Case condition={step === 'code'}>
            <div className="form-header">
              Enter the verification code we sent to {email}
            </div>
            <Form
              name="code-form"
              className="login-form"
              onFinish={onFinishCode}
            >
              <Form.Item
                name="code"
                rules={[
                  {
                    required: true,
                    message: 'Please input your code!',
                  },
                ]}
              >
                <Input
                  prefix={<BsAsterisk />}
                  placeholder="Verification code"
                />
              </Form.Item>

              <Form.Item className="button-container">
                <Button
                  type="default"
                  htmlType="submit"
                  block
                  loading={isLoading}
                >
                  Verify
                </Button>
              </Form.Item>
            </Form>
          </Case>
        </Switch>
      </div>
    </Container>
  );
};

export default Login;
