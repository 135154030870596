import Layout from '@/components/Layout';
import PageHeader from '@/components/PageHeader';
import { useAudit, useTable } from '@/hooks';
import { WebUser } from '@/types';
import { formatISODate } from '@/utils';
import {
  Avatar,
  Button,
  Col,
  Dropdown,
  Input,
  MenuProps,
  Row,
  Select,
  Space,
  Table,
  Typography,
} from 'antd';
import { get } from 'lodash';
import { useEffect, useState } from 'react';
import { BiUser } from 'react-icons/bi';
import { GrView } from 'react-icons/gr';
import { PiDotsThreeOutline } from 'react-icons/pi';
import { SiStackedit } from 'react-icons/si';
import { Link } from 'react-router-dom';
import { Container } from './styles';
import DrawerUserView from './components/DrawerUserView.tsx';
import BrandTagsRender from './components/BrandTagsRender';
import TagsRender from '@/components/TagsRender';

const WebUserList = () => {
  const [filters, setFilters] = useState({
    text: '',
    storeId: '',
    isDeleted: 'no',
  });
  const [detailDrawer, setDetailDrawer] = useState({
    visible: false,
    user: null as WebUser | null,
  });

  const { tableProps, refresh } = useTable<WebUser>({
    service: 'user/read',
    path: `admin/web-user/list`,
    params: {
      filters,
    },
    perPage: 10,
  });

  const audit = useAudit({
    resourceName: 'web-user',
  });

  useEffect(() => {
    audit.onAccess();
  }, []);

  const handleViewUser = (user: WebUser) => {
    setDetailDrawer({
      visible: true,
      user,
    });
  };

  const getTableMenuItems = (
    user: WebUser,
  ): NonNullable<MenuProps['items']> => {
    const items: NonNullable<MenuProps['items']> = [
      {
        key: `user-menu-manage-${user.id}`,
        label: <Link to={`/brand/web-user/${user.id}/manage`}>Manage</Link>,
        icon: <SiStackedit size={20} />,
      },
      {
        key: `web-user-menu-preview-${user.id}`,
        label: 'Quick View',
        icon: <GrView size={20} />,
        onClick: () => {
          audit.onClick({
            target: 'web-user-quick-view',
            data: {
              userId: user.id,
            },
          });
          handleViewUser(user);
        },
      },
    ];

    return items;
  };

  return (
    <Layout noCard={false}>
      <PageHeader noBackButton title="Brand Web Users" />
      <Container>
        <Space direction="vertical" size={16} style={{ width: '100%' }}>
          <Row gutter={16}>
            <Col span={16}>
              <Input.Search
                placeholder="Search by name, brand name or user ID"
                allowClear
                onSearch={(value) => {
                  setFilters((prev) => ({
                    ...prev,
                    text: value,
                  }));
                  audit.onClick({
                    target: 'search-table',
                  });
                }}
                style={{ width: '100%' }}
              />
            </Col>
            <Col span={8}>
              <Select
                style={{ width: '100%' }}
                placeholder="Showing All Users"
                optionFilterProp="children"
                defaultValue={'no'}
                onChange={(value) =>
                  setFilters((prev: any) => ({ ...prev, isDeleted: value }))
                }
                options={[
                  {
                    value: 'yes',
                    label: 'Showing Deleted Users Only',
                  },
                  {
                    value: 'no',
                    label: 'Showing Active Users Only',
                  },
                ]}
              />
            </Col>
          </Row>
          <Table {...tableProps} rowKey={'id'} tableLayout={'fixed'}>
            <Table.Column
              title="Name"
              dataIndex="fullName"
              key="fullName"
              sorter
              fixed="left"
              width={350}
              render={(text: any, record: WebUser, index: number) => {
                return (
                  <Space className="user-row">
                    <Avatar
                      src={record.avatarUrl}
                      style={{ width: 48, height: 48 }}
                      icon={<BiUser />}
                      shape="circle"
                      size={48}
                    />

                    <Space.Compact direction="vertical" className="info">
                      <Typography.Text>
                        {record.fullName || <span>(name not set)</span>}
                      </Typography.Text>
                      {record.email && (
                        <Typography.Text copyable style={{ fontSize: 14 }}>
                          {record.email}
                        </Typography.Text>
                      )}
                      <Typography.Text
                        copyable={{
                          text: record.id,
                          onCopy: () => {
                            audit.onClick({
                              target: 'copy-web-user-id',
                              data: {
                                webUserId: record.id,
                              },
                            });
                          },
                        }}
                      >
                        <code>{record.id}</code>
                      </Typography.Text>
                    </Space.Compact>
                  </Space>
                );
              }}
            />
            <Table.Column
              title="Brands"
              dataIndex="brands"
              key="brands"
              align="left"
              width={'100%'}
              render={(_, record: WebUser) => {
                const brands = get(record, 'brands', null);
                if (!brands || !brands.length) return '-';

                return <BrandTagsRender items={brands} max={20} />;
              }}
            />

            <Table.Column
              title="Updated At"
              dataIndex="updatedAt"
              key="updatedAt"
              align="center"
              sorter
              width={200}
              render={(text: any, record: WebUser, index: number) => {
                if (!text) return '-';
                return (
                  <Typography.Text ellipsis>
                    {formatISODate(text) || '-'}
                  </Typography.Text>
                );
              }}
            />

            <Table.Column
              title="Actions"
              dataIndex="actions"
              key="actions"
              align="right"
              fixed="right"
              width={100}
              render={(text: any, record: WebUser, index: number) => {
                return (
                  <Dropdown menu={{ items: getTableMenuItems(record) }}>
                    <Button>
                      <PiDotsThreeOutline size={20} />
                    </Button>
                  </Dropdown>
                );
              }}
            />
          </Table>
          <Row justify="end" style={{ width: '100%' }}>
            <Typography.Text type="secondary">Legend: &nbsp;</Typography.Text>
            <TagsRender
              items={[
                { label: 'Sales Rep', color: 'purple' },
                { label: 'No Access', color: 'error' },
                { label: 'Owner', color: 'volcano' },
                { label: 'Admin', color: 'magenta' },
                { label: 'Endvr Team', color: 'success' },
                { label: 'Viewer', color: 'default' },
                { label: 'Store Manager', color: 'warning' },
              ]}
              max={7}
            />
          </Row>
        </Space>
      </Container>
      <DrawerUserView
        visible={detailDrawer.visible}
        onClose={() => setDetailDrawer({ visible: false, user: null })}
        user={detailDrawer.user}
      />
    </Layout>
  );
};

export default WebUserList;
