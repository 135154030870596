import { FC } from 'react';
import { Case, Switch } from 'react-if';
import { CompletionStatusTag } from './styles';
import {
  CircleDotDashed,
  ThumbsUp,
  ThumbsDown,
  RefreshCcw,
} from 'lucide-react';

const StatusTag: FC<{
  status?: string;
  processing?: boolean;
  size?: string;
}> = ({ status = '', processing = false, size = 'large' }) => {
  return (
    <Switch>
      <Case condition={status === 'approved'}>
        <CompletionStatusTag icon={<ThumbsUp />} color="green" className={size}>
          Approved
        </CompletionStatusTag>
      </Case>
      <Case condition={status === 'complete'}>
        <CompletionStatusTag
          color={processing ? 'geekblue' : 'default'}
          icon={processing ? <RefreshCcw /> : <CircleDotDashed />}
          className={size}
        >
          {processing ? 'Processing' : 'Pending Approval'}
        </CompletionStatusTag>
      </Case>
      <Case condition={status === 'rejected'}>
        <CompletionStatusTag icon={<ThumbsDown />} color="red" className={size}>
          Rejected
        </CompletionStatusTag>
      </Case>
    </Switch>
  );
};

export default StatusTag;
