import styled from 'styled-components';

export const Container = styled.div`
  min-height: 300px;
  width: 100%;
  position: relative;
  display: flex;
  /* align-items: center; */
  justify-content: center;

  .full-screen-btn {
    position: absolute;
    left: 20px;
    top: 20px;
    width: 40px;
    height: 40px;
    background-color: #343640;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 1;
    border: none;
    outline: none;
    transition: all 0.2s ease-in-out;
    color: #fff;
    font-size: 20px;
  }

  .mapboxgl-map {
    overflow: hidden !important;

    .mapboxgl-canvas-container {
      overflow: hidden !important;
    }
  }

  .control-container {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .content {
      width: 100%;
      height: 100%;
      max-width: calc(100% - 200px);
      position: relative;
      display: flex;
    }
  }
`;

export const ContentHeaderWrapper = styled.div`
  width: 1200px;
  max-width: calc(100% - 40px);
  height: 1px;
  position: absolute;
  top: 20px;

  .content {
    position: absolute;
    right: 0;
    display: flex;
    flex-direction: column;
    row-gap: 24px;

    .ant-segmented {
      background-color: #343640;
      color: #b5b5b5;
      border-radius: 10px;

      .ant-segmented-group {
        .ant-segmented-item {
          height: 34px;
          display: flex;
          align-items: center;
          border-radius: 10px;
          font-weight: 700;
          font-size: 13px;
        }
      }
    }

    .filters {
      .filter-title {
        color: #fff;
        font-size: 12px;
      }

      .filter-options {
        display: flex;
        column-gap: 16px;
      }

      .ant-segmented-item-label {
        display: flex;
        align-items: center;

        .ant-segmented-item-icon {
          font-size: 16px;
          display: flex;
        }
      }
    }

    .kpis {
      width: 280px;
      padding: 24px;
      background-color: #343640;
      border-radius: 10px;
      margin-left: auto;
      display: flex;
      flex-direction: column;
      row-gap: 24px;

      .kpi {
        color: #fff;
        display: flex;
        flex-direction: column;
        row-gap: 4px;

        .title {
          font-size: 16px;
          display: flex;
          align-items: center;
          column-gap: 8px;
          min-height: 38px;

          .icon {
          }

          .text {
            .main {
              display: flex;
              flex-direction: column;
              font-weight: 300;
            }

            .sub {
              font-size: 10px;

              .success {
                color: #77bb61;
              }
            }
          }
        }

        .value {
          font-size: 24px;
          font-weight: 700;

          &.warn {
            color: #ff8000;
          }
        }

        .time {
          font-size: 12px;
          font-weight: 400;

          &.warn {
            color: #ff8000;
          }
        }
      }
    }
  }
`;

export const BottomKpisContainer = styled.div`
  bottom: 20px;
  width: 1200px;
  max-width: calc(100% - 40px);
  height: 144px;
  background-color: #fff;
  margin-top: auto;
  background-color: #343640;
  border-radius: 10px;
  padding: 24px;
  column-gap: 40px;
  display: flex;
  justify-content: space-between;
  position: absolute;

  .kpi {
    color: #fff;
    display: flex;
    flex-direction: column;
    row-gap: 8px;

    .title {
      font-size: 16px;
      display: flex;
      align-items: center;
      column-gap: 8px;
      min-height: 38px;

      .icon {
      }

      .text {
        .main {
          display: flex;
          flex-direction: column;
          font-weight: 300;
        }

        .sub {
          font-size: 10px;

          .success {
            color: #77bb61;
          }
        }
      }
    }

    .value {
      font-size: 24px;
      font-weight: 700;

      &.warn {
        color: #ff8000;
      }
    }

    .time {
      font-size: 12px;
      font-weight: 400;

      &.warn {
        color: #ff8000;
      }
    }
  }
`;
