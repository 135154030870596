import { Modal } from 'antd';
import { ReportKafkaDlqRowData } from '../DataTable';
import { Descriptions } from 'antd';
import { useEffect } from 'react';
import { useAudit } from '@/hooks';

interface ReportKafkaDlqModalProps {
  rowData: ReportKafkaDlqRowData | null;
  setModalVisible: (visible: boolean) => void;
  modalVisible: boolean;
}

const ReportKafkaDlqModal = ({
  rowData,
  setModalVisible,
  modalVisible,
}: ReportKafkaDlqModalProps) => {
  const audit = useAudit({
    resourceName: 'messaging-controller-modal',
  });

  useEffect(() => {
    if (modalVisible) {
      audit.onAccess();
    }
  }, [modalVisible]);

  const handleOk = () => {
    setModalVisible(false);
  };

  const handleCancel = () => {
    setModalVisible(false);
  };

  return (
    <Modal
      title="Fail Details"
      open={modalVisible}
      onOk={handleOk}
      width={1000}
      onCancel={handleCancel}
    >
      {rowData && (
        <Descriptions bordered size={'small'}>
          <Descriptions.Item label="Topic">{rowData.topic}</Descriptions.Item>
          <Descriptions.Item label="Group Id">
            {rowData.groupId}
          </Descriptions.Item>
          <Descriptions.Item label="Client Id">
            {rowData.clientId}
          </Descriptions.Item>
          <Descriptions.Item label="Status">{rowData.status}</Descriptions.Item>
          <Descriptions.Item label="Created At">
            {rowData.createdAt}
          </Descriptions.Item>
          <Descriptions.Item label="Error Detail">
            {rowData.errorDetails}
          </Descriptions.Item>
        </Descriptions>
      )}
    </Modal>
  );
};
export default ReportKafkaDlqModal;
