import {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  flip,
  useClientPoint,
  useFloating,
  useInteractions,
  FloatingPortal,
  useMergeRefs,
} from '@floating-ui/react';
import styled from 'styled-components';
import { mergeProps } from '@react-aria/utils';
import { Image } from 'antd';

export function MagnifiableImage({
  containerStyle,
  src,
  alt,
  magnifierSize = 480,
  initialZoomLevel = 4,
  enablePreview = true,
  ...imgProps
}: React.ImgHTMLAttributes<HTMLElement> & {
  /** `style` prop for the container element when using `enablePreview` as
   * `true`. */
  containerStyle?: React.CSSProperties;
  src: string;
  alt: string;
  magnifierSize?: number;
  initialZoomLevel?: number;
  /**
   * Whether to enable the preview provided by the `Image` component from
   * `antd`.
   *
   * When `true`, a container element will be added around the `Image`
   * component, which may affect the styling. Use the `containerStyle` prop to
   * pass inline styles to the container element.
   *
   * @default true
   */
  enablePreview?: boolean;
}) {
  const { hoverableProps, magnifierProps, refs } = useMagnifier({
    src,
    magnifierSize,
    initialZoomLevel,
  });

  const imageNode = useMemo(() => {
    if (!enablePreview) {
      return (
        <img
          src={src}
          alt={alt}
          {...mergeProps(hoverableProps, imgProps)}
          ref={refs.setHoverable}
        />
      );
    }

    return (
      <div
        {...hoverableProps}
        style={{
          ...hoverableProps.style,
          width: 'fit-content',
          ...containerStyle,
        }}
        ref={refs.setHoverable}
      >
        <Image src={src} alt={alt} {...imgProps} />
      </div>
    );
  }, [
    alt,
    containerStyle,
    enablePreview,
    hoverableProps,
    imgProps,
    refs.setHoverable,
    src,
  ]);

  return (
    <>
      {imageNode}
      <ImageMagnifier {...magnifierProps} ref={refs.setMagnifier} />
    </>
  );
}

export const ImageMagnifier = forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & { showMagnifier: boolean }
>(({ showMagnifier, ...props }, forwardedRef) => {
  if (!showMagnifier) return null;

  return (
    <FloatingPortal>
      <MagnifierContent {...props} ref={forwardedRef} />
    </FloatingPortal>
  );
});

export function useMagnifier({
  src,
  magnifierSize = 480,
  initialZoomLevel = 4,
  enabled = true,
}: {
  /** Source URL of the image to be magnified. */
  src: string;
  /**
   * The size of the magnifier that contains the zoomed image.
   *
   * Its aspect ratio is 1:1.
   *
   * @default 480
   */
  magnifierSize?: number;
  /**
   * The initial zoom multiplier for the magnified image.
   *
   * @default 4
   */
  initialZoomLevel?: number;
  /**
   * Whether the magnifier is enabled or not.
   *
   * @default true
   */
  enabled?: boolean;
}) {
  const {
    showMagnifier,
    imageSize,
    absolutePosition,
    relativePosition,
    zoomLevel,
    hoverableProps,
    hoverableRef,
  } = useMagnifierState({ initialZoomLevel });

  const { refs, floatingStyles, placement, context } = useFloating({
    open: showMagnifier,
    /* For some reason 'start' and 'end' are flipped, so we're using 'top-start'
    to position the magnifier at the top right corner of the cursor */
    placement: 'top-start',
    middleware: [flip()],
  });
  const clientPoint = useClientPoint(context, {
    enabled: showMagnifier,
    x: absolutePosition.x,
    y: absolutePosition.y,
  });
  const { getReferenceProps, getFloatingProps } = useInteractions([
    clientPoint,
  ]);

  const mergedHoverableRef = useMergeRefs([
    enabled ? hoverableRef : undefined,
    refs.setReference,
  ]);

  return {
    hoverableProps: {
      style: { touchAction: 'none' },
      ...getReferenceProps(enabled ? hoverableProps : undefined),
    },
    magnifierProps: {
      showMagnifier,
      style: {
        backgroundImage: `url('${src}')`,
        backgroundSize: `${imageSize.width * zoomLevel}px ${
          imageSize.height * zoomLevel
        }px`,
        backgroundPositionX: `${
          -relativePosition.x * zoomLevel + magnifierSize / 2
        }px`,
        backgroundPositionY: `${
          -relativePosition.y * zoomLevel + magnifierSize / 2
        }px`,
        width: `${magnifierSize}px`,
        height: `${magnifierSize}px`,
        ...floatingStyles,
      },
      'data-placement': placement,
      ...getFloatingProps(),
    },
    refs: {
      setHoverable: mergedHoverableRef,
      setMagnifier: refs.setFloating,
    },
  };
}

function useMagnifierState({ initialZoomLevel }: { initialZoomLevel: number }) {
  const [isHovered, setIsHovered] = useState(false);
  const [isTouchMoving, setIsTouchMoving] = useState(false);
  const [{ imageSize, absolutePosition, relativePosition }, _setState] =
    useState({
      imageSize: { width: 0, height: 0 },
      absolutePosition: { x: 0, y: 0 },
      relativePosition: { x: 0, y: 0 },
    });
  const [zoomLevel, setZoomLevel] = useState(initialZoomLevel);

  const hoverableRef = useRef<HTMLImageElement>(null);

  const showMagnifier = isHovered || isTouchMoving;

  function setPosition(cursorPosition: { x: number; y: number }) {
    const container = hoverableRef.current;
    if (!container) return;

    const boundingClientRect = container.getBoundingClientRect();

    _setState({
      imageSize: {
        width: container.clientWidth,
        height: container.clientHeight,
      },
      absolutePosition: cursorPosition,
      relativePosition: {
        x: cursorPosition.x - boundingClientRect.left,
        y: cursorPosition.y - boundingClientRect.top,
      },
    });
  }

  const handleStart = useCallback((event: MouseEvent | TouchEvent) => {
    if (event instanceof MouseEvent) {
      setIsHovered(true);
      setPosition({ x: event.clientX, y: event.clientY });
      return;
    }

    setIsTouchMoving(true);
    setPosition({
      x: event.touches[0].clientX,
      y: event.touches[0].clientY,
    });
  }, []);

  function handleMove(event: React.MouseEvent | React.TouchEvent) {
    if (event.nativeEvent instanceof MouseEvent) {
      const mouseEvent = event as React.MouseEvent;
      setPosition({ x: mouseEvent.clientX, y: mouseEvent.clientY });
      return;
    }

    const touchEvent = event as React.TouchEvent;
    setPosition({
      x: touchEvent.touches[0].clientX,
      y: touchEvent.touches[0].clientY,
    });
  }

  const handleEnd = useCallback((event: MouseEvent | TouchEvent) => {
    if (event instanceof MouseEvent) {
      setIsHovered(false);
      return;
    }

    event.preventDefault();
    setIsTouchMoving(false);
  }, []);

  const handleWheel = useCallback((event: WheelEvent) => {
    // Prevent scroll
    event.preventDefault();

    const minZoom = 2;
    const maxZoom = 10;

    setZoomLevel((current) => {
      const increasePercentage = -Math.sign(event.deltaY) * 0.1;
      const next = current + current * increasePercentage;
      return Math.max(Math.min(next, maxZoom), minZoom);
    });
  }, []);

  /* Manually set up 'mouseenter', 'mouseleave', 'touchstart' and 'touchend'
   event listeners instead of using React's built-in event props. This is
   because React's synthetic event system considers elements rendered in portals
   (like the preview from `antd`'s `Image` component) to be "inside" their
   parent elements in the Virtual DOM, which can cause unexpected event bubbling
   behavior. */
  useEffect(() => {
    const hoverableElement = hoverableRef.current;
    if (!hoverableElement) return;

    hoverableElement.addEventListener('mouseenter', handleStart);
    hoverableElement.addEventListener('mouseleave', handleEnd);
    hoverableElement.addEventListener('touchstart', handleStart);
    hoverableElement.addEventListener('touchend', handleEnd);

    return () => {
      hoverableElement.removeEventListener('mouseenter', handleStart);
      hoverableElement.removeEventListener('mouseleave', handleEnd);
      hoverableElement.removeEventListener('touchstart', handleStart);
      hoverableElement.removeEventListener('touchend', handleEnd);
    };
  }, [handleStart, handleEnd]);

  /* Set wheel event listener manually, as React sets it as passive through the
  `onWheel` prop, which does not allow us to prevent the default behavior */
  useEffect(() => {
    const hoverableElement = hoverableRef.current;
    if (!hoverableElement) return;

    /* Safari uses wheel events as passive by default, so we need to explicitly
    set it as not passive */
    hoverableElement.addEventListener('wheel', handleWheel, { passive: false });
    return () => {
      hoverableElement.removeEventListener('wheel', handleWheel);
    };
  }, [handleWheel]);

  return {
    showMagnifier,
    imageSize,
    absolutePosition,
    relativePosition,
    zoomLevel,
    hoverableProps: {
      onMouseMove: handleMove,
      onTouchMove: handleMove,
      onDragStart: (event) => event.preventDefault(),
    } satisfies React.ImgHTMLAttributes<HTMLImageElement>,
    hoverableRef,
  };
}

const MagnifierContent = styled.div`
  background-color: rgb(128 128 128 / 0.8);
  background-repeat: no-repeat;
  --blur-size: 4px;
  border: 1px solid lightgray;
  border-radius: 100vw 100vw 100vw 100vw;
  z-index: 1000;
  pointer-events: none;
  animation: fadeIn 0.2s ease-in-out;

  /* For some reason 'start' and 'end' are flipped, so we use 'start' when the
  magnifier is positioned at the right and 'end' when the magnifier is
  positioned at the left */
  /* Also, for some reason, at least on Chromium, changing the border radius
  does not cause the backdrop-filter to update, so we have to use a different
  value for each border radius to force the filter to update */
  &[data-placement='top-start'] {
    border-bottom-left-radius: 0;
    backdrop-filter: blur(calc(var(--blur-size) + 0.01px));
  }
  &[data-placement='top-end'] {
    border-bottom-right-radius: 0;
    backdrop-filter: blur(calc(var(--blur-size) + 0.02px));
  }
  &[data-placement='bottom-start'] {
    border-top-left-radius: 0;
    backdrop-filter: blur(calc(var(--blur-size) + 0.03px));
  }
  &[data-placement='bottom-end'] {
    border-top-right-radius: 0;
    backdrop-filter: blur(calc(var(--blur-size) + 0.04px));
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;
