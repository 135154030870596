import { getList } from '@/services/import.service';
import { useSession } from '@/store';
import { SearchOutlined } from '@ant-design/icons';
import { Button, Popover, Space, Table } from 'antd';
import { FC, useEffect, useState } from 'react';
import { SiStackedit } from 'react-icons/si';
import { When } from 'react-if';
import ReactJson from 'react-json-view';
import { useNavigate } from 'react-router-dom';
import LoadingPage from '../../Loading';
import { TableContainer, TabsContainer } from './styles';

type FileProps = {
  id: string;
  adminUser: string;
  adminUserId: string;
  createdAt: string;
  fileUrl: string;
  fileName: string;
  hiveRequestId: string;
  hiveRequestStatus: string;
  hiveResponse?: any;
  progress?: number;
};

const ProcessedTab: FC = () => {
  const session = useSession();
  const navigate = useNavigate();

  const [list, setList] = useState([] as FileProps[]);
  const [uploading, setUploading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getFileList();
  }, []);

  const getFileList = async () => {
    setIsLoading(true);
    const filters = {
      status: 'success',
    };

    const { status, items } = await getList(filters);
    if (status) {
      setList(items);
    }

    setIsLoading(false);
  };

  return (
    <TabsContainer>
      <When condition={uploading}>
        <LoadingPage />
      </When>
      <TableContainer>
        <Table
          className="table"
          dataSource={list}
          rowKey="_id"
          loading={isLoading}
        >
          <Table.Column<FileProps>
            title="File Name"
            dataIndex="fileName"
            render={(fileName) => {
              return <span>{fileName}</span>;
            }}
          />
          <Table.Column<FileProps>
            title="Requested User"
            dataIndex="adminUser"
            align="center"
          />
          <Table.Column<FileProps>
            title="Created At"
            dataIndex="createdAt"
            align="right"
            render={(createdAt) => {
              return new Date(createdAt).toLocaleString();
            }}
          />
          <Table.Column
            title=""
            dataIndex="actions"
            key="actions"
            align="right"
            render={(text: any, record: FileProps, index: number) => {
              return (
                <Space>
                  <Button
                    icon={<SiStackedit size={20} />}
                    type="primary"
                    onClick={() =>
                      navigate(`/dealer-import/${record.id}/manage`)
                    }
                    style={{ alignItems: 'center', display: 'flex' }}
                  >
                    Manage
                  </Button>
                  <Popover
                    trigger={'click'}
                    title={'Response Payload'}
                    content={
                      <div
                        style={{
                          width: '600px',
                          maxWidth: '100%',
                          height: '360px',
                          overflow: 'auto',
                        }}
                      >
                        <ReactJson
                          src={record.hiveResponse || {}}
                          theme="monokai"
                        />
                      </div>
                    }
                  >
                    <Button
                      icon={<SearchOutlined />}
                      type="dashed"
                      title="AI resp."
                    >
                      AI resp.
                    </Button>
                  </Popover>
                </Space>
              );
            }}
          />
        </Table>
      </TableContainer>
    </TabsContainer>
  );
};

export default ProcessedTab;
