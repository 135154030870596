import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;

  .ant-avatar,
  .ant-image {
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  button {
    width: 200px;
    height: 30px;
    padding: 0;
  }
`;
