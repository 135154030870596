export default [
  {
    name: 'South Africa',
    countryCode: 'ZA',
    region: 'Africa',
    continent: 'Africa',
    subRegion: 'Southern Africa',
  },
  {
    name: 'Canada',
    countryCode: 'CA',
    region: 'North America',
    continent: 'Americas',
    subRegion: 'Northern America',
  },
  {
    name: 'Cayman Islands',
    countryCode: 'KY',
    region: 'Latin America and the Caribbean',
    continent: 'Americas',
    subRegion: 'Caribbean',
  },
  {
    name: 'Colombia',
    countryCode: 'CO',
    region: 'Latin America and the Caribbean',
    continent: 'Americas',
    subRegion: 'South America',
  },
  {
    name: 'Costa Rica',
    countryCode: 'CR',
    region: 'Latin America and the Caribbean',
    continent: 'Americas',
    subRegion: 'Central America',
  },
  {
    name: 'United States',
    countryCode: 'US',
    region: 'North America',
    continent: 'Americas',
    subRegion: 'Northern America',
  },
  {
    name: 'Turkey',
    countryCode: 'TR',
    region: 'Asia',
    continent: 'Asia',
    subRegion: 'Western Asia',
  },
  {
    name: 'Albania',
    countryCode: 'AL',
    region: 'Europe',
    continent: 'Europe',
    subRegion: 'Southern Europe',
  },
  {
    name: 'Austria',
    countryCode: 'AT',
    region: 'Europe',
    continent: 'Europe',
    subRegion: 'Western Europe',
  },
  {
    name: 'Belarus',
    countryCode: 'BY',
    region: 'Europe',
    continent: 'Europe',
    subRegion: 'Eastern Europe',
  },
  {
    name: 'Belgium ',
    countryCode: 'BE',
    region: 'Europe',
    continent: 'Europe',
    subRegion: 'Western Europe',
  },
  {
    name: 'Bosnia and Herzegovina',
    countryCode: 'BA',
    region: 'Europe',
    continent: 'Europe',
    subRegion: 'Southern Europe',
  },
  {
    name: 'Bulgaria',
    countryCode: 'BG',
    region: 'Europe',
    continent: 'Europe',
    subRegion: 'Eastern Europe',
  },
  {
    name: 'Croatia',
    countryCode: 'HR',
    region: 'Europe',
    continent: 'Europe',
    subRegion: 'Southern Europe',
  },
  {
    name: 'Cyprus',
    countryCode: 'CY',
    region: 'Europe',
    continent: 'Europe',
    subRegion: 'Southern Europe',
  },
  {
    name: 'Czech Republic',
    countryCode: 'CZ',
    region: 'Europe',
    continent: 'Europe',
    subRegion: 'Eastern Europe',
  },
  {
    name: 'Denmark',
    countryCode: 'DK',
    region: 'Europe',
    continent: 'Europe',
    subRegion: 'Northern Europe',
  },
  {
    name: 'Estonia',
    countryCode: 'EE',
    region: 'Europe',
    continent: 'Europe',
    subRegion: 'Northern Europe',
  },
  {
    name: 'Finland',
    countryCode: 'FI',
    region: 'Europe',
    continent: 'Europe',
    subRegion: 'Northern Europe',
  },
  {
    name: 'France',
    countryCode: 'FR',
    region: 'Europe',
    continent: 'Europe',
    subRegion: 'Western Europe',
  },
  {
    name: 'Germany',
    countryCode: 'DE',
    region: 'Europe',
    continent: 'Europe',
    subRegion: 'Western Europe',
  },
  {
    name: 'Greece',
    countryCode: 'GR',
    region: 'Europe',
    continent: 'Europe',
    subRegion: 'Southern Europe',
  },
  {
    name: 'Hungary',
    countryCode: 'HU',
    region: 'Europe',
    continent: 'Europe',
    subRegion: 'Eastern Europe',
  },
  {
    name: 'Iceland',
    countryCode: 'IS',
    region: 'Europe',
    continent: 'Europe',
    subRegion: 'Northern Europe',
  },
  {
    name: 'Ireland',
    countryCode: 'IE',
    region: 'Europe',
    continent: 'Europe',
    subRegion: 'Northern Europe',
  },
  {
    name: 'Italy',
    countryCode: 'IT',
    region: 'Europe',
    continent: 'Europe',
    subRegion: 'Southern Europe',
  },
  {
    name: 'Kosovo',
    countryCode: 'XK',
    region: 'Europe',
    continent: 'Europe',
    subRegion: 'Eastern Europe',
  },
  {
    name: 'Latvia',
    countryCode: 'LV',
    region: 'Europe',
    continent: 'Europe',
    subRegion: 'Northern Europe',
  },
  {
    name: 'Lithuania',
    countryCode: 'LT',
    region: 'Europe',
    continent: 'Europe',
    subRegion: 'Northern Europe',
  },
  {
    name: 'Luxembourg',
    countryCode: 'LU',
    region: 'Europe',
    continent: 'Europe',
    subRegion: 'Western Europe',
  },
  {
    name: 'Malta',
    countryCode: 'MT',
    region: 'Europe',
    continent: 'Europe',
    subRegion: 'Southern Europe',
  },
  {
    name: 'Moldova',
    countryCode: 'MD',
    region: 'Europe',
    continent: 'Europe',
    subRegion: 'Eastern Europe',
  },
  {
    name: 'Monaco',
    countryCode: 'MC',
    region: 'Europe',
    continent: 'Europe',
    subRegion: 'Western Europe',
  },
  {
    name: 'Montenegro',
    countryCode: 'ME',
    region: 'Europe',
    continent: 'Europe',
    subRegion: 'Southern Europe',
  },
  {
    name: 'Netherlands',
    countryCode: 'NL',
    region: 'Europe',
    continent: 'Europe',
    subRegion: 'Western Europe',
  },
  {
    name: 'North Macedonia',
    countryCode: 'MK',
    region: 'Europe',
    continent: 'Europe',
    subRegion: 'Southern Europe',
  },
  {
    name: 'Norway',
    countryCode: 'NO',
    region: 'Europe',
    continent: 'Europe',
    subRegion: 'Northern Europe',
  },
  {
    name: 'Poland',
    countryCode: 'PL',
    region: 'Europe',
    continent: 'Europe',
    subRegion: 'Eastern Europe',
  },
  {
    name: 'Portugal',
    countryCode: 'PT',
    region: 'Europe',
    continent: 'Europe',
    subRegion: 'Southern Europe',
  },
  {
    name: 'Romania',
    countryCode: 'RO',
    region: 'Europe',
    continent: 'Europe',
    subRegion: 'Eastern Europe',
  },
  {
    name: 'Russia',
    countryCode: 'RU',
    region: 'Europe',
    continent: 'Europe',
    subRegion: 'Eastern Europe',
  },
  {
    name: 'Serbia',
    countryCode: 'RS',
    region: 'Europe',
    continent: 'Europe',
    subRegion: 'Southern Europe',
  },
  {
    name: 'Slovakia',
    countryCode: 'SK',
    region: 'Europe',
    continent: 'Europe',
    subRegion: 'Eastern Europe',
  },
  {
    name: 'Slovenia',
    countryCode: 'SI',
    region: 'Europe',
    continent: 'Europe',
    subRegion: 'Southern Europe',
  },
  {
    name: 'Spain',
    countryCode: 'ES',
    region: 'Europe',
    continent: 'Europe',
    subRegion: 'Southern Europe',
  },
  {
    name: 'Sweden',
    countryCode: 'SE',
    region: 'Europe',
    continent: 'Europe',
    subRegion: 'Northern Europe',
  },
  {
    name: 'Switzerland',
    countryCode: 'CH',
    region: 'Europe',
    continent: 'Europe',
    subRegion: 'Western Europe',
  },
  {
    name: 'Ukraine',
    countryCode: 'UA',
    region: 'Europe',
    continent: 'Europe',
    subRegion: 'Eastern Europe',
  },
  {
    name: 'United Kingdom (UK)',
    countryCode: 'GB',
    region: 'Europe',
    continent: 'Europe',
    subRegion: 'Northern Europe',
  },
  {
    name: 'Australia',
    countryCode: 'AU',
    region: 'Asia Pacific ',
    continent: 'Oceania',
    subRegion: 'Australia and New Zealand',
  },
  {
    name: 'New Zealand',
    countryCode: 'NZ',
    region: 'Asia Pacific ',
    continent: 'Oceania',
    subRegion: 'Australia and New Zealand',
  },
];
