import { useAudit } from '@/hooks';
import { Drawer, Button, Space, Popconfirm, Descriptions } from 'antd';
import { get, startCase } from 'lodash';
import { sectors } from '@/data/admin-user';
import { When } from 'react-if';
import { useSession } from '@/store';
import React, { useEffect } from 'react';

import { ButtonLink } from '@/components/ButtonLink';

interface UserDetailsProps {
  setUserPreviewOpen: (userPreviewOpen: boolean) => void;
  setPreviewUser: (previewUser: any) => void;
  onDeleteUser: (userId: string) => void;
  userPreviewOpen: any;
  previewUser: any;
}

const dividerStyle = {
  borderColor: 'rgba(0, 0, 0, 0.06)',
  marginBottom: 24,
};

const UserDetails = ({
  setUserPreviewOpen,
  setPreviewUser,
  userPreviewOpen,
  previewUser,
  onDeleteUser,
}: UserDetailsProps) => {
  const session = useSession();

  useEffect(() => {
    if (userPreviewOpen) {
      audit.onAccess();
    }
  }, [userPreviewOpen]);

  const audit = useAudit({
    resourceName: 'user-details',
  });

  return (
    <Drawer
      title="User Details"
      size="large"
      placement="right"
      onClose={() => {
        setUserPreviewOpen(false);
        setPreviewUser(null);
      }}
      open={userPreviewOpen}
      extra={
        <When condition={session.hasPermission('admin_user.edit')}>
          <Space>
            <Popconfirm
              title="Delete User"
              description="Are you sure to delete this user?"
              onConfirm={() => onDeleteUser(previewUser?.id)}
              okText="Yes, I'm sure"
              cancelText="Cancel"
            >
              <Button>Delete</Button>
            </Popconfirm>

            <ButtonLink
              to={`/admin-user/${previewUser?.id}/edit`}
              type="primary"
            >
              Edit
            </ButtonLink>
          </Space>
        </When>
      }
    >
      <When condition={!!previewUser && userPreviewOpen}>
        <Descriptions
          title="Personal"
          column={{
            lg: 2,
          }}
        >
          <Descriptions.Item label="Full Name">
            {previewUser?.fullName}
          </Descriptions.Item>
          <Descriptions.Item label="Email">
            {previewUser?.email}
          </Descriptions.Item>
          <Descriptions.Item label="Phone">
            {previewUser?.phoneNumber || '-'}
          </Descriptions.Item>
        </Descriptions>
        <hr style={dividerStyle} />
        <Descriptions
          title="Company"
          column={{
            lg: 2,
          }}
        >
          <Descriptions.Item label="Sector">
            {sectors.find((s) => s.id === previewUser?.sector)?.name || '-'}
          </Descriptions.Item>
        </Descriptions>
        <hr style={dividerStyle} />
        <Descriptions
          title="Access"
          column={{
            lg: 2,
          }}
        >
          <Descriptions.Item label="Master User">
            {previewUser?.isMasterUser ? 'Yes' : 'No'}
          </Descriptions.Item>

          <Descriptions.Item label="Active Account">
            {previewUser?.isActive ? 'Yes' : 'No'}
          </Descriptions.Item>
        </Descriptions>
        <hr style={dividerStyle} />
        <Descriptions
          title="CRM Permissions"
          column={{
            lg: 1,
          }}
        >
          {!previewUser?.permissions ||
          previewUser?.permissions.length === 0 ? (
            <Descriptions.Item label="Guest">
              This user has no permissions assigned
            </Descriptions.Item>
          ) : null}

          {previewUser?.permissions.map((permission: any) => {
            const _perms = permission.split('.');
            const _group = get(_perms, '[0]');
            const _perm = get(_perms, '[1]');

            return (
              <Descriptions.Item label={startCase(_group)}>
                {_perm === 'edit' ? 'Can Edit' : 'Can View'}
              </Descriptions.Item>
            );
          })}
        </Descriptions>
      </When>
    </Drawer>
  );
};

export default UserDetails;
