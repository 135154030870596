import { FC, useEffect, useState } from 'react';
import { Button, Select, Space } from 'antd';
import { IoMdThumbsUp, IoMdThumbsDown } from 'react-icons/io';
import { ApprovalSectionContainer } from './styles';
import { rejectionReasons } from './constants';
import { isEmpty } from 'lodash';

type Props = {
  data: {
    status: string;
    reason: string;
  };
  isDisabled?: boolean;
  onChange: (status: string, reason: string | null) => void;
};

const SectionApproval: FC<Props> = ({ data, onChange, isDisabled = false }) => {
  const [status, setStatus] = useState(data.status);
  const [reason, setReason] = useState<string | null>(data.reason);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsReady(true);
    }, 100);
  }, []);

  useEffect(() => {
    if (isReady) {
      const _reason = isEmpty(reason) ? null : reason;
      onChange(status, _reason);
    }
  }, [status, reason]);

  return (
    <ApprovalSectionContainer>
      {status === 'rejected' && (
        <Select
          disabled={isDisabled}
          style={{ width: 200 }}
          placeholder="Select a rejection reason"
          optionFilterProp="children"
          defaultValue={reason}
          onChange={(value) => setReason(value)}
          options={rejectionReasons}
        />
      )}

      <Button
        disabled={isDisabled}
        icon={<IoMdThumbsDown />}
        type={status === 'rejected' ? 'primary' : 'default'}
        danger={status === 'rejected'}
        onClick={() => setStatus('rejected')}
      />

      <Button
        disabled={isDisabled}
        icon={<IoMdThumbsUp />}
        type={status === 'approved' ? 'primary' : 'default'}
        style={status === 'approved' ? { backgroundColor: '#52C41A' } : {}}
        onClick={() => {
          setStatus('approved');
          setReason(null);
        }}
      />
    </ApprovalSectionContainer>
  );
};

export default SectionApproval;
