import { FC } from 'react';
import { SalesIncentiveCompletion } from '@/types';
import { Case, Default, Switch } from 'react-if';
import { Tag } from 'antd';
import { startCase } from 'lodash';

type Args = {
  completion: SalesIncentiveCompletion | null;
};

const ScanStatusTag: FC<Args> = ({ completion }) => {
  const processor = completion?.analysisProcessor || 'verify';
  return (
    <Switch>
      <Case
        condition={
          completion?.scanState === 'zzz' && completion?.scanProcessing
        }
      >
        <Tag color={'geekblue'}>In Queue</Tag>
      </Case>
      <Case
        condition={
          completion?.scanState === 'zzz' && !completion?.scanProcessing
        }
      >
        <Tag>Not Requested</Tag>
      </Case>
      <Case condition={completion?.scanState === 'failed'}>
        <Tag color="error">{startCase(processor)} | Failed</Tag>
      </Case>
      <Case condition={completion?.scanState === 'trying'}>
        <Tag color="warning">{startCase(processor)} | Trying</Tag>
      </Case>
      <Case condition={completion?.scanState === 'toReview'}>
        <Tag color="orange">{startCase(processor)} | Take a look!</Tag>
      </Case>
      <Case condition={completion?.scanState === 'good'}>
        <Tag color={'success'}>{startCase(processor)} | Looks good</Tag>
      </Case>
      <Default>
        <span className="inactive-text">(not set)</span>
      </Default>
    </Switch>
  );
};

export default ScanStatusTag;
