import Layout from '@/components/Layout';
import PageHeader from '@/components/PageHeader';
import { useAudit } from '@/hooks';
import { getSubmissionCompletions } from '@/services/sales-incentive.service';
import { Button, DatePicker, message } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { saveAs } from 'file-saver';
import { useState } from 'react';
import * as XLSX from 'xlsx';
import { Container } from './styles';

const { RangePicker } = DatePicker;

export function Report() {
  const audit = useAudit({
    resourceName: 'export-report',
  });

  const [dateFilter, setDateFilter] = useState<{
    startDate: string;
    endDate: string;
  }>({
    startDate: '',
    endDate: '',
  });

  const [isValid, setIsValid] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const disabledDate = (current: Dayjs) => {
    if (!dateFilter.startDate) {
      return false;
    }
    const startDate = dayjs(dateFilter.startDate);
    const maxEndDate = startDate.add(90, 'day');
    return (
      current &&
      (current.isBefore(startDate, 'day') || current.isAfter(maxEndDate, 'day'))
    );
  };

  const downloadReport = async () => {
    audit.onClick({ target: 'download-report', data: dateFilter });
    const { startDate, endDate } = dateFilter;
    messageApi.open({
      type: 'loading',
      content: 'Downloading report...',
      duration: 0,
    });
    try {
      const items = await getSubmissionCompletions(startDate, endDate);
      const worksheet = XLSX.utils.json_to_sheet(items);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Report');

      // Gerar o buffer do Excel
      const excelBuffer = XLSX.write(workbook, {
        bookType: 'xlsx',
        type: 'array',
      });

      // Criar um blob a partir do buffer
      const blob = new Blob([excelBuffer], {
        type: 'application/octet-stream',
      });

      // Salvar o arquivo usando file-saver
      saveAs(blob, `report_${startDate}_to_${endDate}.xlsx`);
    } catch (error) {
      message.error('Failed to download report');
    }
    messageApi.destroy();
  };

  const handleChange = (
    dates: [Dayjs | null, Dayjs | null] | null,
    datesString: [string, string] | null,
  ) => {
    if (dates && dates[0] && dates[1]) {
      const startDate = dates[0].toDate();
      const endDate = dates[1].toDate();
      const diffDays = dayjs(endDate).diff(dayjs(startDate), 'day');
      if (diffDays > 90) {
        message.error('Maximum range is 90 days');
        setIsValid(false);
        return;
      }
      setIsValid(true);
    }
    setDateFilter({
      startDate: datesString ? datesString[0] : '',
      endDate: datesString ? datesString[1] : '',
    });
  };

  return (
    <Layout>
      {contextHolder}
      <PageHeader noBackButton title="Reports" />
      <Container>
        <div className="item">
          <span>Export Submissions: </span>
          <RangePicker onChange={handleChange} disabledDate={disabledDate} />
          <Button onClick={downloadReport} disabled={!isValid} type="primary">
            Export
          </Button>
        </div>
      </Container>
    </Layout>
  );
}
