import styled from 'styled-components';

export const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  .map {
    width: 100%;
    height: 400px;
    border-radius: 10px;
    overflow: hidden;
  }
`;

export const Container = styled.div`
  display: flex;
  height: 100%;
  padding-bottom: 46px;
  flex-direction: column;
  .actions {
    button {
      margin-top: 32px;
    }
  }
`;

export const TabsControlContainer = styled.div`
  .ant-tabs-nav {
    margin-bottom: 0;
    padding: 0 8px;

    &::before {
      border: none;
    }
  }
`;

export const TabsContainer = styled.div`
  background-color: #edf3f7;
  min-height: 100%;
  padding: 24px;
  border-radius: 10px;
`;

export const TableContainer = styled.div`
  margin-top: 24px;
`;
