import { WebUser } from "@/types";
import { formatISODate } from "@/utils";
import { Descriptions, Divider, Drawer, Typography } from "antd";
import { FC } from "react";
import BrandTagsRender from "../BrandTagsRender";

type Props = {
  visible: boolean;
  onClose: () => void;
  user: WebUser | null;
};

const DrawerUserView: FC<Props> = ({
  visible,
  onClose,
  user,
}) => {

  return (
    <Drawer
      title="Web User Quick View"
      placement="right"
      size="large"
      closable={false}
      onClose={onClose}
      open={visible}
    >
      <Descriptions
        title="User Info"
        column={{
          xs: 1,
          sm: 1,
          md: 1,
          lg: 2,
          xl: 2,
          xxl: 2,
        }}
      >
        <Descriptions.Item label="ID">
          <Typography.Text copyable>{user?.id}</Typography.Text>
        </Descriptions.Item>
        <Descriptions.Item label="Name">
          {user?.fullName}
        </Descriptions.Item>
        <Descriptions.Item label="Email">
          <Typography.Text copyable>{user?.email}</Typography.Text>
        </Descriptions.Item>
        {user?.createdAt && (
          <Descriptions.Item label="Registered At">
            {formatISODate(user?.createdAt)}
          </Descriptions.Item>
        )}
      </Descriptions>

      <Divider />

      <Typography.Title level={5}>Brands ({user?.brands.length})</Typography.Title>
      {
        user?.brands.length === 0 ? (
          <Typography.Text type="secondary">No brands</Typography.Text>
        ) : (
          <BrandTagsRender
            items={user?.brands || []}
            max={user?.brands.length}
          />
        )
      }
    </Drawer>
  );

}

export default DrawerUserView;