import { handleError, httpDelete, httpPost } from './util/axios';

export const requestLoginVerificationCode = async (email: string) => {
  try {
    return httpPost('auth', 'admin/login/request-code-verification', {
      email,
    });
  } catch (error) {
    console.error(`fail on requestLoginVerificationCode => ${error}`);
  }
};

export const verifyLoginCode = async (email: string, code: string) => {
  try {
    return httpPost('auth', 'admin/login/verify-code', {
      email,
      code,
    });
  } catch (error) {
    console.error(`fail on verifyLoginCode => ${error}`);
  }
};

export const logout = async (navigate: any) => {
  try {
    const data = await httpDelete('auth', 'admin/revoke-access-token');
    return data;
  } catch (error: any) {
    handleError(error, navigate);
  }
};
