import { Layout } from 'antd';
import styled from 'styled-components';

export const Container = styled(Layout)`
  .layout-header {
    padding-left: 0;

    .logo {
      width: 160px;
      height: 44px;
      margin-left: 20px;
      margin-top: 6px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      background-image: url(${require('../../static/images/white-logo.png')});
    }
  }

  .layout-body {
    .layout-sider {
      padding-top: 16px;

      .ant-menu-item-icon {
        width: 18px;
        height: 18px;
        font-size: 18px;
      }

      .ant-menu-sub {
        li {
          padding-left: 36px !important;
          span {
            font-size: 12px;
          }
        }
      }
    }

    .layout-container {
      //padding: 16px 24px 0;
      background-color: #edf3f7;
      display: flex;
      flex-direction: column;
      align-items: center;
      min-height: calc(100vh - 64px);

      .container-wrapper {
        width: 100%;
        padding: 16px 24px 0;
        flex: 1;
        display: flex;
        flex-direction: column;
      }

      .layout-content {
        padding: 24px;
        margin: 0;
        min-height: 280px;
        border-radius: 10px;
        min-width: 1240px;
        width: 100%;
        max-width: 100%;
        flex: 1;
        position: relative;
        height: 100%;
        display: flex;
        flex-direction: column;
      }

      .layout-footer {
        text-align: center;
        background-color: inherit;
        width: 100%;
        min-width: 1240px;
        max-width: 100%;
      }
    }
  }

  .nested-table-container {
    padding-left: 32px;
  }

  /* @media (max-width: 768px) {
    .layout-body {
      flex-direction: row;
    }
  } */
`;

export const RightMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  .ant-avatar {
    background-color: #cfcfcf;
    border: 2px solid #fff;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
