import {
  Avatar,
  Button,
  Col,
  Descriptions,
  Divider,
  Form,
  Input,
  InputNumber,
  notification,
  Radio,
  Row,
  Select,
  Space,
  Switch as SwitchInput,
} from 'antd';
import { assignWith, get, groupBy, map, startCase } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { BrandDescription, FormButtons, FormContainer } from './styles';

import {
  createBrand,
  getFormData,
  getIndustries,
  inactiveBrandUsers,
  updateBrand,
} from '@/services/brand.service';
// import { getAllConnectors } from '@/services/integration-connector.service';
import { useAudit } from '@/hooks';
import { formatISODate } from '@/utils';
import currencies from '@/data/countries-currencies';
import regions from '@/data/countries-regions';

import LoadingPage from '@/pages/Loading';
import CropUpload from '@/components/CropUpload';
import Layout from '@/components/Layout';
import PageHeader from '@/components/PageHeader';
import { getNonRetailerCompanies } from '@/services/company.service';

type BrandData = {
  id: string;
  ownerId: string;
  name: string;
  logoUrl: string;
  updatedAt: string;
  createdAt: string;
  hasUpdate: boolean;
  isInactive: boolean;
  activeMissionCompletionNotification: boolean;
  eachMissionCompletionNotification: number | null;
  hasNotified: boolean;
  isHidden: boolean;
  settings: {
    smartReview: {
      enabled: boolean;
      changedAt: string;
      changedByUserId: string;
      service: string;
    };
    smartReviewAnalysis: {
      provider: 'default' | 'verify' | 'azure' | 'hive';
      modelId?: string;
    };
    plan: 'prepaid' | 'postpaid';
    currency: string;
    notifyCampaignLowBudget: number;
    stripe: {
      customerId: string;
    };
    consumerEnabled: boolean;
  };
  services: (
    | { type: 'ai_scan'; name: string; value: number; frequency: string }
    | { type: 'prize'; name: string; value: number }
  )[];
  currency: string;
  countries: string[];
  industries: string[];
  privacyPolicyUrl: string;
  billingCountry: string;
  description: string;
  segment: string;
  billingEmail: string;
  language: string;
  owner: {
    id: string;
    name: string;
    alias: string;
  };
  smartReviewAnalysis: {
    provider: string;
    modelId: string;
  };
  hubspotReferenceId: string;
  integration: {
    enabled: boolean;
    connectors: string[];
  };
};

type FormData = Pick<
  BrandData,
  | 'name'
  | 'segment'
  | 'currency'
  | 'language'
  | 'countries'
  | 'industries'
  | 'isHidden'
  | 'isInactive'
  | 'activeMissionCompletionNotification'
  | 'eachMissionCompletionNotification'
  | 'services'
  | 'hubspotReferenceId'
  | 'logoUrl'
> & {
  settings: Pick<
    BrandData['settings'],
    | 'plan'
    | 'notifyCampaignLowBudget'
    | 'smartReviewAnalysis'
    | 'consumerEnabled'
  >;
  notifyAllUsers?: boolean;
};

const DEFAULT_SERVICES: BrandData['services'] = [
  {
    type: 'ai_scan',
    name: 'Smart Review',
    frequency: 'weekly',
    value: 0,
  },
  {
    type: 'prize',
    name: 'Prizes',
    value: 5,
  },
];

const BRAND_DEFAULTS: Partial<FormData> = {
  currency: 'USD',
  language: 'en',
  industries: [],
  countries: [],
  isHidden: false,
  isInactive: false,
  settings: {
    plan: 'postpaid',
    notifyCampaignLowBudget: 0,
    smartReviewAnalysis: {
      provider: 'default',
    },
    consumerEnabled: false,
  },
  activeMissionCompletionNotification: false,
  eachMissionCompletionNotification: null,
  services: DEFAULT_SERVICES,
};

export const CreateEditForm = ({
  formAction,
}: {
  formAction: 'create' | 'edit';
}) => {
  const navigate = useNavigate();
  const { brandId } = useParams<{ brandId: string }>();

  const [pageLoaded, setPageLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [industries, setIndustries] = useState<{ id: string; name: string }[]>(
    [],
  );
  const [companies, setCompanies] = useState<{ id: string; label?: string }[]>(
    [],
  );
  // const [connectors, setConnectors] = useState<any[]>([]);
  const [brandData, setBrandData] = useState<BrandData | null>(null);

  const [form] = Form.useForm<FormData>();
  const isInactive = Form.useWatch('isInactive', form);
  const activeMissionCompletionNotification = Form.useWatch(
    'activeMissionCompletionNotification',
    form,
  );
  // The form value is not defined in the first render
  const services = Form.useWatch('services', form) || DEFAULT_SERVICES;
  const isHiddenBrand = Form.useWatch('isHidden', form) ?? false;
  const smartReviewAnalysisProvider = Form.useWatch(
    ['settings', 'smartReviewAnalysis', 'provider'],
    form,
  );

  const audit = useAudit({
    resourceName: formAction === 'create' ? 'brand-create' : 'brand-edit',
  });

  const regionOptions = useMemo(() => {
    const groups = groupBy(regions, 'region');

    return map(groups, (group, region) => ({
      label: region,
      options: group.map((item) => ({
        label: item.name,
        value: item.countryCode,
      })),
    }));
  }, []);

  const initialValues = useMemo(() => {
    if (formAction === 'create') {
      return BRAND_DEFAULTS;
    }

    if (!brandData) return null;

    // Uses the default values for any missing values
    return assignWith(brandData, BRAND_DEFAULTS, (value, defaultValue) => {
      return value === undefined ? defaultValue : value;
    });
  }, [formAction, brandData]);

  const cancelEdit = () => {
    audit.onClick({
      target: formAction === 'create' ? 'cancel-create' : 'cancel-edit',
    });
    navigate('/brand');
  };

  const onFinish = async (values: FormData) => {
    if (!brandId && formAction === 'edit') return;

    setLoading(true);

    let result;

    if (formAction === 'create') {
      try {
        result = await createBrand(values);
      } catch (error) {}
    } else {
      const isInactive = values.isInactive || false;

      if (isInactive) {
        await inactiveBrandUsers(brandId!);
      }

      try {
        result = await updateBrand(brandId!, {
          ...values,
          isInactive,
        });
      } catch (error) {}
    }

    setLoading(false);

    if (!result || result.status === false) {
      notification.error({
        message: 'Error',
        description:
          result?.message ||
          (formAction === 'create'
            ? 'Failed to create brand for unknown reasons'
            : 'Failed to update brand for unknown reasons'),
      });
      return;
    }

    if (formAction === 'create') {
      audit.onCreate({ target: 'brand-create', data: values });
    } else {
      audit.onUpdate({ target: 'brand-edit', data: values });
    }

    notification.success({
      message: 'Success',
      description:
        formAction === 'create'
          ? `Brand ${values.name} has been created successfully!`
          : `Brand ${values.name} has been updated successfully!`,
    });
    navigate('/brand');
  };

  const loadIndustries = async () => {
    const { industries } = await getIndustries();

    setIndustries(industries);
  };

  const loadCompanies = async () => {
    const companies = await getNonRetailerCompanies();
    if (companies) {
      setCompanies(companies);
    }
  };

  // const loadConnectors = async () => {
  //   const connectors = await getAllConnectors();
  //   setConnectors(connectors);
  // };

  const loadFormData = async () => {
    if (formAction === 'edit') {
      if (!brandId) return;

      const { brand } = await getFormData(brandId);

      if (!brand) {
        navigate('/brand');
      }

      setBrandData((current) => {
        let services = get(brand, 'services', []);

        if (!services.length) {
          services = DEFAULT_SERVICES;
        }

        return {
          ...current,
          ...brand,
          services,
        };
      });
    }

    setTimeout(() => {
      setPageLoaded(true);
    }, 0);

    loadIndustries();
    if (formAction === 'create') {
      loadCompanies();
    }
    // loadConnectors();
  };

  useEffect(() => {
    audit.onAccess();
    loadFormData();
  }, []);

  useEffect(() => {
    if (!initialValues) return;

    if (!activeMissionCompletionNotification) {
      form.setFieldsValue({
        eachMissionCompletionNotification: null,
      });
    }
    // The effect should not be executed when the initial values change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeMissionCompletionNotification, form]);

  useEffect(() => {
    if (!initialValues) return;

    if (isHiddenBrand) {
      form.setFieldsValue({ notifyAllUsers: false });
    }
    // The effect should not be executed when the initial values change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, isHiddenBrand]);

  if (!pageLoaded || !initialValues) {
    return <LoadingPage />;
  }

  return (
    <Layout>
      <PageHeader
        title={formAction === 'create' ? 'Create Brand' : 'Edit Brand'}
      />

      <FormContainer>
        <Form
          form={form}
          layout="vertical"
          autoComplete="off"
          onFinish={onFinish}
          initialValues={initialValues}
          disabled={loading}
        >
          <Row gutter={16} style={{ alignItems: 'flex-start' }}>
            <Col span={16}>
              <Row gutter={16}>
                <Col span={formAction === 'create' ? 12 : 24}>
                  <Form.Item
                    label="Name"
                    name="name"
                    rules={[{ required: true }]}
                  >
                    <Input placeholder="Enter the brand name" />
                  </Form.Item>
                </Col>

                {formAction === 'create' && (
                  <Col span={12}>
                    <Form.Item
                      label="Company"
                      name="ownerId"
                      rules={[{ required: true }]}
                    >
                      <Select
                        placeholder="Select a company"
                        showSearch
                        options={companies.map((company) => ({
                          value: company.id,
                          label:
                            company.label?.trim() ||
                            `ID: ${company.id} (name not set)`,
                          hasName: Boolean(company.label?.trim()),
                        }))}
                        optionRender={(option) => {
                          return option.data.hasName ? (
                            option.label
                          ) : (
                            <span style={{ color: '#999' }}>
                              {option.label}
                            </span>
                          );
                        }}
                        filterOption={(input, option) => {
                          return (option?.label ?? '')
                            .toLowerCase()
                            .includes(input.toLowerCase());
                        }}
                      />
                    </Form.Item>
                  </Col>
                )}
              </Row>

              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item
                    name="segment"
                    label="Segment"
                    rules={[{ required: !isInactive }]}
                  >
                    <Select placeholder="Select a segment">
                      {['growth', 'emerging', 'enterprise'].map((segment) => (
                        <Select.Option key={segment} value={segment}>
                          {startCase(segment)}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="currency"
                    label="Currency"
                    rules={[{ required: !isInactive }]}
                  >
                    <Select showSearch placeholder="Select a currency">
                      {map(
                        groupBy(currencies, 'currency'),
                        (data, currency) => (
                          <Select.Option key={currency} value={currency}>
                            <Space>
                              <Avatar
                                size={22}
                                src={`https://raw.githubusercontent.com/Lissy93/currency-flags/master/assets/flags_svg/${currency.toLowerCase()}.svg`}
                                shape="square"
                              />
                              {currency}
                            </Space>
                          </Select.Option>
                        ),
                      )}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="language"
                    label="Language"
                    rules={[{ required: !isInactive }]}
                  >
                    <Select placeholder="Select a language">
                      <Select.Option value="en">English</Select.Option>
                      <Select.Option value="fr">French</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item
                    name="countries"
                    label="Region"
                    rules={[{ required: !isInactive }]}
                  >
                    <Select
                      showSearch
                      mode={'multiple'}
                      placeholder="Select at least 1 region"
                      options={regionOptions}
                      maxTagCount="responsive"
                    />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item name="industries" label="Industry">
                    <Select
                      mode="multiple"
                      maxTagCount="responsive"
                      placeholder="Select a industry"
                    >
                      {industries.map((industry) => (
                        <Select.Option key={industry.id} value={industry.id}>
                          {industry.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item name={['settings', 'plan']} label="Payment Plan">
                    <Select placeholder="Select a payment plan">
                      <Select.Option value="prepaid">Prepaid</Select.Option>
                      <Select.Option value="postpaid">Postpaid</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Divider orientation="left" orientationMargin="0">
                Brand Status
              </Divider>

              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item
                    name="isHidden"
                    label="Hidden"
                    valuePropName="checked"
                    help="If enabled, the brand will be hidden from the brand catalog search, not receiving new connection requests"
                  >
                    <SwitchInput checkedChildren="Yes" unCheckedChildren="No" />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    name="isInactive"
                    label="Inactive"
                    valuePropName="checked"
                    help="If enabled, brand will be inactive and will not be able to be selected in WebApp and Web users are going to be disconnected to this brand (not the Endvr Team)"
                  >
                    <SwitchInput checkedChildren="Yes" unCheckedChildren="No" />
                  </Form.Item>
                </Col>
              </Row>

              <Divider orientation="left" orientationMargin="0">
                Notifications
              </Divider>

              <Row gutter={16}>
                <Col span={formAction === 'create' ? 8 : 12}>
                  <Form.Item
                    name={['settings', 'notifyCampaignLowBudget']}
                    rules={[{ type: 'number' }]}
                    normalize={(value) => {
                      const parsedValue = parseInt(value);
                      return isNaN(parsedValue) ? 0 : parsedValue;
                    }}
                    label="Low Budget Notification"
                    help="Notify when a campaign is running low on budget"
                  >
                    <Select placeholder="Use global preferences" allowClear>
                      <Select.Option value={0}>Never</Select.Option>
                      <Select.Option value={50}>Over 50%</Select.Option>
                      <Select.Option value={60}>Over 60%</Select.Option>
                      <Select.Option value={70}>Over 70%</Select.Option>
                      <Select.Option value={80}>Over 80%</Select.Option>
                      <Select.Option value={90}>Over 90%</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={formAction === 'create' ? 8 : 12}>
                  <Form.Item label="Pending Completions Notification">
                    <Space>
                      <Form.Item
                        name="activeMissionCompletionNotification"
                        valuePropName="checked"
                      >
                        <SwitchInput
                          checkedChildren="Enabled"
                          unCheckedChildren="Disabled"
                        />
                      </Form.Item>
                      <Form.Item
                        name="eachMissionCompletionNotification"
                        help="Completions for notification trigger"
                        shouldUpdate={true}
                        rules={[
                          { required: activeMissionCompletionNotification },
                        ]}
                      >
                        <InputNumber
                          style={{ width: 150 }}
                          min={1}
                          max={100}
                          disabled={!activeMissionCompletionNotification}
                        />
                      </Form.Item>
                    </Space>
                  </Form.Item>
                </Col>

                {formAction === 'create' && (
                  <Col span={8}>
                    <Form.Item
                      name="notifyAllUsers"
                      label="Notify Store Managers/Owners"
                      valuePropName="checked"
                      help="Notify managers/owners of stores from the same region and industries that the brand has been created"
                    >
                      <SwitchInput
                        checkedChildren="Yes"
                        unCheckedChildren="No"
                        disabled={isHiddenBrand}
                      />
                    </Form.Item>
                  </Col>
                )}
              </Row>

              <Divider orientation="left" orientationMargin="0">
                Services
              </Divider>

              <table className="service-table">
                <thead>
                  <tr>
                    <th>Service</th>
                    <th>Frequency</th>
                    <th>Value</th>
                  </tr>
                </thead>
                <tbody>
                  <Form.List name="services">
                    {(fields) =>
                      fields.map(({ key, name, ...restField }) => (
                        <tr key={key}>
                          {services[key]['type'] === 'ai_scan' ? (
                            <>
                              <td className="type">Smart Review</td>

                              <td>
                                <Form.Item
                                  {...restField}
                                  name={[name, 'frequency']}
                                >
                                  <Select style={{ width: 200 }}>
                                    <Select.Option value="weekly">
                                      Weekly
                                    </Select.Option>
                                    <Select.Option value="wonthly">
                                      Monthly
                                    </Select.Option>
                                  </Select>
                                </Form.Item>
                              </td>

                              <td>
                                <Form.Item
                                  {...restField}
                                  name={[name, 'value']}
                                >
                                  <InputNumber
                                    formatter={(value) =>
                                      `$ ${value}`.replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ',',
                                      )
                                    }
                                    parser={(value) =>
                                      value!.replace(/\$\s?|(,*)/g, '')
                                    }
                                  />
                                </Form.Item>
                              </td>
                            </>
                          ) : services[key]['type'] === 'prize' ? (
                            <>
                              <td className="type">Prizes</td>
                              <td></td>
                              <td>
                                <Form.Item
                                  {...restField}
                                  name={[name, 'value']}
                                >
                                  <InputNumber
                                    min={0}
                                    max={100}
                                    formatter={(value) => `${value}%`}
                                  />
                                </Form.Item>
                              </td>
                            </>
                          ) : null}
                        </tr>
                      ))
                    }
                  </Form.List>
                </tbody>
              </table>

              <Divider orientation="left" orientationMargin="0">
                Smart Review Analysis
              </Divider>

              <Form.Item
                name={['settings', 'smartReviewAnalysis', 'provider']}
                label="Provider"
                rules={[{ required: false }]}
                help="If not selected, will override store preference"
              >
                <Radio.Group buttonStyle="solid">
                  <Radio.Button value="default">Store Preference</Radio.Button>
                  <Radio.Button value="verify">Verify</Radio.Button>
                  <Radio.Button value="azure">Azure</Radio.Button>
                  <Radio.Button value="hive">Hive</Radio.Button>
                </Radio.Group>
              </Form.Item>

              {smartReviewAnalysisProvider === 'azure' && (
                <Form.Item
                  name={['settings', 'smartReviewAnalysis', 'modelId']}
                  label="Model ID"
                  rules={[{ required: true }]}
                  wrapperCol={{ span: 12 }}
                >
                  <Input placeholder="Enter the model ID" />
                </Form.Item>
              )}

              <br />

              <Divider orientation="left" orientationMargin="0">
                Integrations
              </Divider>

              {/* <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    label="Enabled"
                    name={['integration', 'enabled']}
                    valuePropName="checked"
                    help="If enabled, the brand will be able to see the POS integration menu"
                  >
                    <SwitchInput checkedChildren="Yes" unCheckedChildren="No" />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    name={['integration', 'connectors']}
                    label="Enabled Connectors"
                  >
                    <Select
                      mode="multiple"
                      showSearch
                      placeholder="Select a connector"
                    >
                      {connectors.map((row, i) => (
                        <Select.Option
                          key={`option-${row.id}-${i}`}
                          value={row.id}
                        >
                          <Space>
                            <Avatar
                              size={20}
                              src={row.logoUrl}
                              shape="square"
                            />

                            <Typography.Text>{row.name}</Typography.Text>
                          </Space>
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row> */}

              <Row gutter={16}>
                <Col span={16}>
                  <Form.Item
                    name="hubspotReferenceId"
                    label="Hubspot Reference ID"
                    help="The Hubspot Reference ID is used to identify the brand in Hubspot"
                  >
                    <Input placeholder="Enter the Hubspot Reference ID" />
                  </Form.Item>
                </Col>
              </Row>

              <Divider orientation="left" orientationMargin="0">
                Consumer Marketing
              </Divider>

              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    label="Enabled"
                    name={['settings', 'consumerEnabled']}
                    valuePropName="checked"
                    help="If enabled, the brand will be able to create consumer marketing campaigns"
                  >
                    <SwitchInput checkedChildren="Yes" unCheckedChildren="No" />
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <Col span={1}>
              <Divider type="vertical" style={{ height: '70vh' }} />
            </Col>

            <Col span={7}>
              <Form.Item
                name="logoUrl"
                label="Logo"
                valuePropName="value"
                rules={[
                  {
                    required: !isInactive,
                    message: 'Please upload and crop the image!',
                  },
                ]}
              >
                <CropUpload />
              </Form.Item>

              <Divider />

              {formAction === 'edit' && brandData !== null && (
                <BrandDescription
                  title="Extra Details"
                  column={1}
                  layout="vertical"
                >
                  <Descriptions.Item label="Company Owner">
                    {brandData.owner.name || (
                      <div
                        style={{
                          backgroundColor: '#f0f2f5',
                          padding: '8px',
                        }}
                      >
                        {brandData.owner.alias}
                      </div>
                    )}
                  </Descriptions.Item>
                  <Descriptions.Item label="Registration">
                    {formatISODate(brandData.createdAt)}
                  </Descriptions.Item>
                  <Descriptions.Item label="Last Update">
                    {formatISODate(brandData.updatedAt)}
                  </Descriptions.Item>
                </BrandDescription>
              )}
            </Col>
          </Row>

          <FormButtons>
            <Button onClick={cancelEdit}>Cancel</Button>

            <Button type="primary" htmlType="submit" loading={loading}>
              Save
            </Button>
          </FormButtons>
        </Form>
      </FormContainer>
    </Layout>
  );
};
