import { useEffect, useMemo, useState } from 'react';
import { Input, Modal, notification, Spin } from 'antd';
import { useMutation, useQuery } from '@tanstack/react-query';
import styled from 'styled-components';

import { getStoreDetails } from '@/services/store.service';
import { changeUserStore } from '@/services/mobile-user.service';
import { useAudit, useTable } from '@/hooks';
import type { Store } from '@/types';

import { StorePickerPrimitive } from '@/components/StoreGroupModalSelector/StorePicker';

type FormData = {
  storeId: string;
};

/**
 * The modal open state can be controlled in two ways:
 * 1. By not providing the `open` prop, the modal will be open if the `userId`
 *    is defined
 * 2. By providing the `open` prop (which requires the `userId` prop to be
 *    defined), the modal will be open if the `open` prop is true
 */
export const ChangeStoreModal = ({
  open,
  userId,
  storeId,
  onChangeStore,
  onCancel,
}: (
  | { open?: never; userId: string | undefined }
  | { open: boolean; userId: string }
) & {
  storeId: string | undefined;
  onChangeStore: () => void;
  onCancel: () => void;
}) => {
  const isModalOpen = useMemo(
    () => open ?? userId !== undefined,
    [open, userId],
  );
  const [selectedStore, setSelectedStore] = useState<Store>();

  const { data: currentStore, isLoading: isLoadingCurrentStore } = useQuery({
    queryKey: ['get-store-details', storeId],
    queryFn: async () => {
      if (!storeId) return null;

      const result = await getStoreDetails(storeId);
      if (!result) return null;

      return result.store;
    },
    enabled: isModalOpen,
  });

  const [searchText, setSearchText] = useState('');
  const { tableProps } = useTable<Store>({
    service: 'store/read',
    path: `admin/list`,
    perPage: 5,
    params: {
      // Filter out the current store from the list
      selected: [currentStore?.id],
      filters: {
        text: searchText,
        removeSelected: true,
      },
    },
    defaultSort: {
      field: 'updatedAt',
      order: 'descend',
    },
    enabled: isModalOpen && !isLoadingCurrentStore,
  });

  const audit = useAudit({ resourceName: 'mobile-user' });

  const { mutate: handleChangeStore, isPending: isChangingStore } = useMutation(
    {
      mutationFn: async (values: FormData) => {
        if (!userId) return;

        let result;
        try {
          result = await changeUserStore(userId, values.storeId);
        } catch (error) {}

        if (!result) {
          notification.error({ message: 'Failed to update user store' });
          return;
        }

        audit.onUpdate({
          target: 'change-store',
          data: {
            userId,
            newStoreId: values.storeId,
          },
        });
        notification.success({ message: 'User store updated successfully' });

        onChangeStore();
      },
    },
  );

  // Reset the search text when the modal is closed
  useEffect(() => {
    if (!isModalOpen) {
      setSearchText('');
    }
  }, [isModalOpen]);

  return (
    <Modal
      title="Change User Store"
      open={isModalOpen}
      okText="Confirm"
      okButtonProps={{ disabled: isChangingStore || !selectedStore }}
      destroyOnClose
      onOk={() => {
        if (!selectedStore) return;
        handleChangeStore({ storeId: selectedStore.id });
      }}
      onCancel={onCancel}
    >
      {isLoadingCurrentStore || !tableProps.dataSource ? (
        <StyledSpin />
      ) : (
        <>
          {currentStore ? (
            <p>
              <strong>Current Store:</strong>{' '}
              {currentStore.name.trim() ? (
                currentStore.name.trim()
              ) : (
                <span style={{ color: '#999' }}>
                  ID: {currentStore.id} (name not set)
                </span>
              )}
            </p>
          ) : (
            <p>
              This user does not have a valid store associated with their
              account
            </p>
          )}

          {selectedStore ? (
            <p>
              <strong>Selected store:</strong> {selectedStore.name}
            </p>
          ) : (
            <p>No store selected</p>
          )}

          <Input.Search
            style={{ marginBottom: 16 }}
            placeholder="Search by store name"
            allowClear
            onSearch={(value) => {
              setSearchText(value);
            }}
          />

          <StorePickerPrimitive
            tableProps={tableProps}
            selectedRowKeys={selectedStore?.id}
            setSelectedRowKeys={(_id, store) => {
              setSelectedStore(store);
            }}
          />
        </>
      )}
    </Modal>
  );
};

const StyledSpin = styled(Spin)`
  display: block;
  margin: 1rem auto;
`;
