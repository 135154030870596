import styled from 'styled-components';

export const CustomSectionHeader = styled.div`
  display: flex;
  gap: 16px;

  .ant-divider {
    flex: 1;

    min-width: calc(100% - 130px);
  }

  .ant-form-item {
    flex: 1;
  }
`;

export const PriceItemContainer = styled.div`
  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    > strong {
      font-size: 18px;
      font-weight: 500;
    }
  }

  .description {
  }
`;

export const VisibilityContainer = styled.div`
  .ant-segmented-group {
    .ant-segmented-item-selected {
      &.green {
        background-color: #52c41a;
        color: #fff;
      }

      &.red {
        background-color: #ff4d4f;
        color: #fff;
      }
    }
  }
`;
