import 'antd/dist/reset.css';
import { createGlobalStyle, css } from 'styled-components';

const reset = css`
  /*
  1. Prevent padding and border from affecting element width. (https://github.com/mozdevs/cssremedy/issues/4)
  2. Allow adding a border to an element by just adding a border-width. (https://github.com/tailwindcss/tailwindcss/pull/116)
  */

  *,
  ::before,
  ::after {
    box-sizing: border-box; /* 1 */
    border-width: 0; /* 2 */
    border-style: solid; /* 2 */
    border-color: black; /* 2 */
  }

  /*
  1. Change the font styles in all browsers.
  2. Remove the margin in Firefox and Safari.
  3. Remove default padding in all browsers.
  */

  button,
  input,
  optgroup,
  select,
  textarea {
    font-family: inherit; /* 1 */
    font-feature-settings: inherit; /* 1 */
    font-variation-settings: inherit; /* 1 */
    font-size: 100%; /* 1 */
    font-weight: inherit; /* 1 */
    line-height: inherit; /* 1 */
    letter-spacing: inherit; /* 1 */
    color: inherit; /* 1 */
    margin: 0; /* 2 */
    padding: 0; /* 3 */
  }

  /*
  Set the default cursor for buttons.
  */

  button,
  [role='button'] {
    cursor: pointer;
  }

  /*
  Make sure disabled buttons don't get the pointer cursor.
  */
  :disabled {
    cursor: default;
  }
`;

export default createGlobalStyle`
  ${reset}

  .pac-container {
    z-index: 1200;
  }

  #root {
    > .ant-layout {
      min-height: 100vh;
    }
  }

  .table-footer-drawer {
    position: sticky;
    width: 100%;
    bottom: 0;

    .ant-drawer-content-wrapper {
      box-shadow: 0px -8px 8px 0px #0000000D;

      .ant-drawer-body {
        display: flex;
        align-items: center;
        padding: 0 24px;
        justify-content: space-between;
      }
    }
  }

  .ant-modal-root {
  }

  .store-group-modal-selector {
    .ant-modal-wrap {
      .ant-modal {
        .ant-modal-content {
          .ant-modal-header {
            text-align: center;
            .ant-modal-title {
              font-size: 24px;
            }
          }
        }
      }
    }
    .ant-modal-header {
      font-size: 32px;
    }
  }

  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }
`;
