import { FC } from 'react';
import { Button, Divider, notification, Popconfirm } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { When } from 'react-if';
import { ApprovalButtonsContainer } from './styles';
import {
  approveUserCashOut,
  rejectUserCashOut,
} from '@/services/cash-out.service';

type Args = {
  afterChange: () => void;
  size: 'small' | 'large';
  ids: string[];
  disabled: boolean;
};

const ApprovalButtons: FC<Args> = ({ afterChange, size, ids, disabled }) => {
  return (
    <ApprovalButtonsContainer>
      <Popconfirm
        title={
          ids.length > 1
            ? `Do you want to approve the ${ids.length} Cashout requests?`
            : 'Do you want to approve this Cashout requests?'
        }
        description={<Divider style={{ marginBottom: 4 }} />}
        onConfirm={async () => {
          const { status } = await approveUserCashOut(ids);

          if (status) {
            notification.success({
              message: 'Success',
              description: 'Cashout request approved successfully',
            });

            afterChange();
          } else {
            notification.error({
              message: 'Error',
              description: 'Something went wrong',
            });
          }
        }}
      >
        <Button
          disabled={disabled}
          icon={<CheckOutlined />}
          type={'primary'}
          size={size}
        >
          <When condition={size === 'large'}>
            Approve {ids.length > 1 && `(${ids.length})`}
          </When>
        </Button>
      </Popconfirm>

      <Popconfirm
        title={
          ids.length > 1
            ? `Do you want to reject the ${ids.length} Cashout requests?`
            : 'Do you want to reject this Cashout requests?'
        }
        description={<Divider style={{ marginBottom: 4 }} />}
        onConfirm={async () => {
          const { status } = await rejectUserCashOut(ids);

          if (status) {
            notification.success({
              message: 'Success',
              description: 'Cashout request rejected successfully',
            });

            afterChange();
          } else {
            notification.error({
              message: 'Error',
              description: 'Something went wrong',
            });
          }
        }}
      >
        <Button
          disabled={disabled}
          icon={<CloseOutlined />}
          type={'primary'}
          danger
          size={size}
        >
          <When condition={size === 'large'}>
            Reject {ids.length > 1 && `(${ids.length})`}
          </When>
        </Button>
      </Popconfirm>
    </ApprovalButtonsContainer>
  );
};

export default ApprovalButtons;
