import styled from 'styled-components';

export const Container = styled.div`
  box-sizing: border-box;
  background: #0c091e;
  height: 800px;
  width: 100vw;
  min-height: 100vh;
  position: relative;
  overflow: hidden;
  //* {
  //  box-sizing: border-box;
  //}

  .error {
    position: absolute;
    inset: 0;
  }
  ._404 {
    color: #d7d5e4;
    text-align: center;
    font-weight: 400;
    font-size: 128px;
    position: absolute;
    left: 613px;
    top: 198px;
    width: 214px;
    height: 113px;
  }
  .page-not-found {
    color: #9e9bb2;
    text-align: center;
    font-weight: 400;
    font-size: 64px;
    position: absolute;
    left: 440px;
    top: 344px;
    width: 559px;

    a {
      font-size: 18px;
      color: #9e9bb2;
      text-decoration: underline;
      position: relative;
      z-index: 10000;
    }
  }
  .go-to-homepage {
    position: absolute;
    inset: 0;
  }
  .go-to-homepage2 {
    color: #ffffff;
    text-align: right;
    font-weight: 400;
    font-size: 36.63px;
    position: absolute;
    left: 560px;
    top: 598px;
    width: 319.43px;
  }
  .arrow-back {
    position: absolute;
    left: 575.43px;
    top: 610px;
    overflow: visible;
  }
  .stars {
    position: absolute;
    inset: 0;
  }
  .star {
    width: 4px;
    height: 4px;
    position: static;
  }
  .ellipse-42 {
    background: #ffffff;
    border-radius: 50%;
    width: 4px;
    height: 4px;
    position: absolute;
    left: 1191px;
    top: 51px;
    filter: blur(2.5px);
  }
  .ellipse-41 {
    background: #ffffff;
    border-radius: 50%;
    width: 4px;
    height: 4px;
    position: absolute;
    left: 1191px;
    top: 51px;
  }
  .ellipse-422 {
    background: #ffffff;
    border-radius: 50%;
    width: 4px;
    height: 4px;
    position: absolute;
    left: 282px;
    top: 83px;
    filter: blur(2.5px);
  }
  .ellipse-412 {
    background: #ffffff;
    border-radius: 50%;
    width: 4px;
    height: 4px;
    position: absolute;
    left: 282px;
    top: 83px;
  }
  .ellipse-423 {
    background: #ffffff;
    border-radius: 50%;
    width: 4px;
    height: 4px;
    position: absolute;
    left: 70px;
    top: 57px;
    filter: blur(2.5px);
  }
  .ellipse-413 {
    background: #ffffff;
    border-radius: 50%;
    width: 4px;
    height: 4px;
    position: absolute;
    left: 70px;
    top: 57px;
  }
  .ellipse-424 {
    background: #ffffff;
    border-radius: 50%;
    width: 4px;
    height: 4px;
    position: absolute;
    left: 1014px;
    top: 26px;
    filter: blur(2.5px);
  }
  .ellipse-414 {
    background: #ffffff;
    border-radius: 50%;
    width: 4px;
    height: 4px;
    position: absolute;
    left: 1014px;
    top: 26px;
  }
  .ellipse-425 {
    background: #ffffff;
    border-radius: 50%;
    width: 4px;
    height: 4px;
    position: absolute;
    left: 881px;
    top: 63px;
    filter: blur(2.5px);
  }
  .ellipse-415 {
    background: #ffffff;
    border-radius: 50%;
    width: 4px;
    height: 4px;
    position: absolute;
    left: 881px;
    top: 63px;
  }
  .ellipse-426 {
    background: #ffffff;
    border-radius: 50%;
    width: 4px;
    height: 4px;
    position: absolute;
    left: 1388px;
    top: 63px;
    filter: blur(2.5px);
  }
  .ellipse-416 {
    background: #ffffff;
    border-radius: 50%;
    width: 4px;
    height: 4px;
    position: absolute;
    left: 1388px;
    top: 63px;
  }
  .ellipse-427 {
    background: #ffffff;
    border-radius: 50%;
    width: 4px;
    height: 4px;
    position: absolute;
    left: 675px;
    top: 30px;
    filter: blur(2.5px);
  }
  .ellipse-417 {
    background: #ffffff;
    border-radius: 50%;
    width: 4px;
    height: 4px;
    position: absolute;
    left: 675px;
    top: 30px;
  }
  .ellipse-428 {
    background: #ffffff;
    border-radius: 50%;
    width: 4px;
    height: 4px;
    position: absolute;
    left: 961px;
    top: 211px;
    filter: blur(2.5px);
  }
  .ellipse-418 {
    background: #ffffff;
    border-radius: 50%;
    width: 4px;
    height: 4px;
    position: absolute;
    left: 961px;
    top: 211px;
  }
  .ellipse-429 {
    background: #ffffff;
    border-radius: 50%;
    width: 4px;
    height: 4px;
    position: absolute;
    left: 1118px;
    top: 406px;
    filter: blur(2.5px);
  }
  .ellipse-419 {
    background: #ffffff;
    border-radius: 50%;
    width: 4px;
    height: 4px;
    position: absolute;
    left: 1118px;
    top: 406px;
  }
  .ellipse-4210 {
    background: #ffffff;
    border-radius: 50%;
    width: 4px;
    height: 4px;
    position: absolute;
    left: 1297px;
    top: 738px;
    filter: blur(2.5px);
  }
  .ellipse-4110 {
    background: #ffffff;
    border-radius: 50%;
    width: 4px;
    height: 4px;
    position: absolute;
    left: 1297px;
    top: 738px;
  }
  .ellipse-4211 {
    background: #ffffff;
    border-radius: 50%;
    width: 4px;
    height: 4px;
    position: absolute;
    left: 1016px;
    top: 766px;
    filter: blur(2.5px);
  }
  .ellipse-4111 {
    background: #ffffff;
    border-radius: 50%;
    width: 4px;
    height: 4px;
    position: absolute;
    left: 1016px;
    top: 766px;
  }
  .ellipse-4212 {
    background: #ffffff;
    border-radius: 50%;
    width: 4px;
    height: 4px;
    position: absolute;
    left: 1310px;
    top: 450px;
    filter: blur(2.5px);
  }
  .ellipse-4112 {
    background: #ffffff;
    border-radius: 50%;
    width: 4px;
    height: 4px;
    position: absolute;
    left: 1310px;
    top: 450px;
  }
  .ellipse-4213 {
    background: #ffffff;
    border-radius: 50%;
    width: 4px;
    height: 4px;
    position: absolute;
    left: 1392px;
    top: 662px;
    filter: blur(2.5px);
  }
  .ellipse-4113 {
    background: #ffffff;
    border-radius: 50%;
    width: 4px;
    height: 4px;
    position: absolute;
    left: 1392px;
    top: 662px;
  }
  .ellipse-4214 {
    background: #ffffff;
    border-radius: 50%;
    width: 4px;
    height: 4px;
    position: absolute;
    left: 1398px;
    top: 316px;
    filter: blur(2.5px);
  }
  .ellipse-4114 {
    background: #ffffff;
    border-radius: 50%;
    width: 4px;
    height: 4px;
    position: absolute;
    left: 1398px;
    top: 316px;
  }
  .ellipse-4215 {
    background: #ffffff;
    border-radius: 50%;
    width: 4px;
    height: 4px;
    position: absolute;
    left: 632px;
    top: 464px;
    filter: blur(2.5px);
  }
  .ellipse-4115 {
    background: #ffffff;
    border-radius: 50%;
    width: 4px;
    height: 4px;
    position: absolute;
    left: 632px;
    top: 464px;
  }
  .ellipse-4216 {
    background: #ffffff;
    border-radius: 50%;
    width: 4px;
    height: 4px;
    position: absolute;
    left: 803px;
    top: 450px;
    filter: blur(2.5px);
  }
  .ellipse-4116 {
    background: #ffffff;
    border-radius: 50%;
    width: 4px;
    height: 4px;
    position: absolute;
    left: 803px;
    top: 450px;
  }
  .ellipse-4217 {
    background: #ffffff;
    border-radius: 50%;
    width: 4px;
    height: 4px;
    position: absolute;
    left: 941px;
    top: 584px;
    filter: blur(2.5px);
  }
  .ellipse-4117 {
    background: #ffffff;
    border-radius: 50%;
    width: 4px;
    height: 4px;
    position: absolute;
    left: 941px;
    top: 584px;
  }
  .ellipse-4218 {
    background: #ffffff;
    border-radius: 50%;
    width: 4px;
    height: 4px;
    position: absolute;
    left: 1398px;
    top: 505px;
    filter: blur(2.5px);
  }
  .ellipse-4118 {
    background: #ffffff;
    border-radius: 50%;
    width: 4px;
    height: 4px;
    position: absolute;
    left: 1398px;
    top: 505px;
  }
  .ellipse-4219 {
    background: #ffffff;
    border-radius: 50%;
    width: 4px;
    height: 4px;
    position: absolute;
    left: 1176px;
    top: 472px;
    filter: blur(2.5px);
  }
  .ellipse-4119 {
    background: #ffffff;
    border-radius: 50%;
    width: 4px;
    height: 4px;
    position: absolute;
    left: 1176px;
    top: 472px;
  }
  .ellipse-4220 {
    background: #ffffff;
    border-radius: 50%;
    width: 4px;
    height: 4px;
    position: absolute;
    left: 353px;
    top: 28px;
    filter: blur(2.5px);
  }
  .ellipse-4120 {
    background: #ffffff;
    border-radius: 50%;
    width: 4px;
    height: 4px;
    position: absolute;
    left: 353px;
    top: 28px;
  }
  .ellipse-4221 {
    background: #ffffff;
    border-radius: 50%;
    width: 4px;
    height: 4px;
    position: absolute;
    left: 41px;
    top: 204px;
    filter: blur(2.5px);
  }
  .ellipse-4121 {
    background: #ffffff;
    border-radius: 50%;
    width: 4px;
    height: 4px;
    position: absolute;
    left: 41px;
    top: 204px;
  }
  .ellipse-4222 {
    background: #ffffff;
    border-radius: 50%;
    width: 4px;
    height: 4px;
    position: absolute;
    left: 885px;
    top: 301px;
    filter: blur(2.5px);
  }
  .ellipse-4122 {
    background: #ffffff;
    border-radius: 50%;
    width: 4px;
    height: 4px;
    position: absolute;
    left: 885px;
    top: 301px;
  }
  .ellipse-4223 {
    background: #ffffff;
    border-radius: 50%;
    width: 4px;
    height: 4px;
    position: absolute;
    left: 506px;
    top: 205px;
    filter: blur(2.5px);
  }
  .ellipse-4123 {
    background: #ffffff;
    border-radius: 50%;
    width: 4px;
    height: 4px;
    position: absolute;
    left: 506px;
    top: 205px;
  }
  .ellipse-4224 {
    background: #ffffff;
    border-radius: 50%;
    width: 4px;
    height: 4px;
    position: absolute;
    left: 572px;
    top: 307px;
    filter: blur(2.5px);
  }
  .ellipse-4124 {
    background: #ffffff;
    border-radius: 50%;
    width: 4px;
    height: 4px;
    position: absolute;
    left: 572px;
    top: 307px;
  }
  .ellipse-4225 {
    background: #ffffff;
    border-radius: 50%;
    width: 4px;
    height: 4px;
    position: absolute;
    left: 792px;
    top: 728px;
    filter: blur(2.5px);
  }
  .ellipse-4125 {
    background: #ffffff;
    border-radius: 50%;
    width: 4px;
    height: 4px;
    position: absolute;
    left: 792px;
    top: 728px;
  }
  .ellipse-4226 {
    background: #ffffff;
    border-radius: 50%;
    width: 4px;
    height: 4px;
    position: absolute;
    left: 521px;
    top: 520px;
    filter: blur(2.5px);
  }
  .ellipse-4126 {
    background: #ffffff;
    border-radius: 50%;
    width: 4px;
    height: 4px;
    position: absolute;
    left: 521px;
    top: 520px;
  }
  .ellipse-4227 {
    background: #ffffff;
    border-radius: 50%;
    width: 4px;
    height: 4px;
    position: absolute;
    left: 562px;
    top: 680px;
    filter: blur(2.5px);
  }
  .ellipse-4127 {
    background: #ffffff;
    border-radius: 50%;
    width: 4px;
    height: 4px;
    position: absolute;
    left: 562px;
    top: 680px;
  }
  .ellipse-4228 {
    background: #ffffff;
    border-radius: 50%;
    width: 4px;
    height: 4px;
    position: absolute;
    left: 287px;
    top: 734px;
    filter: blur(2.5px);
  }
  .ellipse-4128 {
    background: #ffffff;
    border-radius: 50%;
    width: 4px;
    height: 4px;
    position: absolute;
    left: 287px;
    top: 734px;
  }
  .ellipse-4229 {
    background: #ffffff;
    border-radius: 50%;
    width: 4px;
    height: 4px;
    position: absolute;
    left: 41px;
    top: 764px;
    filter: blur(2.5px);
  }
  .ellipse-4129 {
    background: #ffffff;
    border-radius: 50%;
    width: 4px;
    height: 4px;
    position: absolute;
    left: 41px;
    top: 764px;
  }
  .ellipse-4230 {
    background: #ffffff;
    border-radius: 50%;
    width: 4px;
    height: 4px;
    position: absolute;
    left: 323px;
    top: 312px;
    filter: blur(2.5px);
  }
  .ellipse-4130 {
    background: #ffffff;
    border-radius: 50%;
    width: 4px;
    height: 4px;
    position: absolute;
    left: 323px;
    top: 312px;
  }
  .ellipse-4231 {
    background: #ffffff;
    border-radius: 50%;
    width: 4px;
    height: 4px;
    position: absolute;
    left: 99px;
    top: 519px;
    filter: blur(2.5px);
  }
  .ellipse-4131 {
    background: #ffffff;
    border-radius: 50%;
    width: 4px;
    height: 4px;
    position: absolute;
    left: 99px;
    top: 519px;
  }
  .ellipse-4232 {
    background: #ffffff;
    border-radius: 50%;
    width: 4px;
    height: 4px;
    position: absolute;
    left: 153px;
    top: 645px;
    filter: blur(2.5px);
  }
  .ellipse-4132 {
    background: #ffffff;
    border-radius: 50%;
    width: 4px;
    height: 4px;
    position: absolute;
    left: 153px;
    top: 645px;
  }
  .other-side {
    position: absolute;
    inset: 0;
  }
  .fog-1 {
    position: absolute;
    left: 956px;
    top: 112.13px;
    overflow: visible;
  }
  .earth {
    width: 153px;
    height: 152px;
    position: absolute;
    left: 1063.98px;
    top: 574px;
    overflow: hidden;
  }
  .vector {
    position: absolute;
    left: 0px;
    top: 0px;
    overflow: visible;
  }
  .group {
    position: absolute;
    inset: 0;
  }
  .vector2 {
    position: absolute;
    left: 21.11px;
    top: 21.19px;
    overflow: visible;
  }
  .group2 {
    opacity: 0.75;
    position: absolute;
    left: 31.14px;
    top: 53.53px;
    overflow: visible;
  }
  .group3 {
    position: absolute;
    left: 21.19px;
    top: 21.19px;
    overflow: visible;
  }
  .vector18 {
    opacity: 0.44999998807907104;
    position: absolute;
    left: 21.19px;
    top: 57.24px;
    overflow: visible;
  }
  .vector19 {
    opacity: 0.44999998807907104;
    position: absolute;
    left: 28.03px;
    top: 70.24px;
    overflow: visible;
  }
  .vector20 {
    position: absolute;
    left: 41.6px;
    top: 21.2px;
    overflow: visible;
  }
  .astronaut {
    width: 215.14px;
    height: 268.16px;
    position: static;
  }
  .group-77 {
    width: 160.26px;
    height: 150.44px;
    position: static;
  }
  .rectangle-335 {
    background: #686767;
    border-radius: 15px;
    width: 160.25px;
    height: 150.43px;
    position: absolute;
    left: 1217.76px;
    top: 68.91px;
    transform-origin: 0 0;
    transform: rotate(33.301deg) scale(1, 1);
  }
  .rectangle-336 {
    background: #595959;
    width: 160.25px;
    height: 3.27px;
    position: absolute;
    left: 1195.31px;
    top: 103.08px;
    transform-origin: 0 0;
    transform: rotate(33.301deg) scale(1, 1);
  }
  .rectangle-337 {
    background: #595959;
    width: 160.25px;
    height: 3.27px;
    position: absolute;
    left: 1186.33px;
    top: 116.74px;
    transform-origin: 0 0;
    transform: rotate(33.301deg) scale(1, 1);
  }
  .ellipse-35 {
    background: #7d7d7d;
    border-radius: 50%;
    width: 6.54px;
    height: 6.54px;
    position: absolute;
    left: 1207.49px;
    top: 93.47px;
    transform-origin: 0 0;
    transform: rotate(33.301deg) scale(1, 1);
  }
  .ellipse-36 {
    background: #7d7d7d;
    border-radius: 50%;
    width: 6.54px;
    height: 6.54px;
    position: absolute;
    left: 1325.03px;
    top: 170.68px;
    transform-origin: 0 0;
    transform: rotate(33.301deg) scale(1, 1);
  }
  .ellipse-37 {
    background: #7d7d7d;
    border-radius: 50%;
    width: 6.54px;
    height: 6.54px;
    position: absolute;
    left: 1186.84px;
    top: 124.9px;
    transform-origin: 0 0;
    transform: rotate(33.301deg) scale(1, 1);
  }
  .ellipse-38 {
    background: #7d7d7d;
    border-radius: 50%;
    width: 6.54px;
    height: 6.54px;
    position: absolute;
    left: 1304.38px;
    top: 202.11px;
    transform-origin: 0 0;
    transform: rotate(33.301deg) scale(1, 1);
  }
  .group-78 {
    width: 32.7px;
    height: 86.66px;
    position: static;
  }
  .rectangle-338 {
    background: #d6d6d6;
    border-radius: 10px;
    width: 31.07px;
    height: 83.39px;
    position: absolute;
    left: 1263.44px;
    top: 188.84px;
    transform-origin: 0 0;
    transform: rotate(-2.114deg) scale(1, 1);
  }
  .ellipse-40 {
    background: radial-gradient(
      closest-side,
      rgba(255, 255, 255, 1) 0%,
      rgba(221, 221, 221, 1) 100%
    );
    border-radius: 50%;
    width: 21.26px;
    height: 21.26px;
    position: absolute;
    left: 1277.29px;
    top: 253.77px;
    transform-origin: 0 0;
    transform: rotate(-2.114deg) scale(1, 1);
  }
  .group-79 {
    width: 32.21px;
    height: 85.81px;
    position: static;
  }
  .rectangle-3382 {
    background: #d6d6d6;
    border-radius: 10px;
    width: 31.07px;
    height: 83.39px;
    position: absolute;
    left: 1213.3px;
    top: 158.13px;
    transform-origin: 0 0;
    transform: rotate(-72.886deg) scale(-1, 1);
  }
  .ellipse-402 {
    background: radial-gradient(
      closest-side,
      rgba(255, 255, 255, 1) 0%,
      rgba(221, 221, 221, 1) 100%
    );
    border-radius: 50%;
    width: 21.26px;
    height: 21.26px;
    position: absolute;
    left: 1148.38px;
    top: 166.66px;
    transform-origin: 0 0;
    transform: rotate(-72.886deg) scale(-1, 1);
  }
  .group-75 {
    width: 129.18px;
    height: 268.16px;
    position: static;
  }
  .rectangle-334 {
    border-radius: 0px;
    position: absolute;
    left: 1165.12px;
    top: 211.4px;
    transform: translate(-41.04px, 0px);
    overflow: visible;
  }
  .rectangle-328 {
    background: #e1e1e1;
    width: 106.29px;
    height: 103.01px;
    position: absolute;
    left: 1210.32px;
    top: 121.75px;
    transform-origin: 0 0;
    transform: rotate(33.301deg) scale(1, 1);
  }
  .rectangle-330 {
    background: #e1e1e1;
    width: 99.75px;
    height: 29.43px;
    position: absolute;
    left: 1165.47px;
    top: 195.97px;
    transform-origin: 0 0;
    transform: rotate(33.301deg) scale(1, 1);
  }
  .rectangle-331 {
    background: linear-gradient(
      180deg,
      rgba(59, 176, 148, 1) 0%,
      rgba(35, 32, 48, 1) 100%
    );
    width: 8.18px;
    height: 103.01px;
    position: absolute;
    left: 1223.98px;
    top: 130.72px;
    transform-origin: 0 0;
    transform: rotate(33.301deg) scale(1, 1);
  }
  .rectangle-332 {
    background: linear-gradient(
      180deg,
      rgba(59, 176, 148, 1) 0%,
      rgba(35, 32, 48, 1) 100%
    );
    width: 8.18px;
    height: 103.01px;
    position: absolute;
    left: 1275.92px;
    top: 164.84px;
    transform-origin: 0 0;
    transform: rotate(33.301deg) scale(1, 1);
  }
  .rectangle-329 {
    background: #9a9a9a;
    width: 106.29px;
    height: 3.27px;
    position: absolute;
    left: 1155.55px;
    top: 205.11px;
    transform-origin: 0 0;
    transform: rotate(33.301deg) scale(1, 1);
  }
  .group-74 {
    width: 129.18px;
    height: 129.18px;
    position: static;
  }
  .rectangle-322 {
    background: #d6d6d6;
    border-radius: 50px;
    width: 11.45px;
    height: 26.16px;
    position: absolute;
    left: 1321.6px;
    top: 145.94px;
    transform-origin: 0 0;
    transform: rotate(33.301deg) scale(1, 1);
  }
  .rectangle-323 {
    background: #d6d6d6;
    border-radius: 50px;
    width: 11.45px;
    height: 26.16px;
    position: absolute;
    left: 1223.19px;
    top: 81.3px;
    transform-origin: 0 0;
    transform: rotate(33.301deg) scale(1, 1);
  }
  .ellipse-27 {
    background: #d6d6d6;
    border-radius: 50%;
    border-style: solid;
    border-color: #e1e1e1;
    border-width: 0.5px;
    width: 117.74px;
    height: 117.73px;
    position: absolute;
    left: 1252.43px;
    top: 45.72px;
    transform-origin: 0 0;
    transform: rotate(33.301deg) scale(1, 1);
  }
  .subtract {
    position: absolute;
    left: 1219.22px;
    top: 96.29px;
    transform: translate(-11.47px, 0.05px);
    overflow: visible;
  }
  .subtract2 {
    position: absolute;
    left: 1215.62px;
    top: 101.75px;
    transform: translate(-11.47px, 0.05px);
    overflow: visible;
  }
  .vector-188 {
    position: absolute;
    left: 1241.56px;
    top: 71.22px;
    transform: translate(-26.17px, 7.68px);
    overflow: visible;
  }
  .rectangle-324 {
    background: #c4c4c4;
    border-radius: 10px;
    width: 3.27px;
    height: 1.64px;
    position: absolute;
    left: 1252.48px;
    top: 191.25px;
    transform-origin: 0 0;
    transform: rotate(13.301deg) scale(1, 1);
  }
  .rectangle-325 {
    background: #c4c4c4;
    border-radius: 10px;
    width: 1.64px;
    height: 1.64px;
    position: absolute;
    left: 1259.74px;
    top: 192.11px;
    transform-origin: 0 0;
    transform: rotate(13.301deg) scale(1, 1);
  }
  .mask-group {
    position: absolute;
    left: 1241.56px;
    top: 71.21px;
    transform: translate(-46.14px, 0px);
    overflow: visible;
  }
  .ellipse-30 {
    background: rgba(130, 130, 130, 0.5);
    border-radius: 50%;
    width: 21.26px;
    height: 21.26px;
    position: absolute;
    left: 1243.19px;
    top: 86.6px;
    transform-origin: 0 0;
    transform: rotate(33.301deg) scale(1, 1);
  }
  .ellipse-31 {
    background: rgba(130, 130, 130, 0.5);
    border-radius: 50%;
    width: 11.45px;
    height: 11.45px;
    position: absolute;
    left: 1256.85px;
    top: 95.58px;
    transform-origin: 0 0;
    transform: rotate(33.301deg) scale(1, 1);
  }
  .ellipse-32 {
    background: rgba(130, 130, 130, 0.5);
    border-radius: 50%;
    width: 8.18px;
    height: 8.18px;
    position: absolute;
    left: 1234.21px;
    top: 100.27px;
    transform-origin: 0 0;
    transform: rotate(33.301deg) scale(1, 1);
  }
  .ellipse-33 {
    background: #bc0d0d;
    border-radius: 50%;
    width: 8.18px;
    height: 8.18px;
    position: absolute;
    left: 1264.85px;
    top: 71.49px;
    transform-origin: 0 0;
    transform: rotate(33.301deg) scale(1, 1);
  }
  .ellipse-34 {
    background: #bc0d0d;
    border-radius: 50%;
    width: 8.18px;
    height: 8.18px;
    position: absolute;
    left: 1283.48px;
    top: 75.9px;
    transform-origin: 0 0;
    transform: rotate(33.301deg) scale(1, 1);
  }
  .rectangle-333 {
    background: #273ae7;
    border-radius: 5px;
    width: 44.15px;
    height: 22.89px;
    position: absolute;
    left: 1206.19px;
    top: 181.64px;
    transform-origin: 0 0;
    transform: rotate(33.301deg) scale(1, 1);
  }
  .vector-190 {
    position: absolute;
    left: 1216.69px;
    top: 192.45px;
    transform: translate(-9.06px, 6.69px);
    overflow: visible;
  }
  .one-side {
    position: absolute;
    inset: 0;
  }
  .moon {
    width: 832px;
    height: 832px;
    position: absolute;
    left: -290px;
    top: -210px;
    overflow: hidden;
  }
  .vector21 {
    position: absolute;
    left: 0px;
    top: 0px;
    overflow: visible;
  }
  .vector22 {
    position: absolute;
    left: 94.66px;
    top: 93.61px;
    overflow: visible;
  }
  .group4 {
    position: absolute;
    left: 95.44px;
    top: 130.43px;
    overflow: visible;
  }
  .group5 {
    position: absolute;
    left: 132.9px;
    top: 217.95px;
    overflow: visible;
  }
  .group6 {
    position: absolute;
    left: 95.44px;
    top: 130.43px;
    overflow: visible;
  }
  .vector65 {
    opacity: 0.44999998807907104;
    position: absolute;
    left: 94.66px;
    top: 181.91px;
    overflow: visible;
  }
  .vector66 {
    position: absolute;
    left: 287.58px;
    top: 93.61px;
    overflow: visible;
  }
  .vector-191 {
    position: absolute;
    left: 20.12px;
    top: 69.52px;
    overflow: visible;
  }
`;
