import { FC, useEffect, useMemo } from 'react';
import { Modal } from './styles';
import { useSubmissionDialog } from './dialog-hook';
import type { DetailedManagerSubmission } from '@/types';
import { useAudit } from '@/hooks';
import Completion from './Completion';
import ApprovalButtons from '../ApprovalButtons';

type Args = {
  onClose: () => void;
  onRefresh?: () => void;
  disableApproval?: boolean;
};

const SubmissionDialog: FC<Args> = ({
  onClose,
  onRefresh,
  disableApproval = false,
}) => {
  const submissionDialog = useSubmissionDialog();
  const audit = useAudit({
    resourceName: 'manager-submission-approval-modal',
  });

  const showApprovalButtons = useMemo(() => {
    return (
      !disableApproval &&
      submissionDialog.state.completion?.form.status === 'pending'
    );
  }, [submissionDialog.state.completion?.form.status, disableApproval]);

  function handleGoToNextCompletion() {
    const currentCompletionIndex =
      submissionDialog.state.currentPageCompletionIds.findIndex(
        (id) => id === submissionDialog.state.completion?.form._id,
      );
    if (currentCompletionIndex === -1) {
      onClose();
      return;
    }

    const nextCompletionId =
      submissionDialog.state.currentPageCompletionIds[
        currentCompletionIndex + 1
      ];
    // If the current completion is the last one, do nothing
    if (!nextCompletionId) {
      onClose();
      return;
    }

    submissionDialog.openDialog({ id: nextCompletionId });
  }

  useEffect(() => {
    if (submissionDialog.state.open) {
      audit.onDetail({
        target: submissionDialog.state.completion?.form._id,
        data: {
          completionId: submissionDialog.state.completion?.form._id,
        },
      });
    }
  }, [
    audit,
    submissionDialog.state.completion?.form._id,
    submissionDialog.state.open,
  ]);

  return (
    <Modal
      title="Manager Submission Details Modal"
      centered
      open={submissionDialog.state.open}
      width="900px"
      onCancel={onClose}
      destroyOnClose
      footer={
        showApprovalButtons && submissionDialog.state.completion ? (
          <ApprovalButtons
            completionsId={[submissionDialog.state.completion.form._id]}
            onCompleted={() => {
              // The order here is important
              handleGoToNextCompletion();
              onRefresh?.();
            }}
          />
        ) : (
          <></>
        )
      }
    >
      <Completion
        completion={
          submissionDialog.state.completion as DetailedManagerSubmission
        }
      />
    </Modal>
  );
};

export default SubmissionDialog;
