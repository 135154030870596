import styled from 'styled-components';

export const Container = styled.div`
  background-image: url(${require(`../../../static/images/forbidden-bg.jpeg`)});
  background-size: cover;
  background-position: 50% 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  .container {
    background: rgba(255, 255, 255, 0.8);
    padding: 1em 2em;
    font-size: 1.5em;
    text-align: center;
    margin-top: 30vh;

    h1 {
      color: #e60000;
      font-weight: 700;
    }
  }
`;
