import { FC, useEffect, useState } from 'react';
import { ConsumerCompletion } from '@/types';
import { Container } from './styles';
import { Button, Collapse, Form, Input, notification, Tabs } from 'antd';
import { Case, Default, Switch, When } from 'react-if';
import { saveCampaignApprovalNote } from '@/services/consumer.service';
import PremiumReview from './PremiumReview';
import Overview from './Overview';
import Answers from './Answers';
import ProductFeedback from './ProductFeedback';
import Comments from './Comments';
import { useConsumerSubmissionDialog } from '../dialog-hook';

type Args = {
  completion: ConsumerCompletion;
};

const Completion: FC<Args> = ({ completion }) => {
  const [noteForm] = Form.useForm();
  const [noteActive, setNoteActive] = useState(true);
  const submissionDialog = useConsumerSubmissionDialog();

  useEffect(() => {
    setNoteActive(!completion.bundle.approvalNote);
    noteForm.setFieldValue('approvalNote', completion.bundle.approvalNote);
  }, []);

  if (!completion) {
    return null;
  }

  const elements = [
    {
      label: `Overview`,
      key: 'overview',
      children: <Overview completion={completion} />,
    },
    {
      label: `Premium Review`,
      key: 'premium-review',
      children: <PremiumReview completion={completion} />,
    },
    {
      label: `Answers`,
      key: 'answers',
      children: <Answers completion={completion} />,
    },
    {
      label: `Product Feedback`,
      key: 'product-feedback',
      children: <ProductFeedback completion={completion} />,
      showArrow: false,
    },
    submissionDialog.state.mode !== 'compare'
      ? {
          label: `Comments`,
          key: 'internal-comments',
          children: <Comments completionId={completion.id} />,
        }
      : null,
  ].filter(Boolean) as any[];

  return (
    <Container>
      <When condition={submissionDialog.state.mode !== 'compare'}>
        <Form
          form={noteForm}
          onFinish={async (values) => {
            await saveCampaignApprovalNote(
              completion.bundleId as string,
              values.approvalNote,
            );

            notification.success({
              message: 'Note saved!',
            });

            setNoteActive(false);
          }}
          className="campaign-note-form"
        >
          <div className="header">
            <span className="campaign-name">{completion.bundle?.name}</span>
            <When condition={noteActive}>
              <Button type="primary" htmlType="submit">
                {!completion.bundle?.approvalNote ? 'Add' : 'Save'} Campaign
                Note
              </Button>
            </When>
            <When condition={!noteActive}>
              <Button type="primary" onClick={() => setNoteActive(true)}>
                Edit Campaign Note
              </Button>
            </When>
          </div>

          <Form.Item
            name="approvalNote"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input.TextArea
              autoSize={{ minRows: 4, maxRows: 10 }}
              readOnly={!noteActive}
              bordered={noteActive}
            />
          </Form.Item>
        </Form>
      </When>

      <Switch>
        <Case condition={submissionDialog.state.mode === 'compare'}>
          <Collapse
            defaultActiveKey={elements.map((element) => element.key)}
            size="small"
            bordered={true}
            accordion={false}
            destroyInactivePanel
            items={elements}
          />
        </Case>
        <Default>
          <Tabs
            defaultActiveKey="overview"
            type="card"
            size={'small'}
            className={'content-tabs'}
            destroyInactiveTabPane
            items={elements}
          />
        </Default>
      </Switch>
    </Container>
  );
};

export default Completion;
